import React from 'react'

import { Image } from '../components/Image/Image'

export const ToastLogo = () => {
  return (
    <Image
      path={'icons/toast-logo-white-with-text.svg'}
      alt={'Toast'}
      testId={'ToastLogo'}
    />
  )
}
