import React, { useEffect, useMemo } from 'react'
import { Field, FormikHelpers } from 'formik'
import cx from 'classnames'
import { LinkButton } from '@toasttab/buffet-pui-buttons'
import { AddCircleIcon } from '@toasttab/buffet-pui-icons'
import { FieldLabel } from '@/il8n/en'

import {
  CardType,
  RestaurantCreditCardConfig
} from '../../../../apollo/generated/OptWebGraphQLOperations'
import {
  PaymentMethod,
  SavedCreditCardPaymentMethod
} from '../../../../hooks/place-order/use-available-payment-methods'
import { CheckoutMode } from '../../checkout-modes'
import {
  getDefaultPaymentMethodGuid,
  PaymentInfoPaymentFormValues
} from '../getInitialValues'
import { SaveNewCardAndAccount } from '../../SaveNewCardAndAccount/SaveNewCardAndAccount'
import { CreditCard } from '../../../CreditCard/CreditCard'
import { SavedPaymentMethodField } from '../../../SavedPaymentMethodField'
import { PaymentFormKeys } from '../../types/types'
import { SaveNewCardCheckbox } from '../../../SaveNewCardCheckbox/SaveNewCardCheckbox'
import { PaymentType, SelectedPayment } from '../constants'
import { PaymentMethodTabs } from '../../../PaymentMethodTabs/PaymentMethodTabs'
import { Notification } from '../../../Notification/Notification'
import Radio from '../../../Form/Radio/Radio'
import { useFlag } from '../../../FeatureFlag/use-flag'
import { LDFlags } from '../../../../launchdarkly/flags'
import { SPIAnchor } from '../../../SPI/SPIAnchor'
import { useGetSpiSdk } from '../../../../hooks/SPI/useSpiSdk'
import { SpiPaymentType } from '../../../../types/SPI'
import { LazySpiAnchor } from '../../../SPI/LazySpiAnchor'
import { GuestCurrencyToggle } from '../../GuestCurrency/GuestCurrencyToggle'
import { useGiftCard } from '../../../GiftCardProvider/GiftCardProvider'
import styles from './PaymentInfoComponent.module.css'

type PaymentInfoFormikHelpers = FormikHelpers<PaymentInfoPaymentFormValues>
export interface PaymentInfoComponentV2Props {
  paymentMethods: PaymentMethod[]
  creditCardConfig: RestaurantCreditCardConfig
  mode: CheckoutMode
  giftCardHasSufficientFunds: boolean
  authenticated: boolean
  values: PaymentInfoPaymentFormValues
  setFieldValue: PaymentInfoFormikHelpers['setFieldValue']
  collapseTabsSinglePaymentMethod?: boolean
  payAmount?: number
  shouldUseSpi?: boolean
  spiLoadedFailure: boolean
  setSpiLoadedFailure: (value: boolean) => void
  renderSpi?: boolean
  isSpiInitialized: boolean
  setIsSpiInitialized: (value: boolean) => void
}

const newCreditCardSavedCardGuid = 'NewCreditCard'

export const ComponentV2: React.FC<PaymentInfoComponentV2Props> = ({
  authenticated,
  creditCardConfig,
  paymentMethods,
  values: { paymentType, encryptedCard, savedCardGuid, spiPaymentType },
  mode,
  setFieldValue,
  giftCardHasSufficientFunds,
  collapseTabsSinglePaymentMethod = false,
  shouldUseSpi,
  setSpiLoadedFailure,
  spiLoadedFailure,
  renderSpi,
  isSpiInitialized,
  setIsSpiInitialized
}) => {
  const defaultPaymentGuid = getDefaultPaymentMethodGuid(paymentMethods)
  const shouldDisableCreateAccount = useFlag(LDFlags.OPT_DISABLE_CREATE_ACCOUNT)
  const incrementalAuthEnabled = useFlag(LDFlags.INCREMENTAL_AUTH)
  const spiSdk = useGetSpiSdk()

  const onInit = () => {
    setIsSpiInitialized(true)
  }

  const ref = React.createRef<HTMLDivElement>()

  useEffect(() => {
    // The conditions are:
    //  * user navigates to the 'credit card' tab and has a primary card saved. If so, select that card automatically
    //  * user navigates to the 'credit card' tab and does not have a primary card saved. Automatically select new card.
    //  * user navigates to the 'Apple Pay' tab. Automatically select apple pay.
    //  * user navigates to the 'Click To Pay' tab. Automatically select click to pay.
    if (paymentType === PaymentType.CREDIT_CARD) {
      if (
        defaultPaymentGuid !== undefined &&
        defaultPaymentGuid !== 'ApplePay'
      ) {
        setFieldValue(PaymentFormKeys.SAVED_CARD_GUID, defaultPaymentGuid)
      } else {
        setFieldValue(
          PaymentFormKeys.SAVED_CARD_GUID,
          newCreditCardSavedCardGuid
        )
      }
    } else if (paymentType === PaymentType.CLICK_TO_PAY) {
      setFieldValue(PaymentFormKeys.SAVED_CARD_GUID, 'ClickToPay')
      setFieldValue('spiPayment', false)
    } else {
      setFieldValue(PaymentFormKeys.SAVED_CARD_GUID, 'ApplePay')
    }
  }, [paymentType, setFieldValue, defaultPaymentGuid])

  useEffect(() => {
    if (shouldUseSpi && spiSdk && paymentType !== PaymentType.CREDIT_CARD) {
      try {
        spiSdk.clearPaymentSelection()
        // eslint-disable-next-line no-empty
      } catch (e) {}
    }
  }, [paymentType, spiSdk, shouldUseSpi])

  useEffect(() => {
    if (giftCardHasSufficientFunds) {
      // if has sufficient funds, clear using saved card method
      setFieldValue(PaymentFormKeys.SAVED_CARD_GUID, '')
      // And ensure that button treatments based on type cleared
      setFieldValue(PaymentFormKeys.PAYMENT_TYPE, PaymentType.CREDIT_CARD)
    } else {
      setFieldValue(PaymentFormKeys.SAVED_CARD_GUID, defaultPaymentGuid)
    }
  }, [giftCardHasSufficientFunds, setFieldValue, defaultPaymentGuid])

  const savedCCPaymentMethods: SavedCreditCardPaymentMethod[] = useMemo(() => {
    return paymentMethods.flatMap((pm) => {
      if (
        pm.type === 'SavedCreditCard' &&
        pm.paymentMethodDetails &&
        pm.paymentMethodDetails.expirationMonth &&
        pm.paymentMethodDetails.expirationYear
      ) {
        return [pm]
      }
      return []
    })
  }, [paymentMethods])

  useEffect(() => {
    setFieldValue(
      'paymentType',
      defaultPaymentGuid === 'ApplePay'
        ? PaymentType.APPLE_PAY
        : PaymentType.CREDIT_CARD
    )
    setFieldValue('savedCardGuid', defaultPaymentGuid)
  }, [setFieldValue, defaultPaymentGuid])

  const guestExpressMicrospasEnabled = useFlag(LDFlags.GUEST_EXPRESS_MICROSPAS)

  useEffect(() => {
    if (savedCardGuid === newCreditCardSavedCardGuid) {
      setFieldValue('selectedPayment', SelectedPayment.NEW_CARD)
    } else if (savedCardGuid === 'ApplePay') {
      setFieldValue('selectedPayment', SelectedPayment.APPLE_PAY)
    } else if (savedCardGuid) {
      // the reason we are checking for length, is that there is a brief moment where 'savedCardGuid' is undefined
      setFieldValue('selectedPayment', SelectedPayment.SAVED_CARD)
    }
  }, [savedCardGuid, setFieldValue])

  const savedPaymentFields = useMemo(() => {
    const orderedSavedPaymentFields = savedCCPaymentMethods.map((pm) => (
      <SavedPaymentMethodField
        key={pm.guid}
        fieldName={PaymentFormKeys.SAVED_CARD_GUID}
        paymentMethod={pm}
      />
    ))

    return shouldUseSpi ? [] : [...orderedSavedPaymentFields]
  }, [savedCCPaymentMethods, shouldUseSpi])

  const isAmexAndNotAccepted = useMemo(() => {
    const currentPaymentMethod = savedCCPaymentMethods.find(
      (pm) => pm.guid === savedCardGuid
    )
    const isAmex =
      currentPaymentMethod?.paymentMethodDetails.cardType === CardType.Amex
    return !creditCardConfig.amexAccepted && isAmex
  }, [creditCardConfig.amexAccepted, savedCCPaymentMethods, savedCardGuid])

  // We should only show the 'add new card' button when:
  // * you do already have a saved card
  // * you are not actively trying to add a new card
  const showAddNewCardButton =
    paymentMethods.some((pm) => pm.type === 'SavedCreditCard') &&
    savedCardGuid !== newCreditCardSavedCardGuid

  const showTabs = paymentMethods.some((pm) => {
    return pm.type === 'ApplePay' || pm.type === 'ClickToPay'
  })

  const isGooglePaySelected =
    spiPaymentType === SpiPaymentType.GOOGLE_PAY &&
    paymentType === 'CREDIT_CARD'

  const isApplePaySelected =
    spiPaymentType === SpiPaymentType.APPLE_PAY && paymentType === 'CREDIT_CARD'

  const showDisclaimer = isGooglePaySelected || isApplePaySelected

  const spiPaymentMethod = isSpiInitialized ? paymentMethods : []
  const renderedPaymentMethods = renderSpi ? spiPaymentMethod : paymentMethods
  const { guestCurrencyAccountSpendEnabled, guestCurrencyAccount } =
    useGiftCard()

  // Boolean will return false for undefined or zero, so this works for both cases
  const hasGuestCurrencyAccountBalance = Boolean(
    guestCurrencyAccount?.expectedAvailableBalance
  )
  const showGuestCurrencyToggle = Boolean(
    guestCurrencyAccountSpendEnabled && hasGuestCurrencyAccountBalance
  )

  return (
    <div>
      {showGuestCurrencyToggle ? <GuestCurrencyToggle /> : null}
      {collapseTabsSinglePaymentMethod && paymentMethods.length === 1 ? null : (
        <div
          // Hide the tabs if there is only one tab available. As of 07/12/22, the only cases are 'Credit Card' or 'Apple Pay'
          // thus hide the tabs if Apple Pay isn't present.
          className={`${showTabs ? 'block' : 'hidden'}`}
        >
          <PaymentMethodTabs
            paymentMethods={renderedPaymentMethods}
            spiActive={Boolean(renderSpi)}
          />
        </div>
      )}
      {/* Saved card fields */}
      {paymentType === PaymentType.CREDIT_CARD && savedPaymentFields}
      {!(renderSpi && !spiLoadedFailure) && (
        <div
          className={cx({
            hidden: paymentType !== PaymentType.CREDIT_CARD && !shouldUseSpi
          })}
        >
          <div className={cx(showAddNewCardButton ? '-ml-4' : 'hidden')}>
            <LinkButton
              iconLeft={<AddCircleIcon />}
              onClick={() =>
                setFieldValue('savedCardGuid', newCreditCardSavedCardGuid)
              }
            >
              Add new card
            </LinkButton>
          </div>
          <div
            className={cx({
              hidden: showAddNewCardButton
            })}
          >
            <div
              className={cx({
                hidden: paymentMethods.every(
                  (pm) => pm.type !== 'SavedCreditCard'
                )
              })}
            >
              <Field
                data-testid='new-credit-card'
                component={Radio}
                id={'NewCard'}
                name={PaymentFormKeys.SAVED_CARD_GUID}
                label={<div className='font-semibold'>New card</div>}
                value={newCreditCardSavedCardGuid}
              />
            </div>
            <Field
              component={CreditCard}
              name={PaymentFormKeys.ENCRYPTED_CARD}
              amexAccepted={creditCardConfig.amexAccepted}
              guestExpressMicrospasEnabled={guestExpressMicrospasEnabled}
            />

            {mode !== CheckoutMode.CREATE_PREAUTH_TAB_MODE && authenticated && (
              <Field
                component={SaveNewCardCheckbox}
                name={PaymentFormKeys.SAVE_CARD}
                disabled={!encryptedCard}
              />
            )}
          </div>
          {!authenticated &&
            savedCardGuid === newCreditCardSavedCardGuid &&
            !shouldDisableCreateAccount && (
              <SaveNewCardAndAccount mode={mode} />
            )}
        </div>
      )}
      {shouldUseSpi && !spiLoadedFailure && (
        <div className={cx(styles.disabledWrapper)}>
          {incrementalAuthEnabled ? (
            <Field
              component={LazySpiAnchor}
              name={PaymentFormKeys.ENCRYPTED_CARD}
              handleSpiFailure={() => setSpiLoadedFailure(true)}
              onInit={onInit}
              ref={ref}
            />
          ) : (
            <Field
              component={SPIAnchor}
              name={PaymentFormKeys.ENCRYPTED_CARD}
              amexAccepted={creditCardConfig.amexAccepted}
              zipRequired={true}
              handleSpiFailure={() => setSpiLoadedFailure(true)}
              onInit={onInit}
            />
          )}
          <div
            className={cx(styles.disabledOverlay, {
              [styles.enabled]: giftCardHasSufficientFunds
            })}
          ></div>
        </div>
      )}
      {isAmexAndNotAccepted && (
        <div className='mt-4'>
          <Notification>
            Unfortunately, this restaurant does not accept American Express.
            Please use another credit card to complete your order.
          </Notification>
        </div>
      )}
      {showDisclaimer && (
        <div className='type-caption text-secondary px-2'>
          {isGooglePaySelected
            ? FieldLabel.GOOGLE_PAY_DISCLAIMER
            : FieldLabel.APPLE_PAY_DISCLAIMER}
        </div>
      )}
    </div>
  )
}
