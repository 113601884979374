import React, { useEffect, useState } from 'react'
import { useSpiSdk } from './useSpi'
import {
  createPaymentIntent,
  PaymentIntent,
  updatePaymentIntent as updatePaymentIntentApi
} from '../../api/payments'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { ErrorComponent, logError } from '@local/do-secundo-error'
import ContentLoader from 'react-content-loader'
import { useDebounce, useEffectOnChange } from '../../hooks/util'

const SPI_IFRAME_ANCHOR_ID = 'spi-frame-anchor'

type Props = {
  cartGuid: string
  amount: number
  tipAmount: number
  email: string
  field: { name: string }
  form: { setFieldValue: (name: string, value: boolean) => void }
}

type UpdateOrCreateParams = {
  amount: number
  tipAmount: number
  email: string
  paymentIntent: PaymentIntent | undefined
}

export const CreditCardFormSpi = ({
  cartGuid,
  amount,
  tipAmount,
  email,
  field,
  form
}: Props) => {
  const spiSdk = useSpiSdk()
  const { restaurantGuid, restaurantInfo } = useRestaurant()

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [spiFormMounted, setSpiFormMounted] = useState(false)
  const [spiFormNeedsUpdate, setSpiFormNeedsUpdate] = useState(false)

  const [paymentIntent, setPaymentIntent] = useState<PaymentIntent>()
  const [paymentFormValid, setPaymentFormValid] = useState(false)

  const setCheckoutPaymentFieldValid = (isValid: boolean) =>
    form.setFieldValue(field.name, isValid)

  useEffect(() => {
    setCheckoutPaymentFieldValid(paymentFormValid)
    //eslint-disable-next-line
  }, [paymentFormValid])

  useEffect(() => {
    // Temporarily disables the submit button while its waiting for the
    // SPI form to be updated due to a change (tip amount, email, etc.)
    if (spiFormNeedsUpdate) {
      setCheckoutPaymentFieldValid(false)
    } else {
      setCheckoutPaymentFieldValid(paymentFormValid)
    }
    //eslint-disable-next-line
  }, [spiFormNeedsUpdate])

  const createOrUpdatePaymentIntent = (params: UpdateOrCreateParams) => {
    if (params.amount > 0) {
      if (!params.paymentIntent) {
        createPaymentIntent(cartGuid, {
          amount: params.amount,
          currency: 'USD',
          email: params.email
        })
          .then((resp) => {
            setPaymentIntent(resp)
            // The payment intent API currently doesn't take in a tip amount
            // in the create request. If the tip is already set due to a
            // restaurant default, we need to kick off an update request.
            if (params.tipAmount > 0) {
              updatePaymentIntent({
                ...params,
                paymentIntent: resp
              })
            }
          })
          .catch((err) => {
            logError('Failed to create payment intent', undefined, err)
            setLoading(false)
            setError(true)
          })
      } else {
        updatePaymentIntent(params)
      }
    }
  }

  const updatePaymentIntent = (params: UpdateOrCreateParams) => {
    if (!params.paymentIntent) return
    updatePaymentIntentApi(cartGuid, params.paymentIntent.paymentIntentId, {
      amount: params.amount,
      tipAmount: params.tipAmount,
      email: params.email,
      oAuthToken: params.paymentIntent.oauthToken
    })
      .then(setPaymentIntent)
      .catch((err) => {
        logError('Failed to update payment intent', undefined, err)
        setLoading(false)
        setError(true)
      })
  }

  const createOrUpdatePaymentIntentDebounced = useDebounce(
    createOrUpdatePaymentIntent,
    1000
  )

  useEffect(() => {
    // Create the payment intent on page mount
    createOrUpdatePaymentIntent({
      amount,
      tipAmount,
      email,
      paymentIntent
    })
    //eslint-disable-next-line
  }, [])

  useEffectOnChange(() => {
    // Update the payment intent when any of these fields change,
    // debounced to account for user typing (email and tip for ex.)
    createOrUpdatePaymentIntentDebounced({
      amount,
      tipAmount,
      email,
      paymentIntent
    })
    setSpiFormNeedsUpdate(true)
    //eslint-disable-next-line
  }, [amount, tipAmount, email])

  useEffect(() => {
    let frame: HTMLIFrameElement | undefined
    if (spiSdk && paymentIntent) {
      if (!spiFormMounted) {
        frame = spiSdk.initialize(
          () => {
            setLoading(false)
            setError(false)
            setSpiFormMounted(true)
            spiSdk.monitor((e) => setPaymentFormValid(e.content.isValid))
          },
          () => {
            logError('Failed to initialize SPI')
            setLoading(false)
            setError(true)
          },
          {
            domElementId: SPI_IFRAME_ANCHOR_ID,
            merchantId: restaurantGuid,
            oauthToken: paymentIntent.oauthToken,
            sessionSecret: paymentIntent.sessionSecret,
            acceptAmex: Boolean(restaurantInfo?.amexAccepted),
            zipRequired: true
          }
        )
      } else {
        spiSdk.fetchUpdates()
      }
      setSpiFormNeedsUpdate(false)
    }

    return () => {
      try {
        frame && document.removeChild(frame)
      } catch (e) {}
    }
    //eslint-disable-next-line
  }, [spiSdk, paymentIntent])

  return (
    <div id={SPI_IFRAME_ANCHOR_ID}>
      {loading && (
        <ContentLoader className='w-full'>
          <rect x='8' y='20%' rx='4' ry='4' width='100%' height='12' />
          <rect x='8' y='40%' rx='4' ry='4' width='100%' height='12' />
          <rect x='8' y='60%' rx='4' ry='4' width='100%' height='12' />
        </ContentLoader>
      )}
      {error && (
        <ErrorComponent
          error='Failed to load payment form. Please try refreshing the page.'
          isActionable={false}
        />
      )}
    </div>
  )
}
