import * as React from 'react'
import cx from 'classnames'
import {
  PortalManagement,
  Overlay,
  FloatingArrow,
  useFloatingComponentZIndexClass,
  FloatingList
} from '@toasttab/buffet-pui-floating-ui-base'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { Label } from '../Label'
import {
  DropdownCommonProps,
  DropdownRenderProps,
  RenderToggleProps
} from '../types'
import { variants } from '../utils'
import { useDropdown } from '../useDropdown'
import { MenuDropdownContext } from '../MenuDropdown/MenuDropdownContext'

const colorVariants = {
  white: {
    className: 'bg-white',
    arrowColor: '#fff',
    arrowStroke: `rgba(0, 0, 0, 0.08)`
  },
  gray: {
    className: 'bg-gray-0',
    arrowColor: '#f7f7f7',
    arrowStroke: `rgba(0, 0, 0, 0.08)`
  }
}

export type OverflowBehavior = 'auto' | 'visible'

export interface DropdownProps extends DropdownCommonProps, TestIdentifiable {
  children?: React.ReactNode | ((props: DropdownRenderProps) => React.ReactNode)
  overflowBehavior?: OverflowBehavior
  /** the maximum height of the dropdown in pixels */
  maxHeight?: number
  disableInitialFocus?: boolean
}

export const Dropdown = ({
  testId,
  renderToggle,
  label,
  disabled,
  forceShow = false, // eslint-disable-line
  showArrow = false,
  children,
  matchWidth = false,
  maxHeight,
  containerClassName,
  onClose,
  placement = 'bottom-end',
  variant = 'white',
  overflowBehavior = 'auto',
  role = 'dialog',
  id: _id,
  disableInitialFocus
}: DropdownProps) => {
  const zIndexClass = useFloatingComponentZIndexClass() // elevation: z-40 for floating components (boosted to z-50 when in modals etc)
  const variantProps = colorVariants[variant]
  const parent = React.useContext(MenuDropdownContext)

  const elementsRef = React.useRef<Array<HTMLButtonElement | null>>([])
  const labelsRef = React.useRef<Array<string | null>>([])

  const {
    id,
    contentsId,
    refs,
    context,
    getReferenceProps,
    getFloatingProps,
    getItemProps,
    floatingStyles,
    floatingPlacement,
    isOpen,
    setIsOpen,
    arrowRef,
    arrowParams
  } = useDropdown({
    testId,
    id: _id,
    placement,
    forceShow, // eslint-disable-line
    showArrow,
    matchWidth,
    role,
    onClose,
    listRef: elementsRef
  })

  parent.getItemProps = getItemProps

  return (
    <div
      data-testid={testId}
      className={cx(containerClassName, 'relative inline-block')}
    >
      {label && (
        <Label name={id} disabled={disabled}>
          {label}
        </Label>
      )}
      {renderToggle(
        getReferenceProps({
          id: id,
          disabled,
          ref: refs.setReference,
          'aria-controls': contentsId,
          'aria-owns': contentsId,
          'aria-expanded': isOpen ? 'true' : 'false',
          // @ts-ignore
          'data-testid': `${testId}-toggle-wrapper`,
          ...getItemProps
        }) as unknown as RenderToggleProps
      )}
      <FloatingList elementsRef={elementsRef} labelsRef={labelsRef}>
        {(isOpen || forceShow) && (
          <PortalManagement
            context={context}
            disableInitialFocus={disableInitialFocus}
            isModal
            closeOnFocusOut={false}
          >
            <Overlay
              testId={`overlay-${testId}`}
              lockScroll
              className={zIndexClass}
            >
              <div
                {...getFloatingProps({ 'aria-labelledby': id })}
                data-testid={`${testId}-content-wrapper`}
                tabIndex={-1}
                id={contentsId}
                style={floatingStyles}
                ref={refs.setFloating}
              >
                <div
                  className={cx(
                    variantProps.className,
                    overflowBehavior === 'auto'
                      ? 'overflow-y-auto overflow-x-visible'
                      : 'overflow-visible',
                    'focus:outline-none border-none dropdown-base py-2'
                  )}
                  style={{
                    maxHeight: maxHeight
                      ? `min(100vh - 16px, ${maxHeight / 16}rem)`
                      : `calc(100vh - 16px)`
                  }}
                >
                  {typeof children === 'function'
                    ? children({
                        closeDropdown: () => setIsOpen(false)
                      })
                    : children}
                </div>
                {showArrow && (
                  <FloatingArrow
                    ref={arrowRef}
                    context={context}
                    x={arrowParams.x}
                    y={arrowParams.y}
                    fill={colorVariants[variant].arrowColor}
                    stroke={colorVariants[variant].arrowStroke}
                    strokeWidth={1}
                    width={arrowParams.width}
                    height={arrowParams.height}
                    style={{
                      margin: floatingPlacement.match(/top|bottom/)
                        ? '-1px 0px'
                        : '0px -1px'
                    }}
                    data-testid={`arrow-${placement}`}
                  />
                )}
              </div>
            </Overlay>
          </PortalManagement>
        )}
      </FloatingList>
    </div>
  )
}

Dropdown.variant = Object.freeze(variants)
