import { Opt_GuestCurrencyAccountPaymentInput } from '@toasttab/do-federated-gateway-apollo/generated/DoFederatedGatewayGraphQLOperations'
import {
  OptGlobalGiftCard,
  OptRestaurantGiftCard
} from '../../apollo/generated/OptWebGraphQLOperations'

/**
 * Returns whether a combination of gift cards has sufficient
 * funds to cover the pending amount on an unpaid check.
 */
export const hasSufficientFundsV2 = ({
  rxGiftCard,
  globalGiftCard,
  guestCurrencyAccount,
  tipAmount = 0.0,
  rxGiftCardTipEnabled = false,
  checkAmount
}: {
  rxGiftCard?: OptRestaurantGiftCard
  globalGiftCard?: OptGlobalGiftCard
  guestCurrencyAccount?: Opt_GuestCurrencyAccountPaymentInput
  rxGiftCardTipEnabled: Boolean
  /**
   * A pending, unpaid tip amount to be potentially covered by the gift card
   */
  tipAmount?: number
  /**
   * The amount to be paid on the due portion of a check (i.e. excluding tips).
   */
  checkAmount: number
}) => {
  const availableToastCash = globalGiftCard?.expectedAvailableBalance || 0.0
  const availableRxGiftCard = rxGiftCard?.expectedAvailableBalance || 0.0
  const availableGuestCurrencyAccountBalance =
    guestCurrencyAccount?.expectedAvailableBalance ?? 0.0
  const availableTotal =
    availableRxGiftCard +
    availableToastCash +
    availableGuestCurrencyAccountBalance
  const checkTotal = checkAmount ?? 0.0
  const balTotal = checkTotal + tipAmount

  if (rxGiftCardTipEnabled) {
    return availableTotal >= balTotal
  } else {
    //do logic to ensure the gift card balance only subtracts from the checkBalance and not the tip
    const maxRxGiftCardAmount = Math.min(checkTotal, availableRxGiftCard)
    const balanceAfterRxGiftCard = checkTotal - maxRxGiftCardAmount
    const toastCashAmounts =
      availableToastCash + availableGuestCurrencyAccountBalance
    const remainingBalanceWithTip = balanceAfterRxGiftCard + tipAmount
    return toastCashAmounts >= remainingBalanceWithTip
  }
}
