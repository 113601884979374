import { Subject } from 'rxjs'

export interface Header {
  response$: Subject<PreauthResponse>
}

export interface PreauthRequest {
  header: Header
  checkoutInfo: CheckoutInfo
}

export enum ResponseTypes {
  OK = 'OK',
  ERROR = 'ERROR',
}

export interface Response {
  kind: ResponseTypes.OK
  info: Array<{
    code: any
    message: string
  }>
  warnings: Array<{
    code: any
    message: string
  }>
}

export interface ErrorResponse {
  kind: ResponseTypes.ERROR
  code: any
  message: string
}

export type PreauthResponse = Response | ErrorResponse

export type SplitResponse = Response | ErrorResponse

export enum PaymentMethods {
  APPLE_PAY = 'Apple Pay',
  CREDIT_CARD = 'Credit Card',
}

export interface CheckoutInfo {
  paymentMethod: PaymentMethods
  firstName: string
  lastName: string
  phone: string
  email: string
  encryptedCard: any
  saveCard: boolean
  selectedCard: string | null
  spiContext: SpiContext
}

export interface SpiContext {
  isSpi: boolean
  [key: string]: any
}

export interface EncryptedCard {
  encryptionKeyId: string
  encryptedCardDataString: string
  cardFirst6: string
  cardLast4: string
  zipCode: string
  expMonth: string
  expYear: string
}
