import { Fieldset } from '@local/do-secundo-fieldset'
import { CartTable } from '../Cart/CartTable/CartTable'
import * as Tip from './Tip/Tip'
import { CartFooter } from '../Cart/CartFooter/CartFooter'
import { CartValidation } from '../Cart/Validation/CartValidation'
import React from 'react'
import { Cart } from '../../types/cart'
import { useRestaurant } from '@local/do-secundo-restaurant-provider/src'
import { CheckoutFormValues } from './utils'
import { Customer } from './CustomerInfo/CustomerInfo'

export type Props = {
  values: CheckoutFormValues
  cart?: Cart
  customer: Customer
  showTip: boolean
}

export const CartCheckoutFields = ({
  cart,
  values,
  customer,
  showTip
}: Props) => {
  const {
    applicableConfigs: { brandingConfig }
  } = useRestaurant()

  const tipAmount = showTip ? Tip.getArgsForSubmit({ values }).tipAmount : 0

  return (
    <>
      {cart ? (
        <Fieldset
          label={`Your ${
            brandingConfig?.removeCateringName ? 'order' : 'catering order'
          } (${cart.order.checks[0].selections.length})`}
          collapsable
          collapsed={false}
        >
          <CartTable cart={cart} />
        </Fieldset>
      ) : null}
      {showTip && <Tip.Component />}
      {cart ? (
        <CartFooter
          cart={cart}
          customer={customer}
          showTotal
          showTip={showTip}
          tip={tipAmount}
          isCheckoutForm={true}
          paymentType={values.paymentType}
        />
      ) : null}
      {cart ? <CartValidation cart={cart} /> : null}
    </>
  )
}
