import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const SubtractCircleFillIcon = React.forwardRef(
  function SubtractCircleFillIcon(
    props: IconProps,
    ref?: React.ForwardedRef<HTMLElement>
  ) {
    return (
      <IconWrapper ref={ref} {...props}>
        <>
          {
            <svg
              width={24}
              height={24}
              viewBox='0 0 24 24'
              fill='none'
              className={cx(
                'inline-block align-middle',
                sizes[props.size || 'sm'].className
              )}
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M21.75 12c0 5.385-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12 6.615 2.25 12 2.25s9.75 4.365 9.75 9.75zm-13.251-.748a.75.75 0 000 1.5h7a.75.75 0 000-1.5h-7z'
                fill='currentColor'
              />
            </svg>
          }
        </>
      </IconWrapper>
    )
  }
)
