import useScript from 'react-script-hook'
import { FF, useFlag } from '@local/do-secundo-feature-flag'
import { spiSdkSrc } from '../../api/util'
import { logError } from '@local/do-secundo-error'
import { SpiSdk } from './types'

declare global {
  interface Window {
    Toast: SpiSdk
  }
}

/** SPI is not for intl customers, but COO is also not yet available outside of the US. */
const isIntlRestaurant = false

export const useSpiSdk = () => {
  const spiFeatureEnabled = useFlag(FF.NVS_COO_SPI)
  const spiEnabled = !isIntlRestaurant && spiFeatureEnabled

  const [loading, error] = useScript({
    src: spiEnabled ? spiSdkSrc : null
  })
  if (error) {
    logError('Failed to load SPI SDK', undefined, error)
  }
  return !loading && !error && typeof window !== 'undefined'
    ? window.Toast
    : undefined
}
