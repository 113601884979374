import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js'
import React, { useMemo } from 'react'

import { Image } from '../../Image/Image'
import { useCustomerAuth } from '../../../auth/CustomerAuthContext'

import styles from '.././CheckoutForm.module.css'
import { useRestaurant } from '@local/do-secundo-restaurant-provider/src'
import { asValidPhoneCountryCode } from '../../../auth/phoneUtils'

export const CompletedInfo = () => {
  const { customer } = useCustomerAuth()
  const { restaurantInfo } = useRestaurant()
  const country = asValidPhoneCountryCode(restaurantInfo?.address?.country)

  const phoneNumber = customer?.phone

  const parsedPhoneNumber = useMemo(
    () =>
      phoneNumber && isValidPhoneNumber(phoneNumber, country)
        ? parsePhoneNumber(phoneNumber, country)
        : null,
    [phoneNumber, country]
  )

  if (!customer) {
    return null
  }

  const email = customer.email
  const firstName = customer.firstName
  const lastName = customer.lastName

  return (
    <div>
      {firstName && lastName && (
        <div className='flex pb-2'>
          <div className={styles.icon}>
            <Image
              alt='Customer icon'
              path='icons/user-gray.svg'
              className={''}
            />
          </div>
          {firstName} {lastName}
        </div>
      )}
      {email && (
        <div className='flex pb-2'>
          <div className={styles.icon}>
            <Image alt='Email icon' path='icons/email-gray.svg' />
          </div>
          {email}
        </div>
      )}
      {parsedPhoneNumber?.isValid() && (
        <div className='flex pb-2'>
          <div className={styles.icon}>
            <Image alt='Phone icon' path='icons/phone-gray.svg' />
          </div>
          {parsedPhoneNumber.formatNational()}
        </div>
      )}
    </div>
  )
}
