import { PaymentInput, placeOrder, PlaceOrderRequest } from '../../api/cart'
import {
  AuthorizePaymentInput,
  AuthorizePaymentResponse,
  PaymentState
} from '../../apollo/generated/TakeoutWebGraphQLOperations'
import { Cart } from '../../types/cart'
import { PaymentType } from '../../types/orders'
import { SERVICES } from '../ServiceAvailabilityProvider/ServiceAvaialbilityProvider'
import { ArgsForSubmit } from '../CheckoutForm/utils'
import { logError } from '@local/do-secundo-error'
import { getCheckTotal } from '../../utils/cart-helpers'
import { SpiSdk } from '../CreditCard/types'

interface HandlePlaceOrderArgs extends ArgsForSubmit {
  cart: Cart
  generateReCaptchaToken: (action: string) => Promise<string | undefined>
  doAuthorization: (
    input: Omit<AuthorizePaymentInput, 'restaurantGuid'>
  ) => Promise<AuthorizePaymentResponse | undefined>
  customerGuid?: string
  paymentType: PaymentType
  navigateOnSuccess: (guid: string) => void
  deleteCartGuid: (fastLinkName: string | undefined) => void
  setErrorMessage: (msg: Error | string) => void
  logCriticalError: (msg: string) => void
  fastLinkName: string | undefined
  spiEnabled: boolean
  spiSdk: SpiSdk | undefined
}

export const handlePlaceOrder = async (args: HandlePlaceOrderArgs) => {
  const {
    cart,
    ccInput,
    paymentType,
    navigateOnSuccess,
    customerGuid,
    deleteCartGuid,
    setErrorMessage,
    logCriticalError,
    formValues,
    loyaltyEnroll,
    companyName,
    shouldLookupLoyalty,
    fastLinkName,
    spiEnabled
  } = args

  try {
    let paymentInput: PaymentInput = {
      paymentType
    }

    if (paymentType === 'CREDIT') {
      const paymentGuid = spiEnabled
        ? await handleSpiPaymentRequest(args)
        : await handleLegacyPaymentRequest(args)

      if (!paymentGuid) {
        return
      }

      paymentInput = {
        paymentType: 'CREDIT',
        tipAmount: ccInput.tipAmount,
        paymentGuid: paymentGuid
      }
    }

    const request: PlaceOrderRequest = {
      formValues,
      paymentInput,
      customer: ccInput.customer,
      customerGuid,
      deliveryInfo: ccInput.newAddress?.deliveryInfo,
      loyaltyEnroll,
      companyName,
      shouldLookupLoyalty,
      fastLinkName
    }

    const placedCart = await placeOrder(cart.guid, request)
    const { guid } = placedCart.order
    deleteCartGuid(fastLinkName)
    navigateOnSuccess(guid)
  } catch (e: any) {
    if (e.code === 'CRITICAL_ERROR') {
      logCriticalError(SERVICES.PLACE_ORDER)
    }

    if (e instanceof Response) {
      const error = await e.json()
      logError(error)
      if (error.message) {
        setErrorMessage(`Unable to place order. ${error.message}`)
      } else {
        setErrorMessage('Unable to place order, please try again.')
      }
    } else {
      logError(e)
      setErrorMessage('Unable to place order, please try again.')
    }
  }
}

const handleSpiPaymentRequest = async ({
  spiSdk,
  cart,
  ccInput,
  setErrorMessage
}: HandlePlaceOrderArgs) => {
  let paymentGuid: string | undefined = undefined

  const onCreatePaymentSuccess = async () => {
    await spiSdk?.confirmPayment(
      async (event) => {
        paymentGuid = event.content.payment?.externalReferenceId
      },
      async (error: { cause?: { message?: string } }) => {
        logError(error.cause?.message ?? 'Failed to confirm payment')
        setErrorMessage('Unable to process your payment, please try again')
      }
    )
  }

  await spiSdk?.createPaymentMethod(
    onCreatePaymentSuccess,
    async (error: { cause?: { message?: string } }) => {
      logError(error.cause?.message ?? 'Failed to create payment')
      setErrorMessage('Unable to process your payment, please try again')
    },
    [
      { label: 'Subtotal', amount: getCheckTotal(cart) },
      { label: 'Tip', amount: ccInput.tipAmount }
    ]
  )

  return paymentGuid
}

const handleLegacyPaymentRequest = async ({
  cart,
  ccInput,
  generateReCaptchaToken,
  doAuthorization,
  setErrorMessage,
  fastLinkName
}: HandlePlaceOrderArgs) => {
  let token: string | undefined = undefined
  try {
    token = await generateReCaptchaToken('authorize_payment')
  } catch (e: any) {
    logError(e)
    setErrorMessage(
      'Failed to load reCAPTCHA. Please reload the page and try again.'
    )
    return undefined
  }

  const payment = await doAuthorization({
    newCardInput: ccInput.newCardInput,
    tipAmount: ccInput.tipAmount,
    amount: cart.order.checks[0].totalAmount,
    email: ccInput.customer.email,
    reCaptchaToken: token,
    cartGuid: cart.guid,
    fastLinkName: fastLinkName
  })

  if (
    payment?.paymentState === PaymentState.Denied ||
    payment?.paymentState === PaymentState.FraudReject ||
    payment?.denialReason?.toLowerCase().includes('trans denied')
  ) {
    setErrorMessage('Your payment transaction was denied.')
    return undefined
  } else if (payment?.denialReason) {
    setErrorMessage(payment.denialReason)
    return undefined
  } else if (
    !payment ||
    !payment.paymentGuid ||
    payment.denialReason?.toLowerCase().includes('invalid request')
  ) {
    setErrorMessage(
      'Unexpected error occurred processing your payment, please try again.'
    )
    return undefined
  }

  return payment?.paymentGuid
}
