// @ts-ignore no typedef exists
import envFromHostname from '@toasttab/env-from-hostname'

const env = envFromHostname(window.location.hostname) as
  | 'prod'
  | 'preprod'
  | 'dev'

export const domain =
  env === 'prod'
    ? 'ws-api.toasttab.com'
    : env === 'preprod'
    ? 'ws-preprod-api.eng.toasttab.com'
    : 'ws-dev-api.eng.toastteam.com'

export const apiHost = `https://${domain}`

export const brandingPublicAssetURL =
  env === 'prod'
    ? 'https://d28f3w0x9i80nq.cloudfront.net/'
    : 'https://d1knuqod36pnys.cloudfront.net/'

export const restaurantPublicAssetUrl =
  env === 'prod'
    ? 'https://d2s742iet3d3t1.cloudfront.net/'
    : 'https://dks3o9tl87y4h.cloudfront.net/'

export const RECAPTCHA_SITE_KEY =
  env === 'prod'
    ? '6Lf5dREqAAAAAG567O1wtZKtPkRUW1GXqCGwjesE'
    : '6LcTvBAqAAAAAMK-n0Tc7VBYtS80NDuI9EmHJpqa'

export const spiSdkSrc =
  env === 'prod'
    ? 'https://payments.toasttab.com/assets/loader.js'
    : env === 'preprod'
    ? 'https://payments.preprod.eng.toasttab.com/assets/loader.js'
    : 'https://dev.eng.toastteam.com/loader.js'

const getRestaurantGuid = () => window.OO_GLOBALS.restaurantGuid
const getBaseHeaders = () => ({
  'Content-Type': 'application/json',
  'Toast-Restaurant-External-ID': getRestaurantGuid()
})

const checkError = (response: Response) => {
  if (!response.ok) {
    throw response
  }
  return response
}

export const get = async (
  path: string,
  options?: { headers?: Record<string, string> }
) =>
  fetch(path, {
    ...options,
    headers: {
      ...getBaseHeaders(),
      ...(options?.headers || {})
    },
    method: 'GET'
  }).then(checkError)

export const post = async (
  path: string,
  data?: any,
  options?: { headers?: Record<string, string> }
) =>
  fetch(path, {
    ...options,
    headers: {
      ...getBaseHeaders(),
      ...(options?.headers || {})
    },
    method: 'POST',
    body: JSON.stringify(data)
  }).then(checkError)

export const put = async (
  path: string,
  data?: any,
  options?: { headers?: Record<string, string> }
) =>
  fetch(path, {
    ...options,
    headers: {
      ...getBaseHeaders(),
      ...(options?.headers || {})
    },
    method: 'PUT',
    body: JSON.stringify(data)
  }).then(checkError)

export const patch = async (
  path: string,
  data?: any,
  options?: { headers?: Record<string, string> }
) =>
  fetch(path, {
    ...options,
    headers: {
      ...getBaseHeaders(),
      ...(options?.headers || {})
    },
    body: JSON.stringify(data),
    method: 'PATCH'
  }).then(checkError)

export const doDelete = async (
  path: string,
  options?: { headers?: Record<string, string> }
) =>
  fetch(path, {
    ...options,
    headers: {
      ...getBaseHeaders(),
      ...(options?.headers || {})
    },
    method: 'DELETE'
  }).then(checkError)

const encodeList = ['fastLinkName']

export const getQueryParams = (obj: any) => {
  const query = obj as Record<string, string>
  Object.keys(query).forEach((key) => {
    if (query[key] === undefined || query[key] === null || query[key] === '') {
      delete query[key]
    } else if (encodeList.includes(key)) {
      query[key] = encodeURIComponent(query[key])
    }
  })
  return Object.keys(query).length === 0
    ? ''
    : `?${new URLSearchParams(query).toString()}`
}

export const getRestaurantShortUrl = () => {
  return window.location.pathname.split('/')[2]
}
