import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from '@toasttab/buffet-pui-modal'
import React from 'react'
import Account from './Account'
import { ProfileFooter } from './ProfileFooter'

export type AccountModalProps = {
  isOpen: boolean
  onClose: () => void
}

export const AccountModal = ({ isOpen, onClose }: AccountModalProps) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} size={'xl'}>
      <ModalHeader>
        <div className={'font-bold text-xl'}>My Account</div>
      </ModalHeader>
      <ModalBody>
        <Account />
      </ModalBody>
      <ModalFooter>
        <ProfileFooter closeAccountModal={onClose} />
      </ModalFooter>
    </Modal>
  )
}
