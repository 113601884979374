import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const LoyaltyIcon = React.forwardRef(function LoyaltyIcon(
  props: IconProps,
  ref?: React.ForwardedRef<HTMLElement>
) {
  return (
    <IconWrapper ref={ref} {...props}>
      <>
        {
          <svg
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
            className={cx(
              'inline-block align-middle',
              sizes[props.size || 'sm'].className
            )}
          >
            <path
              d='M5.852 15.394l-1.015 3.885L1 18.25m17.222-9.61l.999-3.826L23 5.827m-3.84-.751a10.095 10.095 0 011.024 12.573A9.807 9.807 0 018.237 21.23m-3.321-2.314A10.096 10.096 0 013.9 6.35a9.81 9.81 0 0111.94-3.584M12 15.594c1.462-.969 3.285-2.203 3.703-4.04a2.244 2.244 0 00-.635-2.042 1.732 1.732 0 00-1.986-.334 3.251 3.251 0 00-1.082.996c-.28-.41-.65-.751-1.082-.996a1.732 1.732 0 00-1.986.334 2.245 2.245 0 00-.635 2.042c.418 1.837 2.241 3.071 3.703 4.04z'
              stroke='currentColor'
              strokeWidth={sizes[props.size || 'sm'].strokeWidth}
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        }
      </>
    </IconWrapper>
  )
})
