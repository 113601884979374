import React, { useCallback, useState } from 'react'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { ToastAccountIcon } from '@toasttab/buffet-pui-icons'
import { FF, useFlag } from '@local/do-secundo-feature-flag/src'
import { ListItem, MenuDropdown } from '@toasttab/buffet-pui-dropdowns'
import LogoutIcon from './LogoutIcon'
import { useCustomerAuth } from '../auth/CustomerAuthContext'
import { alertSuccess } from '../auth/alertUtils'
import { PwlessAuthModal } from '../auth/PwlessAuthModal'
import { AuthenticationSource } from '../auth/auth'
import { LoggingOutModal } from './LoggingOutModal'
import { AccountModal } from './AccountModal'

export const AccountButton = () => {
  const [isAuthModalOpen, setIsAuthModalOpen] = React.useState(false)
  const [isLoggingOut, setIsLoggingOut] = React.useState(false)
  const [isAccountModalOpen, setIsAccountModalOpen] = useState(false)

  const logoutOnOpen = () => setIsLoggingOut(true)
  const logoutOnClose = () => setIsLoggingOut(false)

  const pwLessAuthEnabled = useFlag(FF.NVS_COO_PWLESS_AUTH)

  const { customer, pwlessLogout } = useCustomerAuth()

  const [logoutError, setLogoutError] = useState(false)
  const logout = useCallback(async () => {
    logoutOnOpen()
    const success = await pwlessLogout()
    if (success) {
      logoutOnClose()
      alertSuccess('You are now logged out')
    } else {
      setLogoutError(true)
    }
  }, [logoutOnOpen, logoutOnClose, pwlessLogout])

  if (!customer) {
    return (
      <>
        {pwLessAuthEnabled && (
          <IconButton
            icon={<ToastAccountIcon />}
            onClick={() => setIsAuthModalOpen(true)}
            textClassName='text-default'
          ></IconButton>
        )}
        <PwlessAuthModal
          source={AuthenticationSource.UserNav}
          isOpen={isAuthModalOpen}
          onClose={() => setIsAuthModalOpen(false)}
        />
      </>
    )
  }

  return (
    <>
      <MenuDropdown
        renderToggle={(props) => (
          <IconButton
            {...props}
            icon={<ToastAccountIcon accessibility='decorative' />}
            textClassName='text-default'
          />
        )}
        preventCloseOnClick
      >
        <ListItem
          icon={<ToastAccountIcon accessibility='decorative' />}
          label={'My account'}
          onClick={() => setIsAccountModalOpen(true)}
        />
        <ListItem
          icon={<LogoutIcon color={'currentColor'} />}
          label={'Log out'}
          onClick={logout}
        />
      </MenuDropdown>
      <LoggingOutModal isOpen={isLoggingOut} error={logoutError} />
      <AccountModal
        isOpen={isAccountModalOpen}
        onClose={() => setIsAccountModalOpen(false)}
      />
    </>
  )
}
