import React, { useCallback, useEffect, useState } from 'react'

import * as Sentry from '@sentry/react'
import {
  AuthedCustomerQueryResult,
  CheckoutMode,
  Customer,
  CustomerAuthContextCommonProvider
} from './CustomerAuthContext'
import { formatPhoneNumber } from './PhoneInput'
import { useAuth } from '@toasttab/do-secundo-guest-authentication'
import {
  CustomerDocument,
  getRegistrationSource,
  useAuthedCustomerQuery
} from './auth'
import { useApolloClient } from '@apollo/client'

export const CustomerAuthContextGiaProvider = (
  props: React.PropsWithChildren<{}>
) => {
  const [loading, setLoading] = useState(false)
  const {
    startPasswordless,
    confirmVerificationCode,
    logOut,
    confirmPasswordless,
    setIsAuthenticated,
    isAuthenticated
  } = useAuth()
  const apolloClient = useApolloClient()

  // use this for now but we may need to switch to get this directly from the auth libraries
  const {
    data: customerData,
    loading: loadingCustomer,
    refetch: refetchCustomer
  } = useAuthedCustomerQuery({
    ssr: false,
    fetchPolicy: 'cache-first',
    // @ts-ignore
    apolloClient,
    skip: !isAuthenticated
  })

  const customer = customerData?.customer as Customer

  const [checkoutMode, setCheckoutMode] = useState<CheckoutMode>(
    customer ? CheckoutMode.Toast : CheckoutMode.Guest
  )

  useEffect(() => {
    if (customer) {
      setCheckoutMode(CheckoutMode.Toast)
    }
  }, [customer])

  const passwordlessLogin = useCallback(
    async (phoneNumber: string) => {
      setLoading(true)
      let hasError = false

      try {
        const formattedPhoneNumber = formatPhoneNumber(phoneNumber)

        const { data } = await startPasswordless(
          formattedPhoneNumber,
          getRegistrationSource()
        )

        if (data?.setupGuest.__typename !== 'SetupGuestSuccess') {
          hasError = true
        }
      } catch (err) {
        console.log(err)

        hasError = true
      }

      setLoading(false)
      return !hasError
    },
    [startPasswordless]
  )

  const completeSignup = useCallback(
    async (email: string, firstName: string, lastName: string) => {
      setLoading(true)
      let hasError = false

      try {
        const { data } = await confirmPasswordless(getRegistrationSource(), {
          email,
          firstName,
          lastName
        })

        if (data?.confirmGuest.__typename !== 'ConfirmGuestSuccess') {
          hasError = true
        }
      } catch (err) {
        hasError = true
      }

      setLoading(false)
      return !hasError
    },
    [confirmPasswordless]
  )

  const passwordlessConfirmCode = useCallback(
    async (_: string, code: string) => {
      setLoading(true)
      let customerGuid: string | null = null

      try {
        const { data } = await confirmVerificationCode(
          code,
          getRegistrationSource()
        )
        if (data?.confirmGuest.__typename === 'ConfirmGuestSuccess') {
          customerGuid = data.confirmGuest.guest.id
          setIsAuthenticated(true)
        } else if (
          data?.confirmGuest.__typename ===
          'CompleteGuestProfileInputRequiredError'
        ) {
          // Legacy flow required a customerGuid but we don't get this if their profile is incomplete
          // so send a string for the check.
          customerGuid = 'getguestinfo'
          setIsAuthenticated(true)
        }
      } catch (err) {
        customerGuid = null
      }

      setLoading(false)
      return customerGuid
    },
    [confirmVerificationCode, setIsAuthenticated]
  )

  // For use in instances where operations are contingent upon the existence of an account
  const fetchCustomer = useCallback(async () => {
    try {
      const { data } = await apolloClient.query<
        AuthedCustomerQueryResult['data']
      >({
        query: CustomerDocument,
        fetchPolicy: 'network-only'
      })

      return (data?.customer || null) as Customer | null
    } catch (err) {
      // Error expected, BFF throws when customer doesn't exist
      return null
    }
  }, [apolloClient])

  const pwlessLogout = useCallback(async () => {
    try {
      await logOut()
      apolloClient.writeQuery({
        query: CustomerDocument,
        data: { customer: null }
      })

      setIsAuthenticated(false)
      setCheckoutMode(CheckoutMode.Guest)
      return true
    } catch (err) {
      Sentry.captureException(`ERROR: passwordless logout error ${err}`)
      return false
    }
  }, [apolloClient, logOut, setIsAuthenticated])

  const [savedAddressUsed, setSavedAddressUsed] = React.useState(false)

  return (
    <CustomerAuthContextCommonProvider
      context={{
        customer,
        loadingCustomer: loading || loadingCustomer,
        refetchCustomer,
        passwordlessLogin,
        passwordlessConfirmCode,
        completeSignup,
        pwlessLogout,
        fetchCustomer,
        checkoutMode,
        setCheckoutMode,
        savedAddressUsed,
        setSavedAddressUsed,
        isLoggedIn: !!customer
      }}
    >
      {props.children}
    </CustomerAuthContextCommonProvider>
  )
}
