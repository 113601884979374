import { CSSProperties } from 'react'
import { Id, toast } from 'react-toastify'

export const alertSuccess = (
  message: string,
  containerId: Id = 'mainToastContainer',
  style?: CSSProperties
) => {
  toast.success(message, { role: 'alert', style, containerId: containerId })
}

export const alertError = (
  message: string,
  containerId: Id = 'mainToastContainer',
  style?: CSSProperties
) => {
  toast.error(message, { role: 'alert ', style, containerId: containerId })
}
