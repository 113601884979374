import React, { useMemo, useState } from 'react'
import { Select } from '@toasttab/buffet-pui-select'
import { Button } from '@toasttab/buffet-pui-buttons'
import { LocationIcon } from '@toasttab/buffet-pui-icons'
import { getRestaurantShortUrl } from '../../../api/util'
import { useLocationSwitch } from './navigation'
import { FulfillmentFormValues } from '../../../types/fulfillment'
import { useQuery } from 'react-query'
import { getRestaurantGroupLocations } from '../../../api/restaurant'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { Alert } from '@toasttab/buffet-pui-alerts'
import {
  getAddressForLocation,
  getFormattedMilesAway
} from '../../../utils/address-helpers'
import { useGetCart } from '../../CartQuery/CartQuery'

interface Props {
  fulfillmentValues: FulfillmentFormValues
}

export const LocationPicker = ({ fulfillmentValues }: Props) => {
  const { restaurantInfo } = useRestaurant()
  const { cart } = useGetCart()

  let latitude: number | undefined
  let longitude: number | undefined

  const deliveryAddressPresent = Boolean(
    fulfillmentValues.diningOptionBehavior === 'DELIVERY' &&
      fulfillmentValues.deliveryInfo?.latitude &&
      fulfillmentValues.deliveryInfo?.longitude
  )

  if (deliveryAddressPresent) {
    latitude = fulfillmentValues.deliveryInfo!!.latitude
    longitude = fulfillmentValues.deliveryInfo!!.longitude
  } else {
    latitude = restaurantInfo?.address?.latitude || undefined
    longitude = restaurantInfo?.address?.longitude || undefined
  }

  const { data, isLoading, isError } = useQuery(
    ['group-locations', latitude, longitude],
    () => getRestaurantGroupLocations({ latitude, longitude })
  )

  const switchLocations = useLocationSwitch(fulfillmentValues)
  const currentLocation = getRestaurantShortUrl()

  const [showPicker, setShowPicker] = useState(false)

  const locations = useMemo(
    () =>
      data?.locations
        .filter((loc) => {
          return (
            Boolean(loc.shortUrl) &&
            (fulfillmentValues.diningOptionBehavior === 'DELIVERY'
              ? loc.deliveryEnabled
              : loc.takeoutEnabled)
          )
        })
        .map((loc) => {
          const distanceMiles = getFormattedMilesAway(loc.distanceMiles)
          const outOfRange = deliveryAddressPresent && !loc.inRangeForDelivery
          return {
            label:
              loc.locationName && loc.locationName !== ''
                ? loc.locationName
                : loc.restaurantName,
            value: loc.shortUrl,
            subLabel: `${getAddressForLocation(loc.address)}${
              distanceMiles ? ` • ${distanceMiles}` : ''
            }${outOfRange ? ' (out of range for delivery)' : ''}`,
            disabled: outOfRange
          }
        }),
    [data, fulfillmentValues, deliveryAddressPresent]
  )

  if (isLoading || isError || !locations || locations.length <= 1) {
    return null
  }

  return showPicker ? (
    <>
      {cart && (
        <Alert variant='warning'>
          You have items in your cart. Items will not transfer over to other
          locations.{''}
        </Alert>
      )}
      <Select
        testId='LocationSelect'
        containerClassName='py-4'
        label={'Restaurant location'}
        value={currentLocation}
        onChange={(value) => {
          switchLocations(value)
        }}
        options={locations}
      />
    </>
  ) : (
    <div className='pb-2 -ml-4'>
      <Button
        testId='ChangeLocationButton'
        iconLeft={<LocationIcon />}
        className='self-start'
        variant='link'
        onClick={() => setShowPicker(true)}
      >
        Change restaurant location
      </Button>
    </div>
  )
}
