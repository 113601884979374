import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from '@toasttab/buffet-pui-modal'
import React from 'react'
import { Button } from '@toasttab/buffet-pui-buttons'

export type DeleteAccountModalProps = {
  isOpen: boolean
  onClose: () => void
  onDelete: () => void
}

export const DeleteAccountModal = ({
  isOpen,
  onClose,
  onDelete
}: DeleteAccountModalProps) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <ModalHeader>Delete my account</ModalHeader>
      <ModalBody>
        Your contact information, order history, payment information, and any
        remaining Toast Cash will be deleted across all Toast restaurants. This
        cannot be undone.
      </ModalBody>
      <ModalFooter>
        <Button variant={'destructive'} onClick={onDelete}>
          Delete my account
        </Button>
        <Button onClick={onClose}>Cancel</Button>
      </ModalFooter>
    </Modal>
  )
}
