import { domain, patch, post } from './util'

interface CreatePaymentIntentRequest {
  amount: number
  currency: string
  email: string
  customerId?: string
}

interface UpdatePaymentIntentRequest {
  amount: number
  tipAmount: number
  email: string
  customerId?: string
  oAuthToken: string
}

export interface PaymentIntent {
  paymentIntentId: string
  sessionSecret: string
  status: string
  oauthToken: string
}

export const createPaymentIntent = async (
  cartGuid: string,
  request: CreatePaymentIntentRequest
): Promise<PaymentIntent> => {
  return post(
    `https://${domain}/catering/v1/public/carts/${cartGuid}/payments`,
    request
  ).then((r) => r.json())
}

export const updatePaymentIntent = async (
  cartGuid: string,
  paymentIntentId: string,
  request: UpdatePaymentIntentRequest
): Promise<PaymentIntent> => {
  return patch(
    `https://${domain}/catering/v1/public/carts/${cartGuid}/payments/${paymentIntentId}`,
    request
  ).then((r) => r.json())
}
