import React, { createContext, useContext } from 'react'

import {
  CardType,
  Exact
} from '../apollo/generated/TakeoutWebGraphQLOperations'

import * as Apollo from '@apollo/client'

export enum CheckoutMode {
  Toast = 'Toast',
  Guest = 'Guest'
}

export interface CustomerAuthContextType {
  savedAddressUsed: boolean
  setSavedAddressUsed: (savedAddressUsed: boolean) => void
  checkoutMode: CheckoutMode
  setCheckoutMode: (mode: CheckoutMode) => void
  customer?: Customer | null
  loadingCustomer: boolean
  refetchCustomer: () => void
  fetchCustomer: () => Promise<Customer | null>
  passwordlessLogin: (phoneNumber: string) => Promise<boolean>
  passwordlessConfirmCode: (
    phoneNumber: string,
    code: string
  ) => Promise<string | null>
  completeSignup: (
    email: string,
    firstName: string,
    lastName: string
  ) => Promise<boolean>
  pwlessLogout: () => Promise<boolean>
  isLoggedIn: boolean
}

export type AuthedCustomerQueryVariables = Exact<{ [key: string]: never }>

export type AuthedCustomerQuery = {
  __typename?: 'Query'
  customer: {
    __typename?: 'Customer'
    lastName?: string | null
    firstName?: string | null
    phone?: string | null
    email: string
    id: string
    creditCards: Array<{
      __typename?: 'SavedCreditCard'
      guid: string
      cardType: CardType
      maskedPan: string
      expirationMonth: string
      expirationYear: string
      isPrimary: boolean
    }>
    addresses: Array<{
      __typename?: 'SavedAddress'
      guid: string
      name?: string | null
      deliveryInfo: {
        __typename?: 'DeliveryInfo'
        address1: string
        address2?: string | null
        city: string
        state: string
        zipCode: string
        latitude: number
        longitude: number
        notes?: string | null
      }
    }>
  }
}

export type AuthedCustomerQueryResult = Apollo.QueryResult<
  AuthedCustomerQuery,
  AuthedCustomerQueryVariables
>

export type Customer =
  | (AuthedCustomerQuery['customer'] & { __typename: 'Customer' })
  | undefined
  | null

export const CustomerAuthContext = createContext<
  CustomerAuthContextType | undefined
>(undefined)

export const CustomerAuthContextCommonProvider = (
  props: React.PropsWithChildren<{ context: CustomerAuthContextType }>
) => {
  return (
    <CustomerAuthContext.Provider value={{ ...props.context }}>
      {props.children}
    </CustomerAuthContext.Provider>
  )
}

export const useCustomerAuth = () => {
  const context = useContext(CustomerAuthContext)
  if (!context) {
    throw new Error('useCustomer must be used within a CustomerContextProvider')
  }

  return context
}
