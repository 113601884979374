/* eslint-disable */
/* Generated by GraphQL Code Generator against pre-production environment */

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};



export enum AdPlacement {
  StpCheckout = 'STP_CHECKOUT',
  OptCheckout = 'OPT_CHECKOUT'
}

export type AddPreauthToPartyInput = {
  /** Party member is a part of. */
  partyGuid: Scalars['ID'];
  /** Member's unique identifier. */
  partyMemberGuid: Scalars['ID'];
  /** Member's auth token. */
  memberAuthToken: Scalars['String'];
  /** RestaurantGuid for the new preauth tab. */
  restaurantGuid: Scalars['ID'];
  /** A new credit card with encrypted data. */
  newCardInput?: Maybe<NewCardInput>;
  /** A customer's saved credit card. This is for future use. For now, preauth tabs can only use new cards */
  savedCardInput?: Maybe<SavedCardInput>;
  /** Customer's information. */
  customer?: Maybe<OrderAtTableCustomerInput>;
  /** Apple Pay payment token */
  pkPaymentToken?: Maybe<Scalars['String']>;
};

export type ApplicableCustomerRewardInput = {
  /** The customer reward to be applied to the order being placed. */
  reward: ApplyCustomerRewardInput;
  /** The order amount that the customer is seeing while placing the order, accounting for the reward. */
  customerExpectedAmount: Scalars['Float'];
};

export type AppliedCustomerReward = {
  __typename?: 'AppliedCustomerReward';
  amount?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  rewardType?: Maybe<RewardType | `${RewardType}`>;
  beforeTax?: Maybe<Scalars['Boolean']>;
  tax?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** An object representing the raw applied discount. */
export type AppliedDiscount = {
  __typename?: 'AppliedDiscount';
  discountAmount: Scalars['Float'];
  entityType: Scalars['String'];
  externalId?: Maybe<Scalars['String']>;
  guid?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  processingState?: Maybe<Scalars['String']>;
  appliedPromoCode?: Maybe<Scalars['String']>;
  loyaltyDetails?: Maybe<LoyaltyDetails>;
  discount?: Maybe<Discount>;
};

export type AppliedLoyaltyInfo = {
  __typename?: 'AppliedLoyaltyInfo';
  loyaltyIdentifier: Scalars['ID'];
  maskedLoyaltyIdentifier: Scalars['String'];
  vendor: Scalars['String'];
};

export type AppliedPreauthInfo = {
  __typename?: 'AppliedPreauthInfo';
  guid: Scalars['ID'];
  token: Scalars['String'];
  last4CardDigits: Scalars['String'];
  cardHolderFirstName: Scalars['String'];
  cardHolderLastName?: Maybe<Scalars['String']>;
  cardHolderExpirationMonth: Scalars['String'];
  cardHolderExpirationYear: Scalars['String'];
  cardType: CardType | `${CardType}`;
};

/** A percentage/open/fixed service charge added to a check which could represent in general an upcharge like a gratuity or delivery fee. Whether the upcharge is taxable is defined in the restaurant-configured serviceCharge. The fields on the AppliedServiceCharge are computed based on the referenced ServiceCharge configuration. */
export type AppliedServiceCharge = {
  __typename?: 'AppliedServiceCharge';
  guid?: Maybe<Scalars['ID']>;
  externalId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  gratuity: Scalars['Boolean'];
  taxable: Scalars['Boolean'];
  taxAmount: Scalars['Float'];
  chargeAmount: Scalars['Float'];
  chargeType: ServiceChargeType | `${ServiceChargeType}`;
  serviceChargeCalculation: ServiceChargeCalculation | `${ServiceChargeCalculation}`;
  serviceCharge: ServiceCharge;
  serviceChargeCategory?: Maybe<ServiceChargeCategory | `${ServiceChargeCategory}`>;
  serviceChargeDetails?: Maybe<ServiceChargeDetails>;
};

export type ApplyCustomerRewardInput = {
  name?: Maybe<Scalars['String']>;
  type?: Maybe<RewardType | `${RewardType}`>;
  amount?: Maybe<Scalars['Float']>;
  beforeTax?: Maybe<Scalars['Boolean']>;
};

/** Tokens provided from successful login or refresh response */
export type AuthenticationResponse = {
  __typename?: 'AuthenticationResponse';
  /** The customerGuid for the account that's been logged in */
  customerGuid: Scalars['ID'];
  /** Access token in the form of JWT ( JSON Web Token ). Use the access token in the header of all authenticated requests to this service with the key "toast-customer-access". The access token has a short lifespan of 20 minutes. Use the ***refreshToken*** to obtain a new access token using the ***refresh*** mutation. */
  accessToken: Scalars['String'];
  /** Refresh token in the form of JWT ( JSON Web Token ). Use the refresh token for obtaining a new access token once the access token has expired. The refresh token expires after 30 days. */
  refreshToken: Scalars['String'];
};

/** link to loyalty docs: https://github.toasttab.com/toasttab/toast-cards/blob/main/toast-cards-api/src/main/java/com/toasttab/service/cards/api/inquiry/balance/BalanceStateData.kt */
export type BalanceStateData = {
  __typename?: 'BalanceStateData';
  balance?: Maybe<Scalars['Int']>;
  balancePoints?: Maybe<Scalars['String']>;
  messages?: Maybe<BalanceStateMessages>;
};

/** link to loyalty docs: https://github.toasttab.com/toasttab/toast-cards/blob/main/toast-cards-api/src/main/java/com/toasttab/service/cards/api/inquiry/balance/BalanceMessagesData.kt */
export type BalanceStateMessages = {
  __typename?: 'BalanceStateMessages';
  balance?: Maybe<Scalars['String']>;
  balanceShort?: Maybe<Scalars['String']>;
};

/** Fields that can be changed as part of updating the basic info on an account. All are required, but can be left the same as the current values stored in the database. */
export type BasicInfoInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

/** Concrete type for Boolean feature flag results. Contains the flag and a Boolean value representing its current state. */
export type BooleanFeatureFlagResult = FeatureFlagResult & {
  __typename?: 'BooleanFeatureFlagResult';
  flag: Scalars['String'];
  value: Scalars['Boolean'];
};

export type BrandingInformationInput = {
  /** Type of advertisement being ran */
  adType?: Maybe<Scalars['String']>;
  /** Brand the advertisement is for */
  brand?: Maybe<Scalars['String']>;
  /** Minimum check amount required be eligible for reward */
  minimumSpendAmount?: Maybe<Scalars['Float']>;
  /** Amount user will receive in Toast cash (if $0 no reward is issued) */
  creditRewardsAmount?: Maybe<Scalars['Float']>;
  /** Payment method such as click to pay or apple pay, etc if being used in a joint campaign with a card brand */
  paymentMethod?: Maybe<PaymentMethods | `${PaymentMethods}`>;
};

export enum CardType {
  Visa = 'VISA',
  Mastercard = 'MASTERCARD',
  Amex = 'AMEX',
  Discover = 'DISCOVER',
  Jcb = 'JCB',
  Diners = 'DINERS',
  Cit = 'CIT',
  Maestro = 'MAESTRO',
  Laser = 'LASER',
  Solo = 'SOLO',
  Unknown = 'UNKNOWN'
}

export type CartMessage = {
  __typename?: 'CartMessage';
  messageType?: Maybe<Scalars['String']>;
  userFacingMessage?: Maybe<Scalars['String']>;
};

export type CartPaymentOption = {
  __typename?: 'CartPaymentOption';
  paymentType: PaymentType | `${PaymentType}`;
};

export type CartPaymentOptions = {
  __typename?: 'CartPaymentOptions';
  atCheckout: Array<Maybe<CartPaymentOption>>;
  uponReceipt: Array<Maybe<CartPaymentOption>>;
};

/** An item on an order that has already been placed. */
export type CheckSelection = {
  /** the ID of the item this selection represents */
  itemGuid?: Maybe<Scalars['String']>;
  /** the ID of the item group this selection's item is a part of */
  itemGroupGuid?: Maybe<Scalars['String']>;
  /** External ID that matches with the user's selection when placing the order */
  externalId?: Maybe<Scalars['String']>;
  /** List of all modifiers included on this item */
  modifiers: Array<CompletedOrderModifierSelection>;
  /** The item's display name */
  name: Scalars['String'];
  /** The item's price (including the price of any modifiers) */
  price: Scalars['Float'];
  /** The cost of the Selection before any discounts are applied to the order. Where taxInclusion is 'INCLUDED' a tax inclusive value is returned. */
  preDiscountPrice?: Maybe<Scalars['Float']>;
  /** Quantity of this item (non-integer quantities indicate split items) */
  quantity: Scalars['Float'];
  usesFractionalQuantity: Scalars['Boolean'];
  /** Includes the unit of measure for the item as well as the quantity to be measured. */
  fractionalQuantity: FractionalQuantity;
  /** List of discounts applied directly to this item as opposed to the whole check. */
  appliedDiscounts: Array<AppliedDiscount>;
};

/** An item on an order that has already been placed. */
export type CheckSelectionGuid = CheckSelection & {
  __typename?: 'CheckSelectionGuid';
  /** ID for the selection. If guid is set and externalID is null the selection came from the POS. */
  guid: Scalars['ID'];
  /** Indicates whether this selection was voided */
  voided?: Maybe<Scalars['Boolean']>;
  itemGuid?: Maybe<Scalars['String']>;
  itemGroupGuid?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  modifiers: Array<CompletedOrderModifierSelection>;
  name: Scalars['String'];
  price: Scalars['Float'];
  preDiscountPrice?: Maybe<Scalars['Float']>;
  quantity: Scalars['Float'];
  usesFractionalQuantity: Scalars['Boolean'];
  fractionalQuantity: FractionalQuantity;
  appliedDiscounts: Array<AppliedDiscount>;
};

/** An item on an order that has already been placed. */
export type CheckSelectionNoGuid = CheckSelection & {
  __typename?: 'CheckSelectionNoGuid';
  itemGuid?: Maybe<Scalars['String']>;
  itemGroupGuid?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  modifiers: Array<CompletedOrderModifierSelection>;
  name: Scalars['String'];
  price: Scalars['Float'];
  preDiscountPrice?: Maybe<Scalars['Float']>;
  quantity: Scalars['Float'];
  usesFractionalQuantity: Scalars['Boolean'];
  fractionalQuantity: FractionalQuantity;
  appliedDiscounts: Array<AppliedDiscount>;
};

export type Click2PayInput = {
  srcDpaId: Scalars['String'];
  correlationId: Scalars['String'];
  merchantTransactionId: Scalars['String'];
  flowId: Scalars['String'];
  cardBrand?: Maybe<Scalars['String']>;
};

export enum Client {
  OnlineOrdering = 'ONLINE_ORDERING',
  ConsumerAppIos = 'CONSUMER_APP_IOS'
}

export type CompleteProfileCreationError = Error & {
  __typename?: 'CompleteProfileCreationError';
  code: CompleteProfileCreationErrorCode | `${CompleteProfileCreationErrorCode}`;
  message: Scalars['String'];
};

export enum CompleteProfileCreationErrorCode {
  ServiceUnavailable = 'SERVICE_UNAVAILABLE'
}

export type CompleteProfileCreationInput = {
  /** The source for the account creation (Toast App vs web Online Ordering vs Order and Pay) */
  source: RegistrationSource | `${RegistrationSource}`;
};

export type CompleteProfileCreationResponse = {
  __typename?: 'CompleteProfileCreationResponse';
  guestGuid: Scalars['ID'];
};

export type CompleteProfileCreationResponseOrError = CompleteProfileCreationResponse | CompleteProfileCreationError;

/** An order that has already been placed. Contains fields useful for displaying past orders or for an order confirmation screen. */
export type CompletedOrder = {
  __typename?: 'CompletedOrder';
  /** The guid of the order object in the database */
  guid: Scalars['ID'];
  /** The display number assigned to this order's check (only one check per order for digital orders) */
  checkNumber?: Maybe<Scalars['Int']>;
  /** Guid of the check on this order, used primarily for creating a loyalty account to connect the order to the new account */
  checkGuid: Scalars['ID'];
  /**
   * Provided personal information, either from inputs for guest orders or retrieved from customermgmt for orders with a saved account
   * @deprecated use customerV2
   */
  customer: CustomerInfo;
  /** Provided personal information, either from inputs for guest orders or retrieved from customermgmt for orders with a saved account. Will only be present if returned from PlaceOrder or if the user is authenticated. */
  customerV2?: Maybe<CustomerInfo>;
  /** The provided delivery info used to calculate delivery fees, assuming this was a delivery order. Will only be present if returned from PlaceOrder or if the user is authenticated. */
  deliveryInfo?: Maybe<DeliveryInfo>;
  /** Timestamp of the estimated fulfillment (current time + quote time) */
  estimatedFulfillmentDate?: Maybe<Scalars['Float']>;
  /** The message set by the restaurant in the OO-config for takeout orders, null if unset by the restaurant */
  guestCommunication?: Maybe<Scalars['String']>;
  /** Number of items present on this order */
  numberOfSelections: Scalars['Int'];
  /** Timestamp of when the order was placed */
  openedDateTime: Scalars['Float'];
  /** Enum defining which kind of payment was used to place this order */
  paymentType: PaymentType | `${PaymentType}`;
  /** Restaurant the order was placed at, which contains all relevant information (name, address, etc.) */
  restaurant: Restaurant;
  /** List of items present on this order */
  selections: Array<CompletedOrderSelection>;
  /** The total amount of delivery charges on this order based on **deliveryInfo**, included in the **total** below, assuming this was a delivery order. */
  deliveryChargeTotal: Scalars['Float'];
  /** The total amount of service charges on this order, included in the **total** below. Does not include delivery charges. */
  serviceChargeTotal: Scalars['Float'];
  /** The subtotal of the order (price without tax/tip/service charges), included in the **total** below */
  subtotal: Scalars['Float'];
  /**
   * The total amount of taxes on this order, included in the **total** below
   * @deprecated Use taxV2 field
   */
  tax: Scalars['Float'];
  /** The total amount of taxes on this order after applying global promos, included in the **totalV2** below */
  taxV2: Scalars['Float'];
  /** The tip for the order, included in the **total** below */
  tip: Scalars['Float'];
  /**
   * The final price of the order, including **subtotal**, **tax**, **tip**, and **serviceChargeTotal**
   * @deprecated Use totalV2 field
   */
  total: Scalars['Float'];
  /** The final price of the order, including **subtotal**, **tax**, **tip**, **serviceChargeTotal** and **discounts** */
  totalV2: Scalars['Float'];
  /**
   * The optional applied reward, if any for the order. If present, use the **tax** and **total** for this object for the values after the reward has been applied.
   * @deprecated Use discounts field
   */
  appliedCustomerReward?: Maybe<AppliedCustomerReward>;
  /** Optional discounts, if any, for the order. */
  discounts: Discounts;
  /** The current state the order is in. Order's not submitted to a KDS will return null */
  orderStatus?: Maybe<OrderStatusType | `${OrderStatusType}`>;
  /** Inferred dining option of the order, based on the presence of delivery info. Will be 'TAKEOUT' for both takeout and outpost orders; use **outpostOrder** field to know if an order is an outpost order. */
  diningOptionBehavior: DiningOptionBehavior | `${DiningOptionBehavior}`;
  /** The fee the user has paid for using outpost. Only not null if the user actually order from outpost. */
  outpostFee?: Maybe<Scalars['Float']>;
  /** Whether or not the order is being delivered to Outpost. False (or null) is a standard takeout order. */
  outpostOrder?: Maybe<Scalars['Boolean']>;
  /** Whether the order has loyalty info attached, meaning a loyalty account was found with the same email at this restaurant */
  hasLoyaltyAttached: Scalars['Boolean'];
  /** Gift card applied as payment */
  giftCard?: Maybe<GiftCardPayment>;
  /** (Deprecated) The curbside pickup options for an order. Can be null if curbside pickup indication is not needed. */
  curbsidePickup?: Maybe<CurbsidePickup>;
  /** The curbside pickup information for an order. Can be null if curbside pickup indication is not needed. */
  curbsidePickupV2?: Maybe<CurbsidePickupV2>;
};

export type CompletedOrderInput = {
  orderGuid: Scalars['ID'];
  restaurantGuid: Scalars['ID'];
};

/** A modifier on an item for an order that has already been placed. */
export type CompletedOrderModifierSelection = {
  __typename?: 'CompletedOrderModifierSelection';
  /** Display name of the modifier */
  name: Scalars['String'];
  /** The modifier's price */
  price: Scalars['Float'];
  /** List of nested modifier selections */
  modifiers: Array<CompletedOrderModifierSelection>;
  /** Indicates whether this modifier was voided, only relevant for OPT open tab orders. */
  voided?: Maybe<Scalars['Boolean']>;
};

/** An item on an order that has already been placed. */
export type CompletedOrderSelection = {
  __typename?: 'CompletedOrderSelection';
  /** ID for the selection. If guid is set and externalID is null the selection came from the POS. */
  guid: Scalars['ID'];
  /** the ID of the item this selection represents */
  itemGuid?: Maybe<Scalars['String']>;
  /** the ID of the item group this selection's item is a part of */
  itemGroupGuid?: Maybe<Scalars['String']>;
  /** External ID that matches with the user's selection when placing the order */
  externalId?: Maybe<Scalars['String']>;
  /** List of all modifiers included on this item */
  modifiers: Array<CompletedOrderModifierSelection>;
  /** The item's display name */
  name: Scalars['String'];
  /** The item's price (including the price of any modifiers) */
  price: Scalars['Float'];
  /** The cost of the Selection before any discounts are applied to the order */
  preDiscountPrice?: Maybe<Scalars['Float']>;
  /** Quantity of this item (including modifiers) */
  quantity: Scalars['Int'];
  usesFractionalQuantity: Scalars['Boolean'];
  /** Includes the unit of measure for the item as well as the quantity to be measured. */
  fractionalQuantity: FractionalQuantity;
  /** List of discounts applied directly to this item as opposed to the whole check. */
  appliedDiscounts: Array<Maybe<AppliedDiscount>>;
  /** Indicates whether this selection was voided, only relevant for OPT open tab orders. */
  voided?: Maybe<Scalars['Boolean']>;
};

export type ContactInfo = {
  contactType: ContactInfoType | `${ContactInfoType}`;
  contact: Scalars['String'];
};

export enum ContactInfoType {
  Email = 'EMAIL',
  Phone = 'PHONE'
}

export type CreateAccountError = Error & {
  __typename?: 'CreateAccountError';
  code: CreateAccountErrorCode | `${CreateAccountErrorCode}`;
  message: Scalars['String'];
};

export enum CreateAccountErrorCode {
  EmailInUse = 'EMAIL_IN_USE',
  InvalidAccountInfo = 'INVALID_ACCOUNT_INFO',
  PhoneNumberNotFound = 'PHONE_NUMBER_NOT_FOUND',
  PhoneInUse = 'PHONE_IN_USE'
}

export type CreateAccountInput = {
  /** Email for the new account. Must not be in use by another account, and cannot be changed once the account is created. A verification email will be sent to this address which contains a link that must be clicked to finish creating the account */
  email: Scalars['String'];
  /** Password, used in combination with the email to login to the account */
  password: Scalars['String'];
  /** Restaurant guid, which can be optionally supplied to fetch the restaurant name from the API based on the guid provided. If provided, will override the default name of 'Toast' with the server response. */
  restaurantGuid?: Maybe<Scalars['ID']>;
  /** Url link sent in the email, which is appended with the verification token as a query parameter. Should be used to redirect the user from their email to a page with logic able to handle the the final step of creating an account (i.e. the **verifyEmail** mutation) */
  redirectUrl: Scalars['String'];
  /** The source for the account creation (iOS app vs web Online Ordering) */
  registrationClient: Client | `${Client}`;
  /** Optional basic info can be provided to create the account with these fields (first name, last name, phone number) already set */
  basicInfo?: Maybe<BasicInfoInput>;
  /** Optional input to create a loyalty account at the same time as the OO account is created. **basicInfo** is required for the loyalty account to be successfully created. */
  loyaltyAccountCreationInput?: Maybe<LoyaltyAccountCreationInput>;
};

export type CreateAccountResponse = {
  __typename?: 'CreateAccountResponse';
  customerGuid: Scalars['ID'];
};

export type CreateAccountResponseOrError = CreateAccountResponse | CreateAccountError;

export type CreateConfirmedAccountInput = {
  /** Email for the new account. Must not be in use by another account, and cannot be changed once the account is created. A verification email will be sent to this address which contains a link that must be clicked to finish creating the account */
  email: Scalars['String'];
  /** Password, used in combination with the email to login to the account */
  password: Scalars['String'];
  /** The source for the account creation (iOS app vs web Online Ordering) */
  registrationClient: Client | `${Client}`;
  /** Basic info can be provided to create the account with these fields (first name, last name, phone number) already set */
  basicInfo: BasicInfoInput;
};

export type CreateConfirmedAccountResponseOrError = AuthenticationResponse | CreateAccountError;

export type CreatePaymentIntentForSplitCheckBySelectionsInput = {
  /** Party member is a part of. */
  partyGuid: Scalars['ID'];
  /** Member's unique identifier. */
  partyMemberGuid: Scalars['ID'];
  /** Member's auth token. */
  memberAuthToken: Scalars['String'];
  /** Email to be sent for fraud checks. */
  email?: Maybe<Scalars['String']>;
  /** List of selections to split, paired with floats indicating how they should be split. */
  selectionsToSplit: Array<SplitItemInput>;
};

export type CreatePaymentIntentInput = {
  /** Party member is a part of. */
  partyGuid: Scalars['ID'];
  /** Member's unique identifier. */
  partyMemberGuid: Scalars['ID'];
  /** Member's auth token. */
  memberAuthToken: Scalars['String'];
  /** Identifier for the check */
  checkGuid?: Maybe<Scalars['ID']>;
  /** Indicates whether split payment data should be used for pricing. */
  useSplitPaymentData?: Maybe<Scalars['Boolean']>;
  /** Email to be sent for fraud checks. */
  email?: Maybe<Scalars['String']>;
};

export type CreatePaymentIntentResponse = PaymentIntent | PaymentIntentError;

/** An object representing curbside pickup options */
export type CurbsidePickup = {
  __typename?: 'CurbsidePickup';
  selected: Scalars['Boolean'];
};

export type CurbsidePickupInput = {
  selected: Scalars['Boolean'];
};

export type CurbsidePickupInputV2 = {
  transportDescription: Scalars['String'];
  transportColor?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
};

/** An object representing curbside pickup information */
export type CurbsidePickupV2 = {
  __typename?: 'CurbsidePickupV2';
  transportDescription: Scalars['String'];
  transportColor?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
};

/** The stored data for a customer account that is visible to the customer */
export type Customer = {
  __typename?: 'Customer';
  /** Customer guid */
  guid: Scalars['ID'];
  /** First name, from basic info - not a required field when creating an account on OO so can be nullable */
  firstName?: Maybe<Scalars['String']>;
  /** Last name, from basic info - not a required field when creating an account on OO so can be nullable */
  lastName?: Maybe<Scalars['String']>;
  /** Phone number, from basic info - not a required field when creating an account on OO so can be nullable */
  phone?: Maybe<Scalars['String']>;
  /** Email (primary identifier for the customer account) */
  email: Scalars['String'];
  addresses: Array<SavedAddress>;
  /** List of saved credit cards for this customer, sorted with the primary card first */
  creditCards: Array<SavedCreditCard>;
};

export type CustomerInfo = {
  __typename?: 'CustomerInfo';
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
  email: Scalars['String'];
};

export type CustomerInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
  email: Scalars['String'];
};

export enum DdiMode {
  Opt = 'OPT',
  Mnp = 'MNP',
  Stp = 'STP',
  Tts = 'TTS'
}

export type DeleteAddressError = Error & {
  __typename?: 'DeleteAddressError';
  code: DeleteAddressErrorCode | `${DeleteAddressErrorCode}`;
  message: Scalars['String'];
};

export enum DeleteAddressErrorCode {
  AddressNotFound = 'ADDRESS_NOT_FOUND'
}

export type DeleteAddressInput = {
  addressGuid: Scalars['ID'];
};

export type DeleteAddressResponse = {
  __typename?: 'DeleteAddressResponse';
  customer: Customer;
};

export type DeleteAddressResponseOrError = DeleteAddressResponse | DeleteAddressError;

export type DeleteCreditCardError = Error & {
  __typename?: 'DeleteCreditCardError';
  code: DeleteCreditCardErrorCode | `${DeleteCreditCardErrorCode}`;
  message: Scalars['String'];
};

export enum DeleteCreditCardErrorCode {
  CardNotFound = 'CARD_NOT_FOUND'
}

/** Guid of the desired card to be deleted from the account */
export type DeleteCreditCardInput = {
  cardGuid: Scalars['ID'];
};

export type DeleteCreditCardResponse = {
  __typename?: 'DeleteCreditCardResponse';
  customer: Customer;
};

export type DeleteCreditCardResponseOrError = DeleteCreditCardResponse | DeleteCreditCardError;

export type DeleteItemFromCartInput = {
  /** The GUID of the cart that the cart item referenced by **selectionGuid** would be removed from. */
  cartGuid: Scalars['ID'];
  /** Indicates the cart item to be removed from the cart referenced by **cartGuid**. */
  selectionGuid: Scalars['ID'];
};

export type DeliveryInfo = {
  __typename?: 'DeliveryInfo';
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  zipCode: Scalars['String'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  /** Notes for the delivery driver */
  notes?: Maybe<Scalars['String']>;
};

export type DeliveryInfoInput = {
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  zipCode: Scalars['String'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  /** Notes for the delivery driver */
  notes?: Maybe<Scalars['String']>;
};

export enum DiningOptionBehavior {
  /** The order is to be delivered to the diner. */
  Delivery = 'DELIVERY',
  /** The order is to be picked up at an outpost. */
  Outpost = 'OUTPOST',
  /** The order is to be picked up at the restaurant. */
  TakeOut = 'TAKE_OUT'
}

export type Discount = {
  __typename?: 'Discount';
  guid?: Maybe<Scalars['ID']>;
};

/** An object represeting the various discounts that can be applied to an order. Each field will contain an object with each discount's associated information, such as type, name, and amount. */
export type Discounts = {
  __typename?: 'Discounts';
  globalReward?: Maybe<AppliedCustomerReward>;
  restaurantDiscount?: Maybe<RestaurantDiscount>;
  loyaltyDiscount?: Maybe<LoyaltyDiscount>;
  loyaltyDiscounts: Array<Maybe<LoyaltyDiscount>>;
};

export type DoItemTag = {
  __typename?: 'DoItemTag';
  name: Scalars['String'];
  guid: Scalars['ID'];
};

export type DoMenuImage = {
  __typename?: 'DoMenuImage';
  item?: Maybe<DoMenuImageItem>;
};

export type DoMenuImageItem = {
  __typename?: 'DoMenuImageItem';
  fullPath?: Maybe<Scalars['String']>;
};

export type DoMenuItem = {
  __typename?: 'DoMenuItem';
  guid: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
  price?: Maybe<Scalars['Float']>;
  outOfStock: Scalars['Boolean'];
  images?: Maybe<DoMenuImage>;
  imageUrls?: Maybe<ImageSet>;
  imageLink?: Maybe<Scalars['String']>;
  modifierGroups: Array<Maybe<DoModifierGroup>>;
  itemTags: Array<DoItemTag>;
  dietaryItemTags: Array<DoItemTag>;
  unitOfMeasure?: Maybe<UnitOfMeasure | `${UnitOfMeasure}`>;
  usesFractionalQuantity: Scalars['Boolean'];
};

export type DoModifierGroup = {
  __typename?: 'DoModifierGroup';
  guid: Scalars['ID'];
  name: Scalars['String'];
  minSelections: Scalars['Int'];
  maxSelections: Scalars['Int'];
  pricingMode: Scalars['String'];
  options: Array<Maybe<DoModifierOption>>;
};

export type DoModifierOption = {
  __typename?: 'DoModifierOption';
  guid: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
  maxSelections: Scalars['Int'];
  images: DoMenuImage;
  price: Scalars['Float'];
  isDefault: Scalars['Boolean'];
  outOfStock: Scalars['Boolean'];
  modifierGroups: Array<Maybe<DoModifierGroup>>;
  allowsDuplicates: Scalars['Boolean'];
};

export type EditItemInCartInput = {
  /** The GUID of the cart that the cart item referenced by **selectionGuid** belongs to. */
  cartGuid: Scalars['ID'];
  /** Indicates the cart item to be modified. */
  selectionGuid: Scalars['ID'];
  /** Indicates what the cart item referenced by **selectionGuid** would look like. */
  selection: SelectionInput;
};

/** Indicates the failure to achieve the intended result of a query or mutation. */
export type Error = {
  message: Scalars['String'];
};

/** Interface for all types of feature flag results to implement. LaunchDarkly supports Boolean, String, Number, and JSON feature flags currently. */
export type FeatureFlagResult = {
  flag: Scalars['String'];
};

export enum FeedbackRating {
  Like = 'LIKE',
  Dislike = 'DISLIKE'
}

export type FractionalQuantity = {
  __typename?: 'FractionalQuantity';
  unitOfMeasure: UnitOfMeasure | `${UnitOfMeasure}`;
  quantity: Scalars['Float'];
};

/** Data representing the unit of measure to use when weighing out an item and the amount to weigh. */
export type FractionalQuantityInput = {
  unitOfMeasure: UnitOfMeasure | `${UnitOfMeasure}`;
  quantity: Scalars['Float'];
};

/** General error resulted from temporary unavailability or failure of critical upstream services or tripped circuit breakers. */
export type GeneralError = Error & {
  __typename?: 'GeneralError';
  code: GeneralErrorCode | `${GeneralErrorCode}`;
  message: Scalars['String'];
};

export enum GeneralErrorCode {
  /** Temporary unavailability or failure of upstream services. */
  Retryable = 'RETRYABLE',
  /** Tripped circuit breakers within BFF or critical upstream services. */
  ServiceUnavailable = 'SERVICE_UNAVAILABLE'
}

export type GenerateSmsVerificationCodeInput = {
  phoneNumber: Scalars['String'];
};

export type GenerateSmsVerificationCodeResponse = {
  __typename?: 'GenerateSmsVerificationCodeResponse';
  success: Scalars['Boolean'];
};

export type GetMenusError = Error & {
  __typename?: 'GetMenusError';
  message: Scalars['String'];
  code?: Maybe<GetMenusErrorCode | `${GetMenusErrorCode}`>;
};

export enum GetMenusErrorCode {
  Failed = 'FAILED'
}

export type GetMenusResponse = {
  __typename?: 'GetMenusResponse';
  menus: Array<MenuV2>;
};

export type GetMenusResponseOrError = GetMenusResponse | GetMenusError;

export type GetPopularItemsError = Error & {
  __typename?: 'GetPopularItemsError';
  message: Scalars['String'];
  code: GetPopularItemsErrorCode | `${GetPopularItemsErrorCode}`;
};

export enum GetPopularItemsErrorCode {
  Failed = 'FAILED'
}

export type GetPopularItemsInput = {
  restaurantGuid: Scalars['String'];
  popItemsLimit: Scalars['Int'];
  channelGuid?: Maybe<Scalars['String']>;
};

export type GetPopularItemsResponse = PopularItemsV3 | GetPopularItemsError;

/** Gift Card Config */
export type GiftCardConfig = {
  __typename?: 'GiftCardConfig';
  /** Can add value */
  addValueAllowed: Scalars['Boolean'];
  /** Can lookup value */
  lookupAllowed: Scalars['Boolean'];
  /** Can Purchase Gift Cards */
  purchaseAllowed: Scalars['Boolean'];
  /** Can Redeem Gift Cards */
  redemptionAllowed: Scalars['Boolean'];
  /** Can allow guest to tip with a gift card */
  tipAllowed: Scalars['Boolean'];
};

/** Gift Card Links are so clients can direct guests to Toastweb URLs to purchase or manage gift cards */
export type GiftCardLinks = {
  __typename?: 'GiftCardLinks';
  /** The link in Toastweb for guests to purchase gift cards if null, the client should not display the link */
  purchaseLink?: Maybe<Scalars['String']>;
  /** The link in Toastweb for guests to check a gift card value or add value if addValueEnabled === true if null, the client should not display the link */
  checkValueLink?: Maybe<Scalars['String']>;
  /** Allows the client to set guest expectations for the checkValueLink link if true, indicate that value can be added if false, do no indicate value can be added */
  addValueEnabled: Scalars['Boolean'];
};

export type GiftCardPayment = {
  __typename?: 'GiftCardPayment';
  /** The balance of the gift card used as payment on the order */
  appliedBalance: Scalars['Float'];
};

export type GiftCardPaymentInput = {
  /** The full numeric card number, no spaces */
  cardNumber: Scalars['String'];
  /** The amount that is expected to be used during the place order action If check amount > expectedAvailableBalance and expectedAvailableBalance < the actual balance of the gift card at the time of placing the order and the alternative payment is Credit Card then the order will error because the amount charged to the card would be more than expected by the client */
  expectedAvailableBalance: Scalars['Float'];
};

export type GlobalGiftCardPaymentInput = {
  /** Identifier associated with the Toast Cash (global gift card). */
  cardIdentifier: Scalars['String'];
  /** Amount the Toast Cash (global gift card) can cover. */
  expectedAvailableBalance: Scalars['Float'];
};

export type GooglePayBillingAddress = {
  name: Scalars['String'];
  postalCode: Scalars['String'];
  countryCode: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  address3?: Maybe<Scalars['String']>;
  locality?: Maybe<Scalars['String']>;
  administrativeArea?: Maybe<Scalars['String']>;
  sortingCode?: Maybe<Scalars['String']>;
};

export type GooglePayInput = {
  token: Scalars['String'];
  billingAddress: GooglePayBillingAddress;
};

export type GuestCurrencyAccountPaymentInput = {
  expectedAvailableBalance: Scalars['Float'];
};

export type GuestFeedbackInput = {
  /** ID of the order relating to this feedback */
  orderGuid: Scalars['ID'];
  /** Party guest is a part of. */
  partyGuid?: Maybe<Scalars['ID']>;
  /** Guest's unique identifier. */
  partyMemberGuid?: Maybe<Scalars['ID']>;
  /** ID of the restaurant relating to this feedback */
  restaurantGuid: Scalars['ID'];
  /** either LIKE or DISLIKE */
  feedbackRating: FeedbackRating | `${FeedbackRating}`;
  /** array of string tags the diner associated with their experience */
  feedbackTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** optional additional feedback text */
  feedbackText?: Maybe<Scalars['String']>;
  /** additional metadata in the form of String: String */
  feedbackMeta?: Maybe<Array<Maybe<StringKeyValInput>>>;
  /** optional bool indicating the diner gave permission to be contacted */
  feedbackContactPermission?: Maybe<Scalars['Boolean']>;
  /** guid of the feedback record in the g2 feedback service */
  feedbackGuid?: Maybe<Scalars['String']>;
};

/** GROUP ORDERING */
export type GuestFeedbackResponse = {
  __typename?: 'GuestFeedbackResponse';
  /** ID of the order relating to this feedback */
  orderGuid: Scalars['ID'];
  /** ID of the restaurant relating to this feedback */
  restaurantGuid: Scalars['ID'];
  /** either LIKE or DISLIKE */
  feedbackRating: FeedbackRating | `${FeedbackRating}`;
  /** array of string tags the diner associated with their experience */
  feedbackTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** optional additional feedback text */
  feedbackText?: Maybe<Scalars['String']>;
  /** additional metadata in the form of String: String */
  feedbackMeta?: Maybe<Array<Maybe<StringKeyVal>>>;
  /** optional bool indicating the diner gave permission to be contacted */
  feedbackContactPermission?: Maybe<Scalars['Boolean']>;
  /** guid of the feedback record in the g2 feedback service */
  feedbackGuid?: Maybe<Scalars['String']>;
};

export type I18n = {
  __typename?: 'I18n';
  /** A restaurants operating currency USD, EUR, GBP & CAD */
  currency?: Maybe<Scalars['String']>;
  /** A restaurants operating locale. e.g. 'en-US', 'en-IE', 'fr-CH' */
  locale?: Maybe<Scalars['String']>;
  /** Restaurant's location / country. */
  country?: Maybe<Scalars['String']>;
};

/** Contains a mapping of size to image paths. The sizes other than **raw** are resized from the original image, which is represented by **raw**. Resizing is done by the toast-image-lambda Lambda function and is cached on CloudFront. For details, refer to the [toast-image-lambda documentation](https://github.com/toasttab/system-docs/blob/master/consumer/image-processing/cached-resizing/Readme.md). */
export type ImageSet = {
  __typename?: 'ImageSet';
  /** the raw image that has not gone through resizing */
  raw: Scalars['String'];
  /** 1920p */
  xxl: Scalars['String'];
  /** 1280p */
  xl: Scalars['String'];
  /** 1080p */
  large: Scalars['String'];
  /** 720p */
  medium: Scalars['String'];
  /** 360p */
  small: Scalars['String'];
  /** 180p */
  xs: Scalars['String'];
};

export type ImportAccountError = Error & {
  __typename?: 'ImportAccountError';
  code: ImportAccountErrorCode | `${ImportAccountErrorCode}`;
  message: Scalars['String'];
};

export enum ImportAccountErrorCode {
  /** The customerGuid account has been exported into too many accounts. */
  MaxExports = 'MAX_EXPORTS',
  /** The guestGuid account has imported too many accounts into it. */
  MaxImports = 'MAX_IMPORTS',
  /** The guestGuid account already has an import running. */
  ImportInProgress = 'IMPORT_IN_PROGRESS'
}

export type ImportAccountResponse = {
  __typename?: 'ImportAccountResponse';
  customerGuid: Scalars['ID'];
  guestGuid: Scalars['ID'];
};

export type ImportAccountResponseOrError = ImportAccountResponse | ImportAccountError;

export type ImportStatus = {
  __typename?: 'ImportStatus';
  canImport: Scalars['Boolean'];
  importCount: Scalars['Int'];
};

/** An order with at least one, but potentially more, checks which are open. */
export type InProgressOrder = {
  __typename?: 'InProgressOrder';
  guid: Scalars['ID'];
  /** The check requested. Only the check matching the client's requested check will be returned. Will return null if no check matches the requested number or guid. */
  check?: Maybe<InProgressOrderCheck>;
  /**
   * The check requested. Only the check matching the client's requested check will be returned. Will return null if no check matches the requested number or guid.
   * @deprecated use check
   */
  checks: Array<InProgressOrderCheck>;
  /** The restaurant associated with the order. Used so that the client can know if amex is accepted. */
  restaurant: Restaurant;
};

/** An open check associated with an InProgressOrder. */
export type InProgressOrderCheck = {
  __typename?: 'InProgressOrderCheck';
  guid: Scalars['ID'];
  /** All selections that have not been voided associated with the InProgressOrderCheck */
  selections: Array<InProgressOrderSelection>;
  /** The amount, before tax, on the check */
  subTotal: Scalars['Float'];
  /** The amount of tax on the check */
  taxAmount: Scalars['Float'];
  /** The total amount associated with the check */
  totalAmount: Scalars['Float'];
  /** The total amount unpaid on the check */
  amountRemaining: Scalars['Float'];
  /** The sum of all surcharges applied to the check, most commonly an auto gratuity or less commonly a kitchen appreciation fee. */
  surchargeAmount: Scalars['Float'];
};

/** Fetch an order with at least one open check. The order can be identified by the number of a check it contains, or by the table name the order is located at. */
export type InProgressOrderInput = {
  /** The guid of the restaurant which has the order being paid for */
  restaurantGuid: Scalars['ID'];
  /** The guid of the check to be paid for */
  checkGuid?: Maybe<Scalars['String']>;
  /** Removing support. Will return an error if requested. Please only use checkguid */
  tableName?: Maybe<Scalars['String']>;
};

/** A selection associated with an InProgressOrderCheck. */
export type InProgressOrderSelection = {
  __typename?: 'InProgressOrderSelection';
  /** The name of the selection */
  name: Scalars['String'];
  /** The net price, after tax and discounts, for the selection */
  price: Scalars['Float'];
  /**
   * The number of selections this object represents
   * @deprecated Quantity Fraction
   */
  quantity: Scalars['Int'];
  /** The number of selections this object represents, as a float. */
  quantityFraction: Scalars['Float'];
};

export type IncrementAuthAndFireInput = {
  /** Party member is a part of. */
  partyGuid: Scalars['ID'];
  /** Customer's information. */
  customer?: Maybe<OrderAtTableCustomerInput>;
  /** Member's unique identifier. */
  partyMemberGuid: Scalars['ID'];
  /** Member's auth token. */
  memberAuthToken: Scalars['String'];
};

/**
 * Indicates that although the intended result of a query or mutation was achieved,
 * there are side effects that occurred that wouldn't affect downstream actions.
 */
export type Info = {
  message: Scalars['String'];
};

export type InitializeSplitPaymentDataInput = {
  /** Party member is a part of. */
  partyGuid: Scalars['ID'];
  /** Member's unique identifier. */
  partyMemberGuid: Scalars['ID'];
  /** Member's auth token. */
  memberAuthToken: Scalars['String'];
  /** The split type and metadata */
  splitPaymentInitializationData: SplitPaymentInitializationDataInput;
  /** Indicates whether a split type is being overridden */
  overrideSplit: Scalars['Boolean'];
};

export type InitiateEmailPasswordResetInput = {
  /** Email address to send the password reset email to */
  email: Scalars['String'];
  /** Restaurant guid, which is used to fetch the restaurant name used in the password reset email to give context to the user where their account is from. This is more important for white label sources where the user may not know what "Toast" is. If not provided, will send the email with "Toast" as the restaurant name. */
  restaurantGuid?: Maybe<Scalars['ID']>;
  /** Url link sent in the email, which is appended with the reset token as a query parameter. Should be used to redirect the user from their email to a page with logic able to handle the the final step of resetting the password (i.e. the **resetPassword** mutation) */
  redirectUrl: Scalars['String'];
};

export type InitiateEmailPasswordResetResponse = {
  __typename?: 'InitiateEmailPasswordResetResponse';
  success: Scalars['Boolean'];
};

export type ItemFeedbackInput = {
  /** Can be optionally provided to update an existing item review */
  feedbackGuid?: Maybe<Scalars['ID']>;
  /** Time of submission provided by the client to support resolving race-conditions server-side, in ISO 8061 format. */
  submissionTime: Scalars['String'];
  itemGuid: Scalars['ID'];
  itemGroupGuid?: Maybe<Scalars['ID']>;
  displayName?: Maybe<Scalars['String']>;
  thumbRating?: Maybe<ItemFeedbackThumbRatingValue | `${ItemFeedbackThumbRatingValue}`>;
  /** *Constraints:* * Maximal length: `250` */
  textFeedback?: Maybe<Scalars['String']>;
};

export type ItemFeedbackPrompt = {
  __typename?: 'ItemFeedbackPrompt';
  itemGuid: Scalars['ID'];
  itemGroupGuid?: Maybe<Scalars['ID']>;
  displayName: Scalars['String'];
};

export type ItemFeedbackSurvey = {
  __typename?: 'ItemFeedbackSurvey';
  orderGuid: Scalars['ID'];
  checkGuid: Scalars['ID'];
  restaurantGuid: Scalars['ID'];
  paymentGuid: Scalars['ID'];
  /** The parameters to provide prompts for an item feedback survey Currently supports a maximum of ten prompts */
  itemPrompts: Array<ItemFeedbackPrompt>;
};


export type ItemFeedbackSurveyItemPromptsArgs = {
  maxPrompts: Scalars['Int'];
};

export type ItemFeedbackSurveyError = Error & {
  __typename?: 'ItemFeedbackSurveyError';
  message: Scalars['String'];
};

export type ItemFeedbackSurveyLookupInput = {
  restaurantGuid: Scalars['ID'];
  orderGuid: Scalars['ID'];
  checkGuid: Scalars['ID'];
  paymentGuid: Scalars['ID'];
};

export type ItemFeedbackSurveyResponse = ItemFeedbackSurvey | ItemFeedbackSurveyError;

export enum ItemFeedbackThumbRatingValue {
  Like = 'LIKE',
  Dislike = 'DISLIKE'
}

export type ItemLevelFeedbackConfig = {
  __typename?: 'ItemLevelFeedbackConfig';
  enabled: Scalars['Boolean'];
};

export type ItemLevelFeedbackInput = {
  restaurantGuid: Scalars['ID'];
  orderGuid: Scalars['ID'];
  checkGuid: Scalars['ID'];
  paymentGuid: Scalars['ID'];
  partyMemberGuid?: Maybe<Scalars['ID']>;
  partyGuid?: Maybe<Scalars['ID']>;
  itemRatings: Array<ItemFeedbackInput>;
};

/** General usage is deprecated in favor of ItemLevelFeedbackSubmissionsStatus */
export type ItemLevelFeedbackSubmission = {
  __typename?: 'ItemLevelFeedbackSubmission';
  /** An identifier for the created feedback record. Can be re-used in future mutations in order to update an existing record. For failed submissions, an empty string is returned (prefer ItemLevelFeedbackSubmissionsStatus for a better-formatted schema response) */
  feedbackGuid: Scalars['ID'];
  /** The itemGuid for which the feedback was submitted. Acts as a stable identifier for a review submission in the absence of a feedbackGuid existing prior to the initial feedback submission. */
  itemGuid: Scalars['ID'];
  status: ItemLevelFeedbackSubmissionStatus | `${ItemLevelFeedbackSubmissionStatus}`;
};

export type ItemLevelFeedbackSubmissionFailure = {
  __typename?: 'ItemLevelFeedbackSubmissionFailure';
  /** The itemGuid for which the feedback was submitted. Acts as a stable identifier for a review submission in the absence of a feedbackGuid existing prior to the initial feedback submission. */
  itemGuid: Scalars['ID'];
};

export type ItemLevelFeedbackSubmissionResponse = {
  __typename?: 'ItemLevelFeedbackSubmissionResponse';
  /** @deprecated Use submissionsStatus */
  submissions: Array<ItemLevelFeedbackSubmission>;
  submissionsStatus: Array<ItemLevelFeedbackSubmissionsStatus>;
};

export enum ItemLevelFeedbackSubmissionStatus {
  Success = 'SUCCESS',
  Failure = 'FAILURE'
}

export type ItemLevelFeedbackSubmissionSuccess = {
  __typename?: 'ItemLevelFeedbackSubmissionSuccess';
  /** An identifier for the created feedback record. Can be re-used in future mutations in order to update an existing record. */
  feedbackGuid: Scalars['ID'];
  /** The itemGuid for which the feedback was submitted. Acts as a stable identifier for a review submission in the absence of a feedbackGuid existing prior to the initial feedback submission. */
  itemGuid: Scalars['ID'];
};

export type ItemLevelFeedbackSubmissionsStatus = ItemLevelFeedbackSubmissionSuccess | ItemLevelFeedbackSubmissionFailure;

export type ItemTag = {
  __typename?: 'ItemTag';
  name?: Maybe<Scalars['String']>;
  guid: Scalars['ID'];
};

/** Line items pre-calculated for frontend display. All amounts should be positive: The frontend will decide if they should be shown negative. */
export type LineItems = {
  __typename?: 'LineItems';
  subtotal: Scalars['Float'];
  total: Scalars['Float'];
  tax: Scalars['Float'];
  /** Null means 0 */
  serviceChargeTotal?: Maybe<Scalars['Float']>;
  /** Null means 0 */
  discounts?: Maybe<Scalars['Float']>;
  /** Null means 0 */
  loyaltyAmount?: Maybe<Scalars['Float']>;
  /** Null means 0 */
  totalPaid?: Maybe<Scalars['Float']>;
  /** Null means 0 */
  totalRemaining?: Maybe<Scalars['Float']>;
};

export type Location = {
  __typename?: 'Location';
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  zip: Scalars['String'];
  phone: Scalars['String'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  distanceFromCustomer?: Maybe<Scalars['Float']>;
};

/** Response type returned when a login request fails (e.g. due to an incorrect email/password combination) */
export type LoginError = Error & {
  __typename?: 'LoginError';
  /** Type of error encountered - use this field to differentiate between errors, for example an account being locked versus the wrong login information used */
  code: LoginErrorCode | `${LoginErrorCode}`;
  /** Additional information about the error encountered */
  message: Scalars['String'];
};

export enum LoginErrorCode {
  IncorrectCredentials = 'INCORRECT_CREDENTIALS',
  EmailLocked = 'EMAIL_LOCKED',
  ServiceUnavailable = 'SERVICE_UNAVAILABLE'
}

/** Email/password combination for signing in */
export type LoginInput = {
  /** Account email */
  email: Scalars['String'];
  /** Account password */
  password: Scalars['String'];
};

export type LoginResponse = AuthenticationResponse | MfaChallengeGeneratedResponse | LoginError;

export type LookupPartiesByCheckInput = {
  checkGuid: Scalars['String'];
  restaurantGuid: Scalars['String'];
};

export type LookupPartiesByTableInput = {
  tableName: Scalars['String'];
  restaurantGuid: Scalars['String'];
  activeOnly: Scalars['Boolean'];
};

export type LoyaltyAccountCreationInput = {
  /** Guid of the restaurant that a loyalty account is being created at */
  restaurantGuid: Scalars['ID'];
  /** Guid generated by the front-end client that is constant per session (i.e. retries use the same guid). */
  transactionGuid: Scalars['ID'];
  /** Guid of the check from the placed order. Used to accrue points for that order despite the account being created afterwards. */
  checkIdentifier: Scalars['ID'];
};

export type LoyaltyConfig = {
  __typename?: 'LoyaltyConfig';
  /** The one time point bonus for creating a new loyalty account */
  loyaltySignupBonus?: Maybe<Scalars['Int']>;
  /** Whether loyalty signups are enabled at this restaurant or not. Will be false if the restaurant does not have a loyalty program. */
  loyaltySignupEnabled: Scalars['Boolean'];
  /** Whether loyalty redemptions are enabled at this restaurant or not. Will be false if the restaurant does not have loyalty program. */
  loyaltyRedemptionEnabled: Scalars['Boolean'];
  /** The rate at which points are accrued for loyalty. Example: 2.0 means "1 point is earned for every 2 dollars" */
  accrualRate?: Maybe<Scalars['Float']>;
  /** The number of points that a diner needs to reach before they are allowed to redeem */
  accrualTarget?: Maybe<Scalars['Int']>;
  /** The amount each point is worth. Example: 0.2 means "0.2 dollars for every point" */
  conversionRate?: Maybe<Scalars['Float']>;
  /** The signup method. It can be "EMAIL" or "PHONE_NUMBER". Will be null if the restaurant does not have loyalty program. */
  signupMethod?: Maybe<Scalars['String']>;
  /** Whether loyalty program uses a custom description or not. Will be false if the restaurant does not have loyalty program. */
  useCustomDescription: Scalars['Boolean'];
  /** The custom description text, in case the loyalty program uses one. Will be null if the restaurant does not have loyalty program. */
  customDescription?: Maybe<Scalars['String']>;
  /** The loyalty program name. Will be null if the restaurant does not have loyalty program. Clients usually display "{Restaurant Name} Rewards Program" as a fallback. */
  programName?: Maybe<Scalars['String']>;
  /** The loyalty program type. Will be either "CASHBACK" or "ITEM". Defaulted to "CASHBACK" */
  rewardType: Scalars['String'];
  /** The loyalty program description. */
  programDescription?: Maybe<Scalars['String']>;
};

export type LoyaltyDetails = {
  __typename?: 'LoyaltyDetails';
  vendor?: Maybe<Scalars['String']>;
  referenceId?: Maybe<LoyaltyRedemptionType | `${LoyaltyRedemptionType}`>;
};

/** An object representing a (restaurant-specific) loyalty redemption, which is tied to a customermgmt/loyalty account */
export type LoyaltyDiscount = {
  __typename?: 'LoyaltyDiscount';
  guid: Scalars['ID'];
  amount: Scalars['Float'];
};

export type LoyaltyRedemption = {
  __typename?: 'LoyaltyRedemption';
  type: LoyaltyRedemptionType | `${LoyaltyRedemptionType}`;
  redemptionAmount: Scalars['Float'];
  redemptionGuid: Scalars['ID'];
};

export enum LoyaltyRedemptionType {
  BirthdayRewards = 'BIRTHDAY_REWARDS',
  LoyaltyDollarsAmounts = 'LOYALTY_DOLLARS_AMOUNTS',
  LoyaltyPointsAmount = 'LOYALTY_POINTS_AMOUNT',
  ItemRewards = 'ITEM_REWARDS'
}

/** link to loyalty docs: https://github.toasttab.com/toasttab/toast-cards/blob/main/toast-cards-api/src/main/java/com/toasttab/service/cards/api/inquiry/redemption/RewardMessagesData.kt */
export type LoyaltyRewardMessages = {
  __typename?: 'LoyaltyRewardMessages';
  nextRewardMessage?: Maybe<Scalars['String']>;
  nextRewardMiniMessage?: Maybe<Scalars['String']>;
  unlockedMessage?: Maybe<Scalars['String']>;
  rewardLabelMessage?: Maybe<Scalars['String']>;
  rewardValueMessage?: Maybe<Scalars['String']>;
};

/** link to loyalty docs: https://github.toasttab.com/toasttab/toast-cards/blob/main/toast-cards-api/src/main/java/com/toasttab/service/cards/api/inquiry/redemption/RewardStateData.kt */
export type LoyaltyRewardState = {
  __typename?: 'LoyaltyRewardState';
  leftToTarget?: Maybe<Scalars['Int']>;
  messages?: Maybe<LoyaltyRewardMessages>;
};

export type LoyaltySignupError = Error & {
  __typename?: 'LoyaltySignupError';
  code: LoyaltySignupErrorCode | `${LoyaltySignupErrorCode}`;
  message: Scalars['String'];
};

export enum LoyaltySignupErrorCode {
  SignupFailed = 'SIGNUP_FAILED'
}

export type LoyaltySignupInput = {
  loyaltyAccountCreationInput: LoyaltyAccountCreationInput;
  customerGuid: Scalars['ID'];
};

export type LoyaltySignupResponse = {
  __typename?: 'LoyaltySignupResponse';
  customer?: Maybe<Customer>;
};

export type LoyaltySignupResponseOrError = LoyaltySignupResponse | LoyaltySignupError;

export type MdsCustomServerNotificationInput = {
  /** Restaurant identifier */
  restaurantGuid: Scalars['ID'];
  /** Party member is a part of. */
  partyGuid: Scalars['ID'];
  /** Member's unique identifier. */
  partyMemberGuid: Scalars['ID'];
  /** Member's auth token. */
  memberAuthToken: Scalars['String'];
  /** Message input by the guest */
  message: Scalars['String'];
  /** Table name of this order */
  tableName?: Maybe<Scalars['String']>;
};

export type MdsCustomServerNotificationResponse = MdsServerNotificationResponse | OptPartyError;

export type MdsServerNotificationResponse = {
  __typename?: 'MDSServerNotificationResponse';
  sent: Scalars['Boolean'];
};

export type MnpCreatePartyInput = {
  /** Used for disambiguating table names */
  restaurantGuid: Scalars['ID'];
  /** Table the Party represents */
  tableName: Scalars['String'];
  /** User's name. */
  name?: Maybe<Scalars['String']>;
  /** User's contact info. Phone/email. */
  contactInfo?: Maybe<ContactInfo>;
};

export type MakeCreditCardPrimaryError = Error & {
  __typename?: 'MakeCreditCardPrimaryError';
  code: MakeCreditCardPrimaryErrorCode | `${MakeCreditCardPrimaryErrorCode}`;
  message: Scalars['String'];
};

export enum MakeCreditCardPrimaryErrorCode {
  CardNotFound = 'CARD_NOT_FOUND'
}

/** Guid of the desired card to be made the primary card for the account */
export type MakeCreditCardPrimaryInput = {
  cardGuid: Scalars['ID'];
};

export type MakeCreditCardPrimaryResponse = {
  __typename?: 'MakeCreditCardPrimaryResponse';
  customer: Customer;
};

export type MakeCreditCardPrimaryResponseOrError = MakeCreditCardPrimaryResponse | MakeCreditCardPrimaryError;

export type MenuGroupV2 = {
  __typename?: 'MenuGroupV2';
  name: Scalars['String'];
  guid: Scalars['ID'];
  masterID?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  groups: Array<MenuGroupV2>;
  items: Array<MenuItemV2>;
  itemTags: Array<ItemTag>;
  dietaryItemTags: Array<ItemTag>;
};

export type MenuItemV2 = {
  __typename?: 'MenuItemV2';
  name: Scalars['String'];
  guid: Scalars['ID'];
  itemGroupGuid: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  pricingStrategy: PricingStrategy | `${PricingStrategy}`;
  pricingRules?: Maybe<PricingRules>;
  isDiscountable: Scalars['Boolean'];
  taxInfo: Array<Scalars['String']>;
  calories?: Maybe<Scalars['Int']>;
  unitOfMeasure: UnitOfMeasure | `${UnitOfMeasure}`;
  usesFractionalQuantity: Scalars['Boolean'];
  outOfStock: Scalars['Boolean'];
  stockQuantity?: Maybe<Scalars['Float']>;
  modifierGroups: Array<ModifierGroupV2>;
  imageUrl?: Maybe<Scalars['String']>;
  imageUrls?: Maybe<ImageSet>;
  itemTags: Array<ItemTag>;
  dietaryItemTags: Array<ItemTag>;
  hasModifiers: Scalars['Boolean'];
};


export type MenuItemV2ModifierGroupsArgs = {
  nestingLevel?: Maybe<Scalars['Int']>;
};

export type MenuV2 = {
  __typename?: 'MenuV2';
  name: Scalars['String'];
  id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  groups: Array<MenuGroupV2>;
};

/** Response to indicate that a Multi-Factor Auth challenge is required */
export type MfaChallengeGeneratedResponse = {
  __typename?: 'MfaChallengeGeneratedResponse';
  /** MFA Challenge Token - required on verification */
  challengeToken: Scalars['String'];
  /** Method used to send the MFA code */
  mfaType: MfaType | `${MfaType}`;
  /** Masked email address/phone number to which the MFA code was sent */
  maskedRecipient?: Maybe<Scalars['String']>;
};

/** MFA Challenge input */
export type MfaLoginInput = {
  /** Challenge token sent in the **login** response */
  challengeToken: Scalars['String'];
  /** MFA Code sent via email/sms */
  code: Scalars['String'];
  /** Email used to look up user account */
  email: Scalars['String'];
};

export enum MfaType {
  EmailChallenge = 'EMAIL_CHALLENGE',
  SmsChallenge = 'SMS_CHALLENGE'
}

export type ModifierGroupInput = {
  guid: Scalars['ID'];
  modifiers: Array<SelectionInput>;
};

export type ModifierGroupV2 = {
  __typename?: 'ModifierGroupV2';
  name: Scalars['String'];
  guid: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  pricingStrategy: PricingStrategy | `${PricingStrategy}`;
  pricingRules?: Maybe<PricingRules>;
  defaultOptionsChargePrice: Scalars['Boolean'];
  defaultOptionsSubstitutionPricing: Scalars['Boolean'];
  minSelections: Scalars['Int'];
  maxSelections?: Maybe<Scalars['Int']>;
  requiredMode: RequiredMode | `${RequiredMode}`;
  isMultiSelect: Scalars['Boolean'];
  modifiers: Array<ModifierOptionV2>;
};

export type ModifierOptionV2 = {
  __typename?: 'ModifierOptionV2';
  name: Scalars['String'];
  guid: Scalars['ID'];
  itemGuid: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  pricingStrategy: PricingStrategy | `${PricingStrategy}`;
  pricingRules?: Maybe<PricingRules>;
  calories?: Maybe<Scalars['Int']>;
  isDefault: Scalars['Boolean'];
  allowsDuplicates: Scalars['Boolean'];
  unitOfMeasure: UnitOfMeasure | `${UnitOfMeasure}`;
  modifierGroups: Array<ModifierGroupV2>;
  outOfStock: Scalars['Boolean'];
  stockQuantity?: Maybe<Scalars['Float']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /**
   * Places the order using the cart referenced by **cartGuid** using an Apple Pay payment token, and sends an email receipt to the email in the customer object. If the cart was modified by the server, e.g. an item on the cart is out of stock or order time has been adjusted, the cart will be returned in the **PlaceOrderCartUpdatedError** response since it was modified; otherwise either a successful **PlaceOrderResponse** will be returned (with a warning if afterPlaceOrder failed) or a generic **PlaceOrderError** with a code specifying the error if the request was unsuccessful.
   * @deprecated Use placeOPTApplePayPAYGOrder for DDI
   */
  placeApplePayOrder: PlaceApplePayOrderResponse;
  /** Adds an item to a party member's cart */
  optAddItemToPartyMemberCartV2: OptPartyRefreshResponseV2;
  /** Edits an item in a party member's cart */
  optEditItemInPartyMemberCartV2: OptPartyRefreshResponseV2;
  /** Edits an item in a party member's cart */
  optDeleteItemInPartyMemberCartV2: OptPartyRefreshResponseV2;
  /** Creates a preauthorization tab with an order containing the saved preauth info, then fires a round of items for that order */
  optAddPreauthAndFireV2: OptPartyRefreshResponseV2;
  /** Increments auth amount, then fires a round of items for that order */
  optIncrementAuthAndFire: OptPartyRefreshResponseV2;
  /** Validates cart */
  optValidateV2: OptPartyRefreshResponseV2;
  /** Fires items in member's carts to an order */
  optFireTabRoundV2: OptPartyRefreshResponseV2;
  /** Copies a selection from a party member's tab and adds it to their cart */
  optReorderItemV2: OptPartyRefreshResponseV2;
  /** Create a customer account, which can be used to store basic info, past orders, and credit cards */
  createAccount: CreateAccountResponseOrError;
  /** Start profile creation (process) to start passwordless auth flow */
  startProfileCreation: StartProfileCreationResponseOrError;
  /** Complete profile creation after user has confirmed their phone number with Auth0 thus verifying the users account */
  completeProfileCreation: CompleteProfileCreationResponseOrError;
  /** Create a customer account, which can be used to store basic info, past orders, and credit cards This account has already been verified through sms verification and will return an authenticated response. */
  createConfirmedAccount: CreateConfirmedAccountResponseOrError;
  /** Verifies the email for a new account, which finishes account creation allowing the account to be logged into and used */
  verifyEmail: VerifyEmailResponse;
  /** Update the firstName, lastName, and/or phone number on the account */
  updateBasicInfo: UpdateBasicInfoResponseOrError;
  /** Updates an existing address on the account */
  updateAddress: UpdateAddressResponseOrError;
  /** Deletes an existing address on the account */
  deleteAddress: DeleteAddressResponseOrError;
  /** Sets the credit card as the primary (used by default) card on the account */
  makeCreditCardPrimary: MakeCreditCardPrimaryResponseOrError;
  /** Removes the credit card from the account (and the associated secure token for the card from ccpartner) */
  deleteCreditCard: DeleteCreditCardResponseOrError;
  /** Change the password for the account, requires the old password to match in order to update successfully */
  updatePassword: UpdatePasswordResponseOrError;
  /** Sends an email to the given address which contains a confirmation link with a token that will allow a new password to be entered for the account. */
  initiateEmailPasswordReset: InitiateEmailPasswordResetResponse;
  /** Uses the **resetToken** (obtained from the email sent by **initiateEmailPasswordReset**) to change the old password to the specified new password. */
  resetPassword: ResetPasswordResponse;
  /** Create a new loyalty account using the same email as the customermgmt account. This shared email is the only link between the two accounts. Requires the customer account to already have a saved basic info (first name, last name, phone). */
  loyaltySignup: LoyaltySignupResponseOrError;
  /** Will attempt to import and merge data from the given customermgmt account to the guest-profiles account. Request must be sent along with valid access tokens for both the customermgmt and guest-profiles accounts in the 'toast-customer-access' and 'authorization' headers, respectively. The customerGuid and guestGuid will be extracted from these tokens and used for the import. */
  importAccount: ImportAccountResponseOrError;
  /** Create new access and refresh tokens using an email and password login for the account. These tokens are used for requests that require a user to be logged in, such as getting the list of a user's saved credit cards. See **AuthenticationResponse** for more information on token usage. */
  login: LoginResponse;
  /** Create new access and refresh tokens using a previously obtained refresh token (from ***login*** or from ***refresh***). These tokens are used for requests that require a user to be logged in, such as getting the list of a user's saved credit cards. See **AuthenticationResponse** for more information on token usage. */
  refresh: RefreshResponse;
  /** MFA Challenge Login Mutation - creates new access and refresh tokens when code is correct. */
  mfaLogin: LoginResponse;
  /** Apply a promo code to an order at table tab */
  optPartyPaygApplyPromo: OptPartyRefreshResponseV2;
  /** Remove promo code associated with a table tab */
  optPartyPaygRemovePromo: OptPartyRefreshResponseV2;
  /** Apply a promo code to an order at table tab */
  optPartyTabApplyPromo: OptPartyRefreshResponseV2;
  /** Remove promo code associated with a table tab */
  optPartyTabRemovePromo: OptPartyRefreshResponseV2;
  /** @deprecated Use optApplyLoyaltyPayG */
  optApplyPayGLoyalty: OptPartyRefreshResponseV2;
  optApplyLoyaltyPayG: OptPartyRefreshResponseV2;
  optApplyLoyaltyPayGV2: OptPartyRefreshResponseV2;
  optApplyLoyaltyTabs: OptPartyRefreshResponseV2;
  optApplyLoyaltyTabsV2: OptPartyRefreshResponseV2;
  optAddDiscountPayG: OptPartyRefreshResponseV2;
  optAddDiscountPayGV2: OptPartyRefreshResponseV2;
  optRemoveDiscountPayG: OptPartyRefreshResponseV2;
  optRemoveDiscountPayGV2: OptPartyRefreshResponseV2;
  optAddDiscountTabs: OptPartyRefreshResponseV2;
  optAddDiscountTabsV2: OptPartyRefreshResponseV2;
  /**
   * Allows clients to pay for an open check
   * @deprecated No longer supported.
   */
  payForCheck: PayForCheckResponseOrError;
  /** Allows clients to sign-up to restaurant's rewards program */
  signUpToLoyalty: SignUpToLoyaltyResponseOrError;
  /** Submit feedback from a guest */
  submitGuestFeedback: GuestFeedbackResponse;
  /** Creates a Party with Refresh Return */
  optCreatePartyRefreshV2: OptPartyRefreshResponseV2;
  /** Creates a Party with Refresh Return */
  mnpCreatePartyRefreshV2: OptPartyRefreshResponseV2;
  /** Creates a Party with Refresh Return */
  stpCreatePartyRefreshV2: OptPartyRefreshResponseV2;
  /** Creates a party on a specific order/check or joins a Party if one already exists for that order/check with Refresh Return */
  optJoinOrCreatePartyDynamicQrRefresh: OptPartyRefreshResponseV2;
  /** Creates a party on a specific order/check or joins a Party if one already exists for that order/check with Refresh Return */
  stpJoinOrCreatePartyByMainCheckGuid: OptPartyRefreshResponseV2;
  /** Joins a Party */
  optJoinPartyV2: OptPartyRefreshResponseV2;
  /** Joins a Party */
  optJoinPartySecureV2: OptPartyRefreshResponseV2;
  /** Sets the type of split for the check and how many portions it's being split if the SplitMode is 'EVEN' */
  optApplySplitType: OptPartyRefreshResponseV2;
  /** Updates the number of split even portions that have been paid for */
  optApplyEvenSplitPaidPortion: OptPartyRefreshResponseV2;
  /** Removes splitPaymentData from the party */
  optRemoveSplitPaymentData: OptPartyRefreshResponseV2;
  /** Updates a partyMember in a party */
  updatePartyMember: PartyMemberResponse;
  /** Applies payment and fires PayG order. */
  optPartyPayGCloseout: OptCloseoutResponse;
  /** Applies payment to a party order's main check. */
  optPartyTabCloseout: OptCloseoutResponse;
  /** Persists a splitting of a party's check and accepts payment for that check. */
  optSplitCheckCloseout: OptCloseoutResponse;
  /** Create payment intent. */
  createPaymentIntent: CreatePaymentIntentResponse;
  /** Covers the "split even" use case in Scan to Pay, in which clients specify exactly how to divvy up selections. */
  createPaymentIntentForSplitCheckBySelections: CreatePaymentIntentResponse;
  /** Update payment intent. */
  updatePaymentIntent: UpdatePaymentIntentResponse;
  /** Covers the "split even" use case in Scan to Pay, in which clients specify exactly how to divvy up selections. */
  updatePaymentIntentForSplitCheckBySelections: UpdatePaymentIntentResponse;
  generateSmsVerificationCode?: Maybe<GenerateSmsVerificationCodeResponse>;
  verifySmsCode?: Maybe<VerifySmsCodeResponse>;
  /** accepts the validationURL generated by Apple Pay JS on the client and passes it through to the Apple Pay server’s Payment Session endpoint */
  validateApplePayMerchant: ValidateApplePayMerchantResponse;
  /** Sends a customer notification to the assigned server. */
  mdsCustomServerNotification: MdsCustomServerNotificationResponse;
  submitItemLevelFeedback: ItemLevelFeedbackSubmissionResponse;
};


export type MutationPlaceApplePayOrderArgs = {
  input: PlaceApplePayOrderInput;
};


export type MutationOptAddItemToPartyMemberCartV2Args = {
  optAddItemToPartyMemberCartInput: OptAddItemToPartyMemberCartInput;
};


export type MutationOptEditItemInPartyMemberCartV2Args = {
  optEditItemInPartyMemberCartInput: OptEditOrDeleteItemInPartyMemberCartInput;
};


export type MutationOptDeleteItemInPartyMemberCartV2Args = {
  optDeleteItemInPartyMemberCartInput: OptEditOrDeleteItemInPartyMemberCartInput;
};


export type MutationOptAddPreauthAndFireV2Args = {
  addPreauthAndFireInput: AddPreauthToPartyInput;
};


export type MutationOptIncrementAuthAndFireArgs = {
  incrementAuthAndFire: IncrementAuthAndFireInput;
};


export type MutationOptValidateV2Args = {
  optValidateInput: OptValidateInput;
};


export type MutationOptFireTabRoundV2Args = {
  optFireTabRoundInput: OptFireTabRoundInput;
};


export type MutationOptReorderItemV2Args = {
  optReorderItemInput: OptReorderItemInput;
};


export type MutationCreateAccountArgs = {
  input: CreateAccountInput;
};


export type MutationStartProfileCreationArgs = {
  input: StartProfileCreationInput;
};


export type MutationCompleteProfileCreationArgs = {
  input: CompleteProfileCreationInput;
};


export type MutationCreateConfirmedAccountArgs = {
  input: CreateConfirmedAccountInput;
};


export type MutationVerifyEmailArgs = {
  input: VerifyEmailInput;
};


export type MutationUpdateBasicInfoArgs = {
  input: BasicInfoInput;
};


export type MutationUpdateAddressArgs = {
  input: UpdateAddressInput;
};


export type MutationDeleteAddressArgs = {
  input: DeleteAddressInput;
};


export type MutationMakeCreditCardPrimaryArgs = {
  input: MakeCreditCardPrimaryInput;
};


export type MutationDeleteCreditCardArgs = {
  input: DeleteCreditCardInput;
};


export type MutationUpdatePasswordArgs = {
  input: UpdatePasswordInput;
};


export type MutationInitiateEmailPasswordResetArgs = {
  input: InitiateEmailPasswordResetInput;
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationLoyaltySignupArgs = {
  input: LoyaltySignupInput;
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationRefreshArgs = {
  input: RefreshInput;
};


export type MutationMfaLoginArgs = {
  input: MfaLoginInput;
};


export type MutationOptPartyPaygApplyPromoArgs = {
  optPartyPaygPromoInput: OptPartyPaygPromoInput;
};


export type MutationOptPartyPaygRemovePromoArgs = {
  partyGuid: Scalars['ID'];
  partyMemberGuid: Scalars['ID'];
  memberAuthToken: Scalars['String'];
};


export type MutationOptPartyTabApplyPromoArgs = {
  optPartyTabPromoInput: OptPartyTabPromoInput;
};


export type MutationOptPartyTabRemovePromoArgs = {
  partyGuid: Scalars['ID'];
  partyMemberGuid: Scalars['ID'];
  memberAuthToken: Scalars['String'];
};


export type MutationOptApplyPayGLoyaltyArgs = {
  optApplyPayGLoyaltyInput: OptApplyPayGLoyaltyInput;
};


export type MutationOptApplyLoyaltyPayGArgs = {
  optApplyLoyaltyInput: OptApplyLoyaltyInput;
};


export type MutationOptApplyLoyaltyPayGv2Args = {
  input: OptApplyLoyaltyInput;
};


export type MutationOptApplyLoyaltyTabsArgs = {
  optApplyLoyaltyInput: OptApplyLoyaltyInput;
};


export type MutationOptApplyLoyaltyTabsV2Args = {
  input: OptApplyLoyaltyInput;
};


export type MutationOptAddDiscountPayGArgs = {
  input: OptDiscountInput;
};


export type MutationOptAddDiscountPayGv2Args = {
  input: OptDiscountInput;
};


export type MutationOptRemoveDiscountPayGArgs = {
  input: OptDiscountInput;
};


export type MutationOptRemoveDiscountPayGv2Args = {
  input: OptDiscountInput;
};


export type MutationOptAddDiscountTabsArgs = {
  input: OptDiscountInput;
};


export type MutationOptAddDiscountTabsV2Args = {
  input: OptDiscountInput;
};


export type MutationPayForCheckArgs = {
  input: PayForCheckInput;
};


export type MutationSignUpToLoyaltyArgs = {
  input: SignUpToLoyaltyInput;
};


export type MutationSubmitGuestFeedbackArgs = {
  guestFeedbackInput: GuestFeedbackInput;
};


export type MutationOptCreatePartyRefreshV2Args = {
  optCreatePartyInput: OptCreatePartyInput;
};


export type MutationMnpCreatePartyRefreshV2Args = {
  mnpCreatePartyInput: MnpCreatePartyInput;
};


export type MutationStpCreatePartyRefreshV2Args = {
  stpCreatePartyInput: StpCreatePartyInput;
};


export type MutationOptJoinOrCreatePartyDynamicQrRefreshArgs = {
  optDynamicQrJoinOrCreatePartyInput: OptDynamicQrJoinOrCreatePartyInput;
};


export type MutationStpJoinOrCreatePartyByMainCheckGuidArgs = {
  stpJoinOrCreatePartyByMainCheckGuidInput: StpJoinOrCreatePartyByMainCheckGuidInput;
};


export type MutationOptJoinPartyV2Args = {
  optJoinPartyInput: OptJoinPartyInput;
};


export type MutationOptJoinPartySecureV2Args = {
  optJoinPartySecureInput: OptJoinPartySecureInput;
};


export type MutationOptApplySplitTypeArgs = {
  optSplitInput: InitializeSplitPaymentDataInput;
};


export type MutationOptApplyEvenSplitPaidPortionArgs = {
  optSplitInput: SplitPaymentPaidPortionInput;
};


export type MutationOptRemoveSplitPaymentDataArgs = {
  partyGuid: Scalars['ID'];
  partyMemberGuid: Scalars['ID'];
  memberAuthToken: Scalars['String'];
};


export type MutationUpdatePartyMemberArgs = {
  updatePartyMemberInput: UpdatePartyMemberInput;
};


export type MutationOptPartyPayGCloseoutArgs = {
  optCloseoutInput: OptCloseoutInput;
};


export type MutationOptPartyTabCloseoutArgs = {
  optCloseoutInput?: Maybe<OptCloseoutInput>;
};


export type MutationOptSplitCheckCloseoutArgs = {
  optCloseoutInput: OptCloseoutInput;
};


export type MutationCreatePaymentIntentArgs = {
  createPaymentIntentInput: CreatePaymentIntentInput;
};


export type MutationCreatePaymentIntentForSplitCheckBySelectionsArgs = {
  createPaymentIntentInput: CreatePaymentIntentForSplitCheckBySelectionsInput;
};


export type MutationUpdatePaymentIntentArgs = {
  updatePaymentIntentInput: UpdatePaymentIntentInput;
};


export type MutationUpdatePaymentIntentForSplitCheckBySelectionsArgs = {
  updatePaymentIntentInput: UpdatePaymentIntentForSplitCheckBySelectionsInput;
};


export type MutationGenerateSmsVerificationCodeArgs = {
  input: GenerateSmsVerificationCodeInput;
};


export type MutationVerifySmsCodeArgs = {
  input: VerifySmsCodeInput;
};


export type MutationValidateApplePayMerchantArgs = {
  input: ValidateApplePayMerchantInput;
};


export type MutationMdsCustomServerNotificationArgs = {
  mdsCustomServerNotificationInput: MdsCustomServerNotificationInput;
};


export type MutationSubmitItemLevelFeedbackArgs = {
  input: ItemLevelFeedbackInput;
};

export type NewAddressInput = {
  deliveryInfo: DeliveryInfoInput;
  /** This field indicates whether or not the customer would like to save the new address. If it is not found in the input, it will have **false** as a default value. For unauthenticated place order mutations, addresses will never be saved, but we may implement that in the future. For authenticated place order mutations, the client should include this field as part of the input. */
  saveAddress?: Maybe<Scalars['Boolean']>;
  /** If **saveAddress** is **true**, set the name/label for the address, e.g. "Home", "Work" */
  name?: Maybe<Scalars['String']>;
};

export type NewCardInput = {
  /** The encrypted credit card information. */
  encryptedCardDataString: Scalars['String'];
  /** The algorithm used to encrypt the credit card information. */
  encryptionKeyId: Scalars['String'];
  /** First 6 digits (BIN) to be passed through to CCPartner for fraud prevention */
  cardFirst6?: Maybe<Scalars['String']>;
  /** Last 4 digits to be passed through to CCPartner for fraud prevention */
  cardLast4?: Maybe<Scalars['String']>;
  /** Billing zip code to be passed through to CCPartner for fraud prevention */
  zipCode?: Maybe<Scalars['String']>;
  /** Two-digit expiration month to be passed through to CCPartner for OPT preauth */
  expMonth?: Maybe<Scalars['String']>;
  /** Two-digit expiration year to be passed through to CCPartner for OPT preauth */
  expYear?: Maybe<Scalars['String']>;
  /** This field indicates whether or not the customer would like to save the new credit card. If it is not found in the input, it will have **false** as a default value. For unauthenticated **placeCcOrder**, the value of this field only matters if customer guid is also supplied on the **PlaceCCOrderInput**, to allow saving a card on an unauthenticated account For authenticated **placeCcOrder**, the client should include this field as part of the input. */
  saveCard?: Maybe<Scalars['Boolean']>;
};

/** Concrete type for Numeric feature flag results. Contains the flag and a Float value representing its current state. */
export type NumericFeatureFlagResult = FeatureFlagResult & {
  __typename?: 'NumericFeatureFlagResult';
  flag: Scalars['String'];
  value: Scalars['Float'];
};

export type NvBrandingConfig = {
  __typename?: 'NvBrandingConfig';
  /** Retrieves if new ventures branding applies to the restaurant based on guid. */
  isNvBrandingEnabled: Scalars['Boolean'];
  /**
   * Retrieves keyword to send to Kevel ad server
   * @deprecated No longer needed for ad server.
   */
  campaignKeyword?: Maybe<Scalars['String']>;
};

export type NvBrandsInput = {
  /** GUID for restaurant to see if branding should be shown on that particular restaurant. */
  restaurantGuid: Scalars['String'];
  /** Default value for return if error occurs. */
  defaultValue?: Maybe<Scalars['Boolean']>;
  /** Where the ad will be placed in the guest experience. Defaults to STP_CHECKOUT. */
  adPlacement?: Maybe<AdPlacement | `${AdPlacement}`>;
};

export type OptAddItemToPartyMemberCartInput = {
  /** Party member is a part of. */
  partyGuid: Scalars['ID'];
  /** Member's unique identifier. */
  partyMemberGuid: Scalars['ID'];
  /** Member's auth token. */
  memberAuthToken: Scalars['String'];
  /** Selection to add to cart. */
  selection: SelectionInput;
};

export type OptApplyLoyaltyInput = {
  partyGuid: Scalars['ID'];
  partyMemberGuid: Scalars['ID'];
  memberAuthToken: Scalars['ID'];
};

export type OptApplyPayGLoyaltyInput = {
  partyGuid: Scalars['ID'];
  partyMemberGuid: Scalars['ID'];
  memberAuthToken: Scalars['ID'];
};

export type OptCart = {
  __typename?: 'OPTCart';
  guid: Scalars['ID'];
  lastUpdated?: Maybe<Scalars['String']>;
  order: OptOrderNoGuid;
  creditCardConfig: RestaurantCreditCardConfig;
  paymentOptions: CartPaymentOptions;
  preComputedTips: Array<PreComputedTip>;
  cartMessages?: Maybe<Array<Maybe<CartMessage>>>;
};

export type OptCartError = Error & {
  __typename?: 'OPTCartError';
  message: Scalars['String'];
  code?: Maybe<OptCartErrorCode | `${OptCartErrorCode}`>;
};

export enum OptCartErrorCode {
  CartLookupError = 'CART_LOOKUP_ERROR'
}

export type OptCartResponse = OptCart | OptCartError;

export type OptCheckV2 = {
  /** List of selections (items ordered by guest) on this check. */
  selections: Array<CheckSelection>;
  /** How many selections can be found in the Selections field of this check. */
  numberOfSelections: Scalars['Int'];
  /** Does not include delivery charges. */
  serviceChargeTotal: Scalars['Float'];
  /** Total cost of all selections on this check, after discounts but before tax. */
  subtotal: Scalars['Float'];
  /** Total cost of all selections on this check, before discounts and before tax. Voided items are considered $0.00. */
  preDiscountedSubtotal: Scalars['Float'];
  /** The order tax calculated after applying global promo codes if they exist. */
  tax: Scalars['Float'];
  /** The order tax calculated after applying global promo codes if they exist, minus any taxes on surcharge service charges. This is for display purposes, as surcharge tax should have its own line item. */
  taxWithoutSurchargeTax: Scalars['Float'];
  /** The order total calculated after applying global promo codes if they exist. Includes tip amounts if tip(s) are already on the check from previous payments. */
  total: Scalars['Float'];
  /** An object containing potential discounts applied to order, such as a global reward or a restaurant specific reward. */
  discounts: Discounts;
  /** List of any service charges applied to this check. Null means no charges have been applied to this check. */
  appliedServiceCharges?: Maybe<Array<AppliedServiceCharge>>;
  /** Customer associated with this check. */
  customer?: Maybe<CustomerInfo>;
  /** Precomputed tip suggestions for a check, based on restaurant preferences */
  preComputedTips: Array<PreComputedTip>;
  /** Total amount expected to pay to close the check. Especially useful to validate that client and server check states are in sync, and to display to the guest a consistent remaining balance calculation. */
  expectedPaymentAmount: Scalars['Float'];
  appliedLoyaltyInfo?: Maybe<AppliedLoyaltyInfo>;
};

export type OptCheckV2Guid = OptCheckV2 & {
  __typename?: 'OPTCheckV2Guid';
  /** Unique identifier for this check. */
  guid: Scalars['ID'];
  /** Check number- non-unique identifier. */
  number?: Maybe<Scalars['Int']>;
  /** List of payments made against this check. */
  payments: Array<OptPayment>;
  /** Total already paid toward the balance of this check, including tip amounts. */
  amountAlreadyPaid: Scalars['Float'];
  /** If a card was preauthorized for this check, this field holds the relevant information. */
  appliedPreauthInfo?: Maybe<AppliedPreauthInfo>;
  /** Total tips paid on this check. */
  tip: Scalars['Float'];
  /** Whether or not the check has been closed out. (Derived from voided state and paymentStatus) */
  isClosed: Scalars['Boolean'];
  /** A breakdown of potential surcharge amounts on the check depending on the payment type. Payment types are unique across all list elements, e.g. you won't see multiple surcharge entries with PaymentType.CREDIT. */
  surcharges?: Maybe<Array<SurchargeSummaryForPaymentTypes>>;
  selections: Array<CheckSelection>;
  numberOfSelections: Scalars['Int'];
  serviceChargeTotal: Scalars['Float'];
  subtotal: Scalars['Float'];
  preDiscountedSubtotal: Scalars['Float'];
  tax: Scalars['Float'];
  taxWithoutSurchargeTax: Scalars['Float'];
  total: Scalars['Float'];
  discounts: Discounts;
  appliedServiceCharges?: Maybe<Array<AppliedServiceCharge>>;
  customer?: Maybe<CustomerInfo>;
  preComputedTips: Array<PreComputedTip>;
  expectedPaymentAmount: Scalars['Float'];
  appliedLoyaltyInfo?: Maybe<AppliedLoyaltyInfo>;
};


export type OptCheckV2GuidSurchargesArgs = {
  totalGiftCardBalance?: Maybe<Scalars['Float']>;
};

export type OptCheckV2NoGuid = OptCheckV2 & {
  __typename?: 'OPTCheckV2NoGuid';
  selections: Array<CheckSelection>;
  numberOfSelections: Scalars['Int'];
  serviceChargeTotal: Scalars['Float'];
  subtotal: Scalars['Float'];
  preDiscountedSubtotal: Scalars['Float'];
  tax: Scalars['Float'];
  taxWithoutSurchargeTax: Scalars['Float'];
  total: Scalars['Float'];
  discounts: Discounts;
  appliedServiceCharges?: Maybe<Array<AppliedServiceCharge>>;
  customer?: Maybe<CustomerInfo>;
  preComputedTips: Array<PreComputedTip>;
  expectedPaymentAmount: Scalars['Float'];
  appliedLoyaltyInfo?: Maybe<AppliedLoyaltyInfo>;
  /** A breakdown of potential surcharge amounts on the check depending on the payment type. Payment types are unique across all list elements, e.g. you won't see multiple surcharge entries with PaymentType.CREDIT. */
  surcharges?: Maybe<Array<SurchargeSummaryForPaymentTypes>>;
};

export type OptCloseoutInput = {
  /** RestaurantGuid for party. */
  restaurantGuid: Scalars['ID'];
  /** Party member is a part of. */
  partyGuid: Scalars['ID'];
  /** Member's unique identifier. */
  partyMemberGuid: Scalars['ID'];
  /** Member's auth token. */
  memberAuthToken: Scalars['String'];
  /** An ID corresponding to an already-authorized payment via SPI. */
  spiPayment?: Maybe<SpiPayment>;
  /** A new credit card with encrypted data. */
  newCardInput?: Maybe<NewCardInput>;
  /** A customer's saved credit card. */
  savedCardInput?: Maybe<SavedCardInput>;
  /** A customer's preauthedCard guid, if present, should be compared against the preauth guid stored on the order. If it's a match, use it to submit the payment request. */
  preauthedCardGuid?: Maybe<Scalars['String']>;
  /** Customer's information, required for unauthenticated order. When used with authenticated order, will override email preferences inferred from authorized customer. */
  customer: OrderAtTableCustomerInput;
  /** Customer guid, should only be provided for an unconfirmed account - authenticated orders placed for a confirmed account will use the customer guid from the access token (JWT) */
  customerGuid?: Maybe<Scalars['ID']>;
  /** The amount of tip the customer intends to give. */
  tipAmount: Scalars['Float'];
  /** Gift Card Payment */
  giftCard?: Maybe<GiftCardPaymentInput>;
  /** Apple Pay payment token */
  pkPaymentToken?: Maybe<Scalars['String']>;
  /** Google Pay payment parameters */
  googlePayParams?: Maybe<GooglePayInput>;
  /** Indicates which check to pay for. Affects only optPartyTabCloseout, as other payment mutations automatically infer this information. */
  checkGuid?: Maybe<Scalars['ID']>;
  /** Only necessary if a user wants to split a check off in a non-default manner. Affects only optSplitCheckCloseout. */
  selectionsToSplit?: Maybe<Array<SplitItemInput>>;
  /** Amount the client expects to be paying for their check. Validates against current state of check before payment. Must NOT include surcharge amount. */
  expectedAmount?: Maybe<Scalars['Float']>;
  /** Additional surcharge amount the client expects to be paying for their check. */
  expectedSurchargeAmount?: Maybe<Scalars['Float']>;
  /** Promo code to be used for a Toast Cash redemption. Normal restaurant promo codes should *not* be submitted here. */
  globalGiftCard?: Maybe<GlobalGiftCardPaymentInput>;
  /** Information for issuing rewards */
  adInfo?: Maybe<BrandingInformationInput>;
  /** When splitting evenly, the number of portions to pay for. */
  portionsToBePaid?: Maybe<Scalars['Float']>;
  /** When splitting evenly, the total number of portions in the check. */
  totalPortions?: Maybe<Scalars['Float']>;
  /** When paying with click 2 pay, the information needed to authorize the payment */
  click2PayParams?: Maybe<Click2PayInput>;
  /** Guest communication preferences after a successful payment has been processed. If null, how to handle guest communication will fall back to a default behavior. */
  postPaymentCommunicationPreferences?: Maybe<PostPaymentCommunicationPreferences>;
  /** Pay using Toast Cash from the guest currency account, a global wallet tied to the guest */
  guestCurrencyAccount?: Maybe<GuestCurrencyAccountPaymentInput>;
};

export type OptCloseoutResponse = OptPartyPaymentResponse | OptPartyPaymentErrorV2 | OptSplitCheckPaymentError;

export type OptConfig = {
  __typename?: 'OPTConfig';
  /** Whether or not a restaurant wishes to allow users to open tabs */
  allowTabs?: Maybe<Scalars['Boolean']>;
  /** Whether or not a restaurant wishes to allow for joinable parties */
  allowJoinableParty?: Maybe<Scalars['Boolean']>;
  /** Whether or not a restaurant wishes to enforce preauthorization for tabs */
  allowPreauth?: Maybe<Scalars['Boolean']>;
  /** Whether or not a restaurant wishes to text a guest upon ticket fulfillment */
  allowTextFulfillment?: Maybe<Scalars['Boolean']>;
  /** Whether or not a restaurant has OPT view-only menus enabled */
  viewOnlyMenu?: Maybe<Scalars['Boolean']>;
};

export type OptCreatePartyInput = {
  /** Used for disambiguating table names */
  restaurantGuid: Scalars['ID'];
  /** Table the Party represents */
  tableName: Scalars['String'];
  /** User's name. */
  name?: Maybe<Scalars['String']>;
  /** User's contact info. Phone/email. */
  contactInfo?: Maybe<ContactInfo>;
  /** Users can choose to have a private party */
  visibility?: Maybe<PartyVisibility | `${PartyVisibility}`>;
};

/** GROUP ORDERING */
export type OptEditOrDeleteItemInPartyMemberCartInput = {
  /** Party member is a part of. */
  partyGuid: Scalars['ID'];
  /** Member's unique identifier. */
  partyMemberGuid: Scalars['ID'];
  /** Member's auth token. */
  memberAuthToken: Scalars['String'];
  /** Guid of the selection to be updated. */
  selectionGuid: Scalars['String'];
  /** Selection to add to cart. */
  selection?: Maybe<SelectionInput>;
};

export type OptFireTabRoundInput = {
  /** Party member is a part of. */
  partyGuid: Scalars['ID'];
  /** Customer's information. */
  customer?: Maybe<OrderAtTableCustomerInput>;
  /** Member's unique identifier. */
  partyMemberGuid: Scalars['ID'];
  /** Member's auth token. */
  memberAuthToken: Scalars['String'];
};

export type OptGetGiftCardBalanceInput = {
  /** String corresponding to either a restaurant gift card or a Toast Cash card. */
  cardIdentifier: Scalars['String'];
  /** Party member is a part of. */
  partyGuid: Scalars['ID'];
  /** Member's unique identifier. */
  partyMemberGuid: Scalars['ID'];
  /** Member's auth token. */
  memberAuthToken: Scalars['String'];
};

export type OptGiftCardBalanceResponse = {
  __typename?: 'OPTGiftCardBalanceResponse';
  /** An unredeemed Toast Cash card (global gift card). */
  globalGiftCard?: Maybe<OptGlobalGiftCard>;
  /** An unredeemed restaurant gift card. */
  restaurantGiftCard?: Maybe<OptRestaurantGiftCard>;
};

export type OptGlobalGiftCard = {
  __typename?: 'OPTGlobalGiftCard';
  /** Identifier associated with the Toast Cash (global gift card). */
  cardIdentifier: Scalars['String'];
  /** Amount the Toast Cash (global gift card) can cover. */
  expectedAvailableBalance: Scalars['Float'];
};

export type OptJoinPartyInput = {
  /** Party to join */
  partyGuid: Scalars['ID'];
  /** User's name. */
  name?: Maybe<Scalars['String']>;
  /** User's contact info. Phone/email. */
  contactInfo?: Maybe<ContactInfo>;
};

export type OptJoinPartySecureInput = {
  /** Party to join */
  partyGuid: Scalars['ID'];
  /** PIN required to join party. */
  pin: Scalars['String'];
  /** User's name. */
  name?: Maybe<Scalars['String']>;
  /** User's contact info. Phone/email. */
  contactInfo?: Maybe<ContactInfo>;
};

/** Essentially OPTTabOrderV2, renamed for clarity */
export type OptOrder = {
  checks: Array<OptCheckV2>;
};

export type OptOrderError = Error & {
  __typename?: 'OPTOrderError';
  message: Scalars['String'];
  code: OptPayForCheckErrorCode | `${OptPayForCheckErrorCode}`;
  invalidatesPreauth: Scalars['Boolean'];
};

export type OptOrderGuid = OptOrder & {
  __typename?: 'OPTOrderGuid';
  /** Order guid */
  guid: Scalars['ID'];
  /** Guid for the primary check on this order */
  checkGuid: Scalars['ID'];
  /** Whether the entire order is closed. False if any checks on the order are unpaid. */
  isClosed: Scalars['Boolean'];
  checks: Array<OptCheckV2Guid>;
};

export type OptOrderNoGuid = OptOrder & {
  __typename?: 'OPTOrderNoGuid';
  checks: Array<OptCheckV2NoGuid>;
};

export type OptParties = {
  __typename?: 'OPTParties';
  parties: Array<OptPartyV3>;
};

export type OptPartiesLookupError = Error & {
  __typename?: 'OPTPartiesLookupError';
  message: Scalars['String'];
  code: OptPartiesLookupErrorCode | `${OptPartiesLookupErrorCode}`;
};

export enum OptPartiesLookupErrorCode {
  LookupFailed = 'LOOKUP_FAILED'
}

export type OptPartiesLookupResponse = OptParties | OptPartiesLookupError;

export type OptPartyCartResponse = OptCart | OptPartyError;

export type OptPartyError = Error & {
  __typename?: 'OPTPartyError';
  message: Scalars['String'];
  code?: Maybe<OptPartyErrorCode | `${OptPartyErrorCode}`>;
};

export enum OptPartyErrorCode {
  IncrementAuthFailed = 'INCREMENT_AUTH_FAILED',
  CreatePartyFailed = 'CREATE_PARTY_FAILED',
  CreateOptPartyFailed = 'CREATE_OPT_PARTY_FAILED',
  CreateMnpPartyFailed = 'CREATE_MNP_PARTY_FAILED',
  CreateStpPartyFailed = 'CREATE_STP_PARTY_FAILED',
  LookupPartyFailed = 'LOOKUP_PARTY_FAILED',
  LookupPartyTableFailed = 'LOOKUP_PARTY_TABLE_FAILED',
  GetCartFailed = 'GET_CART_FAILED',
  JoinPartyFailed = 'JOIN_PARTY_FAILED',
  JoinOrCreateOptPartyDynamicQrFailed = 'JOIN_OR_CREATE_OPT_PARTY_DYNAMIC_QR_FAILED',
  JoinOrCreateStpPartyMainCheckGuid = 'JOIN_OR_CREATE_STP_PARTY_MAIN_CHECK_GUID',
  JoinOrCreatePartyFailed = 'JOIN_OR_CREATE_PARTY_FAILED',
  AddToCartFailed = 'ADD_TO_CART_FAILED',
  FireTabRoundFailed = 'FIRE_TAB_ROUND_FAILED',
  CreateSplitPaymentBucketFailed = 'CREATE_SPLIT_PAYMENT_BUCKET_FAILED',
  MoveItemInSplitPaymentBucketFailed = 'MOVE_ITEM_IN_SPLIT_PAYMENT_BUCKET_FAILED',
  AddParticipantsToSplitPaymentBucketFailed = 'ADD_PARTICIPANTS_TO_SPLIT_PAYMENT_BUCKET_FAILED',
  RemoveParticipantsFromSplitPaymentBucketFailed = 'REMOVE_PARTICIPANTS_FROM_SPLIT_PAYMENT_BUCKET_FAILED',
  MissingCardInput = 'MISSING_CARD_INPUT',
  AddPreauthFailed = 'ADD_PREAUTH_FAILED',
  PreauthAlreadyExists = 'PREAUTH_ALREADY_EXISTS',
  CalcSplitPaymentChargesFailed = 'CALC_SPLIT_PAYMENT_CHARGES_FAILED',
  MemberNotFound = 'MEMBER_NOT_FOUND',
  PriceSplitCheckFailed = 'PRICE_SPLIT_CHECK_FAILED',
  ApplyPromoFailed = 'APPLY_PROMO_FAILED',
  RemovePromoFailed = 'REMOVE_PROMO_FAILED',
  ApplyLoyaltyInfoFailed = 'APPLY_LOYALTY_INFO_FAILED',
  ApplyLoyaltyDiscountFailed = 'APPLY_LOYALTY_DISCOUNT_FAILED',
  RemoveLoyaltyDiscountFailed = 'REMOVE_LOYALTY_DISCOUNT_FAILED',
  CartValidationFailed = 'CART_VALIDATION_FAILED',
  /** This error will be sent if the user tries to assign paid portions to a non SPLIT EVENLY split type check. */
  WrongSplitTypeError = 'WRONG_SPLIT_TYPE_ERROR',
  /** This error will be sent if the user tries to assign paid portions to a check, but the number of portions available to split by doesn't match. */
  SplitPortionMismatchError = 'SPLIT_PORTION_MISMATCH_ERROR',
  /** This error will be sent if the user tries to assign paid portions to a check, but the check has been completely paid for */
  SplitPaymentNoPortionsLeftError = 'SPLIT_PAYMENT_NO_PORTIONS_LEFT_ERROR',
  /** This error will be sent if the user tries to assign paid portions to a check, but the number of portions they're paying for are no longer available. Instead, a smaller amount of portions are. */
  SplitPaymentPaidPortionOverpaymentError = 'SPLIT_PAYMENT_PAID_PORTION_OVERPAYMENT_ERROR',
  /** This error will be sent if there has been a payment posted to the check. At this point, the split type and portions are locked in. */
  SplitPaymentAssignmentLockedInError = 'SPLIT_PAYMENT_ASSIGNMENT_LOCKED_IN_ERROR',
  /** This error will be sent if a split type has already been chosen, but no payment has been posted. The user can still change the split type and number of portions, but must request it with an explicit override request */
  SplitPaymentOverrideNeededError = 'SPLIT_PAYMENT_OVERRIDE_NEEDED_ERROR',
  /** This error will be sent if the number of paid portions is fewer than the number of portions being asked to remove */
  SplitPaymentCannotRemovePaidPortions = 'SPLIT_PAYMENT_CANNOT_REMOVE_PAID_PORTIONS',
  /** This error will be sent if the party object doesn't have splitPaymentData */
  SplitPaymentNoSplitDataFound = 'SPLIT_PAYMENT_NO_SPLIT_DATA_FOUND',
  SplitPaymentUnknownError = 'SPLIT_PAYMENT_UNKNOWN_ERROR',
  /** deprecated: use OPTPartyPaymentError */
  UndefinedSplitPaymentMethod = 'UNDEFINED_SPLIT_PAYMENT_METHOD',
  /** deprecated: use OPTPartyPaymentError */
  PartyPaymentOutOfSync = 'PARTY_PAYMENT_OUT_OF_SYNC',
  /** deprecated: use OPTPartyPaymentError */
  PartyAlreadyPaid = 'PARTY_ALREADY_PAID',
  /** deprecated: use OPTPartyPaymentError */
  PartyMemberAlreadyPaid = 'PARTY_MEMBER_ALREADY_PAID',
  UpdatePartyMemberFailed = 'UPDATE_PARTY_MEMBER_FAILED'
}

export type OptPartyMemberStub = {
  __typename?: 'OPTPartyMemberStub';
  name: Scalars['String'];
  emoji?: Maybe<Scalars['String']>;
};

export type OptPartyMemberV2 = {
  __typename?: 'OPTPartyMemberV2';
  /** Unique identifier for member. */
  partyMemberGuid: Scalars['String'];
  /** Check this party member is associated with. Null if they don't have a specific check. */
  claimedCheckGuids: Array<Scalars['ID']>;
  /** Name of this party member. */
  name: Scalars['String'];
  /** Guid corresponding to this member's cart. */
  cartGuid?: Maybe<Scalars['ID']>;
  /** Guid corresponding to this member's preauthed card. Null if this party member did not preauth. */
  preauthCardGuid?: Maybe<Scalars['String']>;
  /** List of selection guids a party member is responsible for ordering. */
  orderSelectionIds: Array<Scalars['String']>;
  availableDiscounts: Array<Maybe<AppliedDiscount>>;
  appliedDiscounts: Array<Maybe<AppliedDiscount>>;
  /** List of available loyalty rewards - if this party member has a loyalty account Used for /v2 versions of apply loyalty and add/remove discounts endpoints */
  availableRewards?: Maybe<Array<Maybe<RedemptionData>>>;
  /** List of applied loyalty rewards - if this party member has a loyalty account Used for /v2 versions of apply loyalty and add/remove discounts endpoints */
  appliedRewards?: Maybe<Array<Maybe<RedemptionData>>>;
  /** Emoji to use as avatar representation of guest */
  emoji?: Maybe<Scalars['String']>;
  /** Number of paid portions that the party member has successfully paid for */
  claimedPortionsPaid?: Maybe<Scalars['Int']>;
  loyaltyState?: Maybe<BalanceStateData>;
};

export type OptPartyPaygPromoInput = {
  /** Party member is a part of. */
  partyGuid: Scalars['ID'];
  /** Member's unique identifier. */
  partyMemberGuid: Scalars['ID'];
  /** Member's auth token. */
  memberAuthToken: Scalars['String'];
  /** Promo code to apply to cart */
  promoCode: Scalars['String'];
};

export type OptPartyPaymentError = Error & {
  __typename?: 'OPTPartyPaymentError';
  message: Scalars['String'];
  code: OptPartyPaymentErrorCode | `${OptPartyPaymentErrorCode}`;
  invalidatesPreauth: Scalars['Boolean'];
};

export enum OptPartyPaymentErrorCode {
  SplitCheckFailed = 'SPLIT_CHECK_FAILED',
  AuthorizationFailed = 'AUTHORIZATION_FAILED',
  PartyAlreadyPaid = 'PARTY_ALREADY_PAID',
  PartyMemberAlreadyPaid = 'PARTY_MEMBER_ALREADY_PAID',
  OrderPaymentError = 'ORDER_PAYMENT_ERROR',
  PaymentFailedAfterAuth = 'PAYMENT_FAILED_AFTER_AUTH',
  PaymentFailedAfterAuthPreauth = 'PAYMENT_FAILED_AFTER_AUTH_PREAUTH',
  PaymentMethodDisallowed = 'PAYMENT_METHOD_DISALLOWED',
  UndefinedSplitPaymentMethod = 'UNDEFINED_SPLIT_PAYMENT_METHOD',
  PartyPaymentOutOfSync = 'PARTY_PAYMENT_OUT_OF_SYNC',
  UnknownPaymentResponse = 'UNKNOWN_PAYMENT_RESPONSE',
  CheckNotFound = 'CHECK_NOT_FOUND'
}

export enum OptPartyPaymentErrorCodeV2 {
  AuthorizationFailed = 'AUTHORIZATION_FAILED',
  OrderPaymentError = 'ORDER_PAYMENT_ERROR',
  PartyPaymentOutOfSync = 'PARTY_PAYMENT_OUT_OF_SYNC',
  CheckNotFound = 'CHECK_NOT_FOUND',
  SplitCheckFailed = 'SPLIT_CHECK_FAILED',
  PaymentAppliedFailed = 'PAYMENT_APPLIED_FAILED',
  TokenizationFailed = 'TOKENIZATION_FAILED'
}

export type OptPartyPaymentErrorV2 = Error & {
  __typename?: 'OPTPartyPaymentErrorV2';
  message: Scalars['String'];
  code: OptPartyPaymentErrorCodeV2 | `${OptPartyPaymentErrorCodeV2}`;
  /** Refreshed party data. Returns null if field is unsupported by the mutation. */
  partyRefreshResponse?: Maybe<OptPartyRefreshResponseV2>;
  invalidatesPreauth: Scalars['Boolean'];
  /** Guid of the created, but unsuccessfully paid, split check. If not present, the error occurred before a new split check was created. */
  splitCheckGuid?: Maybe<Scalars['ID']>;
};

export type OptPartyPaymentResponse = {
  __typename?: 'OPTPartyPaymentResponse';
  /** Any non-blocking errors that occurred during payment, to be presented to the user. */
  warnings: Array<PlaceOrderWarning>;
  /** Refreshed party data. */
  partyRefresh: OptPartyRefreshV2;
  /** indicates whether the payment had loyalty attached */
  hasLoyalty?: Maybe<Scalars['Boolean']>;
  /** indicates whether the payment can be used to sign up for loyalty */
  loyaltySignupPossible?: Maybe<Scalars['Boolean']>;
  /** Returns a feedback survey for the given order payment */
  itemFeedbackSurvey: ItemFeedbackSurveyResponse;
};

export type OptPartyRefreshResponseV2 = OptPartyRefreshV2 | OptPartyError;

export type OptPartyRefreshV2 = {
  __typename?: 'OPTPartyRefreshV2';
  /** The data for the refreshed party. */
  party: OptPartyV2;
  /** The hydrated list of carts in the party. This allows us to show what others are ordering. */
  carts: Array<OptCart>;
  /** The hydrated order with all items in the cart. */
  order?: Maybe<OptOrderGuid>;
  /**
   * Deprecated - use **priceSplitCheck** mutation
   * @deprecated use priceSplitCheck mutation
   */
  splitCheckPreview?: Maybe<OptSplitCheckPreview>;
  /** Represents whether or not a restaurant is offline */
  rxAvailability?: Maybe<Scalars['Boolean']>;
  /** Indicates what type of split is being made on the check */
  splitPaymentData?: Maybe<SplitPaymentData>;
};

export type OptPartyResponseV2 = OptPartyV2 | OptPartyError;

export type OptPartyStub = {
  __typename?: 'OPTPartyStub';
  guid?: Maybe<Scalars['ID']>;
  members?: Maybe<Array<Maybe<OptPartyMemberStub>>>;
  /** Represented as a long - when a party was created in seconds. */
  createdTime?: Maybe<Scalars['String']>;
};

export type OptPartyStubResponse = OptPartyStub | OptPartyError;

export type OptPartyTabPromoInput = {
  /** Party member is a part of. */
  partyGuid: Scalars['ID'];
  /** Member's unique identifier. */
  partyMemberGuid: Scalars['ID'];
  /** Member's auth token. */
  memberAuthToken: Scalars['String'];
  /** Promo code to apply to cart */
  promoCode: Scalars['String'];
  /** Check to which promo should be applied */
  checkGuid: Scalars['ID'];
};

export type OptPartyV2 = {
  __typename?: 'OPTPartyV2';
  /** Identifier for the Party object itself. */
  guid: Scalars['ID'];
  /** Identifier for the user making the request which yielded this object. Matches the property of the same name on one of the objects in the members array. */
  partyMemberGuid?: Maybe<Scalars['ID']>;
  /** Identifier for the Order object corresponding to this Party. */
  orderGuid?: Maybe<Scalars['ID']>;
  /** Identifier for the Order object corresponding to this Party. */
  mainCheckGuid?: Maybe<Scalars['ID']>;
  /** Security PIN to allow other diners to join this Party. */
  pin?: Maybe<Scalars['String']>;
  /** List of members currently taking part in this Party. */
  members: Array<OptPartyMemberV2>;
  /** Auth token specific to party member who made the request. */
  memberAuthToken?: Maybe<Scalars['String']>;
  /** Represented as a long - party's TTL value, when it was last updated. */
  lastUpdated?: Maybe<Scalars['String']>;
  /** Represented as a long - when a party was created in seconds. */
  createdTime?: Maybe<Scalars['String']>;
  /** Calculated line items for all the party's carts combined */
  cartLineItems?: Maybe<LineItems>;
  /** Calculated line items for the party's tab */
  orderLineItems?: Maybe<LineItems>;
  /** The type of DDI experience/mode that this party is for, if null, client should assume OPT */
  mode?: Maybe<DdiMode | `${DdiMode}`>;
  /** The name of the table for the party if it exists */
  tableName?: Maybe<Scalars['String']>;
};

export type OptPartyV3 = {
  __typename?: 'OPTPartyV3';
  guid: Scalars['ID'];
  members: Array<OptPartyMemberV2>;
  restaurantGuid: Scalars['ID'];
  tableGuid?: Maybe<Scalars['ID']>;
  orderGuid?: Maybe<Scalars['ID']>;
  createdTime: Scalars['String'];
  mainCheckGuid?: Maybe<Scalars['ID']>;
  updatedTime: Scalars['String'];
  pin?: Maybe<Scalars['String']>;
  mode?: Maybe<DdiMode | `${DdiMode}`>;
  splitPaymentData?: Maybe<SplitPaymentData>;
  visibility?: Maybe<PartyVisibility | `${PartyVisibility}`>;
  tableName?: Maybe<Scalars['String']>;
};

/** GROUP ORDERING */
export enum OptPayForCheckErrorCode {
  InvalidPaymentRequest = 'INVALID_PAYMENT_REQUEST',
  PaymentConflict = 'PAYMENT_CONFLICT',
  MissingRequiredParameter = 'MISSING_REQUIRED_PARAMETER',
  InvalidCardInformation = 'INVALID_CARD_INFORMATION',
  TransDenied = 'TRANS_DENIED',
  CheckNotFound = 'CHECK_NOT_FOUND',
  ValidationFailed = 'VALIDATION_FAILED',
  PaymentDenied = 'PAYMENT_DENIED',
  CheckAlreadyPaidFor = 'CHECK_ALREADY_PAID_FOR',
  PaymentFailedAfterAuth = 'PAYMENT_FAILED_AFTER_AUTH',
  PaymentFailedAfterAuthPreauth = 'PAYMENT_FAILED_AFTER_AUTH_PREAUTH'
}

export type OptPayForCheckResponse = {
  __typename?: 'OPTPayForCheckResponse';
  completedOrder: CompletedOrder;
  warnings: Array<PlaceOrderWarning>;
};

export type OptPayment = {
  __typename?: 'OPTPayment';
  guid: Scalars['ID'];
  type?: Maybe<PaymentType | `${PaymentType}`>;
  amount: Scalars['Float'];
  tipAmount: Scalars['Float'];
  paymentStatus?: Maybe<PaymentStatus | `${PaymentStatus}`>;
};

export type OptReorderItemInput = {
  /** Party member is a part of. */
  partyGuid: Scalars['ID'];
  /** Member's unique identifier. */
  partyMemberGuid: Scalars['ID'];
  /** Member's auth token. */
  memberAuthToken: Scalars['String'];
  /** SelectionGuid to reorder. */
  selectionGuid: Scalars['String'];
};

export type OptRestaurantGiftCard = {
  __typename?: 'OPTRestaurantGiftCard';
  /** 16-digit number associated with the restaurant gift card. */
  cardNumber: Scalars['String'];
  /** Amount the gift card can cover. */
  expectedAvailableBalance: Scalars['Float'];
};

export type OptSplitCheckInput = {
  /** Party member is a part of. */
  partyGuid: Scalars['ID'];
  /** Member's unique identifier. */
  partyMemberGuid: Scalars['ID'];
  /** Member's auth token. */
  memberAuthToken: Scalars['String'];
  /** List of selections to split, paired with floats indicating how they should be split. */
  selectionsToSplit: Array<SplitItemInput>;
};

export type OptSplitCheckPaymentError = Error & {
  __typename?: 'OPTSplitCheckPaymentError';
  /** User-facing message for the payment error */
  message: Scalars['String'];
  code: OptSplitCheckPaymentErrorCode | `${OptSplitCheckPaymentErrorCode}`;
  /** If the error occurred after a payment authorization with a pre-authorized card, the pre-authorized card cannot be re-used and is therefore invalidated. */
  invalidatesPreauth: Scalars['Boolean'];
  /** Guid of the created, but unsuccessfully paid, split check. If not present, the error occurred before a new split check was created. */
  splitCheckGuid?: Maybe<Scalars['ID']>;
  /** Refreshed party data. Returns null if field is unsupported by the mutation. */
  partyRefreshResponse?: Maybe<OptPartyRefreshResponseV2>;
};

export enum OptSplitCheckPaymentErrorCode {
  OrderPaymentError = 'ORDER_PAYMENT_ERROR',
  AuthorizationFailed = 'AUTHORIZATION_FAILED',
  PaymentAppliedFailed = 'PAYMENT_APPLIED_FAILED',
  /** This error will be sent if the user tries to assign paid portions to a non SPLIT EVENLY split type check. */
  WrongSplitTypeError = 'WRONG_SPLIT_TYPE_ERROR',
  /** This error will be sent if the user tries to assign paid portions to a check, but the number of portions available to split by doesn't match. */
  SplitPortionMismatchError = 'SPLIT_PORTION_MISMATCH_ERROR',
  /** This error will be sent if the user tries to assign paid portions to a check, but the check has been completely paid for */
  SplitPaymentNoPortionsLeftError = 'SPLIT_PAYMENT_NO_PORTIONS_LEFT_ERROR',
  /** This error will be sent if the user tries to assign paid portions to a check, but the number of portions they're paying for are no longer available. Instead, a smaller amount of portions are. */
  SplitPaymentPaidPortionOverpaymentError = 'SPLIT_PAYMENT_PAID_PORTION_OVERPAYMENT_ERROR',
  /** This error will be sent if there has been a payment posted to the check. At this point, the split type and portions are locked in. */
  SplitPaymentAssignmentLockedInError = 'SPLIT_PAYMENT_ASSIGNMENT_LOCKED_IN_ERROR',
  /** This error will be sent if a split type has already been chosen, but no payment has been posted. The user can still change the split type and number of portions, but must request it with an explicit override request */
  SplitPaymentOverrideNeededError = 'SPLIT_PAYMENT_OVERRIDE_NEEDED_ERROR',
  /** This error will be sent if the number of paid portions is fewer than the number of portions being asked to remove */
  SplitPaymentCannotRemovePaidPortions = 'SPLIT_PAYMENT_CANNOT_REMOVE_PAID_PORTIONS',
  /** This error will be sent if the party object doesn't have splitPaymentData */
  SplitPaymentNoSplitDataFound = 'SPLIT_PAYMENT_NO_SPLIT_DATA_FOUND',
  SplitPaymentUnknownError = 'SPLIT_PAYMENT_UNKNOWN_ERROR'
}

export type OptSplitCheckPreview = OptCheckV2 & {
  __typename?: 'OPTSplitCheckPreview';
  selections: Array<CheckSelectionGuid>;
  /** A breakdown of potential surcharge amounts on the split check depending on the payment type. Payment types are unique across all list elements, e.g. you won't see multiple surcharge entries with PaymentType.CREDIT. */
  surcharges?: Maybe<Array<SurchargeSummaryForPaymentTypes>>;
  numberOfSelections: Scalars['Int'];
  serviceChargeTotal: Scalars['Float'];
  subtotal: Scalars['Float'];
  preDiscountedSubtotal: Scalars['Float'];
  tax: Scalars['Float'];
  taxWithoutSurchargeTax: Scalars['Float'];
  total: Scalars['Float'];
  discounts: Discounts;
  appliedServiceCharges?: Maybe<Array<AppliedServiceCharge>>;
  customer?: Maybe<CustomerInfo>;
  preComputedTips: Array<PreComputedTip>;
  expectedPaymentAmount: Scalars['Float'];
  appliedLoyaltyInfo?: Maybe<AppliedLoyaltyInfo>;
};


export type OptSplitCheckPreviewSurchargesArgs = {
  totalGiftCardBalance?: Maybe<Scalars['Float']>;
};

export type OptSplitCheckResponse = OptSplitCheckPreview | OptPartyError;

export type OptValidateInput = {
  /** Party to join */
  partyGuid: Scalars['ID'];
  /** Member's unique identifier. */
  partyMemberGuid: Scalars['ID'];
  /** Member's auth token. */
  memberAuthToken: Scalars['String'];
};

export type OptDiscountInput = {
  partyGuid: Scalars['ID'];
  partyMemberGuid: Scalars['ID'];
  memberAuthToken: Scalars['ID'];
  discountGuid?: Maybe<Scalars['ID']>;
  discountType?: Maybe<LoyaltyRedemptionType | `${LoyaltyRedemptionType}`>;
};

export type OptDynamicQrJoinOrCreatePartyInput = {
  /** Used for disambiguating table names */
  restaurantGuid: Scalars['ID'];
  /** Check the Party represents */
  checkGuid: Scalars['ID'];
  /** Order the Party represents */
  orderGuid: Scalars['ID'];
  /** User's name. */
  name?: Maybe<Scalars['String']>;
  /** User's contact info. Phone/email. */
  contactInfo?: Maybe<ContactInfo>;
  /** Emoji to use as avatar representation of guest */
  emoji?: Maybe<Scalars['String']>;
};

export type Order = {
  __typename?: 'Order';
  promisedDateTime?: Maybe<Scalars['Float']>;
  numberOfSelections?: Maybe<Scalars['Int']>;
  selections?: Maybe<Array<Maybe<Selection>>>;
  /** The delivery info to use to calculate delivery fees, provided this is a delivery order. */
  deliveryInfo?: Maybe<DeliveryInfo>;
  /** The delivery fee based on the current delivery info, provided this is a delivery order. */
  deliveryChargeTotal?: Maybe<Scalars['Float']>;
  /** Does not include delivery charges. */
  serviceChargeTotal?: Maybe<Scalars['Float']>;
  subtotal?: Maybe<Scalars['Float']>;
  /** @deprecated use taxV2 field instead */
  tax?: Maybe<Scalars['Float']>;
  /** @deprecated use totalV2 field instead */
  total?: Maybe<Scalars['Float']>;
  /** The order tax calculated after applying global promo codes if they exist. */
  taxV2?: Maybe<Scalars['Float']>;
  /** The order total calculated after applying global promo codes if they exist. */
  totalV2?: Maybe<Scalars['Float']>;
  /** @deprecated use discounts field instead */
  appliedCustomerReward?: Maybe<AppliedCustomerReward>;
  /** An object containing potential discounts applied to order, such as a global reward or a restaurant specific reward. */
  discounts: Discounts;
  /** A number that indicates how much to charge a user for placing an outpost order. This value will only be non null if the order is an outpost order, otherwise it will be null. */
  outpostFee?: Maybe<Scalars['Float']>;
  appliedServiceCharges?: Maybe<Array<AppliedServiceCharge>>;
};


export type OrderAppliedCustomerRewardArgs = {
  input?: Maybe<ApplyCustomerRewardInput>;
};

export type OrderAtTableCustomerInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email: Scalars['String'];
};

export type OrderAtTablePartySelectionItemDetailsInput = {
  itemGuid: Scalars['ID'];
  itemGroupGuid: Scalars['ID'];
  partyGuid: Scalars['ID'];
  partyMemberGuid: Scalars['ID'];
  memberAuthToken: Scalars['ID'];
  selectionGuid: Scalars['ID'];
};

/** The different states or an order tracked order. If **orderStatus** is null then the restaurant does not support order tracking currently */
export enum OrderStatusType {
  /** An order has been received and is present in the orders database. */
  Received = 'RECEIVED',
  /** An order is being prepared. */
  Preparing = 'PREPARING',
  /** The order has been marked as completed by the restaurant through the KDS. */
  AlmostReady = 'ALMOST_READY',
  /** The order is ready for pickup. Set 90 seconds after the order becomes **ALMOST_READY** to account for bagging and placing order on shelf etc. */
  ReadyForPickup = 'READY_FOR_PICKUP',
  /** When a RECEIVED state order reaches 15 minutes past its estimated completion date, it becomes unfulfilled. */
  Unfulfilled = 'UNFULFILLED'
}

export type PartyMemberResponse = OptPartyMemberV2 | OptPartyError;

export enum PartyVisibility {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export type PayForCheckError = Error & {
  __typename?: 'PayForCheckError';
  message: Scalars['String'];
  code: PayForCheckErrorCode | `${PayForCheckErrorCode}`;
};

export enum PayForCheckErrorCode {
  InvalidPaymentRequest = 'INVALID_PAYMENT_REQUEST',
  PaymentConflict = 'PAYMENT_CONFLICT',
  ValidationFailed = 'VALIDATION_FAILED',
  CheckNotFound = 'CHECK_NOT_FOUND',
  CheckAlreadyPaidFor = 'CHECK_ALREADY_PAID_FOR',
  PaymentDenied = 'PAYMENT_DENIED'
}

export type PayForCheckInput = {
  /** The order guid which contains the check that is being paid for */
  orderGuid: Scalars['ID'];
  /** The guid of the restaurant which has the order being paid for */
  restaurantGuid: Scalars['ID'];
  /** A new credit card with encrypted data */
  newCardInput: NewCardInput;
  /** The amount that the consumer selected to tip */
  tipAmount: Scalars['Float'];
  /** The guid of the check to pay for */
  checkGuid: Scalars['ID'];
  /** The guest's email to send a digital receipt to, also used for accrue loyalty points */
  email: Scalars['String'];
  /** The guest's phone number to accrue loyalty points, can be null */
  phone?: Maybe<Scalars['String']>;
  /** True if the guest opted into marketing, false if not. */
  marketable?: Maybe<Scalars['Boolean']>;
};

export type PayForCheckResponse = {
  __typename?: 'PayForCheckResponse';
  /** An array of all checks associated with the order still open. Used to allow the client to display any remaining balance, and the selections, to the guest. */
  remainingChecks: Array<InProgressOrderCheck>;
  /** Indicates whether loyalty account was found and user accrued points for paid check. */
  hasLoyalty?: Maybe<Scalars['Boolean']>;
};

export type PayForCheckResponseOrError = PayForCheckResponse | PayForCheckError;

export type PaymentIntent = {
  __typename?: 'PaymentIntent';
  id: Scalars['String'];
  externalReferenceId: Scalars['String'];
  sessionSecret: Scalars['String'];
  amount: Scalars['Int'];
  currency: Scalars['String'];
  captureMethod: Scalars['String'];
  status: Scalars['String'];
  creationDate: Scalars['String'];
};

export type PaymentIntentError = {
  __typename?: 'PaymentIntentError';
  errorDetails: Scalars['String'];
};

export enum PaymentMethods {
  ApplePay = 'APPLE_PAY',
  ClickToPay = 'CLICK_TO_PAY',
  CreditCard = 'CREDIT_CARD',
  NewCreditCard = 'NEW_CREDIT_CARD',
  GooglePay = 'GOOGLE_PAY'
}

export enum PaymentStatus {
  Open = 'OPEN',
  Processing = 'PROCESSING',
  AuthorizedAtRisk = 'AUTHORIZED_AT_RISK',
  Authorized = 'AUTHORIZED',
  Error = 'ERROR',
  ErrorNetwork = 'ERROR_NETWORK',
  Denied = 'DENIED',
  ProcessingVoid = 'PROCESSING_VOID',
  VoidedAtRisk = 'VOIDED_AT_RISK',
  Cancelled = 'CANCELLED',
  CaptureInProgress = 'CAPTURE_IN_PROGRESS',
  Captured = 'CAPTURED',
  Voided = 'VOIDED'
}

/** This is a subset of the values from toast-orders' com.toasttab.service.orders.api.Payment.TypeEnum See https://dev.toasttab.com/Ie5QH/apiOrdersApiReference.html#/definitions/Payment */
export enum PaymentType {
  Cash = 'CASH',
  Credit = 'CREDIT',
  Giftcard = 'GIFTCARD',
  HouseAccount = 'HOUSE_ACCOUNT',
  Rewardcard = 'REWARDCARD',
  Levelup = 'LEVELUP',
  Other = 'OTHER',
  Undetermined = 'UNDETERMINED'
}

/** Requires a PKPaymentToken generated from apple pay as well as customer information generated by the Apple Payment Sheet */
export type PlaceApplePayOrderInput = {
  /** GUID for the cart that has the order currently being placed. */
  cartGuid: Scalars['ID'];
  /** A token generated by Apple Pay */
  pkPaymentToken: Scalars['String'];
  /** Customer's information, generated by the Apple Payment Sheet */
  customer?: Maybe<CustomerInput>;
  /** The final delivery info to set on the order. If absent, will use whatever delivery info was previously set on the cart, if any. It is an error if the cart's dining option behavior was configured for **DELIVERY** but no delivery info is provided, either here or previously set. */
  newAddress?: Maybe<NewAddressInput>;
  /** The saved address to use for an authenticated customer. Mutually exclusive with **newAddresss**. */
  savedAddress?: Maybe<SavedAddressInput>;
  /** Gift Card Payment */
  giftCard?: Maybe<GiftCardPaymentInput>;
  /** The amount of tip the customer intends to give. */
  tipAmount: Scalars['Float'];
  /** The global (restaurant-agnostic) customer reward to be applied to the order being placed. The absence of this input in the request indicates that no reward is to be applied. Currently only first-order promo is supported. */
  applicableCustomerRewardInput?: Maybe<ApplicableCustomerRewardInput>;
  /** The promo code supplied by the user for order being placed, must be re-validated before applying. The absence of this input in the request indicates that no promo code is to be applied. */
  promoCode?: Maybe<Scalars['String']>;
  /** ***MVP feature***: The GUID of the dining option to be applied to the cart before placing the order. This overrides the dining option selected from the restaurant admin online ordering configuration, e.g. the takeout dining option would be automatically configured on the cart when it was first created, but selecting an outpost dining option would override this selection during the **placeCcOrder** mutation. */
  diningOptionGuid?: Maybe<Scalars['ID']>;
  /** This input will determine whether the place order mutation should also call loyaltySignup for the user. If the flag is false or not set, loyalty signup will not occur in the place order request. */
  signUpLoyalty?: Maybe<Scalars['Boolean']>;
  /** Determines curbside pickup options for an order */
  curbsidePickup?: Maybe<CurbsidePickupInput>;
  /** Curbside Pickup information for the order. Namely, a vehicle description. */
  curbsidePickupV2?: Maybe<CurbsidePickupInputV2>;
  /** Indicates whether the diner has given consent for the delivery provider to communicate to them. Only relevant if the order is a DaaS order and consent is needed for the delivery provider fulfilling the delivery. Since DaaS orders only accept CC payments, this field is only present on **PlaceCcOrderInput**. */
  deliveryCommunicationConsentGiven?: Maybe<Scalars['Boolean']>;
  /** Indicates if RoundUp for cause is turned on or not in the order Represents the estimated roundUpAmount calculated in the frontend */
  tfgInput?: Maybe<TfgInput>;
};

export type PlaceApplePayOrderResponse = PlaceOrderResponse | PlaceOrderError | PlaceOrderOutOfStockError | PlaceOrderCartUpdatedError;

export type PlaceOptOrderResponse = OptPayForCheckResponse | OptOrderError;

/** Returned when the server prevents the placing of an order due to the cart being updated automatically by the server. */
export type PlaceOrderCartUpdatedError = Error & {
  __typename?: 'PlaceOrderCartUpdatedError';
  code: PlaceOrderCartUpdatedErrorCode | `${PlaceOrderCartUpdatedErrorCode}`;
  message: Scalars['String'];
};

export enum PlaceOrderCartUpdatedErrorCode {
  /** A number of items on the cart has been automatically removed due to being out of stock. */
  OutOfStock = 'OUT_OF_STOCK',
  /** The fulfillment time (for FUTURE) or quote time (for ASAP) on the cart is out of date and no longer accurate. E.g. the server automatically changed the fulfillment/quote time due to price-based prep or kitchen capacity, but the order can still be placed. In this case the **cart** field of this response will have the updated fulfillment/quote time. In contrast, if it is no longer possible to place an order at all, a **PlaceOrderError** would be returned with a code of **ORDER_TIME_UNAVAILABLE**. */
  OrderTimeChanged = 'ORDER_TIME_CHANGED'
}

export type PlaceOrderError = Error & {
  __typename?: 'PlaceOrderError';
  code: PlaceOrderErrorCode | `${PlaceOrderErrorCode}`;
  message: Scalars['String'];
};

export enum PlaceOrderErrorCode {
  UnsatisfiedApprovalRule = 'UNSATISFIED_APPROVAL_RULE',
  CartContentNotFound = 'CART_CONTENT_NOT_FOUND',
  InvalidCreditCard = 'INVALID_CREDIT_CARD',
  OoDisabled = 'OO_DISABLED',
  OrderTimeUnavailable = 'ORDER_TIME_UNAVAILABLE',
  PlaceOrderEmptyCart = 'PLACE_ORDER_EMPTY_CART',
  PlaceOrderFailed = 'PLACE_ORDER_FAILED',
  PlaceOrderInvalidEmail = 'PLACE_ORDER_INVALID_EMAIL',
  RewardNotFound = 'REWARD_NOT_FOUND',
  UnsupportedPaymentMethod = 'UNSUPPORTED_PAYMENT_METHOD',
  MissingCustomerInfo = 'MISSING_CUSTOMER_INFO',
  MissingDeliveryInfo = 'MISSING_DELIVERY_INFO',
  DiningOptionUnavailable = 'DINING_OPTION_UNAVAILABLE',
  NotInDeliveryArea = 'NOT_IN_DELIVERY_AREA',
  LoyaltyRedemptionRemoved = 'LOYALTY_REDEMPTION_REMOVED',
  CriticalError = 'CRITICAL_ERROR',
  AlcoholUnsupported = 'ALCOHOL_UNSUPPORTED'
}

/** Deprecated - use **PlaceOrderCartUpdatedError** */
export type PlaceOrderOutOfStockError = Error & {
  __typename?: 'PlaceOrderOutOfStockError';
  message: Scalars['String'];
};

export type PlaceOrderResponse = {
  __typename?: 'PlaceOrderResponse';
  completedOrder: CompletedOrder;
  warnings: Array<PlaceOrderWarning>;
};

export type PlaceOrderWarning = Warning & {
  __typename?: 'PlaceOrderWarning';
  code: PlaceOrderWarningCode | `${PlaceOrderWarningCode}`;
  message: Scalars['String'];
};

export enum PlaceOrderWarningCode {
  AfterPlaceOrderFailed = 'AFTER_PLACE_ORDER_FAILED',
  LoyaltySignupFailed = 'LOYALTY_SIGNUP_FAILED',
  SendReceiptFailed = 'SEND_RECEIPT_FAILED'
}

export type PopularItemV3 = {
  __typename?: 'PopularItemV3';
  groupGuid: Scalars['String'];
  itemGuid: Scalars['String'];
  doMenuItem: DoMenuItem;
};

export type PopularItemsConfig = {
  __typename?: 'PopularItemsConfig';
  enabled: Scalars['Boolean'];
};

export type PopularItemsV3 = {
  __typename?: 'PopularItemsV3';
  popularItems: Array<PopularItemV3>;
};

export type PostPaymentCommunicationPreferences = {
  /** Whether the guest has explicitly consented to be subscribed for marketing purposes. */
  consentMarketingSubscription: Scalars['Boolean'];
};

export type PreComputedTip = {
  __typename?: 'PreComputedTip';
  /** @deprecated Use percentV2 */
  percent: Scalars['Int'];
  /** PreComputedTip percent as raw floating point value from 0 to 1 */
  percentV2: Scalars['Float'];
  /** Represents PreComputedTip percent as string rounded to single decimal place */
  percentLabel: Scalars['String'];
  value: Scalars['Float'];
  isDefault?: Maybe<Scalars['Boolean']>;
};

export type PricingRules = {
  __typename?: 'PricingRules';
  timeSpecificPricingRules: Array<Scalars['Int']>;
  sizeSpecificPricingGuid: Scalars['String'];
  sizeSequencePricingRules: Array<Scalars['Int']>;
};

export enum PricingStrategy {
  None = 'NONE',
  BasePrice = 'BASE_PRICE',
  MenuSpecificPrice = 'MENU_SPECIFIC_PRICE',
  GroupPrice = 'GROUP_PRICE',
  SizePrice = 'SIZE_PRICE',
  TimeSpecificPrice = 'TIME_SPECIFIC_PRICE',
  SequencePrice = 'SEQUENCE_PRICE',
  OpenPrice = 'OPEN_PRICE'
}

export type Query = {
  __typename?: 'Query';
  /**
   * Retrieves if new ventures branding applies to the restaurant based on guid.
   * @deprecated Use nvBrandingConfig.isNvBrandingEnabled
   */
  isNvBrandingEnabled: Scalars['Boolean'];
  /** New ventures branding config for showing advertisements */
  nvBrandingConfig?: Maybe<NvBrandingConfig>;
  /** Retrieves a cart based on guid. Does not load party. Unauthenticated. Returns null if supplied GUID does not correspond to a cart. */
  optGetCart?: Maybe<OptCartResponse>;
  customer: Customer;
  /** Checks whether the **resetToken** obtained from the email sent by **initiateEmailPasswordReset** is still valid or not. This isn't necessary to use the **resetPassword** mutation, but can be used to check the token validity before prompting the user to enter a new password in. */
  isResetTokenValid: Scalars['Boolean'];
  /** Get's import details related to the guest-profiles account associated to the given access token */
  importStatus: ImportStatus;
  /** Retrieves the value of a single feature flag. If restaurantGuid is provided, it retrieves the value of that flag that corresponds to that restaurantGuid, otherwise fetches the default value. */
  retrieveFlag: FeatureFlagResult;
  /** Retrieves values for a list of feature flags. If restaurantGuid is provided, it retrieves the value of that flag that corresponds to that restaurantGuid, otherwise fetches the default value for each. */
  retrieveFlags: Array<FeatureFlagResult>;
  /** Given an identifier string, attempts to lookup associated restaurant gift cards/Toast Cash (global gift cards). */
  optGetGiftCardBalance?: Maybe<OptGiftCardBalanceResponse>;
  getPopularItems?: Maybe<GetPopularItemsResponse>;
  /** Fetches menus for restaurant Empty array means no menus available or failure */
  getMenus?: Maybe<GetMenusResponseOrError>;
  /** Allows user to query a single past order. */
  completedOrder: CompletedOrder;
  /** Allows clients to query orders that are still open by check number (legacy) or check guid */
  inProgressOrder?: Maybe<InProgressOrder>;
  /** Fetches menus for restaurant */
  orderAtTableMenus: Array<MenuV2>;
  /** Fetches menu item in a group for restaurant using do-menus getMenuItem endpoint */
  orderAtTableMenuItemV2: MenuItemV2;
  /** Gets the latest Party, using table name and restaurant guid. */
  optLookupPartyByTableV2: OptPartyStubResponse;
  /** Gets the Party associated with the supplied PIN. Used for invites. */
  optLookupPartyByTableAndPinV2: OptPartyStubResponse;
  /** Gets a Party, using its guid. */
  optUnverifiedLookupPartyByGuidV2: OptPartyStubResponse;
  /** Gets a Party, using its guid. */
  optLookupPartyByGuidV2: OptPartyRefreshResponseV2;
  /** Gets a Party, using its order guid. */
  optLookupPartyByOrder: OptPartyStubResponse;
  /** Gets a group member's cart. */
  optLookupPartyCartV2: OptPartyCartResponse;
  /** Fetches selection item from a party order. */
  orderAtTablePartySelectionItemV2: SelectionItemV2;
  /** Prices a possible splitting of a party's check. */
  optPriceSplitCheck: OptSplitCheckResponse;
  /** Finds all parties by given table */
  lookupPartiesByTable: OptPartiesLookupResponse;
  /** Finds all parties by given check */
  lookupPartiesByCheck: OptPartiesLookupResponse;
  /** Get a token for the iFrame to call out securely to scoped API endpoints. */
  getBearerToken: Scalars['String'];
  /** @deprecated use restaurantV2 */
  restaurant?: Maybe<Restaurant>;
  /** Restaurant configuration data such as its name and location. */
  restaurantV2: RestaurantOrError;
  itemFeedbackSurvey: ItemFeedbackSurveyResponse;
};


export type QueryIsNvBrandingEnabledArgs = {
  input: NvBrandsInput;
};


export type QueryNvBrandingConfigArgs = {
  input: NvBrandsInput;
};


export type QueryOptGetCartArgs = {
  cartGuid: Scalars['ID'];
};


export type QueryIsResetTokenValidArgs = {
  resetToken: Scalars['String'];
};


export type QueryRetrieveFlagArgs = {
  flag: Scalars['String'];
  restaurantGuid?: Maybe<Scalars['ID']>;
};


export type QueryRetrieveFlagsArgs = {
  flags: Array<Scalars['String']>;
  restaurantGuid?: Maybe<Scalars['ID']>;
};


export type QueryOptGetGiftCardBalanceArgs = {
  input?: Maybe<OptGetGiftCardBalanceInput>;
};


export type QueryGetPopularItemsArgs = {
  input: GetPopularItemsInput;
};


export type QueryGetMenusArgs = {
  restaurantGuid: Scalars['ID'];
  channelGuid?: Maybe<Scalars['ID']>;
};


export type QueryCompletedOrderArgs = {
  input: CompletedOrderInput;
};


export type QueryInProgressOrderArgs = {
  input: InProgressOrderInput;
};


export type QueryOrderAtTableMenusArgs = {
  restaurantGuid: Scalars['ID'];
};


export type QueryOrderAtTableMenuItemV2Args = {
  restaurantGuid: Scalars['ID'];
  itemGuid: Scalars['ID'];
  itemGroupGuid: Scalars['ID'];
  nestingLevel: Scalars['Int'];
};


export type QueryOptLookupPartyByTableV2Args = {
  restaurantGuid: Scalars['ID'];
  tableName: Scalars['String'];
};


export type QueryOptLookupPartyByTableAndPinV2Args = {
  restaurantGuid: Scalars['ID'];
  tableName: Scalars['String'];
  pin: Scalars['String'];
};


export type QueryOptUnverifiedLookupPartyByGuidV2Args = {
  partyGuid: Scalars['ID'];
};


export type QueryOptLookupPartyByGuidV2Args = {
  partyGuid: Scalars['ID'];
  partyMemberGuid: Scalars['ID'];
  memberAuthToken: Scalars['String'];
};


export type QueryOptLookupPartyByOrderArgs = {
  restaurantGuid: Scalars['ID'];
  orderGuid: Scalars['String'];
};


export type QueryOptLookupPartyCartV2Args = {
  partyGuid: Scalars['ID'];
  partyMemberGuid: Scalars['ID'];
  memberAuthToken: Scalars['String'];
};


export type QueryOrderAtTablePartySelectionItemV2Args = {
  input: OrderAtTablePartySelectionItemDetailsInput;
  restaurantGuid: Scalars['ID'];
  nestingLevel: Scalars['Int'];
};


export type QueryOptPriceSplitCheckArgs = {
  optSplitCheckInput: OptSplitCheckInput;
};


export type QueryLookupPartiesByTableArgs = {
  input: LookupPartiesByTableInput;
};


export type QueryLookupPartiesByCheckArgs = {
  input: LookupPartiesByCheckInput;
};


export type QueryRestaurantArgs = {
  guid: Scalars['ID'];
};


export type QueryRestaurantV2Args = {
  guid: Scalars['ID'];
};


export type QueryItemFeedbackSurveyArgs = {
  input: ItemFeedbackSurveyLookupInput;
};

export type RedemptionData = {
  __typename?: 'RedemptionData';
  target?: Maybe<Scalars['Int']>;
  currentState?: Maybe<LoyaltyRewardState>;
  referenceId?: Maybe<Scalars['String']>;
  itemId?: Maybe<Scalars['ID']>;
};

/** Response type returned when a refresh request fails (e.g. the refresh token is expired) */
export type RefreshError = Error & {
  __typename?: 'RefreshError';
  /** Type of error encountered - use this field to differentiate between errors */
  code: RefreshErrorCode | `${RefreshErrorCode}`;
  /** Additional information about the error encountered */
  message: Scalars['String'];
};

export enum RefreshErrorCode {
  InvalidRefreshToken = 'INVALID_REFRESH_TOKEN',
  ServiceUnavailable = 'SERVICE_UNAVAILABLE'
}

export type RefreshInput = {
  /** JWT refresh token */
  refreshToken: Scalars['String'];
};

export type RefreshResponse = AuthenticationResponse | RefreshError;

export enum RegistrationSource {
  OnlineOrdering = 'ONLINE_ORDERING',
  ToastApp = 'TOAST_APP',
  OrderAndPay = 'ORDER_AND_PAY'
}

export enum RequiredMode {
  Required = 'REQUIRED',
  OptionalForceShow = 'OPTIONAL_FORCE_SHOW',
  Optional = 'OPTIONAL'
}

export type ResetPasswordError = Error & {
  __typename?: 'ResetPasswordError';
  code: ResetPasswordErrorCode | `${ResetPasswordErrorCode}`;
  message: Scalars['String'];
};

export enum ResetPasswordErrorCode {
  InvalidToken = 'INVALID_TOKEN',
  InvalidNewPassword = 'INVALID_NEW_PASSWORD'
}

export type ResetPasswordInput = {
  /** Reset token, obtained as a query parameter from the link in the email sent by **initiateEmailPasswordReset** */
  resetToken: Scalars['String'];
  /** The desired new password for the customer account */
  newPassword: Scalars['String'];
};

export type ResetPasswordResponse = AuthenticationResponse | ResetPasswordError;

export type Restaurant = {
  __typename?: 'Restaurant';
  creditCardConfig: RestaurantCreditCardConfig;
  logoUrls?: Maybe<ImageSet>;
  bannerUrls?: Maybe<ImageSet>;
  orderAndPayConfig?: Maybe<OptConfig>;
  guid: Scalars['ID'];
  shortUrl: Scalars['String'];
  /** The restaurant's name that an aggregator client should use. Accounts for Takeout-specific name override */
  name: Scalars['String'];
  /** The restaurant's name that a white-label client should use */
  whiteLabelName: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  /** The restaurant's cuisineType that is a high-level descriptor for the type of food served by the restaurant. This value is defined under the consumer app config and is NOT set by the restaurant. If it is null, then the property has not been defined on the consumer app config. */
  cuisineType?: Maybe<Scalars['String']>;
  minimumDeliveryTime: Scalars['Int'];
  minimumTakeoutTime: Scalars['Int'];
  location: Location;
  /** The Internationalization (i18n) properties relating to a given restaurant */
  i18n?: Maybe<I18n>;
  /** The restaurant's loyalty settings, defaulting to disabled settings if the restaurant has no loyalty or if the loyalty service is unavailable */
  loyaltyConfig: LoyaltyConfig;
  /** The restaurant's Toast For Good settings, defaulting to disabled settings if the restaurant has no Toast For Good settings or if the Toast For Good settings service is unavailable */
  tfgConfig: TfgConfig;
  /** Gift card links */
  giftCardLinks: GiftCardLinks;
  /** Gift card config */
  giftCardConfig: GiftCardConfig;
  /** Links to resturant social media platforms. */
  socialMediaLinks: SocialMediaLinks;
  /** Configuration for optional information a restaurant could spotlight */
  spotlightConfig?: Maybe<SpotlightConfig>;
  /** Configuration for a restaurant's popular items display */
  popularItemsConfig?: Maybe<PopularItemsConfig>;
  /** Configuration for a restaurant's item upsells */
  upsellsConfig?: Maybe<UpsellsConfig>;
  /** Configuration options for the per-selection freeform special requests field */
  specialRequestsConfig: SpecialRequestsConfig;
  /** Determines if the restaurant is enabled for Toast Takeout. */
  consumerAppEnabled?: Maybe<Scalars['Boolean']>;
  /** Determines if the restaurant is allowed to show a CTA. Restaurant must be enabled for the Toast Takeout and have Toast Takeout CTA enabled. */
  consumerCTAEnabled?: Maybe<Scalars['Boolean']>;
  /** Item level feedback config When null, no configuration can be resolved for the restaurant */
  itemLevelFeedbackConfig?: Maybe<ItemLevelFeedbackConfig>;
  /** Determines if the restaurant is operating in test mode */
  testMode: Scalars['Boolean'];
  /** Toast Pay product configuration. When null, no configuration can be resolved for the restaurant */
  toastPayConfig?: Maybe<ToastPayConfig>;
};

export type RestaurantCreditCardConfig = {
  __typename?: 'RestaurantCreditCardConfig';
  /** Indicates if the restaurant accepts American Express credit card payments, assuming that the restaurant allows payment via credit card in the first place. */
  amexAccepted: Scalars['Boolean'];
  /** Indicates if the restaurant allows the diner to specify a tip when paying via credit card, assuming that the restaurant allows payment via credit card in the first place. */
  tipEnabled: Scalars['Boolean'];
};

/** An object representing a restaurant reward, i.e. a reward provided by a restaurant itself. */
export type RestaurantDiscount = {
  __typename?: 'RestaurantDiscount';
  name: Scalars['String'];
  guid: Scalars['ID'];
  amount: Scalars['Float'];
  promoCode: Scalars['String'];
};

export type RestaurantOrError = Restaurant | GeneralError;

export enum RewardType {
  SignUp = 'SIGN_UP',
  CustomerNUse = 'CUSTOMER_N_USE',
  GlobalNUse = 'GLOBAL_N_USE'
}

export type SpiPayment = {
  paymentId: Scalars['String'];
  amount: Scalars['Float'];
  paymentMethodId?: Maybe<Scalars['String']>;
  paymentType?: Maybe<SpiPaymentMethods | `${SpiPaymentMethods}`>;
  /**
   * Links the card fingerprint identified by the payment method id to the guest account.
   * Guest must be logged in.
   */
  shouldLinkPaymentMethod?: Maybe<Scalars['Boolean']>;
};

export type StpCreatePartyInput = {
  /** Used for disambiguating table names */
  restaurantGuid: Scalars['ID'];
  /** Table the Party represents */
  checkGuid: Scalars['ID'];
  /** User's name. */
  name?: Maybe<Scalars['String']>;
  /** User's contact info. Phone/email. */
  contactInfo?: Maybe<ContactInfo>;
};

export type StpJoinOrCreatePartyByMainCheckGuidInput = {
  /** Used to fetch the order */
  restaurantGuid: Scalars['ID'];
  /** Used to query for an existing party */
  checkGuid: Scalars['ID'];
  /** The full name of the guest paying for their portion of a check. Must be unique to the party. If it is not provided, a name will be autogenerated for the guest. */
  name?: Maybe<Scalars['String']>;
  /** The guests phone and email. These can be updated later in the flow */
  contactInfo?: Maybe<ContactInfo>;
};

export type SavedAddress = {
  __typename?: 'SavedAddress';
  guid: Scalars['ID'];
  /** The name/label for this address, e.g. "Home", "Work" */
  name?: Maybe<Scalars['String']>;
  deliveryInfo: DeliveryInfo;
};

export type SavedAddressInput = {
  addressGuid: Scalars['ID'];
};

export type SavedCardInput = {
  cardGuid: Scalars['ID'];
};

/** Saved credit card information; the token itself is stored securely in customermgmt and not provided to the client */
export type SavedCreditCard = {
  __typename?: 'SavedCreditCard';
  /** The issuing network of the card, e.g. "VISA" */
  cardType: CardType | `${CardType}`;
  /** Two digit expiration month */
  expirationMonth: Scalars['String'];
  /** Four digit expiration year */
  expirationYear: Scalars['String'];
  expired: Scalars['Boolean'];
  /** The card guid, used as an identifier for the card */
  guid: Scalars['ID'];
  /** Flag indicating whether the card is the primary (selected by default) card for the account */
  isPrimary: Scalars['Boolean'];
  /** The masked card number with only the last four digits visible, in the format of "XXXX-1234" */
  maskedPan: Scalars['String'];
};

export type Selection = {
  __typename?: 'Selection';
  guid?: Maybe<Scalars['ID']>;
  itemGuid?: Maybe<Scalars['ID']>;
  itemGroupGuid?: Maybe<Scalars['ID']>;
  modifiers?: Maybe<Array<Maybe<Selection>>>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  /** The cost of the Selection before any discounts are applied to the order */
  preDiscountPrice?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Int']>;
  usesFractionalQuantity: Scalars['Boolean'];
  fractionalQuantity: FractionalQuantity;
};

export type SelectionInput = {
  itemGroupGuid?: Maybe<Scalars['ID']>;
  itemGuid: Scalars['ID'];
  modifierGroups: Array<ModifierGroupInput>;
  quantity: Scalars['Int'];
  fractionalQuantity?: Maybe<FractionalQuantityInput>;
  specialInstructions?: Maybe<Scalars['String']>;
};

export type SelectionItemV2 = {
  __typename?: 'SelectionItemV2';
  guid: Scalars['ID'];
  itemGuid: Scalars['ID'];
  itemGroupGuid: Scalars['ID'];
  specialRequest?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  outOfStock?: Maybe<Scalars['Boolean']>;
  stockQuantity?: Maybe<Scalars['Float']>;
  menuItemPrice?: Maybe<Scalars['Float']>;
  selectionItemPrice?: Maybe<Scalars['Float']>;
  unitOfMeasure: UnitOfMeasure | `${UnitOfMeasure}`;
  modifierGroups: Array<SelectionModifierGroupV2>;
  calories?: Maybe<Scalars['Int']>;
  usesFractionalQuantity: Scalars['Boolean'];
  fractionalQuantity: FractionalQuantity;
  imageUrl?: Maybe<Scalars['String']>;
};


export type SelectionItemV2ModifierGroupsArgs = {
  nestingLevel?: Maybe<Scalars['Int']>;
};

export type SelectionModifierGroupV2 = {
  __typename?: 'SelectionModifierGroupV2';
  guid: Scalars['ID'];
  minSelections: Scalars['Int'];
  maxSelections?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  requiredMode: RequiredMode | `${RequiredMode}`;
  modifiers: Array<SelectionModifierOptionV2>;
};

export type SelectionModifierOptionV2 = {
  __typename?: 'SelectionModifierOptionV2';
  itemGuid: Scalars['ID'];
  itemGroupGuid?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Int']>;
  selected?: Maybe<Scalars['Boolean']>;
  modifierGroups: Array<SelectionModifierGroupV2>;
  outOfStock: Scalars['Boolean'];
  stockQuantity?: Maybe<Scalars['Float']>;
};

export type ServiceCharge = {
  __typename?: 'ServiceCharge';
  guid?: Maybe<Scalars['ID']>;
  externalId?: Maybe<Scalars['ID']>;
  entityType?: Maybe<Scalars['String']>;
};

export enum ServiceChargeCalculation {
  PreDiscount = 'PRE_DISCOUNT',
  PostDiscount = 'POST_DISCOUNT'
}

export enum ServiceChargeCategory {
  ServiceCharge = 'SERVICE_CHARGE',
  CreditCardSurcharge = 'CREDIT_CARD_SURCHARGE',
  FundraisingCampaign = 'FUNDRAISING_CAMPAIGN'
}

export type ServiceChargeCriteria = {
  __typename?: 'ServiceChargeCriteria';
  minCheckAmount?: Maybe<Scalars['Float']>;
  maxCheckAmount?: Maybe<Scalars['Float']>;
};

export type ServiceChargeDetails = {
  __typename?: 'ServiceChargeDetails';
  guid?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  properties?: Maybe<Scalars['String']>;
  amountType?: Maybe<ServiceChargeType | `${ServiceChargeType}`>;
  amount?: Maybe<Scalars['Float']>;
  percent?: Maybe<Scalars['Float']>;
  criteria?: Maybe<ServiceChargeCriteria>;
  gratuity?: Maybe<Scalars['Boolean']>;
  taxable?: Maybe<Scalars['Boolean']>;
};

export enum ServiceChargeType {
  Fixed = 'FIXED',
  Percent = 'PERCENT',
  Open = 'OPEN'
}

export type SignUpToLoyaltyError = Error & {
  __typename?: 'SignUpToLoyaltyError';
  message: Scalars['String'];
  code: SignUpToLoyaltyErrorCode | `${SignUpToLoyaltyErrorCode}`;
};

export enum SignUpToLoyaltyErrorCode {
  CannotSignUp = 'CANNOT_SIGN_UP'
}

export type SignUpToLoyaltyInput = {
  /** The guid of the paid check */
  checkGuid: Scalars['ID'];
  /** The guid of the restaurant which has the paid check */
  restaurantGuid: Scalars['ID'];
  /** The guest's email to sign-up to rewards program - either email or phone number, or both, must be provided */
  email?: Maybe<Scalars['String']>;
  /** The guest's phone number to sign-up to rewards program - either email or phone number, or both must, be provided */
  phone?: Maybe<Scalars['String']>;
};

export type SignUpToLoyaltyResponse = {
  __typename?: 'SignUpToLoyaltyResponse';
  hasLoyalty?: Maybe<Scalars['Boolean']>;
  newAccount?: Maybe<Scalars['Boolean']>;
};

export type SignUpToLoyaltyResponseOrError = SignUpToLoyaltyResponse | SignUpToLoyaltyError;

/** Social Media Links allow a restaurant to point customers to their social media pages. */
export type SocialMediaLinks = {
  __typename?: 'SocialMediaLinks';
  /** Link to a restaurant's home page */
  resturantLink?: Maybe<Scalars['String']>;
  /** Link to the restaurant's facebook page. */
  facebookLink?: Maybe<Scalars['String']>;
  /** Link to the restaurant's twitter feed */
  twitterLink?: Maybe<Scalars['String']>;
  /** Twitter user */
  twitterUser?: Maybe<Scalars['String']>;
  /** Instagram */
  instagramLink?: Maybe<Scalars['String']>;
};

export type SpecialRequestsConfig = {
  __typename?: 'SpecialRequestsConfig';
  /** Indicates whether special requests input box should be visible in modifier dialogs. */
  enabled: Scalars['Boolean'];
  /** Indicates what hint text to display in the special requests input box if special requests is enabled. */
  placeholderMessage: Scalars['String'];
};

export enum SpiPaymentMethods {
  ApplePay = 'APPLE_PAY',
  GooglePay = 'GOOGLE_PAY',
  NewCard = 'NEW_CARD',
  SavedCard = 'SAVED_CARD'
}

export type SplitItemInput = {
  /** ID of the selection to split */
  selectionID: Scalars['String'];
  /** How many of the selection to split off */
  proportion: Scalars['Float'];
};

export enum SplitMode {
  MyItems = 'MY_ITEMS',
  Even = 'EVEN'
}

export type SplitPaymentData = {
  __typename?: 'SplitPaymentData';
  /** Indicates what split type the check is being split by */
  splitMode: SplitMode | `${SplitMode}`;
  /** Indicates how many portions the check can be split into */
  evenSplitPortions: Scalars['Int'];
  /** Indicates how many portions of the check have been paid */
  evenSplitPaidPortions: Scalars['Int'];
};

export type SplitPaymentDataInput = {
  /** Indicates what split type the check is being split by */
  splitMode: SplitMode | `${SplitMode}`;
  /** Indicates how many portions the check can be split into */
  evenSplitPortions: Scalars['Int'];
  /** Indicates how many portions of the check have been paid */
  evenSplitPaidPortions: Scalars['Int'];
};

export type SplitPaymentInitializationDataInput = {
  /** Indicates what split type the check is being split by */
  splitMode: SplitMode | `${SplitMode}`;
  /** Indicates how many portions the check can be split into */
  evenSplitPortions: Scalars['Int'];
};

export type SplitPaymentPaidPortionInput = {
  /** Party member is a part of. */
  partyGuid: Scalars['ID'];
  /** Member's unique identifier. */
  partyMemberGuid: Scalars['ID'];
  /** Member's auth token. */
  memberAuthToken: Scalars['String'];
  /** Split Evenly metadata for number of portions, as well as how many have been paid */
  splitPaymentData: SplitPaymentDataInput;
};

/** Configuration for optional information a restaurant could spotlight */
export type SpotlightConfig = {
  __typename?: 'SpotlightConfig';
  /** Spotlight primary header text. Should be a relatively short message Max length of 150 characters */
  headerText?: Maybe<Scalars['String']>;
  /** Spotlight secondary header text. Can be arbitrary length */
  bodyText?: Maybe<Scalars['String']>;
};

export type StartProfileCreationError = Error & {
  __typename?: 'StartProfileCreationError';
  code: StartProfileCreationErrorCode | `${StartProfileCreationErrorCode}`;
  message: Scalars['String'];
};

export enum StartProfileCreationErrorCode {
  ServiceUnavailable = 'SERVICE_UNAVAILABLE',
  InvalidEmail = 'INVALID_EMAIL',
  InvalidName = 'INVALID_NAME'
}

export type StartProfileCreationInput = {
  /** Email for the new account. Must not be in use by another account, and cannot be changed once the account is created. */
  email: Scalars['String'];
  /** phoneNumber for the new account. Must not be in use by another account, and cannot be changed once the account is created. A verification text will be sent to this number */
  phone: Scalars['String'];
  /** The source for the account creation (Toast App vs web Online Ordering vs Order and Pay) */
  source: RegistrationSource | `${RegistrationSource}`;
  /** Optional basic info can be provided to create the account with these fields (first name, last name, phone number) already set */
  basicInfo?: Maybe<BasicInfoInput>;
  /** Optional input to create a loyalty account at the same time as the OO account is created. **basicInfo** is required for the loyalty account to be successfully created. */
  loyaltyAccountCreationInput?: Maybe<LoyaltyAccountCreationInput>;
};

export type StartProfileCreationResponse = {
  __typename?: 'StartProfileCreationResponse';
  guestGuid: Scalars['ID'];
};

export type StartProfileCreationResponseOrError = StartProfileCreationResponse | StartProfileCreationError;

/** Concrete type for String feature flag results. Contains the flag and a String value representing its current state. */
export type StringFeatureFlagResult = FeatureFlagResult & {
  __typename?: 'StringFeatureFlagResult';
  flag: Scalars['String'];
  value: Scalars['String'];
};

/** generic key-value pair object */
export type StringKeyVal = {
  __typename?: 'StringKeyVal';
  key: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

/** generic key-value pair object */
export type StringKeyValInput = {
  key: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

/** Possible payment types that a surcharge summary can be applicable to. Derived from Orders API (link will undergo updates): https://github.toasttab.com/toasttab/toast-orders/blob/arina.ielchiieva/OAPI-1101-check-summary-endpoint/toast-orders-api/src/main/java/com/toasttab/service/orders/enums/PaymentType.kt Independent of the plain PaymentType enum, which does not acknowledge debit cards. */
export enum SurchargePaymentType {
  Credit = 'CREDIT',
  Debit = 'DEBIT',
  Giftcard = 'GIFTCARD',
  Cash = 'CASH'
}

/** The surcharge summary information for a single collection of payment types. If surcharging is not applicable for a restaurant, e.g. the surcharging config is disabled, then paymentTypes will have every payment type, and the amounts and rates will be zero. */
export type SurchargeSummaryForPaymentTypes = {
  __typename?: 'SurchargeSummaryForPaymentTypes';
  /** A list of payment types that this surcharge info is applicable on. */
  paymentTypes: Array<SurchargePaymentType | `${SurchargePaymentType}`>;
  /** The surcharge amount for the check without tax applied. */
  surchargePreTaxAmount: Scalars['Float'];
  /** The surcharge amount for the check with tax applied. */
  surchargeTaxAmount: Scalars['Float'];
  /** The percentage used to calculate surcharge amounts in decimal format (0.03, not 3%). */
  surchargeRate: Scalars['Float'];
};

export type TfgConfig = {
  __typename?: 'TfgConfig';
  /** service charge associated with the Toast for good config */
  serviceChargeGuid?: Maybe<Scalars['String']>;
  /** campaign name */
  campaignName?: Maybe<Scalars['String']>;
  /** campaign Description */
  campaignDescription?: Maybe<Scalars['String']>;
  /** campaign logo */
  campaignLogoURL?: Maybe<Scalars['String']>;
  /** Whether roundup feature is enabled or not */
  active?: Maybe<Scalars['Boolean']>;
};

export type TfgInput = {
  roundUpEnabled: Scalars['Boolean'];
  estimatedRoundUpValue: Scalars['Float'];
};

export type ToastPayConfig = {
  __typename?: 'ToastPayConfig';
  /** Whether we enable ability to pay with split payments for Toast Pay restaurants. This value cannot currently be controlled by the restaurant, and is primarily a toggle we can use for rollout purposes. */
  splitPaymentsEnabled: Scalars['Boolean'];
};

/** Field representing the unit which is used to measure the weight of an item. */
export enum UnitOfMeasure {
  /** None - this is the default */
  None = 'NONE',
  /** Pounds */
  Lb = 'LB',
  /** Ounces */
  Oz = 'OZ',
  /** Kilograms */
  Kg = 'KG',
  /** Grams */
  G = 'G'
}

export type UpdateAddressError = Error & {
  __typename?: 'UpdateAddressError';
  code: UpdateAddressErrorCode | `${UpdateAddressErrorCode}`;
  message: Scalars['String'];
};

export enum UpdateAddressErrorCode {
  AddressNotFound = 'ADDRESS_NOT_FOUND'
}

export type UpdateAddressInput = {
  guid: Scalars['ID'];
  /** The name/label for this address, e.g. "Home", "Work" */
  name: Scalars['String'];
  deliveryInfo: DeliveryInfoInput;
};

export type UpdateAddressResponse = {
  __typename?: 'UpdateAddressResponse';
  customer: Customer;
};

export type UpdateAddressResponseOrError = UpdateAddressResponse | UpdateAddressError;

export type UpdateBasicInfoError = Error & {
  __typename?: 'UpdateBasicInfoError';
  code: UpdateBasicInfoErrorCode | `${UpdateBasicInfoErrorCode}`;
  message: Scalars['String'];
};

export enum UpdateBasicInfoErrorCode {
  InvalidBasicInfo = 'INVALID_BASIC_INFO'
}

export type UpdateBasicInfoResponse = {
  __typename?: 'UpdateBasicInfoResponse';
  customer: Customer;
};

export type UpdateBasicInfoResponseOrError = UpdateBasicInfoResponse | UpdateBasicInfoError;

export type UpdatePartyMemberInput = {
  /** Party member is a part of. */
  partyGuid: Scalars['ID'];
  /** Member's unique identifier. */
  partyMemberGuid: Scalars['ID'];
  /** Member's auth token. */
  memberAuthToken: Scalars['String'];
  /** One of PartyMembers updatable properties. */
  name?: Maybe<Scalars['String']>;
  /** One of PartyMembers updatable properties. */
  emoji?: Maybe<Scalars['String']>;
  /** One of PartyMembers updatable properties. */
  contactInfo?: Maybe<ContactInfo>;
};

export type UpdatePasswordError = Error & {
  __typename?: 'UpdatePasswordError';
  code: UpdatePasswordErrorCode | `${UpdatePasswordErrorCode}`;
  message: Scalars['String'];
};

export enum UpdatePasswordErrorCode {
  PasswordMismatch = 'PASSWORD_MISMATCH',
  InvalidNewPassword = 'INVALID_NEW_PASSWORD'
}

export type UpdatePasswordInput = {
  /** Current password, prior to update */
  oldPassword: Scalars['String'];
  /** The desired password to be updated to */
  newPassword: Scalars['String'];
  /** Restaurant guid, which is used to fetch the restaurant name used in the password reset email to give context to the user where their account is from. This is more important for white label sources where the user may not know what "Toast" is. If not provided, will send the email with "Toast" as the restaurant name. */
  restaurantGuid?: Maybe<Scalars['ID']>;
};

export type UpdatePasswordResponse = {
  __typename?: 'UpdatePasswordResponse';
  customer: Customer;
};

export type UpdatePasswordResponseOrError = UpdatePasswordResponse | UpdatePasswordError;

export type UpdatePaymentIntentForSplitCheckBySelectionsInput = {
  /** Party member is a part of. */
  partyGuid: Scalars['ID'];
  /** Member's unique identifier. */
  partyMemberGuid: Scalars['ID'];
  /** Member's auth token. */
  memberAuthToken: Scalars['String'];
  /** Identifier for the payment intent to be updated */
  paymentIntentId: Scalars['ID'];
  /** Gift Card Payment */
  giftCard?: Maybe<GiftCardPaymentInput>;
  /** Promo code to be used for a Toast Cash redemption. Normal restaurant promo codes should *not* be submitted here. */
  globalGiftCard?: Maybe<GlobalGiftCardPaymentInput>;
  /** Toast Cash from the guest currency account, a global wallet tied to the guest */
  guestCurrencyAccount?: Maybe<GuestCurrencyAccountPaymentInput>;
  /** The amount of tip the customer intends to give. */
  tipAmount: Scalars['Float'];
  /** Email to be sent for fraud checks. */
  email?: Maybe<Scalars['String']>;
  /** Identifier of the payment method, which can inform pricing calculations. */
  paymentMethodId?: Maybe<Scalars['ID']>;
  /** List of selections to split, paired with floats indicating how they should be split. */
  selectionsToSplit: Array<SplitItemInput>;
  /** Indicates to set the externalReferenceId when payment confirmation has to be retried */
  enableConfirmRetry?: Maybe<Scalars['Boolean']>;
};

export type UpdatePaymentIntentInput = {
  /** Party member is a part of. */
  partyGuid: Scalars['ID'];
  /** Member's unique identifier. */
  partyMemberGuid: Scalars['ID'];
  /** Member's auth token. */
  memberAuthToken: Scalars['String'];
  /** Identifier for the payment intent to be updated */
  paymentIntentId: Scalars['ID'];
  /** Gift Card Payment */
  giftCard?: Maybe<GiftCardPaymentInput>;
  /** Promo code to be used for a Toast Cash redemption. Normal restaurant promo codes should *not* be submitted here. */
  globalGiftCard?: Maybe<GlobalGiftCardPaymentInput>;
  /** Toast Cash from the guest currency account, a global wallet tied to the guest */
  guestCurrencyAccount?: Maybe<GuestCurrencyAccountPaymentInput>;
  /** The amount of tip the customer intends to give. */
  tipAmount: Scalars['Float'];
  /** Identifier for the check */
  checkGuid?: Maybe<Scalars['ID']>;
  /** Indicates whether split payment data should be used for pricing. */
  useSplitPaymentData?: Maybe<Scalars['Boolean']>;
  /** Email to be sent for fraud checks. */
  email?: Maybe<Scalars['String']>;
  /** Identifier of the payment method, which can inform pricing calculations. */
  paymentMethodId?: Maybe<Scalars['ID']>;
  /** Indicates to set the externalReferenceId when payment confirmation has to be retried */
  enableConfirmRetry?: Maybe<Scalars['Boolean']>;
};

export type UpdatePaymentIntentResponse = UpdatePaymentIntentSuccess | PaymentIntentError;

export type UpdatePaymentIntentSuccess = {
  __typename?: 'UpdatePaymentIntentSuccess';
  /** The actual amount on the payment intent that we intend to charge, in the minimum denomination of the currency: check amount + tip + surcharge - gift card - Toast Cash */
  intentAmount: Scalars['Int'];
  /** The surcharge portion of the intent amount, in the default currency representation */
  surchargeAmount?: Maybe<Scalars['Float']>;
  /** The base amount the guest is expecting to pay for, not including the surcharge: check amount + tip - gift card - Toast Cash */
  baseAmount?: Maybe<Scalars['Float']>;
};

export type UpsellsConfig = {
  __typename?: 'UpsellsConfig';
  enabled: Scalars['Boolean'];
};

export type ValidateApplePayMerchantError = Error & {
  __typename?: 'ValidateApplePayMerchantError';
  message: Scalars['String'];
  code: ValidateApplePayMerchantErrorCode | `${ValidateApplePayMerchantErrorCode}`;
};

export enum ValidateApplePayMerchantErrorCode {
  ValidateMerchantFailed = 'VALIDATE_MERCHANT_FAILED'
}

export type ValidateApplePayMerchantInput = {
  /** The GUID of the restaurant that client is using for checkout */
  restaurantGuid: Scalars['ID'];
  /** The url generated by the Apple Pay JS client that will be used for merchant validation */
  validationURL: Scalars['String'];
  /** The domain which the Apple Pay JS client is running */
  merchantDomain: Scalars['String'];
};

/** extend type Query {} */
export type ValidateApplePayMerchantResponse = ValidateApplePayMerchantSuccessResponse | ValidateApplePayMerchantError;

export type ValidateApplePayMerchantSuccessResponse = {
  __typename?: 'ValidateApplePayMerchantSuccessResponse';
  merchantSession: Scalars['String'];
};

export type VerifyEmailError = Error & {
  __typename?: 'VerifyEmailError';
  code: VerifyEmailErrorCode | `${VerifyEmailErrorCode}`;
  message: Scalars['String'];
};

export enum VerifyEmailErrorCode {
  InvalidToken = 'INVALID_TOKEN',
  TokenAlreadyUsed = 'TOKEN_ALREADY_USED'
}

export type VerifyEmailInput = {
  /** Token obtained from the email sent by the **createAccount** mutation, which is appended to the redirectUrl as a query parameter on the link in the email */
  verificationToken: Scalars['String'];
};

export type VerifyEmailResponse = AuthenticationResponse | VerifyEmailError;

export type VerifySmsCodeInput = {
  phoneNumber: Scalars['String'];
  smsCode: Scalars['String'];
};

export type VerifySmsCodeResponse = {
  __typename?: 'VerifySmsCodeResponse';
  success: Scalars['Boolean'];
};

/** Indicates that although the intended result of a query or mutation was achieved, there are side effects that would affect downstream actions. */
export type Warning = {
  message: Scalars['String'];
};

export type BrandsQueryVariables = Exact<{
  input: NvBrandsInput;
}>;


export type BrandsQuery = (
  { __typename?: 'Query' }
  & { nvBrandingConfig?: Maybe<(
    { __typename?: 'NvBrandingConfig' }
    & Pick<NvBrandingConfig, 'isNvBrandingEnabled' | 'campaignKeyword'>
  )> }
);

export type Validate_Apple_Pay_MerchantMutationVariables = Exact<{
  input: ValidateApplePayMerchantInput;
}>;


export type Validate_Apple_Pay_MerchantMutation = (
  { __typename?: 'Mutation' }
  & { validateApplePayMerchant: (
    { __typename?: 'ValidateApplePayMerchantSuccessResponse' }
    & Pick<ValidateApplePayMerchantSuccessResponse, 'merchantSession'>
  ) | (
    { __typename?: 'ValidateApplePayMerchantError' }
    & Pick<ValidateApplePayMerchantError, 'message' | 'code'>
  ) }
);

export type Login_ServerMutationVariables = Exact<{
  input: LoginInput;
}>;


export type Login_ServerMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'AuthenticationResponse' }
    & Pick<AuthenticationResponse, 'customerGuid' | 'accessToken' | 'refreshToken'>
  ) | (
    { __typename?: 'MfaChallengeGeneratedResponse' }
    & Pick<MfaChallengeGeneratedResponse, 'challengeToken' | 'mfaType' | 'maskedRecipient'>
  ) | (
    { __typename?: 'LoginError' }
    & Pick<LoginError, 'code' | 'message'>
  ) }
);

export type Mfa_Login_ServerMutationVariables = Exact<{
  input: MfaLoginInput;
}>;


export type Mfa_Login_ServerMutation = (
  { __typename?: 'Mutation' }
  & { mfaLogin: (
    { __typename?: 'AuthenticationResponse' }
    & Pick<AuthenticationResponse, 'customerGuid' | 'accessToken' | 'refreshToken'>
  ) | (
    { __typename?: 'MfaChallengeGeneratedResponse' }
    & Pick<MfaChallengeGeneratedResponse, 'challengeToken' | 'mfaType' | 'maskedRecipient'>
  ) | (
    { __typename?: 'LoginError' }
    & Pick<LoginError, 'code' | 'message'>
  ) }
);

export type Customer_ServerQueryVariables = Exact<{ [key: string]: never; }>;


export type Customer_ServerQuery = (
  { __typename?: 'Query' }
  & { customer: (
    { __typename?: 'Customer' }
    & Pick<Customer, 'guid' | 'firstName' | 'lastName' | 'phone' | 'email'>
  ) }
);

export type RefreshMutationVariables = Exact<{
  input: RefreshInput;
}>;


export type RefreshMutation = (
  { __typename?: 'Mutation' }
  & { refresh: (
    { __typename?: 'AuthenticationResponse' }
    & Pick<AuthenticationResponse, 'customerGuid' | 'accessToken' | 'refreshToken'>
  ) | (
    { __typename?: 'RefreshError' }
    & Pick<RefreshError, 'code' | 'message'>
  ) }
);

export type Opt_Apply_Payg_Promo_CodeMutationVariables = Exact<{
  input: OptPartyPaygPromoInput;
  totalGiftCardBalance?: Maybe<Scalars['Float']>;
}>;


export type Opt_Apply_Payg_Promo_CodeMutation = (
  { __typename?: 'Mutation' }
  & { optApplyPartyPromo: (
    { __typename?: 'OPTPartyRefreshV2' }
    & OptPartyRefreshV2Fragment
  ) | (
    { __typename?: 'OPTPartyError' }
    & Pick<OptPartyError, 'message' | 'code'>
  ) }
);

export type Opt_Remove_Payg_Promo_CodeMutationVariables = Exact<{
  partyGuid: Scalars['ID'];
  partyMemberGuid: Scalars['ID'];
  memberAuthToken: Scalars['String'];
  totalGiftCardBalance?: Maybe<Scalars['Float']>;
}>;


export type Opt_Remove_Payg_Promo_CodeMutation = (
  { __typename?: 'Mutation' }
  & { optRemovePartyPromo: (
    { __typename?: 'OPTPartyRefreshV2' }
    & OptPartyRefreshV2Fragment
  ) | (
    { __typename?: 'OPTPartyError' }
    & Pick<OptPartyError, 'message' | 'code'>
  ) }
);

export type Create_AccountMutationVariables = Exact<{
  input: CreateAccountInput;
}>;


export type Create_AccountMutation = (
  { __typename?: 'Mutation' }
  & { createAccount: (
    { __typename?: 'CreateAccountResponse' }
    & Pick<CreateAccountResponse, 'customerGuid'>
  ) | (
    { __typename?: 'CreateAccountError' }
    & Pick<CreateAccountError, 'code' | 'message'>
  ) }
);

export type Customer_Credit_CardsQueryVariables = Exact<{ [key: string]: never; }>;


export type Customer_Credit_CardsQuery = (
  { __typename?: 'Query' }
  & { customer: (
    { __typename?: 'Customer' }
    & Pick<Customer, 'guid'>
    & { creditCards: Array<(
      { __typename?: 'SavedCreditCard' }
      & Pick<SavedCreditCard, 'guid' | 'maskedPan' | 'expirationMonth' | 'expirationYear' | 'cardType' | 'isPrimary' | 'expired'>
    )> }
  ) }
);

export type Update_Basic_InfoMutationVariables = Exact<{
  input: BasicInfoInput;
}>;


export type Update_Basic_InfoMutation = (
  { __typename?: 'Mutation' }
  & { updateBasicInfo: (
    { __typename?: 'UpdateBasicInfoResponse' }
    & { customer: (
      { __typename?: 'Customer' }
      & Pick<Customer, 'guid' | 'firstName' | 'lastName' | 'phone'>
    ) }
  ) | (
    { __typename?: 'UpdateBasicInfoError' }
    & Pick<UpdateBasicInfoError, 'code' | 'message'>
  ) }
);

export type Update_PasswordMutationVariables = Exact<{
  input: UpdatePasswordInput;
}>;


export type Update_PasswordMutation = (
  { __typename?: 'Mutation' }
  & { updatePassword: (
    { __typename?: 'UpdatePasswordResponse' }
    & { customer: (
      { __typename?: 'Customer' }
      & Pick<Customer, 'guid'>
    ) }
  ) | (
    { __typename?: 'UpdatePasswordError' }
    & Pick<UpdatePasswordError, 'code' | 'message'>
  ) }
);

export type AppliedServiceChargeFragment = (
  { __typename?: 'AppliedServiceCharge' }
  & Pick<AppliedServiceCharge, 'guid' | 'name' | 'externalId' | 'chargeType' | 'chargeAmount' | 'taxAmount' | 'gratuity' | 'serviceChargeCategory'>
  & { serviceChargeDetails?: Maybe<(
    { __typename?: 'ServiceChargeDetails' }
    & Pick<ServiceChargeDetails, 'guid' | 'percent' | 'gratuity'>
  )> }
);

type CheckSelection_CheckSelectionGuid_Fragment = (
  { __typename?: 'CheckSelectionGuid' }
  & CheckSelectionGuidFragment
);

type CheckSelection_CheckSelectionNoGuid_Fragment = (
  { __typename?: 'CheckSelectionNoGuid' }
  & CheckSelectionNoGuidFragment
);

export type CheckSelectionFragment = CheckSelection_CheckSelectionGuid_Fragment | CheckSelection_CheckSelectionNoGuid_Fragment;

export type CheckSelectionGuidFragment = (
  { __typename?: 'CheckSelectionGuid' }
  & Pick<CheckSelectionGuid, 'guid' | 'voided'>
  & CheckSelectionShared_CheckSelectionGuid_Fragment
);

export type CheckSelectionNoGuidFragment = (
  { __typename?: 'CheckSelectionNoGuid' }
  & CheckSelectionShared_CheckSelectionNoGuid_Fragment
);

type CheckSelectionShared_CheckSelectionGuid_Fragment = (
  { __typename?: 'CheckSelectionGuid' }
  & Pick<CheckSelectionGuid, 'itemGuid' | 'itemGroupGuid' | 'externalId' | 'name' | 'preDiscountPrice' | 'price' | 'quantity' | 'usesFractionalQuantity'>
  & { fractionalQuantity: (
    { __typename?: 'FractionalQuantity' }
    & Pick<FractionalQuantity, 'unitOfMeasure' | 'quantity'>
  ), modifiers: Array<(
    { __typename?: 'CompletedOrderModifierSelection' }
    & Pick<CompletedOrderModifierSelection, 'name' | 'price'>
    & { modifiers: Array<(
      { __typename?: 'CompletedOrderModifierSelection' }
      & Pick<CompletedOrderModifierSelection, 'name' | 'price' | 'voided'>
      & { modifiers: Array<(
        { __typename?: 'CompletedOrderModifierSelection' }
        & Pick<CompletedOrderModifierSelection, 'name' | 'price' | 'voided'>
      )> }
    )> }
  )>, appliedDiscounts: Array<(
    { __typename?: 'AppliedDiscount' }
    & Pick<AppliedDiscount, 'discountAmount' | 'entityType' | 'externalId' | 'guid' | 'name' | 'processingState' | 'appliedPromoCode'>
  )> }
);

type CheckSelectionShared_CheckSelectionNoGuid_Fragment = (
  { __typename?: 'CheckSelectionNoGuid' }
  & Pick<CheckSelectionNoGuid, 'itemGuid' | 'itemGroupGuid' | 'externalId' | 'name' | 'preDiscountPrice' | 'price' | 'quantity' | 'usesFractionalQuantity'>
  & { fractionalQuantity: (
    { __typename?: 'FractionalQuantity' }
    & Pick<FractionalQuantity, 'unitOfMeasure' | 'quantity'>
  ), modifiers: Array<(
    { __typename?: 'CompletedOrderModifierSelection' }
    & Pick<CompletedOrderModifierSelection, 'name' | 'price'>
    & { modifiers: Array<(
      { __typename?: 'CompletedOrderModifierSelection' }
      & Pick<CompletedOrderModifierSelection, 'name' | 'price' | 'voided'>
      & { modifiers: Array<(
        { __typename?: 'CompletedOrderModifierSelection' }
        & Pick<CompletedOrderModifierSelection, 'name' | 'price' | 'voided'>
      )> }
    )> }
  )>, appliedDiscounts: Array<(
    { __typename?: 'AppliedDiscount' }
    & Pick<AppliedDiscount, 'discountAmount' | 'entityType' | 'externalId' | 'guid' | 'name' | 'processingState' | 'appliedPromoCode'>
  )> }
);

export type CheckSelectionSharedFragment = CheckSelectionShared_CheckSelectionGuid_Fragment | CheckSelectionShared_CheckSelectionNoGuid_Fragment;

export type CompletedOrderSelectionFragment = (
  { __typename?: 'CompletedOrderSelection' }
  & Pick<CompletedOrderSelection, 'guid' | 'itemGuid' | 'itemGroupGuid' | 'externalId' | 'name' | 'preDiscountPrice' | 'price' | 'quantity' | 'usesFractionalQuantity' | 'voided'>
  & { fractionalQuantity: (
    { __typename?: 'FractionalQuantity' }
    & Pick<FractionalQuantity, 'unitOfMeasure' | 'quantity'>
  ), modifiers: Array<(
    { __typename?: 'CompletedOrderModifierSelection' }
    & Pick<CompletedOrderModifierSelection, 'name'>
    & { modifiers: Array<(
      { __typename?: 'CompletedOrderModifierSelection' }
      & Pick<CompletedOrderModifierSelection, 'name' | 'price' | 'voided'>
      & { modifiers: Array<(
        { __typename?: 'CompletedOrderModifierSelection' }
        & Pick<CompletedOrderModifierSelection, 'name' | 'price' | 'voided'>
      )> }
    )> }
  )>, appliedDiscounts: Array<Maybe<(
    { __typename?: 'AppliedDiscount' }
    & Pick<AppliedDiscount, 'discountAmount' | 'entityType' | 'externalId' | 'guid' | 'name' | 'processingState' | 'appliedPromoCode'>
  )>> }
);

export type DiscountsFragment = (
  { __typename?: 'Discounts' }
  & { loyaltyDiscounts: Array<Maybe<(
    { __typename?: 'LoyaltyDiscount' }
    & Pick<LoyaltyDiscount, 'amount' | 'guid'>
  )>>, restaurantDiscount?: Maybe<(
    { __typename?: 'RestaurantDiscount' }
    & Pick<RestaurantDiscount, 'name' | 'guid' | 'amount' | 'promoCode'>
  )> }
);

export type OptCartFragment = (
  { __typename?: 'OPTCart' }
  & Pick<OptCart, 'guid' | 'lastUpdated'>
  & { order: (
    { __typename?: 'OPTOrderNoGuid' }
    & OptOrderNoGuidFragment
  ), preComputedTips: Array<(
    { __typename?: 'PreComputedTip' }
    & PreComputedTipFragment
  )>, cartMessages?: Maybe<Array<Maybe<(
    { __typename?: 'CartMessage' }
    & Pick<CartMessage, 'messageType' | 'userFacingMessage'>
  )>>> }
);

type OptCheckV2_OptCheckV2Guid_Fragment = (
  { __typename?: 'OPTCheckV2Guid' }
  & OptCheckV2GuidFragment
);

type OptCheckV2_OptCheckV2NoGuid_Fragment = (
  { __typename?: 'OPTCheckV2NoGuid' }
  & OptCheckV2NoGuidFragment
);

type OptCheckV2_OptSplitCheckPreview_Fragment = (
  { __typename?: 'OPTSplitCheckPreview' }
  & OptSplitCheckPreviewFragment
);

export type OptCheckV2Fragment = OptCheckV2_OptCheckV2Guid_Fragment | OptCheckV2_OptCheckV2NoGuid_Fragment | OptCheckV2_OptSplitCheckPreview_Fragment;

export type OptCheckV2GuidFragment = (
  { __typename?: 'OPTCheckV2Guid' }
  & Pick<OptCheckV2Guid, 'guid' | 'number' | 'tip' | 'isClosed'>
  & { payments: Array<(
    { __typename?: 'OPTPayment' }
    & Pick<OptPayment, 'guid' | 'type' | 'amount' | 'tipAmount' | 'paymentStatus'>
  )>, appliedPreauthInfo?: Maybe<(
    { __typename?: 'AppliedPreauthInfo' }
    & Pick<AppliedPreauthInfo, 'guid' | 'token' | 'last4CardDigits' | 'cardType' | 'cardHolderFirstName' | 'cardHolderLastName' | 'cardHolderExpirationMonth' | 'cardHolderExpirationYear'>
  )>, appliedLoyaltyInfo?: Maybe<(
    { __typename?: 'AppliedLoyaltyInfo' }
    & Pick<AppliedLoyaltyInfo, 'loyaltyIdentifier'>
  )>, surcharges?: Maybe<Array<(
    { __typename?: 'SurchargeSummaryForPaymentTypes' }
    & SurchargeSummaryForPaymentTypesFragment
  )>> }
  & OptCheckV2Shared_OptCheckV2Guid_Fragment
);

export type OptCheckV2NoGuidFragment = (
  { __typename?: 'OPTCheckV2NoGuid' }
  & { appliedLoyaltyInfo?: Maybe<(
    { __typename?: 'AppliedLoyaltyInfo' }
    & Pick<AppliedLoyaltyInfo, 'loyaltyIdentifier'>
  )>, surcharges?: Maybe<Array<(
    { __typename?: 'SurchargeSummaryForPaymentTypes' }
    & SurchargeSummaryForPaymentTypesFragment
  )>> }
  & OptCheckV2Shared_OptCheckV2NoGuid_Fragment
);

export type OptSplitCheckPreviewFragment = (
  { __typename?: 'OPTSplitCheckPreview' }
  & { surcharges?: Maybe<Array<(
    { __typename?: 'SurchargeSummaryForPaymentTypes' }
    & SurchargeSummaryForPaymentTypesFragment
  )>> }
  & OptCheckV2Shared_OptSplitCheckPreview_Fragment
);

type OptCheckV2Shared_OptCheckV2Guid_Fragment = (
  { __typename?: 'OPTCheckV2Guid' }
  & Pick<OptCheckV2Guid, 'numberOfSelections' | 'serviceChargeTotal' | 'preDiscountedSubtotal' | 'subtotal' | 'tax' | 'taxWithoutSurchargeTax' | 'total' | 'expectedPaymentAmount'>
  & { customer?: Maybe<(
    { __typename?: 'CustomerInfo' }
    & Pick<CustomerInfo, 'firstName' | 'lastName' | 'email' | 'phone'>
  )>, discounts: (
    { __typename?: 'Discounts' }
    & DiscountsFragment
  ), appliedServiceCharges?: Maybe<Array<(
    { __typename?: 'AppliedServiceCharge' }
    & AppliedServiceChargeFragment
  )>>, selections: Array<(
    { __typename?: 'CheckSelectionGuid' }
    & CheckSelection_CheckSelectionGuid_Fragment
  ) | (
    { __typename?: 'CheckSelectionNoGuid' }
    & CheckSelection_CheckSelectionNoGuid_Fragment
  )>, preComputedTips: Array<(
    { __typename?: 'PreComputedTip' }
    & PreComputedTipFragment
  )> }
);

type OptCheckV2Shared_OptCheckV2NoGuid_Fragment = (
  { __typename?: 'OPTCheckV2NoGuid' }
  & Pick<OptCheckV2NoGuid, 'numberOfSelections' | 'serviceChargeTotal' | 'preDiscountedSubtotal' | 'subtotal' | 'tax' | 'taxWithoutSurchargeTax' | 'total' | 'expectedPaymentAmount'>
  & { customer?: Maybe<(
    { __typename?: 'CustomerInfo' }
    & Pick<CustomerInfo, 'firstName' | 'lastName' | 'email' | 'phone'>
  )>, discounts: (
    { __typename?: 'Discounts' }
    & DiscountsFragment
  ), appliedServiceCharges?: Maybe<Array<(
    { __typename?: 'AppliedServiceCharge' }
    & AppliedServiceChargeFragment
  )>>, selections: Array<(
    { __typename?: 'CheckSelectionGuid' }
    & CheckSelection_CheckSelectionGuid_Fragment
  ) | (
    { __typename?: 'CheckSelectionNoGuid' }
    & CheckSelection_CheckSelectionNoGuid_Fragment
  )>, preComputedTips: Array<(
    { __typename?: 'PreComputedTip' }
    & PreComputedTipFragment
  )> }
);

type OptCheckV2Shared_OptSplitCheckPreview_Fragment = (
  { __typename?: 'OPTSplitCheckPreview' }
  & Pick<OptSplitCheckPreview, 'numberOfSelections' | 'serviceChargeTotal' | 'preDiscountedSubtotal' | 'subtotal' | 'tax' | 'taxWithoutSurchargeTax' | 'total' | 'expectedPaymentAmount'>
  & { customer?: Maybe<(
    { __typename?: 'CustomerInfo' }
    & Pick<CustomerInfo, 'firstName' | 'lastName' | 'email' | 'phone'>
  )>, discounts: (
    { __typename?: 'Discounts' }
    & DiscountsFragment
  ), appliedServiceCharges?: Maybe<Array<(
    { __typename?: 'AppliedServiceCharge' }
    & AppliedServiceChargeFragment
  )>>, selections: Array<(
    { __typename?: 'CheckSelectionGuid' }
    & CheckSelection_CheckSelectionGuid_Fragment
  )>, preComputedTips: Array<(
    { __typename?: 'PreComputedTip' }
    & PreComputedTipFragment
  )> }
);

export type OptCheckV2SharedFragment = OptCheckV2Shared_OptCheckV2Guid_Fragment | OptCheckV2Shared_OptCheckV2NoGuid_Fragment | OptCheckV2Shared_OptSplitCheckPreview_Fragment;

type OptOrder_OptOrderGuid_Fragment = (
  { __typename?: 'OPTOrderGuid' }
  & OptOrderGuidFragment
);

type OptOrder_OptOrderNoGuid_Fragment = (
  { __typename?: 'OPTOrderNoGuid' }
  & OptOrderNoGuidFragment
);

export type OptOrderFragment = OptOrder_OptOrderGuid_Fragment | OptOrder_OptOrderNoGuid_Fragment;

export type OptOrderGuidFragment = (
  { __typename?: 'OPTOrderGuid' }
  & Pick<OptOrderGuid, 'guid' | 'checkGuid' | 'isClosed'>
  & { checks: Array<(
    { __typename?: 'OPTCheckV2Guid' }
    & OptCheckV2GuidFragment
  )> }
);

export type OptOrderNoGuidFragment = (
  { __typename?: 'OPTOrderNoGuid' }
  & { checks: Array<(
    { __typename?: 'OPTCheckV2NoGuid' }
    & OptCheckV2NoGuidFragment
  )> }
);

export type OptPartyMemberV2Fragment = (
  { __typename?: 'OPTPartyMemberV2' }
  & Pick<OptPartyMemberV2, 'partyMemberGuid' | 'claimedCheckGuids' | 'name' | 'cartGuid' | 'preauthCardGuid' | 'orderSelectionIds' | 'claimedPortionsPaid'>
  & { availableDiscounts: Array<Maybe<(
    { __typename?: 'AppliedDiscount' }
    & Pick<AppliedDiscount, 'discountAmount'>
    & { loyaltyDetails?: Maybe<(
      { __typename?: 'LoyaltyDetails' }
      & Pick<LoyaltyDetails, 'vendor' | 'referenceId'>
    )>, discount?: Maybe<(
      { __typename?: 'Discount' }
      & Pick<Discount, 'guid'>
    )> }
  )>>, appliedDiscounts: Array<Maybe<(
    { __typename?: 'AppliedDiscount' }
    & Pick<AppliedDiscount, 'discountAmount'>
    & { loyaltyDetails?: Maybe<(
      { __typename?: 'LoyaltyDetails' }
      & Pick<LoyaltyDetails, 'vendor' | 'referenceId'>
    )>, discount?: Maybe<(
      { __typename?: 'Discount' }
      & Pick<Discount, 'guid'>
    )> }
  )>>, availableRewards?: Maybe<Array<Maybe<(
    { __typename?: 'RedemptionData' }
    & Pick<RedemptionData, 'itemId' | 'referenceId' | 'target'>
    & { currentState?: Maybe<(
      { __typename?: 'LoyaltyRewardState' }
      & Pick<LoyaltyRewardState, 'leftToTarget'>
      & { messages?: Maybe<(
        { __typename?: 'LoyaltyRewardMessages' }
        & Pick<LoyaltyRewardMessages, 'nextRewardMessage' | 'nextRewardMiniMessage' | 'unlockedMessage' | 'rewardLabelMessage' | 'rewardValueMessage'>
      )> }
    )> }
  )>>>, appliedRewards?: Maybe<Array<Maybe<(
    { __typename?: 'RedemptionData' }
    & Pick<RedemptionData, 'itemId' | 'referenceId' | 'target'>
    & { currentState?: Maybe<(
      { __typename?: 'LoyaltyRewardState' }
      & Pick<LoyaltyRewardState, 'leftToTarget'>
      & { messages?: Maybe<(
        { __typename?: 'LoyaltyRewardMessages' }
        & Pick<LoyaltyRewardMessages, 'nextRewardMessage' | 'nextRewardMiniMessage' | 'unlockedMessage' | 'rewardLabelMessage' | 'rewardValueMessage'>
      )> }
    )> }
  )>>>, loyaltyState?: Maybe<(
    { __typename?: 'BalanceStateData' }
    & Pick<BalanceStateData, 'balance'>
    & { messages?: Maybe<(
      { __typename?: 'BalanceStateMessages' }
      & Pick<BalanceStateMessages, 'balance'>
    )> }
  )> }
);

export type OptPartyV2Fragment = (
  { __typename?: 'OPTPartyV2' }
  & Pick<OptPartyV2, 'guid' | 'orderGuid' | 'mainCheckGuid' | 'pin' | 'memberAuthToken' | 'mode' | 'partyMemberGuid' | 'tableName'>
  & { members: Array<(
    { __typename?: 'OPTPartyMemberV2' }
    & OptPartyMemberV2Fragment
  )> }
);

export type PreComputedTipFragment = (
  { __typename?: 'PreComputedTip' }
  & Pick<PreComputedTip, 'percentV2' | 'percentLabel' | 'value' | 'isDefault'>
);

export type SurchargeSummaryForPaymentTypesFragment = (
  { __typename?: 'SurchargeSummaryForPaymentTypes' }
  & Pick<SurchargeSummaryForPaymentTypes, 'paymentTypes' | 'surchargePreTaxAmount' | 'surchargeTaxAmount' | 'surchargeRate'>
);

export type Get_Gift_Card_BalanceQueryVariables = Exact<{
  input: OptGetGiftCardBalanceInput;
}>;


export type Get_Gift_Card_BalanceQuery = (
  { __typename?: 'Query' }
  & { optGetGiftCardBalance?: Maybe<(
    { __typename?: 'OPTGiftCardBalanceResponse' }
    & { globalGiftCard?: Maybe<(
      { __typename?: 'OPTGlobalGiftCard' }
      & Pick<OptGlobalGiftCard, 'cardIdentifier' | 'expectedAvailableBalance'>
    )>, restaurantGiftCard?: Maybe<(
      { __typename?: 'OPTRestaurantGiftCard' }
      & Pick<OptRestaurantGiftCard, 'cardNumber' | 'expectedAvailableBalance'>
    )> }
  )> }
);

export type Submit_Guest_FeedbackMutationVariables = Exact<{
  input: GuestFeedbackInput;
}>;


export type Submit_Guest_FeedbackMutation = (
  { __typename?: 'Mutation' }
  & { submitGuestFeedback: (
    { __typename?: 'GuestFeedbackResponse' }
    & Pick<GuestFeedbackResponse, 'orderGuid' | 'restaurantGuid' | 'feedbackRating' | 'feedbackTags' | 'feedbackText' | 'feedbackContactPermission' | 'feedbackGuid'>
    & { feedbackMeta?: Maybe<Array<Maybe<(
      { __typename?: 'StringKeyVal' }
      & Pick<StringKeyVal, 'key' | 'value'>
    )>>> }
  ) }
);

export type Sign_Up_To_LoyaltyMutationVariables = Exact<{
  input: SignUpToLoyaltyInput;
}>;


export type Sign_Up_To_LoyaltyMutation = (
  { __typename?: 'Mutation' }
  & { signUpToLoyalty: (
    { __typename?: 'SignUpToLoyaltyResponse' }
    & Pick<SignUpToLoyaltyResponse, 'hasLoyalty' | 'newAccount'>
  ) | (
    { __typename?: 'SignUpToLoyaltyError' }
    & Pick<SignUpToLoyaltyError, 'message'>
  ) }
);

export type SelectionModifierFieldsFragment = (
  { __typename?: 'SelectionModifierOptionV2' }
  & Pick<SelectionModifierOptionV2, 'itemGuid' | 'itemGroupGuid' | 'name' | 'price' | 'quantity' | 'selected' | 'outOfStock' | 'stockQuantity'>
);

export type SelectionModifierGroupFieldsFragment = (
  { __typename?: 'SelectionModifierGroupV2' }
  & Pick<SelectionModifierGroupV2, 'guid' | 'minSelections' | 'maxSelections' | 'name' | 'requiredMode'>
);

export type Order_At_Table_Party_Selection_Item_DetailsQueryVariables = Exact<{
  input: OrderAtTablePartySelectionItemDetailsInput;
  restaurantGuid: Scalars['ID'];
  nestingLevel: Scalars['Int'];
}>;


export type Order_At_Table_Party_Selection_Item_DetailsQuery = (
  { __typename?: 'Query' }
  & { orderAtTablePartySelectionItem: (
    { __typename?: 'SelectionItemV2' }
    & OptSelectionItemFragmentFragment
  ), restaurant: (
    { __typename?: 'Restaurant' }
    & Pick<Restaurant, 'guid'>
    & { specialRequestsConfig: (
      { __typename?: 'SpecialRequestsConfig' }
      & Pick<SpecialRequestsConfig, 'enabled' | 'placeholderMessage'>
    ) }
  ) | (
    { __typename?: 'GeneralError' }
    & Pick<GeneralError, 'code' | 'message'>
  ) }
);

export type OptSelectionItemFragmentFragment = (
  { __typename?: 'SelectionItemV2' }
  & Pick<SelectionItemV2, 'description' | 'name' | 'guid' | 'menuItemPrice' | 'itemGuid' | 'itemGroupGuid' | 'quantity' | 'specialRequest' | 'calories' | 'imageUrl' | 'usesFractionalQuantity' | 'outOfStock' | 'stockQuantity'>
  & { fractionalQuantity: (
    { __typename?: 'FractionalQuantity' }
    & Pick<FractionalQuantity, 'unitOfMeasure' | 'quantity'>
  ), modifierGroups: Array<(
    { __typename?: 'SelectionModifierGroupV2' }
    & { modifiers: Array<(
      { __typename?: 'SelectionModifierOptionV2' }
      & { modifierGroups: Array<(
        { __typename?: 'SelectionModifierGroupV2' }
        & { modifiers: Array<(
          { __typename?: 'SelectionModifierOptionV2' }
          & { modifierGroups: Array<(
            { __typename?: 'SelectionModifierGroupV2' }
            & { modifiers: Array<(
              { __typename?: 'SelectionModifierOptionV2' }
              & { modifierGroups: Array<(
                { __typename?: 'SelectionModifierGroupV2' }
                & { modifiers: Array<(
                  { __typename?: 'SelectionModifierOptionV2' }
                  & { modifierGroups: Array<(
                    { __typename?: 'SelectionModifierGroupV2' }
                    & { modifiers: Array<(
                      { __typename?: 'SelectionModifierOptionV2' }
                      & { modifierGroups: Array<(
                        { __typename?: 'SelectionModifierGroupV2' }
                        & { modifiers: Array<(
                          { __typename?: 'SelectionModifierOptionV2' }
                          & { modifierGroups: Array<(
                            { __typename?: 'SelectionModifierGroupV2' }
                            & { modifiers: Array<(
                              { __typename?: 'SelectionModifierOptionV2' }
                              & { modifierGroups: Array<(
                                { __typename?: 'SelectionModifierGroupV2' }
                                & { modifiers: Array<(
                                  { __typename?: 'SelectionModifierOptionV2' }
                                  & { modifierGroups: Array<(
                                    { __typename?: 'SelectionModifierGroupV2' }
                                    & { modifiers: Array<(
                                      { __typename?: 'SelectionModifierOptionV2' }
                                      & { modifierGroups: Array<(
                                        { __typename?: 'SelectionModifierGroupV2' }
                                        & { modifiers: Array<(
                                          { __typename?: 'SelectionModifierOptionV2' }
                                          & SelectionModifierFieldsFragment
                                        )> }
                                        & SelectionModifierGroupFieldsFragment
                                      )> }
                                      & SelectionModifierFieldsFragment
                                    )> }
                                    & SelectionModifierGroupFieldsFragment
                                  )> }
                                  & SelectionModifierFieldsFragment
                                )> }
                                & SelectionModifierGroupFieldsFragment
                              )> }
                              & SelectionModifierFieldsFragment
                            )> }
                            & SelectionModifierGroupFieldsFragment
                          )> }
                          & SelectionModifierFieldsFragment
                        )> }
                        & SelectionModifierGroupFieldsFragment
                      )> }
                      & SelectionModifierFieldsFragment
                    )> }
                    & SelectionModifierGroupFieldsFragment
                  )> }
                  & SelectionModifierFieldsFragment
                )> }
                & SelectionModifierGroupFieldsFragment
              )> }
              & SelectionModifierFieldsFragment
            )> }
            & SelectionModifierGroupFieldsFragment
          )> }
          & SelectionModifierFieldsFragment
        )> }
        & SelectionModifierGroupFieldsFragment
      )> }
      & SelectionModifierFieldsFragment
    )> }
    & SelectionModifierGroupFieldsFragment
  )> }
);

export type Order_At_Table_MenusQueryVariables = Exact<{
  restaurantGuid: Scalars['ID'];
}>;


export type Order_At_Table_MenusQuery = (
  { __typename?: 'Query' }
  & { orderAtTableMenus: Array<(
    { __typename?: 'MenuV2' }
    & Pick<MenuV2, 'id' | 'name'>
    & { groups: Array<(
      { __typename?: 'MenuGroupV2' }
      & Pick<MenuGroupV2, 'guid'>
    )> }
  )> }
);

export type MenuItemV2FragmentFragment = (
  { __typename?: 'MenuItemV2' }
  & Pick<MenuItemV2, 'description' | 'name' | 'guid' | 'itemGroupGuid' | 'calories' | 'price' | 'imageUrl' | 'usesFractionalQuantity' | 'unitOfMeasure' | 'pricingStrategy' | 'outOfStock' | 'stockQuantity'>
  & { modifierGroups: Array<(
    { __typename?: 'ModifierGroupV2' }
    & { modifiers: Array<(
      { __typename?: 'ModifierOptionV2' }
      & { modifierGroups: Array<(
        { __typename?: 'ModifierGroupV2' }
        & { modifiers: Array<(
          { __typename?: 'ModifierOptionV2' }
          & { modifierGroups: Array<(
            { __typename?: 'ModifierGroupV2' }
            & { modifiers: Array<(
              { __typename?: 'ModifierOptionV2' }
              & { modifierGroups: Array<(
                { __typename?: 'ModifierGroupV2' }
                & { modifiers: Array<(
                  { __typename?: 'ModifierOptionV2' }
                  & { modifierGroups: Array<(
                    { __typename?: 'ModifierGroupV2' }
                    & { modifiers: Array<(
                      { __typename?: 'ModifierOptionV2' }
                      & { modifierGroups: Array<(
                        { __typename?: 'ModifierGroupV2' }
                        & { modifiers: Array<(
                          { __typename?: 'ModifierOptionV2' }
                          & { modifierGroups: Array<(
                            { __typename?: 'ModifierGroupV2' }
                            & { modifiers: Array<(
                              { __typename?: 'ModifierOptionV2' }
                              & { modifierGroups: Array<(
                                { __typename?: 'ModifierGroupV2' }
                                & { modifiers: Array<(
                                  { __typename?: 'ModifierOptionV2' }
                                  & { modifierGroups: Array<(
                                    { __typename?: 'ModifierGroupV2' }
                                    & { modifiers: Array<(
                                      { __typename?: 'ModifierOptionV2' }
                                      & { modifierGroups: Array<(
                                        { __typename?: 'ModifierGroupV2' }
                                        & { modifiers: Array<(
                                          { __typename?: 'ModifierOptionV2' }
                                          & ModifierFieldsFragment
                                        )> }
                                        & ModifierGroupFieldsFragment
                                      )> }
                                      & ModifierFieldsFragment
                                    )> }
                                    & ModifierGroupFieldsFragment
                                  )> }
                                  & ModifierFieldsFragment
                                )> }
                                & ModifierGroupFieldsFragment
                              )> }
                              & ModifierFieldsFragment
                            )> }
                            & ModifierGroupFieldsFragment
                          )> }
                          & ModifierFieldsFragment
                        )> }
                        & ModifierGroupFieldsFragment
                      )> }
                      & ModifierFieldsFragment
                    )> }
                    & ModifierGroupFieldsFragment
                  )> }
                  & ModifierFieldsFragment
                )> }
                & ModifierGroupFieldsFragment
              )> }
              & ModifierFieldsFragment
            )> }
            & ModifierGroupFieldsFragment
          )> }
          & ModifierFieldsFragment
        )> }
        & ModifierGroupFieldsFragment
      )> }
      & ModifierFieldsFragment
    )> }
    & ModifierGroupFieldsFragment
  )> }
);

export type Order_At_Table_Menu_Item_Details_V2QueryVariables = Exact<{
  restaurantGuid: Scalars['ID'];
  itemGuid: Scalars['ID'];
  itemGroupGuid: Scalars['ID'];
  nestingLevel: Scalars['Int'];
}>;


export type Order_At_Table_Menu_Item_Details_V2Query = (
  { __typename?: 'Query' }
  & { orderAtTableMenuItem: (
    { __typename?: 'MenuItemV2' }
    & MenuItemV2FragmentFragment
  ), restaurant: (
    { __typename?: 'Restaurant' }
    & Pick<Restaurant, 'guid'>
    & { specialRequestsConfig: (
      { __typename?: 'SpecialRequestsConfig' }
      & Pick<SpecialRequestsConfig, 'enabled' | 'placeholderMessage'>
    ) }
  ) | (
    { __typename?: 'GeneralError' }
    & Pick<GeneralError, 'code' | 'message'>
  ) }
);

export type ModifierGroupFieldsFragment = (
  { __typename?: 'ModifierGroupV2' }
  & Pick<ModifierGroupV2, 'name' | 'guid' | 'minSelections' | 'maxSelections' | 'requiredMode'>
);

export type ModifierFieldsFragment = (
  { __typename?: 'ModifierOptionV2' }
  & Pick<ModifierOptionV2, 'name' | 'itemGuid' | 'price' | 'stockQuantity' | 'outOfStock'>
  & { selected: ModifierOptionV2['isDefault'] }
);

export type ItemTagFieldsFragment = (
  { __typename?: 'ItemTag' }
  & Pick<ItemTag, 'name' | 'guid'>
);

export type Get_Popular_ItemsQueryVariables = Exact<{
  input: GetPopularItemsInput;
}>;


export type Get_Popular_ItemsQuery = (
  { __typename?: 'Query' }
  & { getPopularItems?: Maybe<(
    { __typename?: 'PopularItemsV3' }
    & { popularItems: Array<(
      { __typename?: 'PopularItemV3' }
      & Pick<PopularItemV3, 'groupGuid' | 'itemGuid'>
      & { doMenuItem: (
        { __typename?: 'DoMenuItem' }
        & Pick<DoMenuItem, 'name' | 'guid' | 'price' | 'outOfStock' | 'description'>
        & { images?: Maybe<(
          { __typename?: 'DoMenuImage' }
          & { item?: Maybe<(
            { __typename?: 'DoMenuImageItem' }
            & Pick<DoMenuImageItem, 'fullPath'>
          )> }
        )>, imageUrls?: Maybe<(
          { __typename?: 'ImageSet' }
          & Pick<ImageSet, 'small'>
        )>, modifierGroups: Array<Maybe<(
          { __typename?: 'DoModifierGroup' }
          & Pick<DoModifierGroup, 'guid' | 'name'>
        )>>, itemTags: Array<(
          { __typename?: 'DoItemTag' }
          & Pick<DoItemTag, 'name' | 'guid'>
        )> }
      ) }
    )> }
  ) | (
    { __typename?: 'GetPopularItemsError' }
    & Pick<GetPopularItemsError, 'message' | 'code'>
  )> }
);

export type Update_Party_MemberMutationVariables = Exact<{
  input: UpdatePartyMemberInput;
}>;


export type Update_Party_MemberMutation = (
  { __typename?: 'Mutation' }
  & { updatePartyMember: { __typename: 'OPTPartyMemberV2' } | (
    { __typename: 'OPTPartyError' }
    & Pick<OptPartyError, 'message' | 'code'>
  ) }
);

type OptPartyStubResponse_OptPartyStub_Fragment = (
  { __typename?: 'OPTPartyStub' }
  & Pick<OptPartyStub, 'guid' | 'createdTime'>
  & { members?: Maybe<Array<Maybe<(
    { __typename?: 'OPTPartyMemberStub' }
    & Pick<OptPartyMemberStub, 'name'>
  )>>> }
);

type OptPartyStubResponse_OptPartyError_Fragment = (
  { __typename?: 'OPTPartyError' }
  & Pick<OptPartyError, 'code' | 'message'>
);

export type OptPartyStubResponseFragment = OptPartyStubResponse_OptPartyStub_Fragment | OptPartyStubResponse_OptPartyError_Fragment;

export type OptPartyRefreshV2Fragment = (
  { __typename?: 'OPTPartyRefreshV2' }
  & Pick<OptPartyRefreshV2, 'rxAvailability'>
  & { party: (
    { __typename?: 'OPTPartyV2' }
    & OptPartyV2Fragment
  ), carts: Array<(
    { __typename?: 'OPTCart' }
    & OptCartFragment
  )>, order?: Maybe<(
    { __typename?: 'OPTOrderGuid' }
    & OptOrderGuidFragment
  )>, splitPaymentData?: Maybe<(
    { __typename?: 'SplitPaymentData' }
    & SplitPaymentDataFragment
  )> }
);

export type SplitPaymentDataFragment = (
  { __typename?: 'SplitPaymentData' }
  & Pick<SplitPaymentData, 'splitMode' | 'evenSplitPortions' | 'evenSplitPaidPortions'>
);

export type Opt_Create_PartyMutationVariables = Exact<{
  input: OptCreatePartyInput;
  totalGiftCardBalance?: Maybe<Scalars['Float']>;
}>;


export type Opt_Create_PartyMutation = (
  { __typename?: 'Mutation' }
  & { optPartyRefresh: (
    { __typename?: 'OPTPartyRefreshV2' }
    & OptPartyRefreshV2Fragment
  ) | (
    { __typename?: 'OPTPartyError' }
    & Pick<OptPartyError, 'message' | 'code'>
  ) }
);

export type Opt_Join_Or_Create_Party_Dynamic_QrMutationVariables = Exact<{
  input: OptDynamicQrJoinOrCreatePartyInput;
  totalGiftCardBalance?: Maybe<Scalars['Float']>;
}>;


export type Opt_Join_Or_Create_Party_Dynamic_QrMutation = (
  { __typename?: 'Mutation' }
  & { optPartyRefresh: (
    { __typename?: 'OPTPartyRefreshV2' }
    & OptPartyRefreshV2Fragment
  ) | (
    { __typename?: 'OPTPartyError' }
    & Pick<OptPartyError, 'message' | 'code'>
  ) }
);

export type Opt_Add_Preauth_And_FireMutationVariables = Exact<{
  addPreauthAndFireInput: AddPreauthToPartyInput;
  totalGiftCardBalance?: Maybe<Scalars['Float']>;
}>;


export type Opt_Add_Preauth_And_FireMutation = (
  { __typename?: 'Mutation' }
  & { optAddPreauthAndFire: (
    { __typename?: 'OPTPartyRefreshV2' }
    & OptPartyRefreshV2Fragment
  ) | (
    { __typename?: 'OPTPartyError' }
    & Pick<OptPartyError, 'message' | 'code'>
  ) }
);

export type Opt_Increment_Auth_And_FireMutationVariables = Exact<{
  incrementAuthAndFire: IncrementAuthAndFireInput;
  totalGiftCardBalance?: Maybe<Scalars['Float']>;
}>;


export type Opt_Increment_Auth_And_FireMutation = (
  { __typename?: 'Mutation' }
  & { optIncrementAuthAndFire: (
    { __typename?: 'OPTPartyRefreshV2' }
    & OptPartyRefreshV2Fragment
  ) | (
    { __typename?: 'OPTPartyError' }
    & Pick<OptPartyError, 'message' | 'code'>
  ) }
);

export type Opt_Join_PartyMutationVariables = Exact<{
  input: OptJoinPartyInput;
  totalGiftCardBalance?: Maybe<Scalars['Float']>;
}>;


export type Opt_Join_PartyMutation = (
  { __typename?: 'Mutation' }
  & { optPartyRefresh: (
    { __typename?: 'OPTPartyRefreshV2' }
    & OptPartyRefreshV2Fragment
  ) | (
    { __typename?: 'OPTPartyError' }
    & Pick<OptPartyError, 'message' | 'code'>
  ) }
);

export type Mnp_Create_PartyMutationVariables = Exact<{
  input: MnpCreatePartyInput;
  totalGiftCardBalance?: Maybe<Scalars['Float']>;
}>;


export type Mnp_Create_PartyMutation = (
  { __typename?: 'Mutation' }
  & { optPartyRefresh: (
    { __typename?: 'OPTPartyRefreshV2' }
    & OptPartyRefreshV2Fragment
  ) | (
    { __typename?: 'OPTPartyError' }
    & Pick<OptPartyError, 'message' | 'code'>
  ) }
);

export type Stp_Create_PartyMutationVariables = Exact<{
  input: StpCreatePartyInput;
  totalGiftCardBalance?: Maybe<Scalars['Float']>;
}>;


export type Stp_Create_PartyMutation = (
  { __typename?: 'Mutation' }
  & { optPartyRefresh: (
    { __typename?: 'OPTPartyRefreshV2' }
    & OptPartyRefreshV2Fragment
  ) | (
    { __typename?: 'OPTPartyError' }
    & Pick<OptPartyError, 'message' | 'code'>
  ) }
);

export type Stp_Join_Or_Create_PartyMutationVariables = Exact<{
  input: StpJoinOrCreatePartyByMainCheckGuidInput;
  totalGiftCardBalance?: Maybe<Scalars['Float']>;
}>;


export type Stp_Join_Or_Create_PartyMutation = (
  { __typename?: 'Mutation' }
  & { optPartyRefresh: (
    { __typename?: 'OPTPartyRefreshV2' }
    & OptPartyRefreshV2Fragment
  ) | (
    { __typename?: 'OPTPartyError' }
    & Pick<OptPartyError, 'message' | 'code'>
  ) }
);

export type Opt_Join_Party_SecureMutationVariables = Exact<{
  input: OptJoinPartySecureInput;
  totalGiftCardBalance?: Maybe<Scalars['Float']>;
}>;


export type Opt_Join_Party_SecureMutation = (
  { __typename?: 'Mutation' }
  & { optPartyRefresh: (
    { __typename?: 'OPTPartyRefreshV2' }
    & OptPartyRefreshV2Fragment
  ) | (
    { __typename?: 'OPTPartyError' }
    & Pick<OptPartyError, 'message' | 'code'>
  ) }
);

export type Mds_Custom_Server_NotificationMutationVariables = Exact<{
  input: MdsCustomServerNotificationInput;
}>;


export type Mds_Custom_Server_NotificationMutation = (
  { __typename?: 'Mutation' }
  & { mdsCustomServerNotification: (
    { __typename?: 'MDSServerNotificationResponse' }
    & Pick<MdsServerNotificationResponse, 'sent'>
  ) | (
    { __typename?: 'OPTPartyError' }
    & Pick<OptPartyError, 'message' | 'code'>
  ) }
);

export type Opt_Add_Item_To_Party_Member_CartMutationVariables = Exact<{
  input: OptAddItemToPartyMemberCartInput;
  totalGiftCardBalance?: Maybe<Scalars['Float']>;
}>;


export type Opt_Add_Item_To_Party_Member_CartMutation = (
  { __typename?: 'Mutation' }
  & { optPartyRefresh: (
    { __typename?: 'OPTPartyRefreshV2' }
    & OptPartyRefreshV2Fragment
  ) | (
    { __typename?: 'OPTPartyError' }
    & Pick<OptPartyError, 'message' | 'code'>
  ) }
);

export type Opt_Reorder_ItemMutationVariables = Exact<{
  input: OptReorderItemInput;
  totalGiftCardBalance?: Maybe<Scalars['Float']>;
}>;


export type Opt_Reorder_ItemMutation = (
  { __typename?: 'Mutation' }
  & { optPartyRefresh: (
    { __typename?: 'OPTPartyRefreshV2' }
    & OptPartyRefreshV2Fragment
  ) | (
    { __typename?: 'OPTPartyError' }
    & Pick<OptPartyError, 'message' | 'code'>
  ) }
);

export type Opt_Edit_Item_In_Party_CartMutationVariables = Exact<{
  input: OptEditOrDeleteItemInPartyMemberCartInput;
  totalGiftCardBalance?: Maybe<Scalars['Float']>;
}>;


export type Opt_Edit_Item_In_Party_CartMutation = (
  { __typename?: 'Mutation' }
  & { optPartyRefresh: (
    { __typename?: 'OPTPartyRefreshV2' }
    & OptPartyRefreshV2Fragment
  ) | (
    { __typename?: 'OPTPartyError' }
    & Pick<OptPartyError, 'message' | 'code'>
  ) }
);

export type Opt_Delete_Item_From_Party_CartMutationVariables = Exact<{
  input: OptEditOrDeleteItemInPartyMemberCartInput;
  totalGiftCardBalance?: Maybe<Scalars['Float']>;
}>;


export type Opt_Delete_Item_From_Party_CartMutation = (
  { __typename?: 'Mutation' }
  & { optPartyRefresh: (
    { __typename?: 'OPTPartyRefreshV2' }
    & OptPartyRefreshV2Fragment
  ) | (
    { __typename?: 'OPTPartyError' }
    & Pick<OptPartyError, 'message' | 'code'>
  ) }
);

export type Opt_Lookup_Party_By_OrderQueryVariables = Exact<{
  restaurantGuid: Scalars['ID'];
  orderGuid: Scalars['String'];
}>;


export type Opt_Lookup_Party_By_OrderQuery = (
  { __typename?: 'Query' }
  & { optLookupPartyByOrder: (
    { __typename?: 'OPTPartyStub' }
    & OptPartyStubResponse_OptPartyStub_Fragment
  ) | (
    { __typename?: 'OPTPartyError' }
    & OptPartyStubResponse_OptPartyError_Fragment
  ) }
);

export type Opt_Lookup_Party_By_TableQueryVariables = Exact<{
  restaurantGuid: Scalars['ID'];
  tableName: Scalars['String'];
}>;


export type Opt_Lookup_Party_By_TableQuery = (
  { __typename?: 'Query' }
  & { optLookupPartyByTable: (
    { __typename?: 'OPTPartyStub' }
    & OptPartyStubResponse_OptPartyStub_Fragment
  ) | (
    { __typename?: 'OPTPartyError' }
    & OptPartyStubResponse_OptPartyError_Fragment
  ) }
);

export type Opt_Lookup_Party_By_Table_And_PinQueryVariables = Exact<{
  restaurantGuid: Scalars['ID'];
  tableName: Scalars['String'];
  pin: Scalars['String'];
}>;


export type Opt_Lookup_Party_By_Table_And_PinQuery = (
  { __typename?: 'Query' }
  & { optLookupPartyByTableAndPin: (
    { __typename?: 'OPTPartyStub' }
    & OptPartyStubResponse_OptPartyStub_Fragment
  ) | (
    { __typename?: 'OPTPartyError' }
    & OptPartyStubResponse_OptPartyError_Fragment
  ) }
);

export type Opt_Unverified_Lookup_Party_By_GuidQueryVariables = Exact<{
  partyGuid: Scalars['ID'];
}>;


export type Opt_Unverified_Lookup_Party_By_GuidQuery = (
  { __typename?: 'Query' }
  & { optUnverifiedLookupPartyByGuid: (
    { __typename?: 'OPTPartyStub' }
    & OptPartyStubResponse_OptPartyStub_Fragment
  ) | (
    { __typename?: 'OPTPartyError' }
    & OptPartyStubResponse_OptPartyError_Fragment
  ) }
);

export type Opt_Get_Party_RefreshQueryVariables = Exact<{
  partyGuid: Scalars['ID'];
  partyMemberGuid: Scalars['ID'];
  memberAuthToken: Scalars['String'];
  totalGiftCardBalance?: Maybe<Scalars['Float']>;
}>;


export type Opt_Get_Party_RefreshQuery = (
  { __typename?: 'Query' }
  & { optPartyRefresh: (
    { __typename?: 'OPTPartyRefreshV2' }
    & OptPartyRefreshV2Fragment
  ) | (
    { __typename?: 'OPTPartyError' }
    & Pick<OptPartyError, 'message' | 'code'>
  ) }
);

export type Opt_Fire_RoundMutationVariables = Exact<{
  input: OptFireTabRoundInput;
  totalGiftCardBalance?: Maybe<Scalars['Float']>;
}>;


export type Opt_Fire_RoundMutation = (
  { __typename?: 'Mutation' }
  & { optPartyRefresh: (
    { __typename?: 'OPTPartyRefreshV2' }
    & OptPartyRefreshV2Fragment
  ) | (
    { __typename?: 'OPTPartyError' }
    & Pick<OptPartyError, 'message' | 'code'>
  ) }
);

export type Opt_ValidateMutationVariables = Exact<{
  input: OptValidateInput;
  totalGiftCardBalance?: Maybe<Scalars['Float']>;
}>;


export type Opt_ValidateMutation = (
  { __typename?: 'Mutation' }
  & { optPartyRefresh: (
    { __typename?: 'OPTPartyRefreshV2' }
    & OptPartyRefreshV2Fragment
  ) | (
    { __typename?: 'OPTPartyError' }
    & Pick<OptPartyError, 'message' | 'code'>
  ) }
);

export type Opt_Tab_Apply_Promo_CodeMutationVariables = Exact<{
  input: OptPartyTabPromoInput;
  totalGiftCardBalance?: Maybe<Scalars['Float']>;
}>;


export type Opt_Tab_Apply_Promo_CodeMutation = (
  { __typename?: 'Mutation' }
  & { optApplyPartyPromo: (
    { __typename?: 'OPTPartyRefreshV2' }
    & OptPartyRefreshV2Fragment
  ) | (
    { __typename?: 'OPTPartyError' }
    & Pick<OptPartyError, 'message' | 'code'>
  ) }
);

export type Opt_Tab_Remove_Promo_CodeMutationVariables = Exact<{
  partyGuid: Scalars['ID'];
  partyMemberGuid: Scalars['ID'];
  memberAuthToken: Scalars['String'];
  totalGiftCardBalance?: Maybe<Scalars['Float']>;
}>;


export type Opt_Tab_Remove_Promo_CodeMutation = (
  { __typename?: 'Mutation' }
  & { optRemovePartyPromo: (
    { __typename?: 'OPTPartyRefreshV2' }
    & OptPartyRefreshV2Fragment
  ) | (
    { __typename?: 'OPTPartyError' }
    & Pick<OptPartyError, 'message' | 'code'>
  ) }
);

type OptCloseoutResponse_OptPartyPaymentResponse_Fragment = (
  { __typename?: 'OPTPartyPaymentResponse' }
  & Pick<OptPartyPaymentResponse, 'hasLoyalty' | 'loyaltySignupPossible'>
  & { partyRefresh: (
    { __typename?: 'OPTPartyRefreshV2' }
    & OptPartyRefreshV2Fragment
  ), warnings: Array<(
    { __typename?: 'PlaceOrderWarning' }
    & Pick<PlaceOrderWarning, 'code' | 'message'>
  )> }
);

type OptCloseoutResponse_OptPartyPaymentErrorV2_Fragment = (
  { __typename?: 'OPTPartyPaymentErrorV2' }
  & Pick<OptPartyPaymentErrorV2, 'message' | 'invalidatesPreauth'>
  & { partyPaymentErrorCode: OptPartyPaymentErrorV2['code'] }
);

type OptCloseoutResponse_OptSplitCheckPaymentError_Fragment = (
  { __typename?: 'OPTSplitCheckPaymentError' }
  & Pick<OptSplitCheckPaymentError, 'message' | 'invalidatesPreauth' | 'splitCheckGuid'>
  & { splitCheckErrorCode: OptSplitCheckPaymentError['code'] }
);

export type OptCloseoutResponseFragment = OptCloseoutResponse_OptPartyPaymentResponse_Fragment | OptCloseoutResponse_OptPartyPaymentErrorV2_Fragment | OptCloseoutResponse_OptSplitCheckPaymentError_Fragment;

export type Party_CloseoutMutationVariables = Exact<{
  closeoutInput: OptCloseoutInput;
  totalGiftCardBalance?: Maybe<Scalars['Float']>;
}>;


export type Party_CloseoutMutation = (
  { __typename?: 'Mutation' }
  & { optPartyCloseout: (
    { __typename?: 'OPTPartyPaymentResponse' }
    & OptCloseoutResponse_OptPartyPaymentResponse_Fragment
  ) | (
    { __typename?: 'OPTPartyPaymentErrorV2' }
    & OptCloseoutResponse_OptPartyPaymentErrorV2_Fragment
  ) | (
    { __typename?: 'OPTSplitCheckPaymentError' }
    & OptCloseoutResponse_OptSplitCheckPaymentError_Fragment
  ) }
);

export type Split_Check_CloseoutMutationVariables = Exact<{
  closeoutInput: OptCloseoutInput;
  totalGiftCardBalance?: Maybe<Scalars['Float']>;
}>;


export type Split_Check_CloseoutMutation = (
  { __typename?: 'Mutation' }
  & { optPartyCloseout: (
    { __typename?: 'OPTPartyPaymentResponse' }
    & OptCloseoutResponse_OptPartyPaymentResponse_Fragment
  ) | (
    { __typename?: 'OPTPartyPaymentErrorV2' }
    & OptCloseoutResponse_OptPartyPaymentErrorV2_Fragment
  ) | (
    { __typename?: 'OPTSplitCheckPaymentError' }
    & OptCloseoutResponse_OptSplitCheckPaymentError_Fragment
  ) }
);

export type Price_Split_CheckQueryVariables = Exact<{
  splitCheckInput: OptSplitCheckInput;
  totalGiftCardBalance?: Maybe<Scalars['Float']>;
}>;


export type Price_Split_CheckQuery = (
  { __typename?: 'Query' }
  & { optPriceSplitCheck: (
    { __typename?: 'OPTSplitCheckPreview' }
    & OptSplitCheckPreviewFragment
  ) | (
    { __typename?: 'OPTPartyError' }
    & Pick<OptPartyError, 'code' | 'message'>
  ) }
);

export type Place_Payg_OrderMutationVariables = Exact<{
  closeoutInput: OptCloseoutInput;
  totalGiftCardBalance?: Maybe<Scalars['Float']>;
}>;


export type Place_Payg_OrderMutation = (
  { __typename?: 'Mutation' }
  & { optPartyCloseout: (
    { __typename?: 'OPTPartyPaymentResponse' }
    & OptCloseoutResponse_OptPartyPaymentResponse_Fragment
  ) | (
    { __typename?: 'OPTPartyPaymentErrorV2' }
    & OptCloseoutResponse_OptPartyPaymentErrorV2_Fragment
  ) | (
    { __typename?: 'OPTSplitCheckPaymentError' }
    & OptCloseoutResponse_OptSplitCheckPaymentError_Fragment
  ) }
);

export type Opt_Apply_Split_TypeMutationVariables = Exact<{
  input: InitializeSplitPaymentDataInput;
  totalGiftCardBalance?: Maybe<Scalars['Float']>;
}>;


export type Opt_Apply_Split_TypeMutation = (
  { __typename?: 'Mutation' }
  & { optPartyRefresh: (
    { __typename?: 'OPTPartyRefreshV2' }
    & OptPartyRefreshV2Fragment
  ) | (
    { __typename?: 'OPTPartyError' }
    & Pick<OptPartyError, 'message' | 'code'>
  ) }
);

export type Opt_Apply_Split_Even_Paid_PortionMutationVariables = Exact<{
  input: SplitPaymentPaidPortionInput;
  totalGiftCardBalance?: Maybe<Scalars['Float']>;
}>;


export type Opt_Apply_Split_Even_Paid_PortionMutation = (
  { __typename?: 'Mutation' }
  & { optPartyRefresh: (
    { __typename?: 'OPTPartyRefreshV2' }
    & OptPartyRefreshV2Fragment
  ) | (
    { __typename?: 'OPTPartyError' }
    & Pick<OptPartyError, 'message' | 'code'>
  ) }
);

export type Opt_Remove_Split_Payment_DataMutationVariables = Exact<{
  partyGuid: Scalars['ID'];
  partyMemberGuid: Scalars['ID'];
  memberAuthToken: Scalars['String'];
  totalGiftCardBalance?: Maybe<Scalars['Float']>;
}>;


export type Opt_Remove_Split_Payment_DataMutation = (
  { __typename?: 'Mutation' }
  & { optPartyRefresh: (
    { __typename?: 'OPTPartyRefreshV2' }
    & OptPartyRefreshV2Fragment
  ) | (
    { __typename?: 'OPTPartyError' }
    & Pick<OptPartyError, 'message' | 'code'>
  ) }
);

export type Lookup_Parties_By_TableQueryVariables = Exact<{
  input: LookupPartiesByTableInput;
}>;


export type Lookup_Parties_By_TableQuery = (
  { __typename?: 'Query' }
  & { lookupPartiesByTable: (
    { __typename?: 'OPTParties' }
    & { parties: Array<(
      { __typename?: 'OPTPartyV3' }
      & Pick<OptPartyV3, 'guid'>
      & { members: Array<(
        { __typename?: 'OPTPartyMemberV2' }
        & Pick<OptPartyMemberV2, 'partyMemberGuid' | 'name' | 'emoji'>
      )> }
    )> }
  ) | (
    { __typename?: 'OPTPartiesLookupError' }
    & Pick<OptPartiesLookupError, 'code' | 'message'>
  ) }
);

export type Lookup_Parties_By_CheckQueryVariables = Exact<{
  input: LookupPartiesByCheckInput;
}>;


export type Lookup_Parties_By_CheckQuery = (
  { __typename?: 'Query' }
  & { lookupPartiesByCheck: (
    { __typename?: 'OPTParties' }
    & { parties: Array<(
      { __typename?: 'OPTPartyV3' }
      & Pick<OptPartyV3, 'guid' | 'tableName'>
      & { members: Array<(
        { __typename?: 'OPTPartyMemberV2' }
        & Pick<OptPartyMemberV2, 'partyMemberGuid' | 'name'>
      )> }
    )> }
  ) | (
    { __typename?: 'OPTPartiesLookupError' }
    & Pick<OptPartiesLookupError, 'code' | 'message'>
  ) }
);

type UpdatePaymentIntentResponse_UpdatePaymentIntentSuccess_Fragment = (
  { __typename?: 'UpdatePaymentIntentSuccess' }
  & Pick<UpdatePaymentIntentSuccess, 'intentAmount' | 'surchargeAmount'>
);

type UpdatePaymentIntentResponse_PaymentIntentError_Fragment = (
  { __typename?: 'PaymentIntentError' }
  & Pick<PaymentIntentError, 'errorDetails'>
);

export type UpdatePaymentIntentResponseFragment = UpdatePaymentIntentResponse_UpdatePaymentIntentSuccess_Fragment | UpdatePaymentIntentResponse_PaymentIntentError_Fragment;

export type Update_Payment_IntentMutationVariables = Exact<{
  input: UpdatePaymentIntentInput;
}>;


export type Update_Payment_IntentMutation = (
  { __typename?: 'Mutation' }
  & { updatePaymentIntent: (
    { __typename?: 'UpdatePaymentIntentSuccess' }
    & UpdatePaymentIntentResponse_UpdatePaymentIntentSuccess_Fragment
  ) | (
    { __typename?: 'PaymentIntentError' }
    & UpdatePaymentIntentResponse_PaymentIntentError_Fragment
  ) }
);

type CreatePaymentIntentResponse_PaymentIntent_Fragment = (
  { __typename?: 'PaymentIntent' }
  & Pick<PaymentIntent, 'sessionSecret'>
);

type CreatePaymentIntentResponse_PaymentIntentError_Fragment = (
  { __typename?: 'PaymentIntentError' }
  & Pick<PaymentIntentError, 'errorDetails'>
);

export type CreatePaymentIntentResponseFragment = CreatePaymentIntentResponse_PaymentIntent_Fragment | CreatePaymentIntentResponse_PaymentIntentError_Fragment;

export type Create_Payment_IntentMutationVariables = Exact<{
  input: CreatePaymentIntentInput;
}>;


export type Create_Payment_IntentMutation = (
  { __typename?: 'Mutation' }
  & { createPaymentIntent: (
    { __typename?: 'PaymentIntent' }
    & CreatePaymentIntentResponse_PaymentIntent_Fragment
  ) | (
    { __typename?: 'PaymentIntentError' }
    & CreatePaymentIntentResponse_PaymentIntentError_Fragment
  ) }
);

export type Update_Payment_Intent_For_Split_CheckMutationVariables = Exact<{
  input: UpdatePaymentIntentForSplitCheckBySelectionsInput;
}>;


export type Update_Payment_Intent_For_Split_CheckMutation = (
  { __typename?: 'Mutation' }
  & { updatePaymentIntentForSplitCheckBySelections: (
    { __typename?: 'UpdatePaymentIntentSuccess' }
    & UpdatePaymentIntentResponse_UpdatePaymentIntentSuccess_Fragment
  ) | (
    { __typename?: 'PaymentIntentError' }
    & UpdatePaymentIntentResponse_PaymentIntentError_Fragment
  ) }
);

export type Order_At_Table_Restaurant_InfoQueryVariables = Exact<{
  restaurantGuid: Scalars['ID'];
}>;


export type Order_At_Table_Restaurant_InfoQuery = (
  { __typename?: 'Query' }
  & { restaurant: (
    { __typename?: 'Restaurant' }
    & Pick<Restaurant, 'guid' | 'name' | 'whiteLabelName' | 'description' | 'imageUrl' | 'testMode' | 'consumerCTAEnabled'>
    & { giftCardConfig: (
      { __typename?: 'GiftCardConfig' }
      & Pick<GiftCardConfig, 'redemptionAllowed' | 'tipAllowed'>
    ), giftCardLinks: (
      { __typename?: 'GiftCardLinks' }
      & Pick<GiftCardLinks, 'purchaseLink' | 'checkValueLink' | 'addValueEnabled'>
    ), orderAndPayConfig?: Maybe<(
      { __typename?: 'OPTConfig' }
      & Pick<OptConfig, 'allowTabs' | 'allowJoinableParty' | 'allowPreauth' | 'allowTextFulfillment' | 'viewOnlyMenu'>
    )>, bannerUrls?: Maybe<(
      { __typename?: 'ImageSet' }
      & Pick<ImageSet, 'raw' | 'large' | 'medium'>
    )>, location: (
      { __typename?: 'Location' }
      & Pick<Location, 'address1' | 'address2' | 'city' | 'state' | 'zip' | 'phone'>
    ), logoUrls?: Maybe<(
      { __typename?: 'ImageSet' }
      & Pick<ImageSet, 'small'>
    )>, i18n?: Maybe<(
      { __typename?: 'I18n' }
      & Pick<I18n, 'currency' | 'locale' | 'country'>
    )>, socialMediaLinks: (
      { __typename?: 'SocialMediaLinks' }
      & Pick<SocialMediaLinks, 'twitterLink' | 'facebookLink'>
    ), creditCardConfig: (
      { __typename?: 'RestaurantCreditCardConfig' }
      & Pick<RestaurantCreditCardConfig, 'amexAccepted' | 'tipEnabled'>
    ), specialRequestsConfig: (
      { __typename?: 'SpecialRequestsConfig' }
      & Pick<SpecialRequestsConfig, 'enabled' | 'placeholderMessage'>
    ), loyaltyConfig: (
      { __typename?: 'LoyaltyConfig' }
      & Pick<LoyaltyConfig, 'loyaltySignupEnabled' | 'loyaltyRedemptionEnabled' | 'signupMethod' | 'programName' | 'rewardType' | 'programDescription'>
    ), itemLevelFeedbackConfig?: Maybe<(
      { __typename?: 'ItemLevelFeedbackConfig' }
      & Pick<ItemLevelFeedbackConfig, 'enabled'>
    )>, toastPayConfig?: Maybe<(
      { __typename?: 'ToastPayConfig' }
      & Pick<ToastPayConfig, 'splitPaymentsEnabled'>
    )> }
  ) | (
    { __typename?: 'GeneralError' }
    & Pick<GeneralError, 'code' | 'message'>
  ) }
);

// Generated on 2024-12-05T22:15:31-05:00

export const CompletedOrderSelectionFragmentDoc = gql`
    fragment CompletedOrderSelection on CompletedOrderSelection {
  guid
  itemGuid
  itemGroupGuid
  externalId
  name
  preDiscountPrice
  price
  quantity
  usesFractionalQuantity
  fractionalQuantity {
    unitOfMeasure
    quantity
  }
  modifiers {
    name
    modifiers {
      name
      price
      voided
      modifiers {
        name
        price
        voided
      }
    }
  }
  voided
  appliedDiscounts {
    discountAmount
    entityType
    externalId
    guid
    name
    processingState
    appliedPromoCode
  }
}
    `;
export const SurchargeSummaryForPaymentTypesFragmentDoc = gql`
    fragment SurchargeSummaryForPaymentTypes on SurchargeSummaryForPaymentTypes {
  paymentTypes
  surchargePreTaxAmount
  surchargeTaxAmount
  surchargeRate
}
    `;
export const DiscountsFragmentDoc = gql`
    fragment Discounts on Discounts {
  loyaltyDiscounts {
    amount
    guid
  }
  restaurantDiscount {
    name
    guid
    amount
    promoCode
  }
}
    `;
export const AppliedServiceChargeFragmentDoc = gql`
    fragment AppliedServiceCharge on AppliedServiceCharge {
  guid
  name
  externalId
  chargeType
  chargeAmount
  taxAmount
  gratuity
  serviceChargeDetails {
    guid
    percent
    gratuity
  }
  serviceChargeCategory
}
    `;
export const CheckSelectionSharedFragmentDoc = gql`
    fragment CheckSelectionShared on CheckSelection {
  itemGuid
  itemGroupGuid
  externalId
  name
  preDiscountPrice
  price
  quantity
  usesFractionalQuantity
  fractionalQuantity {
    unitOfMeasure
    quantity
  }
  modifiers {
    name
    price
    modifiers {
      name
      price
      voided
      modifiers {
        name
        price
        voided
      }
    }
  }
  appliedDiscounts {
    discountAmount
    entityType
    externalId
    guid
    name
    processingState
    appliedPromoCode
  }
}
    `;
export const CheckSelectionGuidFragmentDoc = gql`
    fragment CheckSelectionGuid on CheckSelectionGuid {
  guid
  voided
  ...CheckSelectionShared
}
    ${CheckSelectionSharedFragmentDoc}`;
export const CheckSelectionNoGuidFragmentDoc = gql`
    fragment CheckSelectionNoGuid on CheckSelectionNoGuid {
  ...CheckSelectionShared
}
    ${CheckSelectionSharedFragmentDoc}`;
export const CheckSelectionFragmentDoc = gql`
    fragment CheckSelection on CheckSelection {
  ... on CheckSelectionGuid {
    ...CheckSelectionGuid
  }
  ... on CheckSelectionNoGuid {
    ...CheckSelectionNoGuid
  }
}
    ${CheckSelectionGuidFragmentDoc}
${CheckSelectionNoGuidFragmentDoc}`;
export const PreComputedTipFragmentDoc = gql`
    fragment PreComputedTip on PreComputedTip {
  percentV2
  percentLabel
  value
  isDefault
}
    `;
export const OptCheckV2SharedFragmentDoc = gql`
    fragment OPTCheckV2Shared on OPTCheckV2 {
  customer {
    firstName
    lastName
    email
    phone
  }
  numberOfSelections
  serviceChargeTotal
  preDiscountedSubtotal
  subtotal
  tax
  taxWithoutSurchargeTax
  total
  discounts {
    ... on Discounts {
      ...Discounts
    }
  }
  appliedServiceCharges {
    ... on AppliedServiceCharge {
      ...AppliedServiceCharge
    }
  }
  selections {
    ... on CheckSelection {
      ...CheckSelection
    }
  }
  preComputedTips {
    ...PreComputedTip
  }
  expectedPaymentAmount
}
    ${DiscountsFragmentDoc}
${AppliedServiceChargeFragmentDoc}
${CheckSelectionFragmentDoc}
${PreComputedTipFragmentDoc}`;
export const OptCheckV2GuidFragmentDoc = gql`
    fragment OPTCheckV2Guid on OPTCheckV2Guid {
  guid
  number
  payments {
    guid
    type
    amount
    tipAmount
    paymentStatus
  }
  appliedPreauthInfo {
    guid
    token
    last4CardDigits
    cardType
    cardHolderFirstName
    cardHolderLastName
    cardHolderExpirationMonth
    cardHolderExpirationYear
  }
  tip
  isClosed
  appliedLoyaltyInfo {
    loyaltyIdentifier
  }
  surcharges(totalGiftCardBalance: $totalGiftCardBalance) {
    ...SurchargeSummaryForPaymentTypes
  }
  ...OPTCheckV2Shared
}
    ${SurchargeSummaryForPaymentTypesFragmentDoc}
${OptCheckV2SharedFragmentDoc}`;
export const OptCheckV2NoGuidFragmentDoc = gql`
    fragment OPTCheckV2NoGuid on OPTCheckV2NoGuid {
  ...OPTCheckV2Shared
  appliedLoyaltyInfo {
    loyaltyIdentifier
  }
  surcharges {
    ...SurchargeSummaryForPaymentTypes
  }
}
    ${OptCheckV2SharedFragmentDoc}
${SurchargeSummaryForPaymentTypesFragmentDoc}`;
export const OptSplitCheckPreviewFragmentDoc = gql`
    fragment OPTSplitCheckPreview on OPTSplitCheckPreview {
  surcharges(totalGiftCardBalance: $totalGiftCardBalance) {
    ...SurchargeSummaryForPaymentTypes
  }
  ...OPTCheckV2Shared
}
    ${SurchargeSummaryForPaymentTypesFragmentDoc}
${OptCheckV2SharedFragmentDoc}`;
export const OptCheckV2FragmentDoc = gql`
    fragment OPTCheckV2 on OPTCheckV2 {
  ... on OPTCheckV2Guid {
    ...OPTCheckV2Guid
  }
  ... on OPTCheckV2NoGuid {
    ...OPTCheckV2NoGuid
  }
  ... on OPTSplitCheckPreview {
    ...OPTSplitCheckPreview
  }
}
    ${OptCheckV2GuidFragmentDoc}
${OptCheckV2NoGuidFragmentDoc}
${OptSplitCheckPreviewFragmentDoc}`;
export const OptOrderGuidFragmentDoc = gql`
    fragment OPTOrderGuid on OPTOrderGuid {
  guid
  checkGuid
  isClosed
  checks {
    ...OPTCheckV2Guid
  }
}
    ${OptCheckV2GuidFragmentDoc}`;
export const OptOrderNoGuidFragmentDoc = gql`
    fragment OPTOrderNoGuid on OPTOrderNoGuid {
  checks {
    ...OPTCheckV2NoGuid
  }
}
    ${OptCheckV2NoGuidFragmentDoc}`;
export const OptOrderFragmentDoc = gql`
    fragment OPTOrder on OPTOrder {
  ... on OPTOrderGuid {
    ...OPTOrderGuid
  }
  ... on OPTOrderNoGuid {
    ...OPTOrderNoGuid
  }
}
    ${OptOrderGuidFragmentDoc}
${OptOrderNoGuidFragmentDoc}`;
export const SelectionModifierGroupFieldsFragmentDoc = gql`
    fragment SelectionModifierGroupFields on SelectionModifierGroupV2 {
  guid
  minSelections
  maxSelections
  name
  requiredMode
}
    `;
export const SelectionModifierFieldsFragmentDoc = gql`
    fragment SelectionModifierFields on SelectionModifierOptionV2 {
  itemGuid
  itemGroupGuid
  name
  price
  quantity
  selected
  outOfStock
  stockQuantity
}
    `;
export const OptSelectionItemFragmentFragmentDoc = gql`
    fragment optSelectionItemFragment on SelectionItemV2 {
  description
  name
  guid
  menuItemPrice
  itemGuid
  itemGroupGuid
  quantity
  specialRequest
  calories
  imageUrl
  usesFractionalQuantity
  fractionalQuantity {
    unitOfMeasure
    quantity
  }
  outOfStock
  stockQuantity
  modifierGroups(nestingLevel: $nestingLevel) {
    ...SelectionModifierGroupFields
    modifiers {
      ...SelectionModifierFields
      modifierGroups {
        ...SelectionModifierGroupFields
        modifiers {
          ...SelectionModifierFields
          modifierGroups {
            ...SelectionModifierGroupFields
            modifiers {
              ...SelectionModifierFields
              modifierGroups {
                ...SelectionModifierGroupFields
                modifiers {
                  ...SelectionModifierFields
                  modifierGroups {
                    ...SelectionModifierGroupFields
                    modifiers {
                      ...SelectionModifierFields
                      modifierGroups {
                        ...SelectionModifierGroupFields
                        modifiers {
                          ...SelectionModifierFields
                          modifierGroups {
                            ...SelectionModifierGroupFields
                            modifiers {
                              ...SelectionModifierFields
                              modifierGroups {
                                ...SelectionModifierGroupFields
                                modifiers {
                                  ...SelectionModifierFields
                                  modifierGroups {
                                    ...SelectionModifierGroupFields
                                    modifiers {
                                      ...SelectionModifierFields
                                      modifierGroups {
                                        ...SelectionModifierGroupFields
                                        modifiers {
                                          ...SelectionModifierFields
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    ${SelectionModifierGroupFieldsFragmentDoc}
${SelectionModifierFieldsFragmentDoc}`;
export const ModifierGroupFieldsFragmentDoc = gql`
    fragment ModifierGroupFields on ModifierGroupV2 {
  name
  guid
  minSelections
  maxSelections
  requiredMode
}
    `;
export const ModifierFieldsFragmentDoc = gql`
    fragment ModifierFields on ModifierOptionV2 {
  name
  itemGuid
  selected: isDefault
  price
  stockQuantity
  outOfStock
}
    `;
export const MenuItemV2FragmentFragmentDoc = gql`
    fragment MenuItemV2Fragment on MenuItemV2 {
  description
  name
  guid
  itemGroupGuid
  calories
  price
  imageUrl
  usesFractionalQuantity
  unitOfMeasure
  pricingStrategy
  outOfStock
  stockQuantity
  modifierGroups(nestingLevel: $nestingLevel) {
    ...ModifierGroupFields
    modifiers {
      ...ModifierFields
      modifierGroups {
        ...ModifierGroupFields
        modifiers {
          ...ModifierFields
          modifierGroups {
            ...ModifierGroupFields
            modifiers {
              ...ModifierFields
              modifierGroups {
                ...ModifierGroupFields
                modifiers {
                  ...ModifierFields
                  modifierGroups {
                    ...ModifierGroupFields
                    modifiers {
                      ...ModifierFields
                      modifierGroups {
                        ...ModifierGroupFields
                        modifiers {
                          ...ModifierFields
                          modifierGroups {
                            ...ModifierGroupFields
                            modifiers {
                              ...ModifierFields
                              modifierGroups {
                                ...ModifierGroupFields
                                modifiers {
                                  ...ModifierFields
                                  modifierGroups {
                                    ...ModifierGroupFields
                                    modifiers {
                                      ...ModifierFields
                                      modifierGroups {
                                        ...ModifierGroupFields
                                        modifiers {
                                          ...ModifierFields
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    ${ModifierGroupFieldsFragmentDoc}
${ModifierFieldsFragmentDoc}`;
export const ItemTagFieldsFragmentDoc = gql`
    fragment ItemTagFields on ItemTag {
  name
  guid
}
    `;
export const OptPartyStubResponseFragmentDoc = gql`
    fragment OPTPartyStubResponse on OPTPartyStubResponse {
  ... on OPTPartyStub {
    guid
    members {
      name
    }
    createdTime
  }
  ... on OPTPartyError {
    code
    message
  }
}
    `;
export const OptPartyMemberV2FragmentDoc = gql`
    fragment OPTPartyMemberV2 on OPTPartyMemberV2 {
  partyMemberGuid
  claimedCheckGuids
  name
  cartGuid
  preauthCardGuid
  orderSelectionIds
  availableDiscounts {
    discountAmount
    loyaltyDetails {
      vendor
      referenceId
    }
    discount {
      guid
    }
  }
  appliedDiscounts {
    discountAmount
    loyaltyDetails {
      vendor
      referenceId
    }
    discount {
      guid
    }
  }
  availableRewards {
    itemId
    referenceId
    target
    currentState {
      leftToTarget
      messages {
        nextRewardMessage
        nextRewardMiniMessage
        unlockedMessage
        rewardLabelMessage
        rewardValueMessage
      }
    }
  }
  appliedRewards {
    itemId
    referenceId
    target
    currentState {
      leftToTarget
      messages {
        nextRewardMessage
        nextRewardMiniMessage
        unlockedMessage
        rewardLabelMessage
        rewardValueMessage
      }
    }
  }
  loyaltyState {
    balance
    messages {
      balance
    }
  }
  claimedPortionsPaid
}
    `;
export const OptPartyV2FragmentDoc = gql`
    fragment OPTPartyV2 on OPTPartyV2 {
  guid
  orderGuid
  mainCheckGuid
  pin
  members {
    ... on OPTPartyMemberV2 {
      ...OPTPartyMemberV2
    }
  }
  memberAuthToken
  mode
  partyMemberGuid
  tableName
}
    ${OptPartyMemberV2FragmentDoc}`;
export const OptCartFragmentDoc = gql`
    fragment OPTCart on OPTCart {
  guid
  lastUpdated
  order {
    ... on OPTOrderNoGuid {
      ...OPTOrderNoGuid
    }
  }
  preComputedTips {
    ...PreComputedTip
  }
  cartMessages {
    messageType
    userFacingMessage
  }
}
    ${OptOrderNoGuidFragmentDoc}
${PreComputedTipFragmentDoc}`;
export const SplitPaymentDataFragmentDoc = gql`
    fragment SplitPaymentData on SplitPaymentData {
  splitMode
  evenSplitPortions
  evenSplitPaidPortions
}
    `;
export const OptPartyRefreshV2FragmentDoc = gql`
    fragment OPTPartyRefreshV2 on OPTPartyRefreshV2 {
  party {
    ... on OPTPartyV2 {
      ...OPTPartyV2
    }
  }
  carts {
    ... on OPTCart {
      ...OPTCart
    }
  }
  order {
    ... on OPTOrderGuid {
      ...OPTOrderGuid
    }
  }
  rxAvailability
  splitPaymentData {
    ... on SplitPaymentData {
      ...SplitPaymentData
    }
  }
}
    ${OptPartyV2FragmentDoc}
${OptCartFragmentDoc}
${OptOrderGuidFragmentDoc}
${SplitPaymentDataFragmentDoc}`;
export const OptCloseoutResponseFragmentDoc = gql`
    fragment OPTCloseoutResponse on OPTCloseoutResponse {
  ... on OPTPartyPaymentResponse {
    partyRefresh {
      ... on OPTPartyRefreshV2 {
        ...OPTPartyRefreshV2
      }
    }
    hasLoyalty
    loyaltySignupPossible
    warnings {
      code
      message
    }
  }
  ... on OPTPartyPaymentErrorV2 {
    partyPaymentErrorCode: code
    message
    invalidatesPreauth
  }
  ... on OPTSplitCheckPaymentError {
    message
    splitCheckErrorCode: code
    invalidatesPreauth
    splitCheckGuid
  }
}
    ${OptPartyRefreshV2FragmentDoc}`;
export const UpdatePaymentIntentResponseFragmentDoc = gql`
    fragment UpdatePaymentIntentResponse on UpdatePaymentIntentResponse {
  ... on UpdatePaymentIntentSuccess {
    intentAmount
    surchargeAmount
  }
  ... on PaymentIntentError {
    errorDetails
  }
}
    `;
export const CreatePaymentIntentResponseFragmentDoc = gql`
    fragment CreatePaymentIntentResponse on CreatePaymentIntentResponse {
  ... on PaymentIntent {
    sessionSecret
  }
  ... on PaymentIntentError {
    errorDetails
  }
}
    `;
export const BrandsDocument = gql`
    query Brands($input: NvBrandsInput!) {
  nvBrandingConfig(input: $input) {
    isNvBrandingEnabled
    campaignKeyword
  }
}
    `;

/**
 * __useBrandsQuery__
 *
 * To run a query within a React component, call `useBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBrandsQuery(baseOptions: Apollo.QueryHookOptions<BrandsQuery, BrandsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BrandsQuery, BrandsQueryVariables>(BrandsDocument, options);
      }
export function useBrandsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrandsQuery, BrandsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BrandsQuery, BrandsQueryVariables>(BrandsDocument, options);
        }
export type BrandsQueryHookResult = ReturnType<typeof useBrandsQuery>;
export type BrandsLazyQueryHookResult = ReturnType<typeof useBrandsLazyQuery>;
export type BrandsQueryResult = Apollo.QueryResult<BrandsQuery, BrandsQueryVariables>;
export const Validate_Apple_Pay_MerchantDocument = gql`
    mutation VALIDATE_APPLE_PAY_MERCHANT($input: ValidateApplePayMerchantInput!) {
  validateApplePayMerchant(input: $input) {
    ... on ValidateApplePayMerchantSuccessResponse {
      merchantSession
    }
    ... on ValidateApplePayMerchantError {
      message
      code
    }
  }
}
    `;
export type Validate_Apple_Pay_MerchantMutationFn = Apollo.MutationFunction<Validate_Apple_Pay_MerchantMutation, Validate_Apple_Pay_MerchantMutationVariables>;

/**
 * __useValidate_Apple_Pay_MerchantMutation__
 *
 * To run a mutation, you first call `useValidate_Apple_Pay_MerchantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidate_Apple_Pay_MerchantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateApplePayMerchantMutation, { data, loading, error }] = useValidate_Apple_Pay_MerchantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useValidate_Apple_Pay_MerchantMutation(baseOptions?: Apollo.MutationHookOptions<Validate_Apple_Pay_MerchantMutation, Validate_Apple_Pay_MerchantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Validate_Apple_Pay_MerchantMutation, Validate_Apple_Pay_MerchantMutationVariables>(Validate_Apple_Pay_MerchantDocument, options);
      }
export type Validate_Apple_Pay_MerchantMutationHookResult = ReturnType<typeof useValidate_Apple_Pay_MerchantMutation>;
export type Validate_Apple_Pay_MerchantMutationResult = Apollo.MutationResult<Validate_Apple_Pay_MerchantMutation>;
export type Validate_Apple_Pay_MerchantMutationOptions = Apollo.BaseMutationOptions<Validate_Apple_Pay_MerchantMutation, Validate_Apple_Pay_MerchantMutationVariables>;
export const Login_ServerDocument = gql`
    mutation LOGIN_SERVER($input: LoginInput!) {
  login(input: $input) {
    ... on AuthenticationResponse {
      customerGuid
      accessToken
      refreshToken
    }
    ... on MfaChallengeGeneratedResponse {
      challengeToken
      mfaType
      maskedRecipient
    }
    ... on LoginError {
      code
      message
    }
  }
}
    `;
export type Login_ServerMutationFn = Apollo.MutationFunction<Login_ServerMutation, Login_ServerMutationVariables>;

/**
 * __useLogin_ServerMutation__
 *
 * To run a mutation, you first call `useLogin_ServerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogin_ServerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginServerMutation, { data, loading, error }] = useLogin_ServerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLogin_ServerMutation(baseOptions?: Apollo.MutationHookOptions<Login_ServerMutation, Login_ServerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Login_ServerMutation, Login_ServerMutationVariables>(Login_ServerDocument, options);
      }
export type Login_ServerMutationHookResult = ReturnType<typeof useLogin_ServerMutation>;
export type Login_ServerMutationResult = Apollo.MutationResult<Login_ServerMutation>;
export type Login_ServerMutationOptions = Apollo.BaseMutationOptions<Login_ServerMutation, Login_ServerMutationVariables>;
export const Mfa_Login_ServerDocument = gql`
    mutation MFA_LOGIN_SERVER($input: MfaLoginInput!) {
  mfaLogin(input: $input) {
    ... on AuthenticationResponse {
      customerGuid
      accessToken
      refreshToken
    }
    ... on MfaChallengeGeneratedResponse {
      challengeToken
      mfaType
      maskedRecipient
    }
    ... on LoginError {
      code
      message
    }
  }
}
    `;
export type Mfa_Login_ServerMutationFn = Apollo.MutationFunction<Mfa_Login_ServerMutation, Mfa_Login_ServerMutationVariables>;

/**
 * __useMfa_Login_ServerMutation__
 *
 * To run a mutation, you first call `useMfa_Login_ServerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMfa_Login_ServerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mfaLoginServerMutation, { data, loading, error }] = useMfa_Login_ServerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMfa_Login_ServerMutation(baseOptions?: Apollo.MutationHookOptions<Mfa_Login_ServerMutation, Mfa_Login_ServerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Mfa_Login_ServerMutation, Mfa_Login_ServerMutationVariables>(Mfa_Login_ServerDocument, options);
      }
export type Mfa_Login_ServerMutationHookResult = ReturnType<typeof useMfa_Login_ServerMutation>;
export type Mfa_Login_ServerMutationResult = Apollo.MutationResult<Mfa_Login_ServerMutation>;
export type Mfa_Login_ServerMutationOptions = Apollo.BaseMutationOptions<Mfa_Login_ServerMutation, Mfa_Login_ServerMutationVariables>;
export const Customer_ServerDocument = gql`
    query CUSTOMER_SERVER {
  customer {
    guid
    firstName
    lastName
    phone
    email
  }
}
    `;

/**
 * __useCustomer_ServerQuery__
 *
 * To run a query within a React component, call `useCustomer_ServerQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomer_ServerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomer_ServerQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomer_ServerQuery(baseOptions?: Apollo.QueryHookOptions<Customer_ServerQuery, Customer_ServerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Customer_ServerQuery, Customer_ServerQueryVariables>(Customer_ServerDocument, options);
      }
export function useCustomer_ServerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Customer_ServerQuery, Customer_ServerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Customer_ServerQuery, Customer_ServerQueryVariables>(Customer_ServerDocument, options);
        }
export type Customer_ServerQueryHookResult = ReturnType<typeof useCustomer_ServerQuery>;
export type Customer_ServerLazyQueryHookResult = ReturnType<typeof useCustomer_ServerLazyQuery>;
export type Customer_ServerQueryResult = Apollo.QueryResult<Customer_ServerQuery, Customer_ServerQueryVariables>;
export const RefreshDocument = gql`
    mutation REFRESH($input: RefreshInput!) {
  refresh(input: $input) {
    ... on AuthenticationResponse {
      customerGuid
      accessToken
      refreshToken
    }
    ... on RefreshError {
      code
      message
    }
  }
}
    `;
export type RefreshMutationFn = Apollo.MutationFunction<RefreshMutation, RefreshMutationVariables>;

/**
 * __useRefreshMutation__
 *
 * To run a mutation, you first call `useRefreshMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshMutation, { data, loading, error }] = useRefreshMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRefreshMutation(baseOptions?: Apollo.MutationHookOptions<RefreshMutation, RefreshMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshMutation, RefreshMutationVariables>(RefreshDocument, options);
      }
export type RefreshMutationHookResult = ReturnType<typeof useRefreshMutation>;
export type RefreshMutationResult = Apollo.MutationResult<RefreshMutation>;
export type RefreshMutationOptions = Apollo.BaseMutationOptions<RefreshMutation, RefreshMutationVariables>;
export const Opt_Apply_Payg_Promo_CodeDocument = gql`
    mutation OPT_APPLY_PAYG_PROMO_CODE($input: OPTPartyPaygPromoInput!, $totalGiftCardBalance: Float) {
  optApplyPartyPromo: optPartyPaygApplyPromo(optPartyPaygPromoInput: $input) {
    ... on OPTPartyRefreshV2 {
      ...OPTPartyRefreshV2
    }
    ... on OPTPartyError {
      message
      code
    }
  }
}
    ${OptPartyRefreshV2FragmentDoc}`;
export type Opt_Apply_Payg_Promo_CodeMutationFn = Apollo.MutationFunction<Opt_Apply_Payg_Promo_CodeMutation, Opt_Apply_Payg_Promo_CodeMutationVariables>;

/**
 * __useOpt_Apply_Payg_Promo_CodeMutation__
 *
 * To run a mutation, you first call `useOpt_Apply_Payg_Promo_CodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOpt_Apply_Payg_Promo_CodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [optApplyPaygPromoCodeMutation, { data, loading, error }] = useOpt_Apply_Payg_Promo_CodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *      totalGiftCardBalance: // value for 'totalGiftCardBalance'
 *   },
 * });
 */
export function useOpt_Apply_Payg_Promo_CodeMutation(baseOptions?: Apollo.MutationHookOptions<Opt_Apply_Payg_Promo_CodeMutation, Opt_Apply_Payg_Promo_CodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Opt_Apply_Payg_Promo_CodeMutation, Opt_Apply_Payg_Promo_CodeMutationVariables>(Opt_Apply_Payg_Promo_CodeDocument, options);
      }
export type Opt_Apply_Payg_Promo_CodeMutationHookResult = ReturnType<typeof useOpt_Apply_Payg_Promo_CodeMutation>;
export type Opt_Apply_Payg_Promo_CodeMutationResult = Apollo.MutationResult<Opt_Apply_Payg_Promo_CodeMutation>;
export type Opt_Apply_Payg_Promo_CodeMutationOptions = Apollo.BaseMutationOptions<Opt_Apply_Payg_Promo_CodeMutation, Opt_Apply_Payg_Promo_CodeMutationVariables>;
export const Opt_Remove_Payg_Promo_CodeDocument = gql`
    mutation OPT_REMOVE_PAYG_PROMO_CODE($partyGuid: ID!, $partyMemberGuid: ID!, $memberAuthToken: String!, $totalGiftCardBalance: Float) {
  optRemovePartyPromo: optPartyPaygRemovePromo(
    partyGuid: $partyGuid
    partyMemberGuid: $partyMemberGuid
    memberAuthToken: $memberAuthToken
  ) {
    ... on OPTPartyRefreshV2 {
      ...OPTPartyRefreshV2
    }
    ... on OPTPartyError {
      message
      code
    }
  }
}
    ${OptPartyRefreshV2FragmentDoc}`;
export type Opt_Remove_Payg_Promo_CodeMutationFn = Apollo.MutationFunction<Opt_Remove_Payg_Promo_CodeMutation, Opt_Remove_Payg_Promo_CodeMutationVariables>;

/**
 * __useOpt_Remove_Payg_Promo_CodeMutation__
 *
 * To run a mutation, you first call `useOpt_Remove_Payg_Promo_CodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOpt_Remove_Payg_Promo_CodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [optRemovePaygPromoCodeMutation, { data, loading, error }] = useOpt_Remove_Payg_Promo_CodeMutation({
 *   variables: {
 *      partyGuid: // value for 'partyGuid'
 *      partyMemberGuid: // value for 'partyMemberGuid'
 *      memberAuthToken: // value for 'memberAuthToken'
 *      totalGiftCardBalance: // value for 'totalGiftCardBalance'
 *   },
 * });
 */
export function useOpt_Remove_Payg_Promo_CodeMutation(baseOptions?: Apollo.MutationHookOptions<Opt_Remove_Payg_Promo_CodeMutation, Opt_Remove_Payg_Promo_CodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Opt_Remove_Payg_Promo_CodeMutation, Opt_Remove_Payg_Promo_CodeMutationVariables>(Opt_Remove_Payg_Promo_CodeDocument, options);
      }
export type Opt_Remove_Payg_Promo_CodeMutationHookResult = ReturnType<typeof useOpt_Remove_Payg_Promo_CodeMutation>;
export type Opt_Remove_Payg_Promo_CodeMutationResult = Apollo.MutationResult<Opt_Remove_Payg_Promo_CodeMutation>;
export type Opt_Remove_Payg_Promo_CodeMutationOptions = Apollo.BaseMutationOptions<Opt_Remove_Payg_Promo_CodeMutation, Opt_Remove_Payg_Promo_CodeMutationVariables>;
export const Create_AccountDocument = gql`
    mutation CREATE_ACCOUNT($input: CreateAccountInput!) {
  createAccount(input: $input) {
    ... on CreateAccountResponse {
      customerGuid
    }
    ... on CreateAccountError {
      code
      message
    }
  }
}
    `;
export type Create_AccountMutationFn = Apollo.MutationFunction<Create_AccountMutation, Create_AccountMutationVariables>;

/**
 * __useCreate_AccountMutation__
 *
 * To run a mutation, you first call `useCreate_AccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreate_AccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountMutation, { data, loading, error }] = useCreate_AccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreate_AccountMutation(baseOptions?: Apollo.MutationHookOptions<Create_AccountMutation, Create_AccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Create_AccountMutation, Create_AccountMutationVariables>(Create_AccountDocument, options);
      }
export type Create_AccountMutationHookResult = ReturnType<typeof useCreate_AccountMutation>;
export type Create_AccountMutationResult = Apollo.MutationResult<Create_AccountMutation>;
export type Create_AccountMutationOptions = Apollo.BaseMutationOptions<Create_AccountMutation, Create_AccountMutationVariables>;
export const Customer_Credit_CardsDocument = gql`
    query CUSTOMER_CREDIT_CARDS {
  customer {
    guid
    creditCards {
      guid
      maskedPan
      expirationMonth
      expirationYear
      cardType
      isPrimary
      expired @client
    }
  }
}
    `;

/**
 * __useCustomer_Credit_CardsQuery__
 *
 * To run a query within a React component, call `useCustomer_Credit_CardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomer_Credit_CardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomer_Credit_CardsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomer_Credit_CardsQuery(baseOptions?: Apollo.QueryHookOptions<Customer_Credit_CardsQuery, Customer_Credit_CardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Customer_Credit_CardsQuery, Customer_Credit_CardsQueryVariables>(Customer_Credit_CardsDocument, options);
      }
export function useCustomer_Credit_CardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Customer_Credit_CardsQuery, Customer_Credit_CardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Customer_Credit_CardsQuery, Customer_Credit_CardsQueryVariables>(Customer_Credit_CardsDocument, options);
        }
export type Customer_Credit_CardsQueryHookResult = ReturnType<typeof useCustomer_Credit_CardsQuery>;
export type Customer_Credit_CardsLazyQueryHookResult = ReturnType<typeof useCustomer_Credit_CardsLazyQuery>;
export type Customer_Credit_CardsQueryResult = Apollo.QueryResult<Customer_Credit_CardsQuery, Customer_Credit_CardsQueryVariables>;
export const Update_Basic_InfoDocument = gql`
    mutation UPDATE_BASIC_INFO($input: BasicInfoInput!) {
  updateBasicInfo(input: $input) {
    ... on UpdateBasicInfoResponse {
      customer {
        guid
        firstName
        lastName
        phone
      }
    }
    ... on UpdateBasicInfoError {
      code
      message
    }
  }
}
    `;
export type Update_Basic_InfoMutationFn = Apollo.MutationFunction<Update_Basic_InfoMutation, Update_Basic_InfoMutationVariables>;

/**
 * __useUpdate_Basic_InfoMutation__
 *
 * To run a mutation, you first call `useUpdate_Basic_InfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Basic_InfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBasicInfoMutation, { data, loading, error }] = useUpdate_Basic_InfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdate_Basic_InfoMutation(baseOptions?: Apollo.MutationHookOptions<Update_Basic_InfoMutation, Update_Basic_InfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Update_Basic_InfoMutation, Update_Basic_InfoMutationVariables>(Update_Basic_InfoDocument, options);
      }
export type Update_Basic_InfoMutationHookResult = ReturnType<typeof useUpdate_Basic_InfoMutation>;
export type Update_Basic_InfoMutationResult = Apollo.MutationResult<Update_Basic_InfoMutation>;
export type Update_Basic_InfoMutationOptions = Apollo.BaseMutationOptions<Update_Basic_InfoMutation, Update_Basic_InfoMutationVariables>;
export const Update_PasswordDocument = gql`
    mutation UPDATE_PASSWORD($input: UpdatePasswordInput!) {
  updatePassword(input: $input) {
    ... on UpdatePasswordResponse {
      customer {
        guid
      }
    }
    ... on UpdatePasswordError {
      code
      message
    }
  }
}
    `;
export type Update_PasswordMutationFn = Apollo.MutationFunction<Update_PasswordMutation, Update_PasswordMutationVariables>;

/**
 * __useUpdate_PasswordMutation__
 *
 * To run a mutation, you first call `useUpdate_PasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_PasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePasswordMutation, { data, loading, error }] = useUpdate_PasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdate_PasswordMutation(baseOptions?: Apollo.MutationHookOptions<Update_PasswordMutation, Update_PasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Update_PasswordMutation, Update_PasswordMutationVariables>(Update_PasswordDocument, options);
      }
export type Update_PasswordMutationHookResult = ReturnType<typeof useUpdate_PasswordMutation>;
export type Update_PasswordMutationResult = Apollo.MutationResult<Update_PasswordMutation>;
export type Update_PasswordMutationOptions = Apollo.BaseMutationOptions<Update_PasswordMutation, Update_PasswordMutationVariables>;
export const Get_Gift_Card_BalanceDocument = gql`
    query GET_GIFT_CARD_BALANCE($input: OPTGetGiftCardBalanceInput!) {
  optGetGiftCardBalance(input: $input) {
    ... on OPTGiftCardBalanceResponse {
      globalGiftCard {
        cardIdentifier
        expectedAvailableBalance
      }
      restaurantGiftCard {
        cardNumber
        expectedAvailableBalance
      }
    }
  }
}
    `;

/**
 * __useGet_Gift_Card_BalanceQuery__
 *
 * To run a query within a React component, call `useGet_Gift_Card_BalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Gift_Card_BalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Gift_Card_BalanceQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGet_Gift_Card_BalanceQuery(baseOptions: Apollo.QueryHookOptions<Get_Gift_Card_BalanceQuery, Get_Gift_Card_BalanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_Gift_Card_BalanceQuery, Get_Gift_Card_BalanceQueryVariables>(Get_Gift_Card_BalanceDocument, options);
      }
export function useGet_Gift_Card_BalanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_Gift_Card_BalanceQuery, Get_Gift_Card_BalanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_Gift_Card_BalanceQuery, Get_Gift_Card_BalanceQueryVariables>(Get_Gift_Card_BalanceDocument, options);
        }
export type Get_Gift_Card_BalanceQueryHookResult = ReturnType<typeof useGet_Gift_Card_BalanceQuery>;
export type Get_Gift_Card_BalanceLazyQueryHookResult = ReturnType<typeof useGet_Gift_Card_BalanceLazyQuery>;
export type Get_Gift_Card_BalanceQueryResult = Apollo.QueryResult<Get_Gift_Card_BalanceQuery, Get_Gift_Card_BalanceQueryVariables>;
export const Submit_Guest_FeedbackDocument = gql`
    mutation SUBMIT_GUEST_FEEDBACK($input: GuestFeedbackInput!) {
  submitGuestFeedback(guestFeedbackInput: $input) {
    ... on GuestFeedbackResponse {
      orderGuid
      restaurantGuid
      feedbackRating
      feedbackTags
      feedbackText
      feedbackMeta {
        key
        value
      }
      feedbackContactPermission
      feedbackGuid
    }
  }
}
    `;
export type Submit_Guest_FeedbackMutationFn = Apollo.MutationFunction<Submit_Guest_FeedbackMutation, Submit_Guest_FeedbackMutationVariables>;

/**
 * __useSubmit_Guest_FeedbackMutation__
 *
 * To run a mutation, you first call `useSubmit_Guest_FeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmit_Guest_FeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitGuestFeedbackMutation, { data, loading, error }] = useSubmit_Guest_FeedbackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmit_Guest_FeedbackMutation(baseOptions?: Apollo.MutationHookOptions<Submit_Guest_FeedbackMutation, Submit_Guest_FeedbackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Submit_Guest_FeedbackMutation, Submit_Guest_FeedbackMutationVariables>(Submit_Guest_FeedbackDocument, options);
      }
export type Submit_Guest_FeedbackMutationHookResult = ReturnType<typeof useSubmit_Guest_FeedbackMutation>;
export type Submit_Guest_FeedbackMutationResult = Apollo.MutationResult<Submit_Guest_FeedbackMutation>;
export type Submit_Guest_FeedbackMutationOptions = Apollo.BaseMutationOptions<Submit_Guest_FeedbackMutation, Submit_Guest_FeedbackMutationVariables>;
export const Sign_Up_To_LoyaltyDocument = gql`
    mutation SIGN_UP_TO_LOYALTY($input: SignUpToLoyaltyInput!) {
  signUpToLoyalty(input: $input) {
    ... on SignUpToLoyaltyResponse {
      hasLoyalty
      newAccount
    }
    ... on SignUpToLoyaltyError {
      message
    }
  }
}
    `;
export type Sign_Up_To_LoyaltyMutationFn = Apollo.MutationFunction<Sign_Up_To_LoyaltyMutation, Sign_Up_To_LoyaltyMutationVariables>;

/**
 * __useSign_Up_To_LoyaltyMutation__
 *
 * To run a mutation, you first call `useSign_Up_To_LoyaltyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSign_Up_To_LoyaltyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpToLoyaltyMutation, { data, loading, error }] = useSign_Up_To_LoyaltyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSign_Up_To_LoyaltyMutation(baseOptions?: Apollo.MutationHookOptions<Sign_Up_To_LoyaltyMutation, Sign_Up_To_LoyaltyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Sign_Up_To_LoyaltyMutation, Sign_Up_To_LoyaltyMutationVariables>(Sign_Up_To_LoyaltyDocument, options);
      }
export type Sign_Up_To_LoyaltyMutationHookResult = ReturnType<typeof useSign_Up_To_LoyaltyMutation>;
export type Sign_Up_To_LoyaltyMutationResult = Apollo.MutationResult<Sign_Up_To_LoyaltyMutation>;
export type Sign_Up_To_LoyaltyMutationOptions = Apollo.BaseMutationOptions<Sign_Up_To_LoyaltyMutation, Sign_Up_To_LoyaltyMutationVariables>;
export const Order_At_Table_Party_Selection_Item_DetailsDocument = gql`
    query ORDER_AT_TABLE_PARTY_SELECTION_ITEM_DETAILS($input: OrderAtTablePartySelectionItemDetailsInput!, $restaurantGuid: ID!, $nestingLevel: Int!) {
  orderAtTablePartySelectionItem: orderAtTablePartySelectionItemV2(
    input: $input
    restaurantGuid: $restaurantGuid
    nestingLevel: $nestingLevel
  ) {
    ... on SelectionItemV2 {
      ...optSelectionItemFragment
    }
  }
  restaurant: restaurantV2(guid: $restaurantGuid) {
    ... on Restaurant {
      guid
      specialRequestsConfig {
        enabled
        placeholderMessage
      }
    }
    ... on GeneralError {
      code
      message
    }
  }
}
    ${OptSelectionItemFragmentFragmentDoc}`;

/**
 * __useOrder_At_Table_Party_Selection_Item_DetailsQuery__
 *
 * To run a query within a React component, call `useOrder_At_Table_Party_Selection_Item_DetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrder_At_Table_Party_Selection_Item_DetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrder_At_Table_Party_Selection_Item_DetailsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      restaurantGuid: // value for 'restaurantGuid'
 *      nestingLevel: // value for 'nestingLevel'
 *   },
 * });
 */
export function useOrder_At_Table_Party_Selection_Item_DetailsQuery(baseOptions: Apollo.QueryHookOptions<Order_At_Table_Party_Selection_Item_DetailsQuery, Order_At_Table_Party_Selection_Item_DetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Order_At_Table_Party_Selection_Item_DetailsQuery, Order_At_Table_Party_Selection_Item_DetailsQueryVariables>(Order_At_Table_Party_Selection_Item_DetailsDocument, options);
      }
export function useOrder_At_Table_Party_Selection_Item_DetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Order_At_Table_Party_Selection_Item_DetailsQuery, Order_At_Table_Party_Selection_Item_DetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Order_At_Table_Party_Selection_Item_DetailsQuery, Order_At_Table_Party_Selection_Item_DetailsQueryVariables>(Order_At_Table_Party_Selection_Item_DetailsDocument, options);
        }
export type Order_At_Table_Party_Selection_Item_DetailsQueryHookResult = ReturnType<typeof useOrder_At_Table_Party_Selection_Item_DetailsQuery>;
export type Order_At_Table_Party_Selection_Item_DetailsLazyQueryHookResult = ReturnType<typeof useOrder_At_Table_Party_Selection_Item_DetailsLazyQuery>;
export type Order_At_Table_Party_Selection_Item_DetailsQueryResult = Apollo.QueryResult<Order_At_Table_Party_Selection_Item_DetailsQuery, Order_At_Table_Party_Selection_Item_DetailsQueryVariables>;
export const Order_At_Table_MenusDocument = gql`
    query ORDER_AT_TABLE_MENUS($restaurantGuid: ID!) {
  orderAtTableMenus(restaurantGuid: $restaurantGuid) {
    id
    name
    groups {
      guid
    }
  }
}
    `;

/**
 * __useOrder_At_Table_MenusQuery__
 *
 * To run a query within a React component, call `useOrder_At_Table_MenusQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrder_At_Table_MenusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrder_At_Table_MenusQuery({
 *   variables: {
 *      restaurantGuid: // value for 'restaurantGuid'
 *   },
 * });
 */
export function useOrder_At_Table_MenusQuery(baseOptions: Apollo.QueryHookOptions<Order_At_Table_MenusQuery, Order_At_Table_MenusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Order_At_Table_MenusQuery, Order_At_Table_MenusQueryVariables>(Order_At_Table_MenusDocument, options);
      }
export function useOrder_At_Table_MenusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Order_At_Table_MenusQuery, Order_At_Table_MenusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Order_At_Table_MenusQuery, Order_At_Table_MenusQueryVariables>(Order_At_Table_MenusDocument, options);
        }
export type Order_At_Table_MenusQueryHookResult = ReturnType<typeof useOrder_At_Table_MenusQuery>;
export type Order_At_Table_MenusLazyQueryHookResult = ReturnType<typeof useOrder_At_Table_MenusLazyQuery>;
export type Order_At_Table_MenusQueryResult = Apollo.QueryResult<Order_At_Table_MenusQuery, Order_At_Table_MenusQueryVariables>;
export const Order_At_Table_Menu_Item_Details_V2Document = gql`
    query ORDER_AT_TABLE_MENU_ITEM_DETAILS_V2($restaurantGuid: ID!, $itemGuid: ID!, $itemGroupGuid: ID!, $nestingLevel: Int!) {
  orderAtTableMenuItem: orderAtTableMenuItemV2(
    restaurantGuid: $restaurantGuid
    itemGuid: $itemGuid
    itemGroupGuid: $itemGroupGuid
    nestingLevel: $nestingLevel
  ) {
    ...MenuItemV2Fragment
  }
  restaurant: restaurantV2(guid: $restaurantGuid) {
    ... on Restaurant {
      guid
      specialRequestsConfig {
        enabled
        placeholderMessage
      }
    }
    ... on GeneralError {
      code
      message
    }
  }
}
    ${MenuItemV2FragmentFragmentDoc}`;

/**
 * __useOrder_At_Table_Menu_Item_Details_V2Query__
 *
 * To run a query within a React component, call `useOrder_At_Table_Menu_Item_Details_V2Query` and pass it any options that fit your needs.
 * When your component renders, `useOrder_At_Table_Menu_Item_Details_V2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrder_At_Table_Menu_Item_Details_V2Query({
 *   variables: {
 *      restaurantGuid: // value for 'restaurantGuid'
 *      itemGuid: // value for 'itemGuid'
 *      itemGroupGuid: // value for 'itemGroupGuid'
 *      nestingLevel: // value for 'nestingLevel'
 *   },
 * });
 */
export function useOrder_At_Table_Menu_Item_Details_V2Query(baseOptions: Apollo.QueryHookOptions<Order_At_Table_Menu_Item_Details_V2Query, Order_At_Table_Menu_Item_Details_V2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Order_At_Table_Menu_Item_Details_V2Query, Order_At_Table_Menu_Item_Details_V2QueryVariables>(Order_At_Table_Menu_Item_Details_V2Document, options);
      }
export function useOrder_At_Table_Menu_Item_Details_V2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Order_At_Table_Menu_Item_Details_V2Query, Order_At_Table_Menu_Item_Details_V2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Order_At_Table_Menu_Item_Details_V2Query, Order_At_Table_Menu_Item_Details_V2QueryVariables>(Order_At_Table_Menu_Item_Details_V2Document, options);
        }
export type Order_At_Table_Menu_Item_Details_V2QueryHookResult = ReturnType<typeof useOrder_At_Table_Menu_Item_Details_V2Query>;
export type Order_At_Table_Menu_Item_Details_V2LazyQueryHookResult = ReturnType<typeof useOrder_At_Table_Menu_Item_Details_V2LazyQuery>;
export type Order_At_Table_Menu_Item_Details_V2QueryResult = Apollo.QueryResult<Order_At_Table_Menu_Item_Details_V2Query, Order_At_Table_Menu_Item_Details_V2QueryVariables>;
export const Get_Popular_ItemsDocument = gql`
    query GET_POPULAR_ITEMS($input: GetPopularItemsInput!) {
  getPopularItems: getPopularItems(input: $input) {
    ... on PopularItemsV3 {
      popularItems {
        groupGuid
        itemGuid
        doMenuItem {
          name
          guid
          price
          outOfStock
          description
          images {
            item {
              fullPath
            }
          }
          imageUrls {
            small
          }
          modifierGroups {
            guid
            name
          }
          itemTags {
            name
            guid
          }
        }
      }
    }
    ... on GetPopularItemsError {
      message
      code
    }
  }
}
    `;

/**
 * __useGet_Popular_ItemsQuery__
 *
 * To run a query within a React component, call `useGet_Popular_ItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Popular_ItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Popular_ItemsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGet_Popular_ItemsQuery(baseOptions: Apollo.QueryHookOptions<Get_Popular_ItemsQuery, Get_Popular_ItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_Popular_ItemsQuery, Get_Popular_ItemsQueryVariables>(Get_Popular_ItemsDocument, options);
      }
export function useGet_Popular_ItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_Popular_ItemsQuery, Get_Popular_ItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_Popular_ItemsQuery, Get_Popular_ItemsQueryVariables>(Get_Popular_ItemsDocument, options);
        }
export type Get_Popular_ItemsQueryHookResult = ReturnType<typeof useGet_Popular_ItemsQuery>;
export type Get_Popular_ItemsLazyQueryHookResult = ReturnType<typeof useGet_Popular_ItemsLazyQuery>;
export type Get_Popular_ItemsQueryResult = Apollo.QueryResult<Get_Popular_ItemsQuery, Get_Popular_ItemsQueryVariables>;
export const Update_Party_MemberDocument = gql`
    mutation UPDATE_PARTY_MEMBER($input: UpdatePartyMemberInput!) {
  updatePartyMember: updatePartyMember(updatePartyMemberInput: $input) {
    ... on OPTPartyMemberV2 {
      __typename
    }
    ... on OPTPartyError {
      message
      code
      __typename
    }
    __typename
  }
}
    `;
export type Update_Party_MemberMutationFn = Apollo.MutationFunction<Update_Party_MemberMutation, Update_Party_MemberMutationVariables>;

/**
 * __useUpdate_Party_MemberMutation__
 *
 * To run a mutation, you first call `useUpdate_Party_MemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Party_MemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePartyMemberMutation, { data, loading, error }] = useUpdate_Party_MemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdate_Party_MemberMutation(baseOptions?: Apollo.MutationHookOptions<Update_Party_MemberMutation, Update_Party_MemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Update_Party_MemberMutation, Update_Party_MemberMutationVariables>(Update_Party_MemberDocument, options);
      }
export type Update_Party_MemberMutationHookResult = ReturnType<typeof useUpdate_Party_MemberMutation>;
export type Update_Party_MemberMutationResult = Apollo.MutationResult<Update_Party_MemberMutation>;
export type Update_Party_MemberMutationOptions = Apollo.BaseMutationOptions<Update_Party_MemberMutation, Update_Party_MemberMutationVariables>;
export const Opt_Create_PartyDocument = gql`
    mutation OPT_CREATE_PARTY($input: OPTCreatePartyInput!, $totalGiftCardBalance: Float) {
  optPartyRefresh: optCreatePartyRefreshV2(optCreatePartyInput: $input) {
    ... on OPTPartyRefreshV2 {
      ...OPTPartyRefreshV2
    }
    ... on OPTPartyError {
      message
      code
    }
  }
}
    ${OptPartyRefreshV2FragmentDoc}`;
export type Opt_Create_PartyMutationFn = Apollo.MutationFunction<Opt_Create_PartyMutation, Opt_Create_PartyMutationVariables>;

/**
 * __useOpt_Create_PartyMutation__
 *
 * To run a mutation, you first call `useOpt_Create_PartyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOpt_Create_PartyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [optCreatePartyMutation, { data, loading, error }] = useOpt_Create_PartyMutation({
 *   variables: {
 *      input: // value for 'input'
 *      totalGiftCardBalance: // value for 'totalGiftCardBalance'
 *   },
 * });
 */
export function useOpt_Create_PartyMutation(baseOptions?: Apollo.MutationHookOptions<Opt_Create_PartyMutation, Opt_Create_PartyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Opt_Create_PartyMutation, Opt_Create_PartyMutationVariables>(Opt_Create_PartyDocument, options);
      }
export type Opt_Create_PartyMutationHookResult = ReturnType<typeof useOpt_Create_PartyMutation>;
export type Opt_Create_PartyMutationResult = Apollo.MutationResult<Opt_Create_PartyMutation>;
export type Opt_Create_PartyMutationOptions = Apollo.BaseMutationOptions<Opt_Create_PartyMutation, Opt_Create_PartyMutationVariables>;
export const Opt_Join_Or_Create_Party_Dynamic_QrDocument = gql`
    mutation OPT_JOIN_OR_CREATE_PARTY_DYNAMIC_QR($input: OptDynamicQrJoinOrCreatePartyInput!, $totalGiftCardBalance: Float) {
  optPartyRefresh: optJoinOrCreatePartyDynamicQrRefresh(
    optDynamicQrJoinOrCreatePartyInput: $input
  ) {
    ... on OPTPartyRefreshV2 {
      ...OPTPartyRefreshV2
    }
    ... on OPTPartyError {
      message
      code
    }
  }
}
    ${OptPartyRefreshV2FragmentDoc}`;
export type Opt_Join_Or_Create_Party_Dynamic_QrMutationFn = Apollo.MutationFunction<Opt_Join_Or_Create_Party_Dynamic_QrMutation, Opt_Join_Or_Create_Party_Dynamic_QrMutationVariables>;

/**
 * __useOpt_Join_Or_Create_Party_Dynamic_QrMutation__
 *
 * To run a mutation, you first call `useOpt_Join_Or_Create_Party_Dynamic_QrMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOpt_Join_Or_Create_Party_Dynamic_QrMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [optJoinOrCreatePartyDynamicQrMutation, { data, loading, error }] = useOpt_Join_Or_Create_Party_Dynamic_QrMutation({
 *   variables: {
 *      input: // value for 'input'
 *      totalGiftCardBalance: // value for 'totalGiftCardBalance'
 *   },
 * });
 */
export function useOpt_Join_Or_Create_Party_Dynamic_QrMutation(baseOptions?: Apollo.MutationHookOptions<Opt_Join_Or_Create_Party_Dynamic_QrMutation, Opt_Join_Or_Create_Party_Dynamic_QrMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Opt_Join_Or_Create_Party_Dynamic_QrMutation, Opt_Join_Or_Create_Party_Dynamic_QrMutationVariables>(Opt_Join_Or_Create_Party_Dynamic_QrDocument, options);
      }
export type Opt_Join_Or_Create_Party_Dynamic_QrMutationHookResult = ReturnType<typeof useOpt_Join_Or_Create_Party_Dynamic_QrMutation>;
export type Opt_Join_Or_Create_Party_Dynamic_QrMutationResult = Apollo.MutationResult<Opt_Join_Or_Create_Party_Dynamic_QrMutation>;
export type Opt_Join_Or_Create_Party_Dynamic_QrMutationOptions = Apollo.BaseMutationOptions<Opt_Join_Or_Create_Party_Dynamic_QrMutation, Opt_Join_Or_Create_Party_Dynamic_QrMutationVariables>;
export const Opt_Add_Preauth_And_FireDocument = gql`
    mutation OPT_ADD_PREAUTH_AND_FIRE($addPreauthAndFireInput: AddPreauthToPartyInput!, $totalGiftCardBalance: Float) {
  optAddPreauthAndFire: optAddPreauthAndFireV2(
    addPreauthAndFireInput: $addPreauthAndFireInput
  ) {
    ... on OPTPartyRefreshV2 {
      ...OPTPartyRefreshV2
    }
    ... on OPTPartyError {
      message
      code
    }
  }
}
    ${OptPartyRefreshV2FragmentDoc}`;
export type Opt_Add_Preauth_And_FireMutationFn = Apollo.MutationFunction<Opt_Add_Preauth_And_FireMutation, Opt_Add_Preauth_And_FireMutationVariables>;

/**
 * __useOpt_Add_Preauth_And_FireMutation__
 *
 * To run a mutation, you first call `useOpt_Add_Preauth_And_FireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOpt_Add_Preauth_And_FireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [optAddPreauthAndFireMutation, { data, loading, error }] = useOpt_Add_Preauth_And_FireMutation({
 *   variables: {
 *      addPreauthAndFireInput: // value for 'addPreauthAndFireInput'
 *      totalGiftCardBalance: // value for 'totalGiftCardBalance'
 *   },
 * });
 */
export function useOpt_Add_Preauth_And_FireMutation(baseOptions?: Apollo.MutationHookOptions<Opt_Add_Preauth_And_FireMutation, Opt_Add_Preauth_And_FireMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Opt_Add_Preauth_And_FireMutation, Opt_Add_Preauth_And_FireMutationVariables>(Opt_Add_Preauth_And_FireDocument, options);
      }
export type Opt_Add_Preauth_And_FireMutationHookResult = ReturnType<typeof useOpt_Add_Preauth_And_FireMutation>;
export type Opt_Add_Preauth_And_FireMutationResult = Apollo.MutationResult<Opt_Add_Preauth_And_FireMutation>;
export type Opt_Add_Preauth_And_FireMutationOptions = Apollo.BaseMutationOptions<Opt_Add_Preauth_And_FireMutation, Opt_Add_Preauth_And_FireMutationVariables>;
export const Opt_Increment_Auth_And_FireDocument = gql`
    mutation OPT_INCREMENT_AUTH_AND_FIRE($incrementAuthAndFire: IncrementAuthAndFireInput!, $totalGiftCardBalance: Float) {
  optIncrementAuthAndFire: optIncrementAuthAndFire(
    incrementAuthAndFire: $incrementAuthAndFire
  ) {
    ... on OPTPartyRefreshV2 {
      ...OPTPartyRefreshV2
    }
    ... on OPTPartyError {
      message
      code
    }
  }
}
    ${OptPartyRefreshV2FragmentDoc}`;
export type Opt_Increment_Auth_And_FireMutationFn = Apollo.MutationFunction<Opt_Increment_Auth_And_FireMutation, Opt_Increment_Auth_And_FireMutationVariables>;

/**
 * __useOpt_Increment_Auth_And_FireMutation__
 *
 * To run a mutation, you first call `useOpt_Increment_Auth_And_FireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOpt_Increment_Auth_And_FireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [optIncrementAuthAndFireMutation, { data, loading, error }] = useOpt_Increment_Auth_And_FireMutation({
 *   variables: {
 *      incrementAuthAndFire: // value for 'incrementAuthAndFire'
 *      totalGiftCardBalance: // value for 'totalGiftCardBalance'
 *   },
 * });
 */
export function useOpt_Increment_Auth_And_FireMutation(baseOptions?: Apollo.MutationHookOptions<Opt_Increment_Auth_And_FireMutation, Opt_Increment_Auth_And_FireMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Opt_Increment_Auth_And_FireMutation, Opt_Increment_Auth_And_FireMutationVariables>(Opt_Increment_Auth_And_FireDocument, options);
      }
export type Opt_Increment_Auth_And_FireMutationHookResult = ReturnType<typeof useOpt_Increment_Auth_And_FireMutation>;
export type Opt_Increment_Auth_And_FireMutationResult = Apollo.MutationResult<Opt_Increment_Auth_And_FireMutation>;
export type Opt_Increment_Auth_And_FireMutationOptions = Apollo.BaseMutationOptions<Opt_Increment_Auth_And_FireMutation, Opt_Increment_Auth_And_FireMutationVariables>;
export const Opt_Join_PartyDocument = gql`
    mutation OPT_JOIN_PARTY($input: OPTJoinPartyInput!, $totalGiftCardBalance: Float) {
  optPartyRefresh: optJoinPartyV2(optJoinPartyInput: $input) {
    ... on OPTPartyRefreshV2 {
      ...OPTPartyRefreshV2
    }
    ... on OPTPartyError {
      message
      code
    }
  }
}
    ${OptPartyRefreshV2FragmentDoc}`;
export type Opt_Join_PartyMutationFn = Apollo.MutationFunction<Opt_Join_PartyMutation, Opt_Join_PartyMutationVariables>;

/**
 * __useOpt_Join_PartyMutation__
 *
 * To run a mutation, you first call `useOpt_Join_PartyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOpt_Join_PartyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [optJoinPartyMutation, { data, loading, error }] = useOpt_Join_PartyMutation({
 *   variables: {
 *      input: // value for 'input'
 *      totalGiftCardBalance: // value for 'totalGiftCardBalance'
 *   },
 * });
 */
export function useOpt_Join_PartyMutation(baseOptions?: Apollo.MutationHookOptions<Opt_Join_PartyMutation, Opt_Join_PartyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Opt_Join_PartyMutation, Opt_Join_PartyMutationVariables>(Opt_Join_PartyDocument, options);
      }
export type Opt_Join_PartyMutationHookResult = ReturnType<typeof useOpt_Join_PartyMutation>;
export type Opt_Join_PartyMutationResult = Apollo.MutationResult<Opt_Join_PartyMutation>;
export type Opt_Join_PartyMutationOptions = Apollo.BaseMutationOptions<Opt_Join_PartyMutation, Opt_Join_PartyMutationVariables>;
export const Mnp_Create_PartyDocument = gql`
    mutation MNP_CREATE_PARTY($input: MNPCreatePartyInput!, $totalGiftCardBalance: Float) {
  optPartyRefresh: mnpCreatePartyRefreshV2(mnpCreatePartyInput: $input) {
    ... on OPTPartyRefreshV2 {
      ...OPTPartyRefreshV2
    }
    ... on OPTPartyError {
      message
      code
    }
  }
}
    ${OptPartyRefreshV2FragmentDoc}`;
export type Mnp_Create_PartyMutationFn = Apollo.MutationFunction<Mnp_Create_PartyMutation, Mnp_Create_PartyMutationVariables>;

/**
 * __useMnp_Create_PartyMutation__
 *
 * To run a mutation, you first call `useMnp_Create_PartyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMnp_Create_PartyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mnpCreatePartyMutation, { data, loading, error }] = useMnp_Create_PartyMutation({
 *   variables: {
 *      input: // value for 'input'
 *      totalGiftCardBalance: // value for 'totalGiftCardBalance'
 *   },
 * });
 */
export function useMnp_Create_PartyMutation(baseOptions?: Apollo.MutationHookOptions<Mnp_Create_PartyMutation, Mnp_Create_PartyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Mnp_Create_PartyMutation, Mnp_Create_PartyMutationVariables>(Mnp_Create_PartyDocument, options);
      }
export type Mnp_Create_PartyMutationHookResult = ReturnType<typeof useMnp_Create_PartyMutation>;
export type Mnp_Create_PartyMutationResult = Apollo.MutationResult<Mnp_Create_PartyMutation>;
export type Mnp_Create_PartyMutationOptions = Apollo.BaseMutationOptions<Mnp_Create_PartyMutation, Mnp_Create_PartyMutationVariables>;
export const Stp_Create_PartyDocument = gql`
    mutation STP_CREATE_PARTY($input: STPCreatePartyInput!, $totalGiftCardBalance: Float) {
  optPartyRefresh: stpCreatePartyRefreshV2(stpCreatePartyInput: $input) {
    ... on OPTPartyRefreshV2 {
      ...OPTPartyRefreshV2
    }
    ... on OPTPartyError {
      message
      code
    }
  }
}
    ${OptPartyRefreshV2FragmentDoc}`;
export type Stp_Create_PartyMutationFn = Apollo.MutationFunction<Stp_Create_PartyMutation, Stp_Create_PartyMutationVariables>;

/**
 * __useStp_Create_PartyMutation__
 *
 * To run a mutation, you first call `useStp_Create_PartyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStp_Create_PartyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stpCreatePartyMutation, { data, loading, error }] = useStp_Create_PartyMutation({
 *   variables: {
 *      input: // value for 'input'
 *      totalGiftCardBalance: // value for 'totalGiftCardBalance'
 *   },
 * });
 */
export function useStp_Create_PartyMutation(baseOptions?: Apollo.MutationHookOptions<Stp_Create_PartyMutation, Stp_Create_PartyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Stp_Create_PartyMutation, Stp_Create_PartyMutationVariables>(Stp_Create_PartyDocument, options);
      }
export type Stp_Create_PartyMutationHookResult = ReturnType<typeof useStp_Create_PartyMutation>;
export type Stp_Create_PartyMutationResult = Apollo.MutationResult<Stp_Create_PartyMutation>;
export type Stp_Create_PartyMutationOptions = Apollo.BaseMutationOptions<Stp_Create_PartyMutation, Stp_Create_PartyMutationVariables>;
export const Stp_Join_Or_Create_PartyDocument = gql`
    mutation STP_JOIN_OR_CREATE_PARTY($input: STPJoinOrCreatePartyByMainCheckGuidInput!, $totalGiftCardBalance: Float) {
  optPartyRefresh: stpJoinOrCreatePartyByMainCheckGuid(
    stpJoinOrCreatePartyByMainCheckGuidInput: $input
  ) {
    ... on OPTPartyRefreshV2 {
      ...OPTPartyRefreshV2
    }
    ... on OPTPartyError {
      message
      code
    }
  }
}
    ${OptPartyRefreshV2FragmentDoc}`;
export type Stp_Join_Or_Create_PartyMutationFn = Apollo.MutationFunction<Stp_Join_Or_Create_PartyMutation, Stp_Join_Or_Create_PartyMutationVariables>;

/**
 * __useStp_Join_Or_Create_PartyMutation__
 *
 * To run a mutation, you first call `useStp_Join_Or_Create_PartyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStp_Join_Or_Create_PartyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stpJoinOrCreatePartyMutation, { data, loading, error }] = useStp_Join_Or_Create_PartyMutation({
 *   variables: {
 *      input: // value for 'input'
 *      totalGiftCardBalance: // value for 'totalGiftCardBalance'
 *   },
 * });
 */
export function useStp_Join_Or_Create_PartyMutation(baseOptions?: Apollo.MutationHookOptions<Stp_Join_Or_Create_PartyMutation, Stp_Join_Or_Create_PartyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Stp_Join_Or_Create_PartyMutation, Stp_Join_Or_Create_PartyMutationVariables>(Stp_Join_Or_Create_PartyDocument, options);
      }
export type Stp_Join_Or_Create_PartyMutationHookResult = ReturnType<typeof useStp_Join_Or_Create_PartyMutation>;
export type Stp_Join_Or_Create_PartyMutationResult = Apollo.MutationResult<Stp_Join_Or_Create_PartyMutation>;
export type Stp_Join_Or_Create_PartyMutationOptions = Apollo.BaseMutationOptions<Stp_Join_Or_Create_PartyMutation, Stp_Join_Or_Create_PartyMutationVariables>;
export const Opt_Join_Party_SecureDocument = gql`
    mutation OPT_JOIN_PARTY_SECURE($input: OPTJoinPartySecureInput!, $totalGiftCardBalance: Float) {
  optPartyRefresh: optJoinPartySecureV2(optJoinPartySecureInput: $input) {
    ... on OPTPartyRefreshV2 {
      ...OPTPartyRefreshV2
    }
    ... on OPTPartyError {
      message
      code
    }
  }
}
    ${OptPartyRefreshV2FragmentDoc}`;
export type Opt_Join_Party_SecureMutationFn = Apollo.MutationFunction<Opt_Join_Party_SecureMutation, Opt_Join_Party_SecureMutationVariables>;

/**
 * __useOpt_Join_Party_SecureMutation__
 *
 * To run a mutation, you first call `useOpt_Join_Party_SecureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOpt_Join_Party_SecureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [optJoinPartySecureMutation, { data, loading, error }] = useOpt_Join_Party_SecureMutation({
 *   variables: {
 *      input: // value for 'input'
 *      totalGiftCardBalance: // value for 'totalGiftCardBalance'
 *   },
 * });
 */
export function useOpt_Join_Party_SecureMutation(baseOptions?: Apollo.MutationHookOptions<Opt_Join_Party_SecureMutation, Opt_Join_Party_SecureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Opt_Join_Party_SecureMutation, Opt_Join_Party_SecureMutationVariables>(Opt_Join_Party_SecureDocument, options);
      }
export type Opt_Join_Party_SecureMutationHookResult = ReturnType<typeof useOpt_Join_Party_SecureMutation>;
export type Opt_Join_Party_SecureMutationResult = Apollo.MutationResult<Opt_Join_Party_SecureMutation>;
export type Opt_Join_Party_SecureMutationOptions = Apollo.BaseMutationOptions<Opt_Join_Party_SecureMutation, Opt_Join_Party_SecureMutationVariables>;
export const Mds_Custom_Server_NotificationDocument = gql`
    mutation MDS_CUSTOM_SERVER_NOTIFICATION($input: MDSCustomServerNotificationInput!) {
  mdsCustomServerNotification: mdsCustomServerNotification(
    mdsCustomServerNotificationInput: $input
  ) {
    ... on MDSServerNotificationResponse {
      sent
    }
    ... on OPTPartyError {
      message
      code
    }
  }
}
    `;
export type Mds_Custom_Server_NotificationMutationFn = Apollo.MutationFunction<Mds_Custom_Server_NotificationMutation, Mds_Custom_Server_NotificationMutationVariables>;

/**
 * __useMds_Custom_Server_NotificationMutation__
 *
 * To run a mutation, you first call `useMds_Custom_Server_NotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMds_Custom_Server_NotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mdsCustomServerNotificationMutation, { data, loading, error }] = useMds_Custom_Server_NotificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMds_Custom_Server_NotificationMutation(baseOptions?: Apollo.MutationHookOptions<Mds_Custom_Server_NotificationMutation, Mds_Custom_Server_NotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Mds_Custom_Server_NotificationMutation, Mds_Custom_Server_NotificationMutationVariables>(Mds_Custom_Server_NotificationDocument, options);
      }
export type Mds_Custom_Server_NotificationMutationHookResult = ReturnType<typeof useMds_Custom_Server_NotificationMutation>;
export type Mds_Custom_Server_NotificationMutationResult = Apollo.MutationResult<Mds_Custom_Server_NotificationMutation>;
export type Mds_Custom_Server_NotificationMutationOptions = Apollo.BaseMutationOptions<Mds_Custom_Server_NotificationMutation, Mds_Custom_Server_NotificationMutationVariables>;
export const Opt_Add_Item_To_Party_Member_CartDocument = gql`
    mutation OPT_ADD_ITEM_TO_PARTY_MEMBER_CART($input: OPTAddItemToPartyMemberCartInput!, $totalGiftCardBalance: Float) {
  optPartyRefresh: optAddItemToPartyMemberCartV2(
    optAddItemToPartyMemberCartInput: $input
  ) {
    ... on OPTPartyRefreshV2 {
      ...OPTPartyRefreshV2
    }
    ... on OPTPartyError {
      message
      code
    }
  }
}
    ${OptPartyRefreshV2FragmentDoc}`;
export type Opt_Add_Item_To_Party_Member_CartMutationFn = Apollo.MutationFunction<Opt_Add_Item_To_Party_Member_CartMutation, Opt_Add_Item_To_Party_Member_CartMutationVariables>;

/**
 * __useOpt_Add_Item_To_Party_Member_CartMutation__
 *
 * To run a mutation, you first call `useOpt_Add_Item_To_Party_Member_CartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOpt_Add_Item_To_Party_Member_CartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [optAddItemToPartyMemberCartMutation, { data, loading, error }] = useOpt_Add_Item_To_Party_Member_CartMutation({
 *   variables: {
 *      input: // value for 'input'
 *      totalGiftCardBalance: // value for 'totalGiftCardBalance'
 *   },
 * });
 */
export function useOpt_Add_Item_To_Party_Member_CartMutation(baseOptions?: Apollo.MutationHookOptions<Opt_Add_Item_To_Party_Member_CartMutation, Opt_Add_Item_To_Party_Member_CartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Opt_Add_Item_To_Party_Member_CartMutation, Opt_Add_Item_To_Party_Member_CartMutationVariables>(Opt_Add_Item_To_Party_Member_CartDocument, options);
      }
export type Opt_Add_Item_To_Party_Member_CartMutationHookResult = ReturnType<typeof useOpt_Add_Item_To_Party_Member_CartMutation>;
export type Opt_Add_Item_To_Party_Member_CartMutationResult = Apollo.MutationResult<Opt_Add_Item_To_Party_Member_CartMutation>;
export type Opt_Add_Item_To_Party_Member_CartMutationOptions = Apollo.BaseMutationOptions<Opt_Add_Item_To_Party_Member_CartMutation, Opt_Add_Item_To_Party_Member_CartMutationVariables>;
export const Opt_Reorder_ItemDocument = gql`
    mutation OPT_REORDER_ITEM($input: OPTReorderItemInput!, $totalGiftCardBalance: Float) {
  optPartyRefresh: optReorderItemV2(optReorderItemInput: $input) {
    ... on OPTPartyRefreshV2 {
      ...OPTPartyRefreshV2
    }
    ... on OPTPartyError {
      message
      code
    }
  }
}
    ${OptPartyRefreshV2FragmentDoc}`;
export type Opt_Reorder_ItemMutationFn = Apollo.MutationFunction<Opt_Reorder_ItemMutation, Opt_Reorder_ItemMutationVariables>;

/**
 * __useOpt_Reorder_ItemMutation__
 *
 * To run a mutation, you first call `useOpt_Reorder_ItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOpt_Reorder_ItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [optReorderItemMutation, { data, loading, error }] = useOpt_Reorder_ItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *      totalGiftCardBalance: // value for 'totalGiftCardBalance'
 *   },
 * });
 */
export function useOpt_Reorder_ItemMutation(baseOptions?: Apollo.MutationHookOptions<Opt_Reorder_ItemMutation, Opt_Reorder_ItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Opt_Reorder_ItemMutation, Opt_Reorder_ItemMutationVariables>(Opt_Reorder_ItemDocument, options);
      }
export type Opt_Reorder_ItemMutationHookResult = ReturnType<typeof useOpt_Reorder_ItemMutation>;
export type Opt_Reorder_ItemMutationResult = Apollo.MutationResult<Opt_Reorder_ItemMutation>;
export type Opt_Reorder_ItemMutationOptions = Apollo.BaseMutationOptions<Opt_Reorder_ItemMutation, Opt_Reorder_ItemMutationVariables>;
export const Opt_Edit_Item_In_Party_CartDocument = gql`
    mutation OPT_EDIT_ITEM_IN_PARTY_CART($input: OPTEditOrDeleteItemInPartyMemberCartInput!, $totalGiftCardBalance: Float) {
  optPartyRefresh: optEditItemInPartyMemberCartV2(
    optEditItemInPartyMemberCartInput: $input
  ) {
    ... on OPTPartyRefreshV2 {
      ...OPTPartyRefreshV2
    }
    ... on OPTPartyError {
      message
      code
    }
  }
}
    ${OptPartyRefreshV2FragmentDoc}`;
export type Opt_Edit_Item_In_Party_CartMutationFn = Apollo.MutationFunction<Opt_Edit_Item_In_Party_CartMutation, Opt_Edit_Item_In_Party_CartMutationVariables>;

/**
 * __useOpt_Edit_Item_In_Party_CartMutation__
 *
 * To run a mutation, you first call `useOpt_Edit_Item_In_Party_CartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOpt_Edit_Item_In_Party_CartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [optEditItemInPartyCartMutation, { data, loading, error }] = useOpt_Edit_Item_In_Party_CartMutation({
 *   variables: {
 *      input: // value for 'input'
 *      totalGiftCardBalance: // value for 'totalGiftCardBalance'
 *   },
 * });
 */
export function useOpt_Edit_Item_In_Party_CartMutation(baseOptions?: Apollo.MutationHookOptions<Opt_Edit_Item_In_Party_CartMutation, Opt_Edit_Item_In_Party_CartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Opt_Edit_Item_In_Party_CartMutation, Opt_Edit_Item_In_Party_CartMutationVariables>(Opt_Edit_Item_In_Party_CartDocument, options);
      }
export type Opt_Edit_Item_In_Party_CartMutationHookResult = ReturnType<typeof useOpt_Edit_Item_In_Party_CartMutation>;
export type Opt_Edit_Item_In_Party_CartMutationResult = Apollo.MutationResult<Opt_Edit_Item_In_Party_CartMutation>;
export type Opt_Edit_Item_In_Party_CartMutationOptions = Apollo.BaseMutationOptions<Opt_Edit_Item_In_Party_CartMutation, Opt_Edit_Item_In_Party_CartMutationVariables>;
export const Opt_Delete_Item_From_Party_CartDocument = gql`
    mutation OPT_DELETE_ITEM_FROM_PARTY_CART($input: OPTEditOrDeleteItemInPartyMemberCartInput!, $totalGiftCardBalance: Float) {
  optPartyRefresh: optDeleteItemInPartyMemberCartV2(
    optDeleteItemInPartyMemberCartInput: $input
  ) {
    ... on OPTPartyRefreshV2 {
      ...OPTPartyRefreshV2
    }
    ... on OPTPartyError {
      message
      code
    }
  }
}
    ${OptPartyRefreshV2FragmentDoc}`;
export type Opt_Delete_Item_From_Party_CartMutationFn = Apollo.MutationFunction<Opt_Delete_Item_From_Party_CartMutation, Opt_Delete_Item_From_Party_CartMutationVariables>;

/**
 * __useOpt_Delete_Item_From_Party_CartMutation__
 *
 * To run a mutation, you first call `useOpt_Delete_Item_From_Party_CartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOpt_Delete_Item_From_Party_CartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [optDeleteItemFromPartyCartMutation, { data, loading, error }] = useOpt_Delete_Item_From_Party_CartMutation({
 *   variables: {
 *      input: // value for 'input'
 *      totalGiftCardBalance: // value for 'totalGiftCardBalance'
 *   },
 * });
 */
export function useOpt_Delete_Item_From_Party_CartMutation(baseOptions?: Apollo.MutationHookOptions<Opt_Delete_Item_From_Party_CartMutation, Opt_Delete_Item_From_Party_CartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Opt_Delete_Item_From_Party_CartMutation, Opt_Delete_Item_From_Party_CartMutationVariables>(Opt_Delete_Item_From_Party_CartDocument, options);
      }
export type Opt_Delete_Item_From_Party_CartMutationHookResult = ReturnType<typeof useOpt_Delete_Item_From_Party_CartMutation>;
export type Opt_Delete_Item_From_Party_CartMutationResult = Apollo.MutationResult<Opt_Delete_Item_From_Party_CartMutation>;
export type Opt_Delete_Item_From_Party_CartMutationOptions = Apollo.BaseMutationOptions<Opt_Delete_Item_From_Party_CartMutation, Opt_Delete_Item_From_Party_CartMutationVariables>;
export const Opt_Lookup_Party_By_OrderDocument = gql`
    query OPT_LOOKUP_PARTY_BY_ORDER($restaurantGuid: ID!, $orderGuid: String!) {
  optLookupPartyByOrder: optLookupPartyByOrder(
    restaurantGuid: $restaurantGuid
    orderGuid: $orderGuid
  ) {
    ...OPTPartyStubResponse
  }
}
    ${OptPartyStubResponseFragmentDoc}`;

/**
 * __useOpt_Lookup_Party_By_OrderQuery__
 *
 * To run a query within a React component, call `useOpt_Lookup_Party_By_OrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpt_Lookup_Party_By_OrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpt_Lookup_Party_By_OrderQuery({
 *   variables: {
 *      restaurantGuid: // value for 'restaurantGuid'
 *      orderGuid: // value for 'orderGuid'
 *   },
 * });
 */
export function useOpt_Lookup_Party_By_OrderQuery(baseOptions: Apollo.QueryHookOptions<Opt_Lookup_Party_By_OrderQuery, Opt_Lookup_Party_By_OrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Opt_Lookup_Party_By_OrderQuery, Opt_Lookup_Party_By_OrderQueryVariables>(Opt_Lookup_Party_By_OrderDocument, options);
      }
export function useOpt_Lookup_Party_By_OrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Opt_Lookup_Party_By_OrderQuery, Opt_Lookup_Party_By_OrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Opt_Lookup_Party_By_OrderQuery, Opt_Lookup_Party_By_OrderQueryVariables>(Opt_Lookup_Party_By_OrderDocument, options);
        }
export type Opt_Lookup_Party_By_OrderQueryHookResult = ReturnType<typeof useOpt_Lookup_Party_By_OrderQuery>;
export type Opt_Lookup_Party_By_OrderLazyQueryHookResult = ReturnType<typeof useOpt_Lookup_Party_By_OrderLazyQuery>;
export type Opt_Lookup_Party_By_OrderQueryResult = Apollo.QueryResult<Opt_Lookup_Party_By_OrderQuery, Opt_Lookup_Party_By_OrderQueryVariables>;
export const Opt_Lookup_Party_By_TableDocument = gql`
    query OPT_LOOKUP_PARTY_BY_TABLE($restaurantGuid: ID!, $tableName: String!) {
  optLookupPartyByTable: optLookupPartyByTableV2(
    restaurantGuid: $restaurantGuid
    tableName: $tableName
  ) {
    ...OPTPartyStubResponse
  }
}
    ${OptPartyStubResponseFragmentDoc}`;

/**
 * __useOpt_Lookup_Party_By_TableQuery__
 *
 * To run a query within a React component, call `useOpt_Lookup_Party_By_TableQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpt_Lookup_Party_By_TableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpt_Lookup_Party_By_TableQuery({
 *   variables: {
 *      restaurantGuid: // value for 'restaurantGuid'
 *      tableName: // value for 'tableName'
 *   },
 * });
 */
export function useOpt_Lookup_Party_By_TableQuery(baseOptions: Apollo.QueryHookOptions<Opt_Lookup_Party_By_TableQuery, Opt_Lookup_Party_By_TableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Opt_Lookup_Party_By_TableQuery, Opt_Lookup_Party_By_TableQueryVariables>(Opt_Lookup_Party_By_TableDocument, options);
      }
export function useOpt_Lookup_Party_By_TableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Opt_Lookup_Party_By_TableQuery, Opt_Lookup_Party_By_TableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Opt_Lookup_Party_By_TableQuery, Opt_Lookup_Party_By_TableQueryVariables>(Opt_Lookup_Party_By_TableDocument, options);
        }
export type Opt_Lookup_Party_By_TableQueryHookResult = ReturnType<typeof useOpt_Lookup_Party_By_TableQuery>;
export type Opt_Lookup_Party_By_TableLazyQueryHookResult = ReturnType<typeof useOpt_Lookup_Party_By_TableLazyQuery>;
export type Opt_Lookup_Party_By_TableQueryResult = Apollo.QueryResult<Opt_Lookup_Party_By_TableQuery, Opt_Lookup_Party_By_TableQueryVariables>;
export const Opt_Lookup_Party_By_Table_And_PinDocument = gql`
    query OPT_LOOKUP_PARTY_BY_TABLE_AND_PIN($restaurantGuid: ID!, $tableName: String!, $pin: String!) {
  optLookupPartyByTableAndPin: optLookupPartyByTableAndPinV2(
    restaurantGuid: $restaurantGuid
    tableName: $tableName
    pin: $pin
  ) {
    ...OPTPartyStubResponse
  }
}
    ${OptPartyStubResponseFragmentDoc}`;

/**
 * __useOpt_Lookup_Party_By_Table_And_PinQuery__
 *
 * To run a query within a React component, call `useOpt_Lookup_Party_By_Table_And_PinQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpt_Lookup_Party_By_Table_And_PinQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpt_Lookup_Party_By_Table_And_PinQuery({
 *   variables: {
 *      restaurantGuid: // value for 'restaurantGuid'
 *      tableName: // value for 'tableName'
 *      pin: // value for 'pin'
 *   },
 * });
 */
export function useOpt_Lookup_Party_By_Table_And_PinQuery(baseOptions: Apollo.QueryHookOptions<Opt_Lookup_Party_By_Table_And_PinQuery, Opt_Lookup_Party_By_Table_And_PinQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Opt_Lookup_Party_By_Table_And_PinQuery, Opt_Lookup_Party_By_Table_And_PinQueryVariables>(Opt_Lookup_Party_By_Table_And_PinDocument, options);
      }
export function useOpt_Lookup_Party_By_Table_And_PinLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Opt_Lookup_Party_By_Table_And_PinQuery, Opt_Lookup_Party_By_Table_And_PinQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Opt_Lookup_Party_By_Table_And_PinQuery, Opt_Lookup_Party_By_Table_And_PinQueryVariables>(Opt_Lookup_Party_By_Table_And_PinDocument, options);
        }
export type Opt_Lookup_Party_By_Table_And_PinQueryHookResult = ReturnType<typeof useOpt_Lookup_Party_By_Table_And_PinQuery>;
export type Opt_Lookup_Party_By_Table_And_PinLazyQueryHookResult = ReturnType<typeof useOpt_Lookup_Party_By_Table_And_PinLazyQuery>;
export type Opt_Lookup_Party_By_Table_And_PinQueryResult = Apollo.QueryResult<Opt_Lookup_Party_By_Table_And_PinQuery, Opt_Lookup_Party_By_Table_And_PinQueryVariables>;
export const Opt_Unverified_Lookup_Party_By_GuidDocument = gql`
    query OPT_UNVERIFIED_LOOKUP_PARTY_BY_GUID($partyGuid: ID!) {
  optUnverifiedLookupPartyByGuid: optUnverifiedLookupPartyByGuidV2(
    partyGuid: $partyGuid
  ) {
    ...OPTPartyStubResponse
  }
}
    ${OptPartyStubResponseFragmentDoc}`;

/**
 * __useOpt_Unverified_Lookup_Party_By_GuidQuery__
 *
 * To run a query within a React component, call `useOpt_Unverified_Lookup_Party_By_GuidQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpt_Unverified_Lookup_Party_By_GuidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpt_Unverified_Lookup_Party_By_GuidQuery({
 *   variables: {
 *      partyGuid: // value for 'partyGuid'
 *   },
 * });
 */
export function useOpt_Unverified_Lookup_Party_By_GuidQuery(baseOptions: Apollo.QueryHookOptions<Opt_Unverified_Lookup_Party_By_GuidQuery, Opt_Unverified_Lookup_Party_By_GuidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Opt_Unverified_Lookup_Party_By_GuidQuery, Opt_Unverified_Lookup_Party_By_GuidQueryVariables>(Opt_Unverified_Lookup_Party_By_GuidDocument, options);
      }
export function useOpt_Unverified_Lookup_Party_By_GuidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Opt_Unverified_Lookup_Party_By_GuidQuery, Opt_Unverified_Lookup_Party_By_GuidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Opt_Unverified_Lookup_Party_By_GuidQuery, Opt_Unverified_Lookup_Party_By_GuidQueryVariables>(Opt_Unverified_Lookup_Party_By_GuidDocument, options);
        }
export type Opt_Unverified_Lookup_Party_By_GuidQueryHookResult = ReturnType<typeof useOpt_Unverified_Lookup_Party_By_GuidQuery>;
export type Opt_Unverified_Lookup_Party_By_GuidLazyQueryHookResult = ReturnType<typeof useOpt_Unverified_Lookup_Party_By_GuidLazyQuery>;
export type Opt_Unverified_Lookup_Party_By_GuidQueryResult = Apollo.QueryResult<Opt_Unverified_Lookup_Party_By_GuidQuery, Opt_Unverified_Lookup_Party_By_GuidQueryVariables>;
export const Opt_Get_Party_RefreshDocument = gql`
    query OPT_GET_PARTY_REFRESH($partyGuid: ID!, $partyMemberGuid: ID!, $memberAuthToken: String!, $totalGiftCardBalance: Float) {
  optPartyRefresh: optLookupPartyByGuidV2(
    partyGuid: $partyGuid
    partyMemberGuid: $partyMemberGuid
    memberAuthToken: $memberAuthToken
  ) {
    ... on OPTPartyRefreshV2 {
      ...OPTPartyRefreshV2
    }
    ... on OPTPartyError {
      message
      code
    }
  }
}
    ${OptPartyRefreshV2FragmentDoc}`;

/**
 * __useOpt_Get_Party_RefreshQuery__
 *
 * To run a query within a React component, call `useOpt_Get_Party_RefreshQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpt_Get_Party_RefreshQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpt_Get_Party_RefreshQuery({
 *   variables: {
 *      partyGuid: // value for 'partyGuid'
 *      partyMemberGuid: // value for 'partyMemberGuid'
 *      memberAuthToken: // value for 'memberAuthToken'
 *      totalGiftCardBalance: // value for 'totalGiftCardBalance'
 *   },
 * });
 */
export function useOpt_Get_Party_RefreshQuery(baseOptions: Apollo.QueryHookOptions<Opt_Get_Party_RefreshQuery, Opt_Get_Party_RefreshQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Opt_Get_Party_RefreshQuery, Opt_Get_Party_RefreshQueryVariables>(Opt_Get_Party_RefreshDocument, options);
      }
export function useOpt_Get_Party_RefreshLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Opt_Get_Party_RefreshQuery, Opt_Get_Party_RefreshQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Opt_Get_Party_RefreshQuery, Opt_Get_Party_RefreshQueryVariables>(Opt_Get_Party_RefreshDocument, options);
        }
export type Opt_Get_Party_RefreshQueryHookResult = ReturnType<typeof useOpt_Get_Party_RefreshQuery>;
export type Opt_Get_Party_RefreshLazyQueryHookResult = ReturnType<typeof useOpt_Get_Party_RefreshLazyQuery>;
export type Opt_Get_Party_RefreshQueryResult = Apollo.QueryResult<Opt_Get_Party_RefreshQuery, Opt_Get_Party_RefreshQueryVariables>;
export const Opt_Fire_RoundDocument = gql`
    mutation OPT_FIRE_ROUND($input: OPTFireTabRoundInput!, $totalGiftCardBalance: Float) {
  optPartyRefresh: optFireTabRoundV2(optFireTabRoundInput: $input) {
    ... on OPTPartyRefreshV2 {
      ...OPTPartyRefreshV2
    }
    ... on OPTPartyError {
      message
      code
    }
  }
}
    ${OptPartyRefreshV2FragmentDoc}`;
export type Opt_Fire_RoundMutationFn = Apollo.MutationFunction<Opt_Fire_RoundMutation, Opt_Fire_RoundMutationVariables>;

/**
 * __useOpt_Fire_RoundMutation__
 *
 * To run a mutation, you first call `useOpt_Fire_RoundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOpt_Fire_RoundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [optFireRoundMutation, { data, loading, error }] = useOpt_Fire_RoundMutation({
 *   variables: {
 *      input: // value for 'input'
 *      totalGiftCardBalance: // value for 'totalGiftCardBalance'
 *   },
 * });
 */
export function useOpt_Fire_RoundMutation(baseOptions?: Apollo.MutationHookOptions<Opt_Fire_RoundMutation, Opt_Fire_RoundMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Opt_Fire_RoundMutation, Opt_Fire_RoundMutationVariables>(Opt_Fire_RoundDocument, options);
      }
export type Opt_Fire_RoundMutationHookResult = ReturnType<typeof useOpt_Fire_RoundMutation>;
export type Opt_Fire_RoundMutationResult = Apollo.MutationResult<Opt_Fire_RoundMutation>;
export type Opt_Fire_RoundMutationOptions = Apollo.BaseMutationOptions<Opt_Fire_RoundMutation, Opt_Fire_RoundMutationVariables>;
export const Opt_ValidateDocument = gql`
    mutation OPT_VALIDATE($input: OPTValidateInput!, $totalGiftCardBalance: Float) {
  optPartyRefresh: optValidateV2(optValidateInput: $input) {
    ... on OPTPartyRefreshV2 {
      ...OPTPartyRefreshV2
    }
    ... on OPTPartyError {
      message
      code
    }
  }
}
    ${OptPartyRefreshV2FragmentDoc}`;
export type Opt_ValidateMutationFn = Apollo.MutationFunction<Opt_ValidateMutation, Opt_ValidateMutationVariables>;

/**
 * __useOpt_ValidateMutation__
 *
 * To run a mutation, you first call `useOpt_ValidateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOpt_ValidateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [optValidateMutation, { data, loading, error }] = useOpt_ValidateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      totalGiftCardBalance: // value for 'totalGiftCardBalance'
 *   },
 * });
 */
export function useOpt_ValidateMutation(baseOptions?: Apollo.MutationHookOptions<Opt_ValidateMutation, Opt_ValidateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Opt_ValidateMutation, Opt_ValidateMutationVariables>(Opt_ValidateDocument, options);
      }
export type Opt_ValidateMutationHookResult = ReturnType<typeof useOpt_ValidateMutation>;
export type Opt_ValidateMutationResult = Apollo.MutationResult<Opt_ValidateMutation>;
export type Opt_ValidateMutationOptions = Apollo.BaseMutationOptions<Opt_ValidateMutation, Opt_ValidateMutationVariables>;
export const Opt_Tab_Apply_Promo_CodeDocument = gql`
    mutation OPT_TAB_APPLY_PROMO_CODE($input: OPTPartyTabPromoInput!, $totalGiftCardBalance: Float) {
  optApplyPartyPromo: optPartyTabApplyPromo(optPartyTabPromoInput: $input) {
    ... on OPTPartyRefreshV2 {
      ...OPTPartyRefreshV2
    }
    ... on OPTPartyError {
      message
      code
    }
  }
}
    ${OptPartyRefreshV2FragmentDoc}`;
export type Opt_Tab_Apply_Promo_CodeMutationFn = Apollo.MutationFunction<Opt_Tab_Apply_Promo_CodeMutation, Opt_Tab_Apply_Promo_CodeMutationVariables>;

/**
 * __useOpt_Tab_Apply_Promo_CodeMutation__
 *
 * To run a mutation, you first call `useOpt_Tab_Apply_Promo_CodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOpt_Tab_Apply_Promo_CodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [optTabApplyPromoCodeMutation, { data, loading, error }] = useOpt_Tab_Apply_Promo_CodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *      totalGiftCardBalance: // value for 'totalGiftCardBalance'
 *   },
 * });
 */
export function useOpt_Tab_Apply_Promo_CodeMutation(baseOptions?: Apollo.MutationHookOptions<Opt_Tab_Apply_Promo_CodeMutation, Opt_Tab_Apply_Promo_CodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Opt_Tab_Apply_Promo_CodeMutation, Opt_Tab_Apply_Promo_CodeMutationVariables>(Opt_Tab_Apply_Promo_CodeDocument, options);
      }
export type Opt_Tab_Apply_Promo_CodeMutationHookResult = ReturnType<typeof useOpt_Tab_Apply_Promo_CodeMutation>;
export type Opt_Tab_Apply_Promo_CodeMutationResult = Apollo.MutationResult<Opt_Tab_Apply_Promo_CodeMutation>;
export type Opt_Tab_Apply_Promo_CodeMutationOptions = Apollo.BaseMutationOptions<Opt_Tab_Apply_Promo_CodeMutation, Opt_Tab_Apply_Promo_CodeMutationVariables>;
export const Opt_Tab_Remove_Promo_CodeDocument = gql`
    mutation OPT_TAB_REMOVE_PROMO_CODE($partyGuid: ID!, $partyMemberGuid: ID!, $memberAuthToken: String!, $totalGiftCardBalance: Float) {
  optRemovePartyPromo: optPartyTabRemovePromo(
    partyGuid: $partyGuid
    partyMemberGuid: $partyMemberGuid
    memberAuthToken: $memberAuthToken
  ) {
    ... on OPTPartyRefreshV2 {
      ...OPTPartyRefreshV2
    }
    ... on OPTPartyError {
      message
      code
    }
  }
}
    ${OptPartyRefreshV2FragmentDoc}`;
export type Opt_Tab_Remove_Promo_CodeMutationFn = Apollo.MutationFunction<Opt_Tab_Remove_Promo_CodeMutation, Opt_Tab_Remove_Promo_CodeMutationVariables>;

/**
 * __useOpt_Tab_Remove_Promo_CodeMutation__
 *
 * To run a mutation, you first call `useOpt_Tab_Remove_Promo_CodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOpt_Tab_Remove_Promo_CodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [optTabRemovePromoCodeMutation, { data, loading, error }] = useOpt_Tab_Remove_Promo_CodeMutation({
 *   variables: {
 *      partyGuid: // value for 'partyGuid'
 *      partyMemberGuid: // value for 'partyMemberGuid'
 *      memberAuthToken: // value for 'memberAuthToken'
 *      totalGiftCardBalance: // value for 'totalGiftCardBalance'
 *   },
 * });
 */
export function useOpt_Tab_Remove_Promo_CodeMutation(baseOptions?: Apollo.MutationHookOptions<Opt_Tab_Remove_Promo_CodeMutation, Opt_Tab_Remove_Promo_CodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Opt_Tab_Remove_Promo_CodeMutation, Opt_Tab_Remove_Promo_CodeMutationVariables>(Opt_Tab_Remove_Promo_CodeDocument, options);
      }
export type Opt_Tab_Remove_Promo_CodeMutationHookResult = ReturnType<typeof useOpt_Tab_Remove_Promo_CodeMutation>;
export type Opt_Tab_Remove_Promo_CodeMutationResult = Apollo.MutationResult<Opt_Tab_Remove_Promo_CodeMutation>;
export type Opt_Tab_Remove_Promo_CodeMutationOptions = Apollo.BaseMutationOptions<Opt_Tab_Remove_Promo_CodeMutation, Opt_Tab_Remove_Promo_CodeMutationVariables>;
export const Party_CloseoutDocument = gql`
    mutation PARTY_CLOSEOUT($closeoutInput: OPTCloseoutInput!, $totalGiftCardBalance: Float) {
  optPartyCloseout: optPartyTabCloseout(optCloseoutInput: $closeoutInput) {
    ...OPTCloseoutResponse
  }
}
    ${OptCloseoutResponseFragmentDoc}`;
export type Party_CloseoutMutationFn = Apollo.MutationFunction<Party_CloseoutMutation, Party_CloseoutMutationVariables>;

/**
 * __useParty_CloseoutMutation__
 *
 * To run a mutation, you first call `useParty_CloseoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useParty_CloseoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [partyCloseoutMutation, { data, loading, error }] = useParty_CloseoutMutation({
 *   variables: {
 *      closeoutInput: // value for 'closeoutInput'
 *      totalGiftCardBalance: // value for 'totalGiftCardBalance'
 *   },
 * });
 */
export function useParty_CloseoutMutation(baseOptions?: Apollo.MutationHookOptions<Party_CloseoutMutation, Party_CloseoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Party_CloseoutMutation, Party_CloseoutMutationVariables>(Party_CloseoutDocument, options);
      }
export type Party_CloseoutMutationHookResult = ReturnType<typeof useParty_CloseoutMutation>;
export type Party_CloseoutMutationResult = Apollo.MutationResult<Party_CloseoutMutation>;
export type Party_CloseoutMutationOptions = Apollo.BaseMutationOptions<Party_CloseoutMutation, Party_CloseoutMutationVariables>;
export const Split_Check_CloseoutDocument = gql`
    mutation SPLIT_CHECK_CLOSEOUT($closeoutInput: OPTCloseoutInput!, $totalGiftCardBalance: Float) {
  optPartyCloseout: optSplitCheckCloseout(optCloseoutInput: $closeoutInput) {
    ...OPTCloseoutResponse
  }
}
    ${OptCloseoutResponseFragmentDoc}`;
export type Split_Check_CloseoutMutationFn = Apollo.MutationFunction<Split_Check_CloseoutMutation, Split_Check_CloseoutMutationVariables>;

/**
 * __useSplit_Check_CloseoutMutation__
 *
 * To run a mutation, you first call `useSplit_Check_CloseoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSplit_Check_CloseoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [splitCheckCloseoutMutation, { data, loading, error }] = useSplit_Check_CloseoutMutation({
 *   variables: {
 *      closeoutInput: // value for 'closeoutInput'
 *      totalGiftCardBalance: // value for 'totalGiftCardBalance'
 *   },
 * });
 */
export function useSplit_Check_CloseoutMutation(baseOptions?: Apollo.MutationHookOptions<Split_Check_CloseoutMutation, Split_Check_CloseoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Split_Check_CloseoutMutation, Split_Check_CloseoutMutationVariables>(Split_Check_CloseoutDocument, options);
      }
export type Split_Check_CloseoutMutationHookResult = ReturnType<typeof useSplit_Check_CloseoutMutation>;
export type Split_Check_CloseoutMutationResult = Apollo.MutationResult<Split_Check_CloseoutMutation>;
export type Split_Check_CloseoutMutationOptions = Apollo.BaseMutationOptions<Split_Check_CloseoutMutation, Split_Check_CloseoutMutationVariables>;
export const Price_Split_CheckDocument = gql`
    query PRICE_SPLIT_CHECK($splitCheckInput: OPTSplitCheckInput!, $totalGiftCardBalance: Float) {
  optPriceSplitCheck(optSplitCheckInput: $splitCheckInput) {
    ... on OPTSplitCheckPreview {
      ...OPTSplitCheckPreview
    }
    ... on OPTPartyError {
      code
      message
    }
  }
}
    ${OptSplitCheckPreviewFragmentDoc}`;

/**
 * __usePrice_Split_CheckQuery__
 *
 * To run a query within a React component, call `usePrice_Split_CheckQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrice_Split_CheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrice_Split_CheckQuery({
 *   variables: {
 *      splitCheckInput: // value for 'splitCheckInput'
 *      totalGiftCardBalance: // value for 'totalGiftCardBalance'
 *   },
 * });
 */
export function usePrice_Split_CheckQuery(baseOptions: Apollo.QueryHookOptions<Price_Split_CheckQuery, Price_Split_CheckQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Price_Split_CheckQuery, Price_Split_CheckQueryVariables>(Price_Split_CheckDocument, options);
      }
export function usePrice_Split_CheckLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Price_Split_CheckQuery, Price_Split_CheckQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Price_Split_CheckQuery, Price_Split_CheckQueryVariables>(Price_Split_CheckDocument, options);
        }
export type Price_Split_CheckQueryHookResult = ReturnType<typeof usePrice_Split_CheckQuery>;
export type Price_Split_CheckLazyQueryHookResult = ReturnType<typeof usePrice_Split_CheckLazyQuery>;
export type Price_Split_CheckQueryResult = Apollo.QueryResult<Price_Split_CheckQuery, Price_Split_CheckQueryVariables>;
export const Place_Payg_OrderDocument = gql`
    mutation PLACE_PAYG_ORDER($closeoutInput: OPTCloseoutInput!, $totalGiftCardBalance: Float) {
  optPartyCloseout: optPartyPayGCloseout(optCloseoutInput: $closeoutInput) {
    ...OPTCloseoutResponse
  }
}
    ${OptCloseoutResponseFragmentDoc}`;
export type Place_Payg_OrderMutationFn = Apollo.MutationFunction<Place_Payg_OrderMutation, Place_Payg_OrderMutationVariables>;

/**
 * __usePlace_Payg_OrderMutation__
 *
 * To run a mutation, you first call `usePlace_Payg_OrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlace_Payg_OrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [placePaygOrderMutation, { data, loading, error }] = usePlace_Payg_OrderMutation({
 *   variables: {
 *      closeoutInput: // value for 'closeoutInput'
 *      totalGiftCardBalance: // value for 'totalGiftCardBalance'
 *   },
 * });
 */
export function usePlace_Payg_OrderMutation(baseOptions?: Apollo.MutationHookOptions<Place_Payg_OrderMutation, Place_Payg_OrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Place_Payg_OrderMutation, Place_Payg_OrderMutationVariables>(Place_Payg_OrderDocument, options);
      }
export type Place_Payg_OrderMutationHookResult = ReturnType<typeof usePlace_Payg_OrderMutation>;
export type Place_Payg_OrderMutationResult = Apollo.MutationResult<Place_Payg_OrderMutation>;
export type Place_Payg_OrderMutationOptions = Apollo.BaseMutationOptions<Place_Payg_OrderMutation, Place_Payg_OrderMutationVariables>;
export const Opt_Apply_Split_TypeDocument = gql`
    mutation OPT_APPLY_SPLIT_TYPE($input: InitializeSplitPaymentDataInput!, $totalGiftCardBalance: Float) {
  optPartyRefresh: optApplySplitType(optSplitInput: $input) {
    ... on OPTPartyRefreshV2 {
      ...OPTPartyRefreshV2
    }
    ... on OPTPartyError {
      message
      code
    }
  }
}
    ${OptPartyRefreshV2FragmentDoc}`;
export type Opt_Apply_Split_TypeMutationFn = Apollo.MutationFunction<Opt_Apply_Split_TypeMutation, Opt_Apply_Split_TypeMutationVariables>;

/**
 * __useOpt_Apply_Split_TypeMutation__
 *
 * To run a mutation, you first call `useOpt_Apply_Split_TypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOpt_Apply_Split_TypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [optApplySplitTypeMutation, { data, loading, error }] = useOpt_Apply_Split_TypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *      totalGiftCardBalance: // value for 'totalGiftCardBalance'
 *   },
 * });
 */
export function useOpt_Apply_Split_TypeMutation(baseOptions?: Apollo.MutationHookOptions<Opt_Apply_Split_TypeMutation, Opt_Apply_Split_TypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Opt_Apply_Split_TypeMutation, Opt_Apply_Split_TypeMutationVariables>(Opt_Apply_Split_TypeDocument, options);
      }
export type Opt_Apply_Split_TypeMutationHookResult = ReturnType<typeof useOpt_Apply_Split_TypeMutation>;
export type Opt_Apply_Split_TypeMutationResult = Apollo.MutationResult<Opt_Apply_Split_TypeMutation>;
export type Opt_Apply_Split_TypeMutationOptions = Apollo.BaseMutationOptions<Opt_Apply_Split_TypeMutation, Opt_Apply_Split_TypeMutationVariables>;
export const Opt_Apply_Split_Even_Paid_PortionDocument = gql`
    mutation OPT_APPLY_SPLIT_EVEN_PAID_PORTION($input: SplitPaymentPaidPortionInput!, $totalGiftCardBalance: Float) {
  optPartyRefresh: optApplyEvenSplitPaidPortion(optSplitInput: $input) {
    ... on OPTPartyRefreshV2 {
      ...OPTPartyRefreshV2
    }
    ... on OPTPartyError {
      message
      code
    }
  }
}
    ${OptPartyRefreshV2FragmentDoc}`;
export type Opt_Apply_Split_Even_Paid_PortionMutationFn = Apollo.MutationFunction<Opt_Apply_Split_Even_Paid_PortionMutation, Opt_Apply_Split_Even_Paid_PortionMutationVariables>;

/**
 * __useOpt_Apply_Split_Even_Paid_PortionMutation__
 *
 * To run a mutation, you first call `useOpt_Apply_Split_Even_Paid_PortionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOpt_Apply_Split_Even_Paid_PortionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [optApplySplitEvenPaidPortionMutation, { data, loading, error }] = useOpt_Apply_Split_Even_Paid_PortionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      totalGiftCardBalance: // value for 'totalGiftCardBalance'
 *   },
 * });
 */
export function useOpt_Apply_Split_Even_Paid_PortionMutation(baseOptions?: Apollo.MutationHookOptions<Opt_Apply_Split_Even_Paid_PortionMutation, Opt_Apply_Split_Even_Paid_PortionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Opt_Apply_Split_Even_Paid_PortionMutation, Opt_Apply_Split_Even_Paid_PortionMutationVariables>(Opt_Apply_Split_Even_Paid_PortionDocument, options);
      }
export type Opt_Apply_Split_Even_Paid_PortionMutationHookResult = ReturnType<typeof useOpt_Apply_Split_Even_Paid_PortionMutation>;
export type Opt_Apply_Split_Even_Paid_PortionMutationResult = Apollo.MutationResult<Opt_Apply_Split_Even_Paid_PortionMutation>;
export type Opt_Apply_Split_Even_Paid_PortionMutationOptions = Apollo.BaseMutationOptions<Opt_Apply_Split_Even_Paid_PortionMutation, Opt_Apply_Split_Even_Paid_PortionMutationVariables>;
export const Opt_Remove_Split_Payment_DataDocument = gql`
    mutation OPT_REMOVE_SPLIT_PAYMENT_DATA($partyGuid: ID!, $partyMemberGuid: ID!, $memberAuthToken: String!, $totalGiftCardBalance: Float) {
  optPartyRefresh: optRemoveSplitPaymentData(
    partyGuid: $partyGuid
    partyMemberGuid: $partyMemberGuid
    memberAuthToken: $memberAuthToken
  ) {
    ... on OPTPartyRefreshV2 {
      ...OPTPartyRefreshV2
    }
    ... on OPTPartyError {
      message
      code
    }
  }
}
    ${OptPartyRefreshV2FragmentDoc}`;
export type Opt_Remove_Split_Payment_DataMutationFn = Apollo.MutationFunction<Opt_Remove_Split_Payment_DataMutation, Opt_Remove_Split_Payment_DataMutationVariables>;

/**
 * __useOpt_Remove_Split_Payment_DataMutation__
 *
 * To run a mutation, you first call `useOpt_Remove_Split_Payment_DataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOpt_Remove_Split_Payment_DataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [optRemoveSplitPaymentDataMutation, { data, loading, error }] = useOpt_Remove_Split_Payment_DataMutation({
 *   variables: {
 *      partyGuid: // value for 'partyGuid'
 *      partyMemberGuid: // value for 'partyMemberGuid'
 *      memberAuthToken: // value for 'memberAuthToken'
 *      totalGiftCardBalance: // value for 'totalGiftCardBalance'
 *   },
 * });
 */
export function useOpt_Remove_Split_Payment_DataMutation(baseOptions?: Apollo.MutationHookOptions<Opt_Remove_Split_Payment_DataMutation, Opt_Remove_Split_Payment_DataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Opt_Remove_Split_Payment_DataMutation, Opt_Remove_Split_Payment_DataMutationVariables>(Opt_Remove_Split_Payment_DataDocument, options);
      }
export type Opt_Remove_Split_Payment_DataMutationHookResult = ReturnType<typeof useOpt_Remove_Split_Payment_DataMutation>;
export type Opt_Remove_Split_Payment_DataMutationResult = Apollo.MutationResult<Opt_Remove_Split_Payment_DataMutation>;
export type Opt_Remove_Split_Payment_DataMutationOptions = Apollo.BaseMutationOptions<Opt_Remove_Split_Payment_DataMutation, Opt_Remove_Split_Payment_DataMutationVariables>;
export const Lookup_Parties_By_TableDocument = gql`
    query LOOKUP_PARTIES_BY_TABLE($input: LookupPartiesByTableInput!) {
  lookupPartiesByTable: lookupPartiesByTable(input: $input) {
    ... on OPTParties {
      parties {
        guid
        members {
          partyMemberGuid
          name
          emoji
        }
      }
    }
    ... on OPTPartiesLookupError {
      code
      message
    }
  }
}
    `;

/**
 * __useLookup_Parties_By_TableQuery__
 *
 * To run a query within a React component, call `useLookup_Parties_By_TableQuery` and pass it any options that fit your needs.
 * When your component renders, `useLookup_Parties_By_TableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLookup_Parties_By_TableQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLookup_Parties_By_TableQuery(baseOptions: Apollo.QueryHookOptions<Lookup_Parties_By_TableQuery, Lookup_Parties_By_TableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Lookup_Parties_By_TableQuery, Lookup_Parties_By_TableQueryVariables>(Lookup_Parties_By_TableDocument, options);
      }
export function useLookup_Parties_By_TableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Lookup_Parties_By_TableQuery, Lookup_Parties_By_TableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Lookup_Parties_By_TableQuery, Lookup_Parties_By_TableQueryVariables>(Lookup_Parties_By_TableDocument, options);
        }
export type Lookup_Parties_By_TableQueryHookResult = ReturnType<typeof useLookup_Parties_By_TableQuery>;
export type Lookup_Parties_By_TableLazyQueryHookResult = ReturnType<typeof useLookup_Parties_By_TableLazyQuery>;
export type Lookup_Parties_By_TableQueryResult = Apollo.QueryResult<Lookup_Parties_By_TableQuery, Lookup_Parties_By_TableQueryVariables>;
export const Lookup_Parties_By_CheckDocument = gql`
    query LOOKUP_PARTIES_BY_CHECK($input: LookupPartiesByCheckInput!) {
  lookupPartiesByCheck: lookupPartiesByCheck(input: $input) {
    ... on OPTParties {
      parties {
        guid
        members {
          partyMemberGuid
          name
        }
        tableName
      }
    }
    ... on OPTPartiesLookupError {
      code
      message
    }
  }
}
    `;

/**
 * __useLookup_Parties_By_CheckQuery__
 *
 * To run a query within a React component, call `useLookup_Parties_By_CheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useLookup_Parties_By_CheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLookup_Parties_By_CheckQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLookup_Parties_By_CheckQuery(baseOptions: Apollo.QueryHookOptions<Lookup_Parties_By_CheckQuery, Lookup_Parties_By_CheckQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Lookup_Parties_By_CheckQuery, Lookup_Parties_By_CheckQueryVariables>(Lookup_Parties_By_CheckDocument, options);
      }
export function useLookup_Parties_By_CheckLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Lookup_Parties_By_CheckQuery, Lookup_Parties_By_CheckQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Lookup_Parties_By_CheckQuery, Lookup_Parties_By_CheckQueryVariables>(Lookup_Parties_By_CheckDocument, options);
        }
export type Lookup_Parties_By_CheckQueryHookResult = ReturnType<typeof useLookup_Parties_By_CheckQuery>;
export type Lookup_Parties_By_CheckLazyQueryHookResult = ReturnType<typeof useLookup_Parties_By_CheckLazyQuery>;
export type Lookup_Parties_By_CheckQueryResult = Apollo.QueryResult<Lookup_Parties_By_CheckQuery, Lookup_Parties_By_CheckQueryVariables>;
export const Update_Payment_IntentDocument = gql`
    mutation UPDATE_PAYMENT_INTENT($input: UpdatePaymentIntentInput!) {
  updatePaymentIntent(updatePaymentIntentInput: $input) {
    ...UpdatePaymentIntentResponse
  }
}
    ${UpdatePaymentIntentResponseFragmentDoc}`;
export type Update_Payment_IntentMutationFn = Apollo.MutationFunction<Update_Payment_IntentMutation, Update_Payment_IntentMutationVariables>;

/**
 * __useUpdate_Payment_IntentMutation__
 *
 * To run a mutation, you first call `useUpdate_Payment_IntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Payment_IntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaymentIntentMutation, { data, loading, error }] = useUpdate_Payment_IntentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdate_Payment_IntentMutation(baseOptions?: Apollo.MutationHookOptions<Update_Payment_IntentMutation, Update_Payment_IntentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Update_Payment_IntentMutation, Update_Payment_IntentMutationVariables>(Update_Payment_IntentDocument, options);
      }
export type Update_Payment_IntentMutationHookResult = ReturnType<typeof useUpdate_Payment_IntentMutation>;
export type Update_Payment_IntentMutationResult = Apollo.MutationResult<Update_Payment_IntentMutation>;
export type Update_Payment_IntentMutationOptions = Apollo.BaseMutationOptions<Update_Payment_IntentMutation, Update_Payment_IntentMutationVariables>;
export const Create_Payment_IntentDocument = gql`
    mutation CREATE_PAYMENT_INTENT($input: CreatePaymentIntentInput!) {
  createPaymentIntent(createPaymentIntentInput: $input) {
    ...CreatePaymentIntentResponse
  }
}
    ${CreatePaymentIntentResponseFragmentDoc}`;
export type Create_Payment_IntentMutationFn = Apollo.MutationFunction<Create_Payment_IntentMutation, Create_Payment_IntentMutationVariables>;

/**
 * __useCreate_Payment_IntentMutation__
 *
 * To run a mutation, you first call `useCreate_Payment_IntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreate_Payment_IntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentIntentMutation, { data, loading, error }] = useCreate_Payment_IntentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreate_Payment_IntentMutation(baseOptions?: Apollo.MutationHookOptions<Create_Payment_IntentMutation, Create_Payment_IntentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Create_Payment_IntentMutation, Create_Payment_IntentMutationVariables>(Create_Payment_IntentDocument, options);
      }
export type Create_Payment_IntentMutationHookResult = ReturnType<typeof useCreate_Payment_IntentMutation>;
export type Create_Payment_IntentMutationResult = Apollo.MutationResult<Create_Payment_IntentMutation>;
export type Create_Payment_IntentMutationOptions = Apollo.BaseMutationOptions<Create_Payment_IntentMutation, Create_Payment_IntentMutationVariables>;
export const Update_Payment_Intent_For_Split_CheckDocument = gql`
    mutation UPDATE_PAYMENT_INTENT_FOR_SPLIT_CHECK($input: UpdatePaymentIntentForSplitCheckBySelectionsInput!) {
  updatePaymentIntentForSplitCheckBySelections(updatePaymentIntentInput: $input) {
    ...UpdatePaymentIntentResponse
  }
}
    ${UpdatePaymentIntentResponseFragmentDoc}`;
export type Update_Payment_Intent_For_Split_CheckMutationFn = Apollo.MutationFunction<Update_Payment_Intent_For_Split_CheckMutation, Update_Payment_Intent_For_Split_CheckMutationVariables>;

/**
 * __useUpdate_Payment_Intent_For_Split_CheckMutation__
 *
 * To run a mutation, you first call `useUpdate_Payment_Intent_For_Split_CheckMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Payment_Intent_For_Split_CheckMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaymentIntentForSplitCheckMutation, { data, loading, error }] = useUpdate_Payment_Intent_For_Split_CheckMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdate_Payment_Intent_For_Split_CheckMutation(baseOptions?: Apollo.MutationHookOptions<Update_Payment_Intent_For_Split_CheckMutation, Update_Payment_Intent_For_Split_CheckMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Update_Payment_Intent_For_Split_CheckMutation, Update_Payment_Intent_For_Split_CheckMutationVariables>(Update_Payment_Intent_For_Split_CheckDocument, options);
      }
export type Update_Payment_Intent_For_Split_CheckMutationHookResult = ReturnType<typeof useUpdate_Payment_Intent_For_Split_CheckMutation>;
export type Update_Payment_Intent_For_Split_CheckMutationResult = Apollo.MutationResult<Update_Payment_Intent_For_Split_CheckMutation>;
export type Update_Payment_Intent_For_Split_CheckMutationOptions = Apollo.BaseMutationOptions<Update_Payment_Intent_For_Split_CheckMutation, Update_Payment_Intent_For_Split_CheckMutationVariables>;
export const Order_At_Table_Restaurant_InfoDocument = gql`
    query ORDER_AT_TABLE_RESTAURANT_INFO($restaurantGuid: ID!) {
  restaurant: restaurantV2(guid: $restaurantGuid) {
    ... on Restaurant {
      guid
      giftCardConfig {
        redemptionAllowed
        tipAllowed
      }
      giftCardLinks {
        purchaseLink
        checkValueLink
        addValueEnabled
      }
      orderAndPayConfig {
        allowTabs
        allowJoinableParty
        allowPreauth
        allowTextFulfillment
        viewOnlyMenu
      }
      name
      whiteLabelName
      description
      imageUrl
      bannerUrls {
        raw
        large
        medium
      }
      testMode
      consumerCTAEnabled
      location {
        address1
        address2
        city
        state
        zip
        phone
      }
      logoUrls {
        small
      }
      i18n {
        currency
        locale
        country
      }
      socialMediaLinks {
        twitterLink
        facebookLink
      }
      creditCardConfig {
        amexAccepted
        tipEnabled
      }
      specialRequestsConfig {
        enabled
        placeholderMessage
      }
      loyaltyConfig {
        loyaltySignupEnabled
        loyaltyRedemptionEnabled
        signupMethod
        programName
        rewardType
        programDescription
      }
      itemLevelFeedbackConfig {
        enabled
      }
      toastPayConfig {
        splitPaymentsEnabled
      }
    }
    ... on GeneralError {
      code
      message
    }
  }
}
    `;

/**
 * __useOrder_At_Table_Restaurant_InfoQuery__
 *
 * To run a query within a React component, call `useOrder_At_Table_Restaurant_InfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrder_At_Table_Restaurant_InfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrder_At_Table_Restaurant_InfoQuery({
 *   variables: {
 *      restaurantGuid: // value for 'restaurantGuid'
 *   },
 * });
 */
export function useOrder_At_Table_Restaurant_InfoQuery(baseOptions: Apollo.QueryHookOptions<Order_At_Table_Restaurant_InfoQuery, Order_At_Table_Restaurant_InfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Order_At_Table_Restaurant_InfoQuery, Order_At_Table_Restaurant_InfoQueryVariables>(Order_At_Table_Restaurant_InfoDocument, options);
      }
export function useOrder_At_Table_Restaurant_InfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Order_At_Table_Restaurant_InfoQuery, Order_At_Table_Restaurant_InfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Order_At_Table_Restaurant_InfoQuery, Order_At_Table_Restaurant_InfoQueryVariables>(Order_At_Table_Restaurant_InfoDocument, options);
        }
export type Order_At_Table_Restaurant_InfoQueryHookResult = ReturnType<typeof useOrder_At_Table_Restaurant_InfoQuery>;
export type Order_At_Table_Restaurant_InfoLazyQueryHookResult = ReturnType<typeof useOrder_At_Table_Restaurant_InfoLazyQuery>;
export type Order_At_Table_Restaurant_InfoQueryResult = Apollo.QueryResult<Order_At_Table_Restaurant_InfoQuery, Order_At_Table_Restaurant_InfoQueryVariables>;