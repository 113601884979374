import { Modal, ModalBody, ModalHeader } from '@toasttab/buffet-pui-modal'
import React from 'react'

import styles from '../auth/auth.module.css'

type LogoutProps = {
  isOpen: boolean
  error?: boolean
}

export const LoggingOutModal = ({ error, isOpen }: LogoutProps) => {
  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>
        <div className={styles.header} data-testid='Logout'>
          <div className={styles.headerText}>
            {!error ? 'Logging out...' : 'Log out failed'}
          </div>
        </div>
      </ModalHeader>
      {error && (
        <ModalBody className={styles.logoutModalContent}>
          <div className={styles.body}>
            {error && <p>Something went wrong, please try again later</p>}
          </div>
        </ModalBody>
      )}
    </Modal>
  )
}