import React, { useEffect } from 'react'
import Formik from 'formik'
import { InputChangedEvent, SpiInputState } from '../../types/SPI'
import { PaymentType } from '../CheckoutForm/PaymentInfo/constants'
import { useInitializeSpi } from './SpiDataProvider'

type props = {
  sessionSecret: string
  amexAccepted: boolean
  zipRequired: boolean
  spiBearerToken: string
  handleSpiFailure: () => void
  onInit: () => void
  ref: any
} & Formik.FieldProps

export const LazySpiAnchor = ({
  handleSpiFailure,
  field,
  form,
  onInit,
  ref
}: props) => {
  const { initializeSpi } = useInitializeSpi()
  const onStateChange = React.useCallback((e: InputChangedEvent) => {
    form.setFieldValue(
      field.name,
      e.content.isValid ? SpiInputState.VALID : SpiInputState.INVALID
    )
    form.setFieldValue('spiPayment', true)
    form.setFieldValue('spiPaymentType', e.content.selectedPaymentMethod)
    form.setFieldValue('paymentType', PaymentType.CREDIT_CARD)
    form.setFieldTouched(field.name, true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const unmountSpi = initializeSpi(
      'spi-frame-anchor-opt',
      onInit,
      handleSpiFailure,
      onStateChange
    )

    return unmountSpi
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initializeSpi, onStateChange])

  return <div ref={ref} id='spi-frame-anchor-opt' />
}
