import * as React from 'react'
import PropTypes from 'prop-types'

import { ErrorComponent } from '@local/do-secundo-error'

export interface OOGlobals {
  devError?: any
  enabledFeatureFlags: string[]
  gatewayBaseUri: string
  toastwebBaseUri: string
  restaurantGuid: string
  routerBasename: string
  shortUrl: string
  mode: string
  managementGroupGuid: string
  session?: Session
}

export interface Session {
  id: string
  issuedAt: number
  expiresAt: number
}

// @ts-ignore
const OOGlobalsContext = React.createContext<OOGlobals>({})

export const OOGlobalsProvider = ({
  devError,
  enabledFeatureFlags,
  gatewayBaseUri,
  toastwebBaseUri,
  restaurantGuid,
  routerBasename,
  shortUrl,
  managementGroupGuid,
  mode,
  session,
  children
}: OOGlobals & { children: React.ReactNode }) => {
  return (
    <OOGlobalsContext.Provider
      value={{
        devError,
        enabledFeatureFlags,
        gatewayBaseUri,
        toastwebBaseUri,
        restaurantGuid,
        routerBasename,
        shortUrl,
        managementGroupGuid,
        mode,
        session
      }}
    >
      {children}
    </OOGlobalsContext.Provider>
  )
}

export const OOGlobalsConsumer: React.FC<{
  children: (context: OOGlobals) => React.ReactNode
}> = ({ children }) => (
  <OOGlobalsContext.Consumer>
    {(context) => (
      <>
        {context.devError && (
          <ErrorComponent
            error={context.devError}
            isActionable={false}
            retry={() => {}}
          />
        )}
        {children(context)}
      </>
    )}
  </OOGlobalsContext.Consumer>
)

OOGlobalsConsumer.propTypes = {
  children: PropTypes.func.isRequired
}

export const useOOGlobals = () => React.useContext(OOGlobalsContext)
