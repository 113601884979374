import React from 'react'
import { Modal, ModalBody, ModalHeader } from '@toasttab/buffet-pui-modal'

import styles from '../auth/auth.module.css'
import cx from 'classnames'
import { ConfirmCodeForm } from '../auth/ConfirmCode'

export type onCompleteProps = {
  identity: string
  code: string
  setError: (err: string) => void
}

type ModalProps = {
  onComplete: ({ identity, code, setError }: onCompleteProps) => Promise<any>
  identity: string
  sendCode?: () => void
  isOpen: boolean
  onClose: () => void
}

export const ConfirmationCodeModal = ({
  onComplete,
  identity,
  sendCode,
  isOpen,
  onClose
}: ModalProps) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <ModalHeader />
      <ModalBody className={cx(styles.modalContentV2, styles.fullScreenMobile)}>
        <div className={cx(styles.body, 'mb-4')}>
          <div className={styles.headerContent}>
            <h2 className={styles.title} id='code-confirmation-modal-title'>
              Enter verification
            </h2>
            <p className={styles.subtitle}>{`Code sent to ${identity}`}</p>
          </div>

          <ConfirmCodeForm
            identity={identity}
            onComplete={onComplete}
            sendCode={sendCode}
          />
        </div>
      </ModalBody>
    </Modal>
  )
}
