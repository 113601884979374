import React from 'react'
import { BaseSchema, array, object, string } from 'yup'
import { EventType, FormField } from '../../types/form'
import { RenderFormField } from './RenderFormField'
import { InitialValuesArgs } from '../CheckoutForm/utils'

interface Props {
  formFields: FormField[]
}

export const DynamicFormSection: React.FC<Props> = ({ formFields }) => {
  return (
    <div>
      {formFields.map((field) => {
        return (
          <div className='mb-4' key={field.guid}>
            <RenderFormField
              type={field.type}
              label={field.label}
              name={field.guid}
              options={field.options}
              isRequired={field.required}
            />
          </div>
        )
      })}
    </div>
  )
}

export const getInitialValues = ({ formFields }: InitialValuesArgs) => {
  return formFields.reduce((acc, field) => {
    if (field.type === 'MULTI_SELECT') {
      acc[field.guid] = []
    } else {
      acc[field.guid] = ''
    }
    return acc
  }, {} as Record<string, string | string[]>)
}

export const getValidationSchema = ({
  formFields
}: {
  formFields: FormField[]
}) => {
  return formFields.reduce((acc, field) => {
    let schema: BaseSchema = string().trim()

    if (field.type === 'MULTI_SELECT') {
      schema = field.required
        ? array().min(1, 'Please select at least one item')
        : array()
    } else if (field.type === 'TIME') {
      schema = object()
    }

    schema = field.required ? schema.required('Required') : schema.notRequired()

    acc[field.guid] = schema
    return acc
  }, {} as Record<string, BaseSchema>)
}

export const getArgsForSubmit = (
  eventType: EventType,
  values: Record<string, any>
) => {
  return eventType.formFields
    ?.filter((field) => values[field.guid] !== '')
    .map((field) => {
      const value = values[field.guid]

      let finalValue = value
      if (field.type === 'TIME') {
        finalValue = `${value.hours}:${value.minutes || '00'}${
          value.isPm ? 'pm' : 'am'
        }`
      }

      if (field.type === 'MULTI_SELECT') {
        finalValue = JSON.stringify(value)
      }

      return {
        eventTypeFormFieldGuid: field.guid,
        value: finalValue
      }
    })
}
