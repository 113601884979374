import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const ShareArrowIcon = React.forwardRef(function ShareArrowIcon(
  props: IconProps,
  ref?: React.ForwardedRef<HTMLElement>
) {
  return (
    <IconWrapper ref={ref} {...props}>
      <>
        {
          <svg
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
            className={cx(
              'inline-block align-middle',
              sizes[props.size || 'sm'].className
            )}
          >
            <path
              d='M7.223 5.041h-2.01c-1.207 0-2.213 1-2.213 2.2v10.7c0 1.2 1.006 2.2 2.212 2.2h12.57c1.207 0 2.212-1 2.212-2.2v-1.8m-3.921-9.8c.2 0 .301-.2.301-.3v-1.9c0-.2.302-.4.402-.2l3.922 4c.403.4.403 1.1 0 1.5l-3.922 3.8c-.2.2-.402 0-.402-.2v-2c0-.2-.1-.3-.301-.3-1.308 0-6.235.6-8.95 3.5 0 .1.503-7 8.95-7.9z'
              stroke='currentColor'
              strokeWidth={sizes[props.size || 'sm'].strokeWidth}
              strokeMiterlimit={10}
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        }
      </>
    </IconWrapper>
  )
})
