import React, { ReactElement, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import classnames from 'classnames'
import InputMessage from './InputMessage'

import styles from './auth.module.css'

type InputFieldProps = {
  id: string
  label?: string
  required?: boolean
  validate?: (value: string) => boolean | string | undefined
  warn?: (value: string) => string | undefined
  warning?: string
  focusMessage?: string
  children?: ReactElement
} & React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>

const InputField: React.FC<InputFieldProps> = ({
  id,
  label,
  required,
  validate,
  warn,
  warning,
  focusMessage,
  disabled,
  children,
  ...rest
}) => {
  const {
    register,
    formState: { errors }
  } = useFormContext()
  const [focus, setFocus] = useState(false)

  const autocomplete = (id: string) => {
    switch (id) {
      case 'firstName':
        return 'given-name'
      case 'lastName':
        return 'family-name'
      case 'emailAddress':
        return 'email'
      default:
        return ''
    }
  }

  const sanitizedId = id.replace(/['"\[\]]/g, '.')
  return (
    <div
      className={classnames(
        styles.inputField,
        styles.formInputContainer,
        errors[id] && styles.inputError,
        (disabled || rest.readOnly) && styles.inputDisabled
      )}
      data-testid={`form-input-${sanitizedId}`}
    >
      <div className={styles.inputFieldInner}>
        {children ? (
          children
        ) : (
          <label
            htmlFor={sanitizedId}
            data-testid={`input-label-${sanitizedId}`}
          >
            <input
              id={sanitizedId}
              aria-required={required}
              aria-describedby={`${sanitizedId}-input-message`}
              data-testid={`input-${sanitizedId}`}
              autoComplete={autocomplete(sanitizedId)}
              onFocus={() => setFocus(true)}
              placeholder=' '
              disabled={disabled}
              {...register(sanitizedId, {
                required: required ? 'required' : false,
                validate,
                onBlur: () => setFocus(false)
              })}
              {...rest}
            />
            <span>{label}</span>
          </label>
        )}
      </div>
      <InputMessage
        id={sanitizedId}
        warn={warn}
        warning={warning}
        focus={focus}
        focusMessage={focusMessage}
      />
    </div>
  )
}

export default InputField
