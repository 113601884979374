import React, { ReactNode, useCallback, useMemo, useState } from 'react'
import { FormProvider, useForm, UseFormReturn, useWatch } from 'react-hook-form'

import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js'

import { Modal, ModalBody, ModalHeader } from '@toasttab/buffet-pui-modal'
import { AuthenticationSource } from './auth'
import { CompleteProfileForm } from './CompleteProfileForm'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { ArrowBackIcon } from '@toasttab/buffet-pui-icons'
import { useCustomerAuth } from './CustomerAuthContext'
import { alertSuccess } from './alertUtils'
import PhoneInput from './PhoneInput'
import { ConfirmCodeForm, onCompleteProps } from './ConfirmCode'
import { CAInfo, ToastTOSAndPrivacy } from './ToastTOSAndPrivacy'
import { useRestaurant } from '@local/do-secundo-restaurant-provider/src'
import { asValidPhoneCountryCode } from './phoneUtils'

import styles from './auth.module.css'
import { ToastLogo } from './ToastLogo'
import { Loading, LoadingVariant } from '@local/do-secundo-loading'
import cx from 'classnames'

type FormInputs = {
  yourInfoPhone: string
}

export enum LoginStage {
  EnterPhone = 'EnterPhone',
  EnterVerificationCode = 'EnterVerificationCode',
  CompleteProfile = 'CompleteProfile'
}

export type AccountCreationFormInputs = {
  email?: string
  firstName?: string
  lastName?: string
  phoneNumber: string
}

export const PwlessAuthModal = ({
  isOpen,
  onClose,
  source,
  skipLoginToast,
  onSuccessfulLogin,
  contentClassname,
  currentLoginStage = LoginStage.EnterPhone,
  accountInfo,
  showBackButton = true,
  secondaryCTAText,
  secondaryContent,
  customLoginHeader
}: {
  isOpen: boolean
  onClose: () => void
  source: AuthenticationSource
  skipLoginToast?: boolean
  onSuccessfulLogin?: () => void
  contentClassname?: string
  currentLoginStage?: LoginStage
  accountInfo?: AccountCreationFormInputs
  showBackButton?: boolean
  secondaryCTAText?: string
  secondaryContent?: ReactNode
  customLoginHeader?: string
}) => {
  const [loginStage, setLoginStage] = useState(currentLoginStage)
  const formMethods = useForm<FormInputs>({
    mode: 'onTouched',
    defaultValues: { yourInfoPhone: accountInfo?.phoneNumber || '' }
  })
  const phoneNumber = useWatch({
    name: 'yourInfoPhone',
    control: formMethods.control
  })
  const [showSecondaryContent, setShowSecondaryContent] = useState(false)
  const withBackArrow = useMemo(
    () =>
      (loginStage != LoginStage.EnterPhone && showBackButton) ||
      showSecondaryContent,
    [loginStage, showBackButton, showSecondaryContent]
  )

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <ModalHeader
        data-testid='PwlessAuth'
        aria-label='pwless-auth-modal-header'
      >
        {withBackArrow && (
          <div className={'-mt-3 -ml-3'}>
            <IconButton
              icon={
                <ArrowBackIcon size={'md'} aria-label='Return to phone input' />
              }
              iconColor='secondary'
              onClick={() => {
                if (showSecondaryContent) {
                  setShowSecondaryContent(false)
                } else {
                  setLoginStage(LoginStage.EnterPhone)
                  formMethods.reset({ yourInfoPhone: '' })
                }
              }}
            />
          </div>
        )}
      </ModalHeader>
      <ModalBody
        className={cx(
          styles.modalContentV2,
          styles.fullScreenMobile,
          contentClassname,
          loginStage === LoginStage.CompleteProfile
            ? styles.completeProfile
            : '',
          secondaryCTAText ? styles.secondaryCTA : ''
        )}
      >
        {showSecondaryContent ? (
          secondaryContent
        ) : (
          <div className={styles.body}>
            {loginStage === LoginStage.CompleteProfile ? (
              <CompleteProfileForm
                accountInfo={accountInfo ?? { phoneNumber }}
                onSuccessfulLogin={onSuccessfulLogin}
                skipLoginToast={skipLoginToast}
                onClose={onClose}
              />
            ) : (
              <LoginForm
                formMethods={formMethods}
                loginStage={loginStage}
                setLoginStage={setLoginStage}
                source={source}
                onSuccessfulLogin={onSuccessfulLogin}
                customHeader={customLoginHeader}
                secondaryCTA={
                  secondaryCTAText ? (
                    <button
                      type='button'
                      className={cx(styles.cta, styles.secondary)}
                      onClick={() => setShowSecondaryContent(true)}
                    >
                      <div className={styles.text}>{secondaryCTAText}</div>
                    </button>
                  ) : undefined
                }
                skipLoginToast={skipLoginToast ?? false}
                onClose={onClose}
              />
            )}
          </div>
        )}
      </ModalBody>
    </Modal>
  )
}

const LoginForm = ({
  formMethods,
  loginStage,
  setLoginStage,
  source,
  skipLoginToast = false,
  onSuccessfulLogin,
  secondaryCTA,
  customHeader,
  onClose
}: {
  formMethods: UseFormReturn<FormInputs>
  loginStage: LoginStage
  setLoginStage: (loginStage: LoginStage) => void
  source: AuthenticationSource
  skipLoginToast: boolean
  onSuccessfulLogin?: () => void
  secondaryCTA?: ReactNode
  customHeader?: string
  onClose: () => void
}) => {
  const {
    customer,
    passwordlessLogin,
    fetchCustomer,
    completeSignup,
    passwordlessConfirmCode
  } = useCustomerAuth()
  const [error, setError] = useState('')
  const [verificationLoading, setVerificationLoading] = useState(false)

  const { restaurantInfo } = useRestaurant()

  const country = asValidPhoneCountryCode(restaurantInfo?.address?.country)
  const phoneNumber = useWatch({
    name: 'yourInfoPhone',
    control: formMethods.control
  })
  const parsedPhoneNumber = useMemo(
    () =>
      isValidPhoneNumber(phoneNumber, country)
        ? parsePhoneNumber(phoneNumber, country)
        : null,
    [country, phoneNumber]
  )

  const validatePhone = useCallback(
    (value: string) =>
      // Don't use the memoized parsedPhoneNumber declared above because this is a callback to be passed for form validation
      isValidPhoneNumber(value, country) || 'Enter a valid local phone number',
    [country]
  )

  const handlePhoneInputKeydown = async (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      await onSubmitPhone()
      event.stopPropagation()
      event.preventDefault()
    }
  }

  const onSubmitPhone = useCallback(async () => {
    if (!parsedPhoneNumber) return

    setVerificationLoading(true)
    const success = await passwordlessLogin(parsedPhoneNumber.number)
    setVerificationLoading(false)

    if (success) {
      setError('')
      setLoginStage(LoginStage.EnterVerificationCode)
    } else {
      setError('Error sending confirmation code')
    }
  }, [
    setVerificationLoading,
    setLoginStage,
    passwordlessLogin,
    parsedPhoneNumber,
    source
  ])

  const onLogin = useCallback(async () => {
    const fetchedCustomer = await fetchCustomer()
    if (!fetchedCustomer) {
      setLoginStage(LoginStage.CompleteProfile)
    } else {
      onSuccessfulLogin?.()
      onClose()
      if (!skipLoginToast)
        alertSuccess(
          fetchedCustomer?.firstName
            ? `Welcome back, ${fetchedCustomer?.firstName}!`
            : 'Welcome back!'
        )
      await completeSignup(
        fetchedCustomer?.email || '',
        fetchedCustomer?.firstName || '',
        fetchedCustomer?.lastName || ''
      )
    }
  }, [
    fetchCustomer,
    setLoginStage,
    onSuccessfulLogin,
    onClose,
    skipLoginToast,
    source,
    completeSignup
  ])

  const onConfirmCodeComplete = useCallback(
    async ({ identity, code, setError }: onCompleteProps) => {
      const response = await passwordlessConfirmCode(identity, code)
      if (response) {
        await onLogin()
      } else {
        setError('The code you entered was incorrect. Please try again.')
      }
    },
    [onLogin, passwordlessConfirmCode]
  )

  if (verificationLoading) {
    return <Loading variant={LoadingVariant.SECONDARY} />
  }

  let verificationMessage: string
  if (loginStage === LoginStage.EnterVerificationCode) {
    const phoneString = parsedPhoneNumber
      ? parsedPhoneNumber.formatNational()
      : phoneNumber
    verificationMessage = `Code sent to ${phoneString}`
  } else {
    verificationMessage = "We'll send you a code to log in or sign up."
  }

  return (
    <FormProvider {...formMethods}>
      <div className={styles.headerContent}>
        <div className={styles.title} id='pwless-auth-modal-header'>
          {customHeader ??
            (loginStage === LoginStage.EnterVerificationCode
              ? 'Enter verification'
              : 'Enter your phone number')}
        </div>
        <p className={styles.subtitle}>{verificationMessage}</p>
      </div>
      <div id='pwlessauthcontent'>
        {loginStage === LoginStage.EnterVerificationCode &&
        parsedPhoneNumber ? (
          <ConfirmCodeForm
            identity={parsedPhoneNumber.number}
            onComplete={onConfirmCodeComplete}
            sendCode={onSubmitPhone}
          />
        ) : (
          <div className={styles.phoneForm}>
            <PhoneInput
              id='yourInfoPhone'
              aria-labelledby='pwlessauthcontent'
              required
              label='Phone number'
              validate={validatePhone}
              autoComplete='tel'
              defaultValue={customer?.phone || ''}
              warning={error}
              filled={Boolean(customer?.phone)}
              onKeyDown={handlePhoneInputKeydown}
              unregisterOnUnmount={false}
              shouldUnFocus={false}
              initialFocus={true}
              isSingletonField={true}
            />
            <button
              type='button'
              className={cx(styles.cta, styles.submitPhoneButton)}
              onClick={onSubmitPhone}
              disabled={!parsedPhoneNumber}
            >
              <div className={styles.text}>
                <span>Continue with</span>
                <ToastLogo />
              </div>
            </button>
            {secondaryCTA}
          </div>
        )}
      </div>
      <p className={styles.legal} role='contentinfo'>
        {getLegalText(false, loginStage)}
      </p>
    </FormProvider>
  )
}

const getLegalText = (isIntlRestaurant: boolean, loginStage: LoginStage) => {
  return loginStage === LoginStage.EnterPhone ? (
    <>
      By providing a mobile number, you give Toast permission to send you a
      one-time verification code. Message and data rates may apply. Subject to{' '}
      <ToastTOSAndPrivacy />. {!isIntlRestaurant && <CAInfo />}
    </>
  ) : (
    <>
      By continuing, your name and email address may be shared with this
      restaurant/restaurant group. Subject to <ToastTOSAndPrivacy /> and
      Merchant&apos;s Terms and Policies apply.{' '}
      {!isIntlRestaurant && <CAInfo />}
    </>
  )
}
