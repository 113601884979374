import React, { useState, useRef, useCallback, useEffect } from 'react'
import styles from './Spotlight.module.css'
import { Modal, ModalBody, ModalHeader } from '@toasttab/buffet-pui-modal'
import { AnnouncementIcon } from '@toasttab/buffet-pui-icons'
import { useGetWindowSize } from '@local/do-secundo-use-get-window-size'
import { SpotlightConfig } from '../../../types/config'
import { useRedirectToFulfillment } from '../../../hooks/useRedirectToFullfilment'

interface Props {
  config?: SpotlightConfig
  showModal: boolean
  setShowModal: (modalShown: boolean) => void
}

const validateAndFormatURL = (url?: string): string | undefined => {
  if (!url) {
    // If no URL is provided
    return undefined
  }
  try {
    // Try to parse the URL for validation
    new URL(url)
    return url
  } catch (e) {
    // If the url is invalid, assume it's just a domain name and prepend http://
    return `http://${url}`
  }
}

export const Spotlight = ({ config, showModal, setShowModal }: Props) => {
  const { shouldShowFulfillmentModal, redirectToFulfillment } =
    useRedirectToFulfillment()

  const [didClickToClose, setDidClickToClose] = useState(false)

  const openModal = () => setShowModal(true)
  const closeModal = () => {
    if (
      config?.modalOpenByDefault &&
      !didClickToClose &&
      shouldShowFulfillmentModal
    ) {
      setDidClickToClose(true)
      redirectToFulfillment()
    }
    setShowModal(false)
  }

  const [isOverflowing, setIsOverflowing] = useState(showModal)
  const containerRef = useRef(null)

  const setOverflowingOnResize = useCallback(() => {
    if (containerRef.current) {
      const { clientWidth, scrollWidth } = containerRef.current
      setIsOverflowing(scrollWidth > clientWidth)
    }
  }, [containerRef])

  useEffect(() => {
    setOverflowingOnResize()
    window.addEventListener('resize', setOverflowingOnResize)
    return () => window.removeEventListener('resize', setOverflowingOnResize)
  }, [setOverflowingOnResize, containerRef])

  const { isMobile } = useGetWindowSize()

  if (!config || !config.enabled || !config.headerText) return null

  const { headerText, bodyText } = config
  const url = validateAndFormatURL(config.url)

  return (
    <>
      <header className={styles.spotlight} role='banner'>
        <AnnouncementIcon accessibility='decorative' />
        <p
          data-testid='spotlight-header-content'
          className={styles.text}
          ref={containerRef}
        >
          {headerText}
        </p>
        {(bodyText || url || isOverflowing) && (
          <button
            data-testid='SpotlightMoreButton'
            className={styles.moreLink}
            onClick={openModal}
          >
            More
          </button>
        )}
      </header>
      <Modal
        testId='SpotlightModal'
        position={isMobile ? 'pin-bottom' : 'pin-center'}
        size='xl'
        isOpen={showModal}
        onRequestClose={closeModal}
      >
        <ModalHeader />
        <ModalBody className={styles.modalBody}>
          <p className={styles.header}>{headerText}</p>
          <p className={styles.body}>{bodyText}</p>
          {url && (
            <a
              className='text-link w-full text-center break-all mb-4'
              target='_blank'
              rel='noopener noreferrer'
              href={url}
            >
              {url}
            </a>
          )}
        </ModalBody>
      </Modal>
    </>
  )
}
