import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'

import {
  useGiftCardEnabled,
  useGiftCardTipEnabled
} from '../IfGiftCard/use-gift-card-enabled'

import {
  OptRestaurantGiftCard,
  OptGlobalGiftCard,
  useGet_Gift_Card_BalanceLazyQuery
} from '../../apollo/generated/OptWebGraphQLOperations'
import {
  Opt_GuestCurrencyAccountPaymentInput,
  useGuestWalletQuery
} from '@toasttab/do-federated-gateway-apollo/generated/DoFederatedGatewayGraphQLOperations'
import { useFlag } from '../FeatureFlag/use-flag'
import { useRestaurant } from '../RestaurantProvider/RestaurantProvider'
import { useAuth } from '../AuthProvider/AuthProvider'
import { doFederatedApolloClient } from '../../apollo/do-federated-apollo-client'
import { LDFlags } from '../../launchdarkly/flags'

const noop = () => {}

export interface GiftCardContextTypeV2 {
  rxGiftCardEnabled: boolean
  rxGiftCardTipEnabled: boolean
  guestCurrencyAccountSpendEnabled: boolean
  rxGiftCard?: OptRestaurantGiftCard
  globalGiftCard?: OptGlobalGiftCard
  guestCurrencyAccount?: Opt_GuestCurrencyAccountPaymentInput
  shouldUseGuestCurrencyAccount: boolean
  applyGiftCard(): void
  clearRxGiftCard(): void
  clearGlobalGiftCard(): void
  applyGuestCurrencyAccount(): void
  removeGuestCurrencyAccount(): void
  error?: { message: string }
  loading: boolean
}

const emptyGiftCardContextV2: GiftCardContextTypeV2 = {
  rxGiftCardEnabled: false,
  rxGiftCardTipEnabled: false,
  guestCurrencyAccountSpendEnabled: false,
  rxGiftCard: undefined,
  globalGiftCard: undefined,
  guestCurrencyAccount: undefined,
  shouldUseGuestCurrencyAccount: false,
  applyGiftCard: noop,
  clearRxGiftCard: noop,
  clearGlobalGiftCard: noop,
  applyGuestCurrencyAccount: noop,
  removeGuestCurrencyAccount: noop,
  error: undefined,
  loading: false
}

const GiftCardContextV2 = React.createContext<GiftCardContextTypeV2>(
  emptyGiftCardContextV2
)

export const GiftCardProvider: React.FC = ({ children }) => {
  const rxGiftCardEnabled = useGiftCardEnabled()
  const rxGiftCardTipEnabled = useGiftCardTipEnabled()
  const guestCurrencyAccountSpendEnabled = useFlag(
    LDFlags.OPT_SPEND_GUEST_CURRENCY_WEB
  )
  const [rxGiftCard, setRxGiftCard] =
    useState<GiftCardContextTypeV2['rxGiftCard']>(undefined)
  const [globalGiftCard, setGlobalGiftCard] =
    useState<GiftCardContextTypeV2['globalGiftCard']>(undefined)
  const [giftCardError, setGiftCardError] =
    useState<GiftCardContextTypeV2['error']>(undefined)
  const [guestCurrencyAccount, setGuestCurrencyAccount] =
    useState<GiftCardContextTypeV2['guestCurrencyAccount']>(undefined)
  const [shouldUseGuestCurrencyAccount, setShouldUseGuestCurrencyAccount] =
    useState<GiftCardContextTypeV2['shouldUseGuestCurrencyAccount']>(false)

  const applyGuestCurrencyAccount = useCallback(() => {
    setShouldUseGuestCurrencyAccount(true)
  }, [])
  const removeGuestCurrencyAccount = useCallback(() => {
    setShouldUseGuestCurrencyAccount(false)
  }, [])
  const clearRxGiftCard = useCallback(() => {
    setRxGiftCard(undefined)
    setGiftCardError(undefined)
  }, [])
  const clearGlobalGiftCard = useCallback(() => {
    setGlobalGiftCard(undefined)
    setGiftCardError(undefined)
  }, [])

  const [applyGiftCard, giftCardResponse] = useGet_Gift_Card_BalanceLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      if (data?.optGetGiftCardBalance?.globalGiftCard) {
        setGlobalGiftCard(data.optGetGiftCardBalance.globalGiftCard)
      } else if (data?.optGetGiftCardBalance?.restaurantGiftCard) {
        setRxGiftCard(data.optGetGiftCardBalance.restaurantGiftCard)
      } else {
        setGiftCardError({
          message: 'No valid Toast Cash or gift card found. Try again.'
        })
      }
    },
    onError() {
      clearRxGiftCard()
      clearGlobalGiftCard()
    }
  })
  const { restaurantGuid } = useRestaurant()
  const hasCustomer = Boolean(useAuth().authenticated)
  useGuestWalletQuery({
    skip: !guestCurrencyAccountSpendEnabled || !hasCustomer || !restaurantGuid,
    variables: {
      restaurantId: restaurantGuid
    },
    client: doFederatedApolloClient,
    // ensures fresh values if component re-mounts
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      if (
        data?.guest.wallet.guestCurrencyAccount.availableBalanceByRestaurant
          .amount
      ) {
        setGuestCurrencyAccount({
          expectedAvailableBalance: Number(
            data.guest.wallet.guestCurrencyAccount.availableBalanceByRestaurant
              .amount
          )
        })
      }
    }
  })

  const context: GiftCardContextTypeV2 = {
    rxGiftCardEnabled,
    rxGiftCardTipEnabled,
    guestCurrencyAccountSpendEnabled,
    rxGiftCard,
    globalGiftCard,
    guestCurrencyAccount,
    shouldUseGuestCurrencyAccount,
    applyGiftCard,
    clearRxGiftCard,
    clearGlobalGiftCard,
    applyGuestCurrencyAccount,
    removeGuestCurrencyAccount,
    error: giftCardError,
    loading: giftCardResponse.loading
  }

  return (
    <GiftCardContextV2.Provider value={context}>
      {children}
    </GiftCardContextV2.Provider>
  )
}

GiftCardProvider.propTypes = {
  children: PropTypes.node
}

export const useGiftCard = () => {
  return React.useContext(GiftCardContextV2)
}
