import React, { useState } from 'react'

import { Image } from '../components/Image/Image'
import { useCustomerAuth } from '../auth/CustomerAuthContext'
import AnimatedSection from '../auth/AnimatedSection'
import { usePayment } from './PaymentContext'
import { CreditCardRow } from './CreditCardRow'
import SaveCardCheckbox from './SaveCardCheckbox'

export const getCreditCardImage = (cardType?: string) => {
  switch (cardType) {
    case 'VISA':
      return (
        <Image
          className='cardLogo'
          alt='Visa icon'
          path='icons/visa-logo.svg'
        />
      )
    case 'MASTERCARD':
      return (
        <Image
          className='cardLogo'
          alt='Mastercard icon'
          path='icons/mastercard-logo.svg'
        />
      )
    case 'AMEX':
      return (
        <Image
          className='cardLogo'
          alt='American Express icon'
          path='icons/amex-logo.svg'
        />
      )
    case 'DISCOVER':
      return (
        <Image
          className='cardLogo'
          alt='Discover icon'
          path='icons/discover-logo.svg'
        />
      )
    case 'JCB':
      return (
        <Image className='cardLogo' alt='JCB icon' path='icons/jcb-logo.svg' />
      )
    case 'DINERS':
      return (
        <Image
          className='cardLogo'
          alt='Diners icon'
          path='icons/diners-club-logo.svg'
        />
      )
    case 'MAESTRO':
      return (
        <Image
          className='cardLogo'
          alt='Maestro icon'
          path='icons/maestro-logo.svg'
        />
      )
    default:
      return (
        <Image
          className='cardLogo'
          alt='Credit card icon'
          path='icons/credit-card-gray.svg'
        />
      )
  }
}

// export const SavedCreditCards = () => {
//   const { customer } = useCustomerAuth()
//   const { newCreditCard } = usePayment()
//   const [errorMsg, setErrorMsg] = useState('')
//
//   if (!customer?.creditCards && !newCreditCard) {
//     return null
//   }
//
//   return (
//     <div className='savedCreditCards'>
//       <hr />
//       {[...(customer?.creditCards || [])]
//         .sort((a, b) => {
//           if (a.isPrimary) return -1
//           if (b.isPrimary) return 1
//           return a.guid > b.guid ? 1 : -1
//         })
//         .map((card) => (
//           <CreditCardRow key={card.guid} {...card} onError={setErrorMsg} />
//         ))}
//       <AnimatedSection expanded={Boolean(newCreditCard)}>
//         <CreditCardRow
//           encryptionKeyId={newCreditCard?.encryptionKeyId}
//           maskedPan={`XXXX-${newCreditCard?.cardLast4}`}
//           expirationMonth={newCreditCard?.expMonth}
//           expirationYear={newCreditCard?.expYear}
//           isPrimary={false}
//           onError={setErrorMsg}
//         />
//         {customer && <SaveCardCheckbox />}
//       </AnimatedSection>
//       <AnimatedSection expanded={!newCreditCard}>
//         <div className='addCard'>
//           <CreditCardModal>
//             <div className='plusSign'>
//               <Image alt='New card' path='icons/plus.svg' />
//             </div>
//             <span>Use a different card</span>
//           </CreditCardModal>
//         </div>
//       </AnimatedSection>
//       {errorMsg && <div className='submitError'>{errorMsg}</div>}
//     </div>
//   )
// }
//
// export default SavedCreditCards
