import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const CheckCircleSelectedDisplayIcon = React.forwardRef(
  function CheckCircleSelectedDisplayIcon(
    props: IconProps,
    ref?: React.ForwardedRef<HTMLElement>
  ) {
    return (
      <IconWrapper ref={ref} {...props}>
        <>
          {
            <svg
              width={64}
              height={64}
              viewBox='0 0 64 64'
              fill='none'
              className={cx(
                'inline-block align-middle',
                sizes[props.size || 'xl'].className
              )}
            >
              <path
                d='M32 56c13.255 0 24-10.745 24-24S45.255 8 32 8 8 18.745 8 32s10.745 24 24 24z'
                stroke='#5069C3'
                strokeWidth={sizes[props.size || 'xl'].displayStrokeWidth}
                strokeMiterlimit={10}
              />
              <path
                d='M21.3 33.1l7.6 8.3 13.8-18.7'
                stroke='#FF4C00'
                strokeWidth={sizes[props.size || 'xl'].displayStrokeWidth}
                strokeMiterlimit={10}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          }
        </>
      </IconWrapper>
    )
  }
)
