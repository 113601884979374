import React, { useCallback, useState } from 'react'
import { alertError, alertSuccess } from '../auth/alertUtils'
import { useCustomerAuth } from '../auth/CustomerAuthContext'
import { useApolloClient } from '@apollo/client'
import { Button } from '@toasttab/buffet-pui-buttons'
import { DeleteAccountModal } from './DeleteAccountModal'
import { useDeleteAccountMutation } from './account-mutations'

const toastFail = () => {
  alertError('There was an error while saving. Please refresh the page.')
}

const toastSuccess = () => {
  alertSuccess('Your account has been deleted')
}

export type ProfileSettingsProps = {
  closeAccountModal: () => void
}

export const ProfileFooter = ({ closeAccountModal }: ProfileSettingsProps) => {
  const { pwlessLogout } = useCustomerAuth()
  const [deleteAccount] = useDeleteAccountMutation()
  const apolloClient = useApolloClient()

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const accountDelete = useCallback(async () => {
    try {
      const { data } = await deleteAccount({ client: apolloClient })
      if (data?.deleteAccount.__typename === 'DeleteAccountResponse') {
        const success = await pwlessLogout()
        if (success) {
          toastSuccess()
        } else {
          toastFail()
        }
      }
    } catch (err) {
      toastFail()
    } finally {
      closeAccountModal()
    }
  }, [deleteAccount, closeAccountModal, pwlessLogout, apolloClient])

  return (
    <>
      <Button
        variant={'destructive-secondary'}
        onClick={() => setIsDeleteModalOpen(true)}
      >
        Delete this account
      </Button>
      <DeleteAccountModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onDelete={accountDelete}
      />
      <Button onClick={closeAccountModal}>Close</Button>
    </>
  )
}
