import React, { FC, useEffect } from 'react'
import { useCustomerAuth } from '../auth/CustomerAuthContext'
import ProfileInformation from './ProfileInformation'

import { ProfileWallet } from './ProfileWallet'
import styles from './account.module.css'

const Account: FC = () => {
  const { customer, refetchCustomer } = useCustomerAuth()
  // const { restaurantInfo } = useRestaurant()
  // const address = restaurantInfo?.address

  // refetch the customer to get the latest data
  useEffect(() => {
    refetchCustomer()
  }, [refetchCustomer])

  if (!customer) {
    return <>Account not found. Try refreshing the page and logging in again</>
  }

  return (
    <div data-testid='Account'>
      <ProfileInformation />
      <div className={styles.sectionSeparatorWrapper}>
        <hr className={styles.sectionSeparator} />
      </div>
      {/*TODO - there is a bug in OO's address section - will come back to this*/}
      {/*once they resolve it*/}
      {/*<div className={styles.addressSection}>*/}
      {/*  <ProfileAddresses*/}
      {/*    selectedLocationLat={address?.latitude}*/}
      {/*    selelctedLocationLong={address?.longitude}*/}
      {/*  />*/}
      {/*</div>*/}
      <ProfileWallet />
    </div>
  )
}

export default Account
