import React, { useEffect, useState } from 'react'
import { Button } from '@toasttab/buffet-pui-buttons'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'

import {
  defaultStateStrings,
  getStates
} from '@toasttab/buffet-pui-country-utilities'
import { Label } from '@toasttab/buffet-pui-input-base'
import { HelperText } from '@toasttab/buffet-pui-text-base'
import { useTaxExempt } from './useTaxExempt'
import { Cart } from '../../../types/cart'
import { TextInput } from '@toasttab/buffet-pui-text-input'
import { Select } from '@toasttab/buffet-pui-select'
import { TailSpin } from 'react-loader-spinner'

export const TaxExempt = ({ cart }: { cart: Cart }) => {
  const [isTaxExempt, setIsTaxExempt] = useState(false)
  const [taxExemptRequest, setTaxExemptRequest] = useState<{
    taxExemptId?: string
    taxExemptState?: string
  }>({})

  useEffect(() => {
    if (cart?.order.checks[0].taxExemptionAccount) {
      setIsTaxExempt(true)
      setTaxExemptRequest({
        taxExemptId: cart?.order.checks[0].taxExemptionAccount?.number,
        taxExemptState: cart?.order.checks[0].taxExemptionAccount?.state
      })
    }
  }, [cart.order.checks[0].taxExemptionAccount])

  const { applyTaxExempt, isLoading, errorMessage } = useTaxExempt(cart.guid)

  const { restaurantInfo, ooConfig } = useRestaurant()

  if (!ooConfig?.taxExemptEnabled) return null

  const country = restaurantInfo?.address?.country

  if (country !== 'US' && country !== 'CA') {
    return null
  }

  const states = getStates(country, defaultStateStrings)

  const toggleTaxExempt = async () => {
    if (isTaxExempt) {
      setTaxExemptRequest({})
      applyTaxExempt({})
    }
    setIsTaxExempt(!isTaxExempt)
  }

  const dirty =
    cart?.order.checks[0].taxExemptionAccount?.number !==
      taxExemptRequest.taxExemptId ||
    cart?.order.checks[0].taxExemptionAccount?.state !==
      taxExemptRequest.taxExemptState

  const needsApply =
    !taxExemptRequest.taxExemptId?.length ||
    !taxExemptRequest.taxExemptState?.length ||
    dirty

  const isValid =
    taxExemptRequest.taxExemptId?.length &&
    taxExemptRequest.taxExemptState?.length

  return (
    <div className='mb-4'>
      {isTaxExempt && (
        <div>
          <div>
            <Label>Tax Exempt Account</Label>
          </div>
          <div className={'flex flex-wrap'}>
            <TextInput
              value={taxExemptRequest.taxExemptId}
              testId='tax-exempt-input-field'
              label='ID'
              type='text'
              id='tax_exempt_id'
              key='tax-exempt-id'
              required
              maxLength={12}
              containerClassName={'pb-2 w-64 pr-4'}
              readOnly={isLoading}
              onChange={(e) => {
                setTaxExemptRequest({
                  ...taxExemptRequest,
                  taxExemptId: e.target.value
                })
              }}
            />
            <Select
              value={taxExemptRequest.taxExemptState}
              testId={'tax-exempt-state-select'}
              label={country === 'US' ? 'State' : 'Province'}
              options={states}
              required
              containerClassName={'w-52'}
              disabled={isLoading}
              onChange={(state) => {
                setTaxExemptRequest({
                  ...taxExemptRequest,
                  taxExemptState: state
                })
              }}
            />
            {isLoading ? (
              <TailSpin
                wrapperClass='mt-8 ml-4'
                color='#2b4fb9'
                width='24px'
                height='24px'
              />
            ) : (
              <Button
                testId={'apply-tax-exempt-button'}
                className={'mt-6 -mr-4'}
                variant={'link'}
                disabled={!needsApply || !isValid}
                onClick={() => {
                  applyTaxExempt(taxExemptRequest)
                }}
              >
                {needsApply ? 'Apply' : 'Applied'}
              </Button>
            )}
          </div>
          <HelperText
            helperText={`By providing a tax exempt ID you agree to provide any supporting documentation needed to ${
              restaurantInfo?.name ?? 'the restaurant'
            }.`}
          />
        </div>
      )}
      <Button
        testId='tax-exempt-button'
        variant='link'
        className='-ml-4'
        onClick={toggleTaxExempt}
      >
        {isTaxExempt ? 'Remove tax exempt account' : 'My order is tax exempt'}
      </Button>
      {errorMessage && (
        <div className='pt-2 text-caption text-color-error'>{errorMessage}</div>
      )}
    </div>
  )
}
