import React from 'react'
import { track } from '@toasttab/do-secundo-analytics'
import {
  ComponentV2 as LegacyPaymentInfo,
  PaymentInfoComponentV2Props
} from './ComponentV2'
import {
  MFEPaymentInfoComponent,
  MFEPaymentInfoComponentProps
} from './MFEPaymentInfoComponent/MFEPaymentInfoComponent'
import { OptPartyV2Fragment } from '../../../apollo/generated/OptWebGraphQLOperations'
import { useSpiAllowed } from '../../../hooks/SPI/useSpiAllowed'
import { PaymentInfoError } from './PaymentInfoError'
export { getInitialValues } from './getInitialValues'
export { getArgsForSubmit } from './getArgsForSubmit'
export { getValidationSchema } from './getValidationSchema'

export type ComponentProps = PaymentInfoComponentV2Props &
  MFEPaymentInfoComponentProps & {
    partyMode: OptPartyV2Fragment['mode']
  }

export const Component = (props: ComponentProps) => {
  const {
    paymentMethods,
    authenticated,
    creditCardConfig,
    giftCardHasSufficientFunds,
    mode,
    setFieldValue,
    values,
    collapseTabsSinglePaymentMethod,
    payAmount,
    shouldUseSpi,
    isSpiInitialized,
    setIsSpiInitialized,
    spiLoadedFailure,
    setSpiLoadedFailure
  } = props

  const spiAllowed = useSpiAllowed()

  React.useEffect(() => {
    if (spiAllowed && spiLoadedFailure) {
      track('SPI failed to load or initialize')
    }
  }, [spiLoadedFailure, spiAllowed])

  if (spiAllowed && spiLoadedFailure) {
    return <PaymentInfoError />
  }

  if (!spiAllowed) {
    return <MFEPaymentInfoComponent {...props} />
  }

  return (
    <LegacyPaymentInfo
      spiLoadedFailure={spiLoadedFailure}
      setSpiLoadedFailure={setSpiLoadedFailure}
      paymentMethods={paymentMethods}
      authenticated={authenticated}
      creditCardConfig={creditCardConfig}
      giftCardHasSufficientFunds={giftCardHasSufficientFunds}
      mode={mode}
      setFieldValue={setFieldValue}
      values={values}
      collapseTabsSinglePaymentMethod={collapseTabsSinglePaymentMethod}
      payAmount={payAmount}
      shouldUseSpi={shouldUseSpi}
      renderSpi={spiAllowed}
      isSpiInitialized={isSpiInitialized}
      setIsSpiInitialized={setIsSpiInitialized}
    />
  )
}
