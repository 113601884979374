import { setContext } from '@apollo/client/link/context'
import { getAuthenticationHeader } from '@toasttab/do-secundo-guest-authentication'

export const getGiaAuthLink = () =>
  setContext(async (_, { headers: originalHeaders }) => {
    const authHeader = await getAuthenticationHeader()
    const headers = authHeader ? { [authHeader.key]: authHeader.value } : {}

    return {
      headers: {
        ...originalHeaders,
        ...headers
      }
    }
  })

export const securityTokenHeaderLinkFactory = (sessionToken: string) =>
  setContext((_, { headers: originalHeaders }) => {
    return {
      headers: {
        ...originalHeaders,
        'Toast-Session-ID': sessionToken
      }
    }
  })
