import React, { useEffect } from 'react'
import { parseISO } from 'date-fns'
import { Formats } from '@toasttab/buffet-pui-date-utilities'

import { useAvailability } from '@local/do-secundo-availability-provider'
import {
  getDatesAndTimesFromResponse,
  useFulfillmentDateTimeValidator
} from '../fulfillment-helpers'
import { isSameISOTime } from '../../../utils/time-utils'

import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { formatHours } from '../../../utils/restaurant-schedule-helpers'
import {
  FulfillmentFormValues,
  FutureFulfillmentType
} from '../../../types/fulfillment'
import { FormikProps } from 'formik'
import { DiningOptionFulfillmentData } from '../../../hooks/useDiningOptions'
import { DatePicker } from '@toasttab/buffet-pui-date-picker'
import { Select } from '@toasttab/buffet-pui-select'
import { ScheduleIcon } from '@toasttab/buffet-pui-icons'
import { HelperText } from '@toasttab/buffet-pui-text-base'

const setTime = ({ data, date, time, diningOptionBehavior, setFieldValue }) => {
  // Select same time on different day if available, otherwise don't update time
  const { timeList: newTimes } = getDatesAndTimesFromResponse(
    data,
    date,
    diningOptionBehavior
  )

  if (
    newTimes &&
    newTimes.length &&
    !newTimes.some((timeSlot) => timeSlot.time === time)
  ) {
    let matchingTime = newTimes.find((timeSlot) =>
      isSameISOTime(timeSlot.time, time)
    )

    if (matchingTime) {
      setFieldValue('fulfillmentTime', matchingTime?.time)
    }
  }
}

interface Props {
  formik: FormikProps<FulfillmentFormValues>
  data: DiningOptionFulfillmentData[]
}

export const FulfillmentTimeSelector: React.FC<Props> = ({ formik, data }) => {
  const { setFieldValue, values } = formik
  const { diningOptionBehavior, fulfillmentDate: selectedDate } = values
  const { availability } = useAvailability()
  const { restaurantInfo, ooConfig, error, loading } = useRestaurant()
  const { timeList, unavailableDates } = getDatesAndTimesFromResponse(
    data,
    selectedDate,
    diningOptionBehavior
  )

  const { message: errorMsg } = useFulfillmentDateTimeValidator(
    diningOptionBehavior,
    selectedDate,
    values.fulfillmentTime
  )

  // When user switches between dining options,
  // automatically update date if same time is available
  useEffect(() => {
    setTime({
      data,
      date: selectedDate,
      time: values.fulfillmentTime,
      diningOptionBehavior,
      setFieldValue
    })
  }, [
    data,
    diningOptionBehavior,
    setFieldValue,
    selectedDate,
    values.fulfillmentTime
  ])

  if (loading || error) return null

  const timeZoneId = restaurantInfo?.timeZoneId

  const endDate = new Date()
  endDate.setDate(new Date().getDate() + (ooConfig?.scheduledOrderMaxDays ?? 0))

  return (
    <div>
      {availability &&
        diningOptionBehavior &&
        availability[diningOptionBehavior] && (
          <div>
            <div
              data-testid='fulfillment-selectors'
              id='fulfillment_selectors'
              className='flex w-full space-x-2'
            >
              <DatePicker
                label='Date'
                containerClassName='w-full'
                formatValue={Formats.date.shorter}
                fromDate={new Date()}
                toDate={endDate}
                value={
                  values.fulfillmentDate
                    ? parseISO(values.fulfillmentDate)
                    : undefined
                }
                onSelect={(selectedDate?: Date) => {
                  setFieldValue('fulfillmentDate', selectedDate?.toISOString())
                }}
                disabledDays={unavailableDates?.map((d) => parseISO(d))}
              />
              <Select
                testId={'time-selector'}
                label='Time'
                containerClassName='w-full'
                iconLeft={<ScheduleIcon />}
                options={
                  timeList?.map((timeSlot) => {
                    if (timeSlot.type === FutureFulfillmentType.ServiceGap) {
                      return {
                        label: '----------',
                        value: '',
                        disabled: true
                      }
                    }
                    return {
                      label: formatHours(timeSlot.time, timeZoneId, false),
                      value: timeSlot.time
                    }
                  }) || []
                }
                value={values.fulfillmentTime}
                onChange={(value: any) =>
                  setFieldValue('fulfillmentTime', value)
                }
              />
            </div>
            {errorMsg && <HelperText invalid errorText={errorMsg} />}
          </div>
        )}
    </div>
  )
}
