import { Field } from 'formik'
import styles from './CheckoutForm.module.css'
import { Fieldset } from '@local/do-secundo-fieldset'
import * as CustomerInfo from './CustomerInfo/CustomerInfo'
import { CheckoutDeliveryInfo } from './CheckoutDeliveryInfo/CheckoutDeliveryInfo'
import { DynamicFormSection } from '../DynamicForms/DynamicFormSection'
import { PromoCodeInput } from './PromoCode/PromoCodeInput'
import { TaxExempt } from './TaxExempt/TaxExempt'
import { getArgsForSubmit as getTipArgsForSubmit } from './Tip/Tip'
import cx from 'classnames'
import { CreditCard } from '../CreditCard/CreditCard'
import { LegalCopy } from '../LegalCopy/LegalCopy'
import { CheckoutError } from '../CheckoutError/CheckoutError'
import { Button, ButtonType, ButtonVariant } from '@local/do-secundo-button'
import { PlaceOrderDegradedMessage } from './PlaceOrderDegradedMessage/PlaceOrderDegradedMessage'
import React from 'react'
import { EventType } from '../../types/form'
import { Customer } from './CustomerInfo/CustomerInfo'
import { CheckoutFormValues } from './utils'
import { useRestaurant } from '@local/do-secundo-restaurant-provider/src'
import { useGetCart } from '../CartQuery/CartQuery'
import { Radio } from '@local/do-secundo-form'
import { getCheckTotal } from '../../utils/cart-helpers'
import { LoyaltyAccountLookup } from './LoyaltyAccountLookup'
import { CartCheckoutFields } from './CartCheckoutFields'
import { CreditCardFormSpi } from '../CreditCard/CreditCardFormSpi'
import { FF, useFlag } from '@local/do-secundo-feature-flag'

export const CheckoutFormBody = ({
  eventType,
  handleSubmit,
  customer,
  values,
  isSubmitting,
  canCheckout,
  error,
  isPlaceOrderDegraded
}: {
  eventType: EventType | undefined
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void
  customer: Customer
  values: CheckoutFormValues
  isSubmitting: boolean
  canCheckout: boolean
  error?: Error | string
  isPlaceOrderDegraded: boolean
}) => {
  const spiEnabled = useFlag(FF.NVS_COO_SPI)

  const { cart } = useGetCart()
  const { restaurantInfo, ooConfig } = useRestaurant()

  const canShowTip = values.paymentType === 'CREDIT' && cart?.allowTipping
  const tipAmount = canShowTip ? getTipArgsForSubmit({ values }).tipAmount : 0

  const isPayLaterAllowed = Boolean(ooConfig?.payLaterEnabled)

  if (!cart) {
    return null
  }

  const showPaymentOptions = getCheckTotal(cart) > 0

  return (
    <>
      <CustomerInfo.CustomerInfo
        cart={cart}
        customer={customer}
        companyName={values.companyName}
      />
      <CheckoutDeliveryInfo />
      {eventType && (
        <Fieldset label='Details'>
          <DynamicFormSection formFields={eventType.formFields} />
        </Fieldset>
      )}
      <LoyaltyAccountLookup cart={cart} customer={customer} />
      <Fieldset
        label='Payment Information'
        collapsable
        id='payment_type_fields'
      >
        <PromoCodeInput cart={cart} />
        <TaxExempt cart={cart} />
        {showPaymentOptions && (
          <>
            {isPayLaterAllowed && (
              <div className={styles['radio-tabs']}>
                <Field
                  data-testid='payment-type-CC'
                  style='tab'
                  component={Radio}
                  name='paymentType'
                  id='payment_type_cc'
                  label='Credit card'
                  value='CREDIT'
                />
                <Field
                  data-testid='payment-type-other'
                  style='tab'
                  component={Radio}
                  name='paymentType'
                  id='payment_type_other'
                  label='Other'
                  value='OTHER'
                />
              </div>
            )}
            {values.paymentType === 'OTHER' && (
              <div className='pt-2'>
                {`${
                  restaurantInfo?.name || 'The restaurant'
                } will follow up with payment options for this order.`}
              </div>
            )}
            <div
              className={cx({
                [styles.hidden]: values.paymentType !== 'CREDIT'
              })}
            >
              {spiEnabled && (
                <Field
                  component={CreditCardFormSpi}
                  name='isPaymentInfoValid'
                  cartGuid={cart.guid}
                  amount={getCheckTotal(cart)}
                  tipAmount={tipAmount}
                  email={customer.email}
                />
              )}
              {!spiEnabled && (
                <Field
                  component={CreditCard}
                  name='encryptedCard'
                  amexAccepted={restaurantInfo?.amexAccepted}
                />
              )}
            </div>
          </>
        )}
      </Fieldset>
      <CartCheckoutFields
        values={values}
        customer={customer}
        cart={cart}
        showTip={Boolean(canShowTip)}
      />
      {error && (
        <div className='px-5 pb-0'>
          <CheckoutError
            loading={isSubmitting}
            error={error}
            onSubmit={handleSubmit}
            cart={cart}
          />
        </div>
      )}
      <div className={styles.wrapper}>
        <Button
          data-testid='submit-button'
          id='submit-button'
          variant={ButtonVariant.PRIMARY}
          type={ButtonType.SUBMIT}
          loading={isSubmitting}
          disabled={!canCheckout}
        >
          Place order
        </Button>
        <LegalCopy />
        <div className='h-6' />
      </div>
      {isPlaceOrderDegraded && (
        <PlaceOrderDegradedMessage
          restaurantPhone={restaurantInfo?.address?.phone}
        />
      )}
    </>
  )
}
