import React, { createContext, useContext, useState } from 'react'
import { NewCardInput } from '../apollo/generated/TakeoutWebGraphQLOperations'

export enum PaymentOption {
  CreditCard = 'CreditCard',
  UponReceipt = 'UponReceipt',
  ApplePay = 'ApplePay',
  PayNow = 'PayNow'
}

export type SelectedCreditCard = {
  newCardSelected: boolean
  savedCardGuid: string | null
}

export type PaymentContextType = {
  paymentOption: PaymentOption | null
  setPaymentOption: (option: PaymentOption | null) => void
  newCreditCard: NewCardInput | null
  setNewCreditCard: (card: NewCardInput | null) => void
  setSaveNewCreditCard: (saveCard: boolean) => void
  selectedCreditCard: SelectedCreditCard | null
  setSelectedCreditCard: (
    card:
      | SelectedCreditCard
      | null
      | ((old: SelectedCreditCard | null) => SelectedCreditCard | null)
  ) => void
}
export type ToastCashInfo = {
  toastCashAvailableAmount: number
  toastCashAccountId: string
}

export const PaymentContext = createContext<PaymentContextType | undefined>(
  undefined
)

export const PaymentContextProvider = (
  props: React.PropsWithChildren<{
    useGiftCardFlow?: boolean
    giftCardAmount?: number
  }>
) => {
  const [paymentOption, setPaymentOption] = useState<PaymentOption | null>(null)
  const [newCreditCard, setNewCreditCard] = useState<NewCardInput | null>(null)
  const [selectedCreditCard, setSelectedCreditCard] =
    useState<SelectedCreditCard | null>(null)

  const [toastCashInfo, setToastCashInfo] =
    React.useState<ToastCashInfo | null>(null)

  return (
    <PaymentContext.Provider
      value={{
        paymentOption,
        setPaymentOption,
        newCreditCard,
        setNewCreditCard,
        setSaveNewCreditCard: (saveCard: boolean) =>
          setNewCreditCard((old) => (old ? { ...old, saveCard } : null)),
        selectedCreditCard,
        setSelectedCreditCard
      }}
    >
      {props.children}
    </PaymentContext.Provider>
  )
}

export const usePayment = () => {
  const context = useContext(PaymentContext)
  if (!context) {
    throw new Error('usePayment must be used within a PaymentContextProvider')
  }

  return context
}

export const useOptionalPayment = () => {
  return useContext(PaymentContext)
}
