import React, { useEffect, useRef } from 'react'

import { motion, useAnimation } from 'framer-motion'

type Props = {
  expanded: boolean
  testid?: string
  className?: string
  slowTransition?: boolean
  onExit?: () => void
}

type AnimatedSectionProps = React.PropsWithChildren<Props>

const AnimatedSection = ({
  expanded,
  testid,
  className,
  slowTransition,
  onExit,
  children
}: AnimatedSectionProps) => {
  const wasOpen = useRef(false)
  const controls = useAnimation()

  useEffect(() => {
    const closeSection = async (sectionWasOpen: boolean) => {
      await controls.start({
        overflow: 'hidden',
        height: 0
      })

      // to preserve the animation and make sure the component is no longer visible (for a11y),
      // mark as display:none only after animation completes
      controls.set({ display: 'none' })

      if (sectionWasOpen && onExit) {
        onExit()
      }
    }

    if (expanded) {
      controls.start({
        height: 'auto',
        transitionEnd: { overflow: 'visible' },
        display: 'block'
      })
    } else {
      closeSection(wasOpen.current)
    }
    wasOpen.current = expanded
  }, [expanded, controls, onExit])

  return (
    <motion.div
      data-testid={testid}
      className={className}
      initial={
        expanded
          ? { overflow: 'visible', height: 'auto', display: 'block' }
          : { overflow: 'hidden', height: 0, display: 'none' }
      }
      animate={controls}
      hidden={!expanded}
      aria-hidden={!expanded}
      transition={{ duration: slowTransition ? 0.8 : 0.5, ease: 'easeInOut' }}
    >
      {children}
    </motion.div>
  )
}

/**
 * AnimatedSection uses framer-motion. During Jest testing, this can make tests unreliable.
 * This mock replaces AnimatedSection with a regular <div>.
 * @see {@link https://toasttab.atlassian.net/browse/WOO-1216} for context.
 * @see jest.setup.tsx for how this mock is applied globally.
 */
export const MockedAnimatedSection = ({
  expanded,
  testid,
  ...props
}: AnimatedSectionProps) => (
  <div {...props} data-testid={testid} hidden={!expanded}>
    {props.children}
  </div>
)

export default AnimatedSection
