import React from 'react'
import { DDIMode } from '../../types/DDIGlobals'
import { QRScannerApp } from './QRScannerApp'
import { AuthProviderPasswordless } from '../AuthProvider/AuthProviderPasswordless'
import OnlineOrderingApp from './OnlineOrderingApp'

const Application = () => {
  return (
    <AuthProviderPasswordless>
      <OnlineOrderingApp />
    </AuthProviderPasswordless>
  )
}

type AppSelectorProps = {
  mode: DDIMode
  uniqueIdentifier?: string
}

export const AppSelector = ({ mode }: AppSelectorProps) => {
  switch (mode) {
    case DDIMode.QR:
      return <QRScannerApp />
    default:
      return <Application />
  }
}
