import React from 'react'
import {
  Address,
  CustomLocation,
  CustomLocationsConfig
} from '../../../types/config'
import { Select, SelectItemContainer } from '@toasttab/buffet-pui-select'
import { useRestaurant } from '@local/do-secundo-restaurant-provider/src'
import { AddIcon } from '@toasttab/buffet-pui-icons'
import { getAddressForLocation } from '../../../utils/address-helpers'
import { FulfillmentFormValues } from '../../../types/fulfillment'
import { DiningOptionBehavior } from '../../../types/cart'

interface Props {
  customLocationsConfig: CustomLocationsConfig | undefined
  values: FulfillmentFormValues
  setValues: (values: FulfillmentFormValues) => void
}

const addressToString = (address: Address | undefined) => {
  if (!address) return undefined

  return getAddressForLocation(address)
}

export const getDefaultCustomLocation = (
  customLocationConfig: CustomLocationsConfig | undefined,
  behavior: DiningOptionBehavior | undefined
): CustomLocation | undefined => {
  if (!customLocationConfig || !behavior) {
    return undefined
  }

  if (
    (behavior === 'TAKE_OUT' && !customLocationConfig.standardTakeout) ||
    (behavior === 'DELIVERY' && !customLocationConfig.standardDelivery)
  ) {
    const byBehavior = customLocationConfig.locations.filter(
      (b) => b.behavior === behavior
    )

    if (byBehavior.length === 1) {
      const defaultLocation = byBehavior[0]
      return {
        ...defaultLocation,
        address: defaultLocation.address
          ? {
              ...defaultLocation.address,
              zipCode: defaultLocation.address?.zip
            }
          : undefined
      }
    }
  }

  return undefined
}

export const CustomLocationPicker = ({
  customLocationsConfig,
  values,
  setValues
}: Props) => {
  const { restaurantInfo } = useRestaurant()

  const { standardFulfillment, diningOptionBehavior, customLocationName } =
    values

  const customLocations =
    customLocationsConfig?.locations.filter(
      (location) => location.behavior === diningOptionBehavior
    ) || []

  const options = customLocations.map((location, index) => ({
    label: location.name,
    subLabel: addressToString(location.address),
    value: index
  }))

  const supportStandardTakeout =
    diningOptionBehavior === 'TAKE_OUT' &&
    customLocationsConfig?.standardTakeout &&
    (restaurantInfo?.name ?? restaurantInfo?.locationName)

  if (supportStandardTakeout) {
    options.unshift({
      label: restaurantInfo!!.name ?? restaurantInfo!!.locationName,
      subLabel: addressToString(restaurantInfo!!.address),
      value: -1
    })
  }

  const supportStandardDelivery =
    customLocationsConfig?.standardDelivery &&
    diningOptionBehavior === 'DELIVERY'

  if (supportStandardDelivery) {
    options.unshift({
      label: 'Enter delivery address',
      subLabel: undefined,
      value: -1
    })
  }

  const getValue = (customLocationName: string | undefined) => {
    let value: number | undefined = undefined

    if (customLocationName) {
      const locationIndex = customLocations.findIndex((location) => {
        return location.name === customLocationName
      })

      if (locationIndex >= 0) {
        value = locationIndex
      }
    } else if (
      (supportStandardTakeout || customLocationsConfig?.standardDelivery) &&
      standardFulfillment
    ) {
      value = -1
    }

    return value
  }

  if (
    !diningOptionBehavior ||
    !customLocationsConfig ||
    customLocations.length === 0
  ) {
    return null
  }

  return (
    <Select
      testId='CustomLocationSelect'
      containerClassName='py-4'
      label={
        diningOptionBehavior === 'TAKE_OUT'
          ? 'Pickup location'
          : 'Delivery location'
      }
      placeholder={'Select one'}
      value={getValue(customLocationName)}
      onChange={(value) => {
        if (value === -1) {
          setValues({
            ...values,
            customLocationName: undefined,
            standardFulfillment: true,
            deliveryInfo: undefined
          })
        } else {
          setValues({
            ...values,
            customLocationName: customLocations[value].name,
            standardFulfillment: false,
            deliveryInfo:
              customLocations[value].behavior === 'DELIVERY'
                ? {
                    ...customLocations[value].address,
                    zipCode: customLocations[value].address?.zip
                  }
                : undefined
          })
        }
      }}
      renderItem={(option) => (
        <SelectItemContainer {...option}>
          {option.item.value === -1 && diningOptionBehavior === 'DELIVERY' ? (
            <div className='text-link font-semibold'>
              <AddIcon />
              {option.item.label}
            </div>
          ) : (
            <div>
              {option.item.label}
              {option.item.subLabel && (
                <div className={'text-secondary type-subhead'}>
                  {option.item.subLabel}
                </div>
              )}
            </div>
          )}
        </SelectItemContainer>
      )}
      options={options}
    />
  )
}
