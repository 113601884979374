import React, { useCallback } from 'react'
import { AuthenticationSource } from '../../auth/auth'
import { PwlessAuthModal } from '../../auth/PwlessAuthModal'
import { usePayment } from '../../account/PaymentContext'

import styles from './CheckoutPage.module.css'

import { Image } from '../Image/Image'
import cx from 'classnames'
import { CheckoutMode, useCustomerAuth } from '../../auth/CustomerAuthContext'

export type SelectCheckoutModeProps = {
  setSelectedCheckoutMode: (value: boolean) => void
}

export const SelectCheckoutMode = ({
  setSelectedCheckoutMode
}: SelectCheckoutModeProps) => {
  const { setCheckoutMode } = useCustomerAuth()

  const selectMode = (mode: CheckoutMode) => {
    setCheckoutMode(mode)
    setSelectedCheckoutMode(true)
  }

  return (
    <div>
      <ToastCheckoutButton selectMode={selectMode} />
      <GuestCheckoutButton selectMode={selectMode} />
    </div>
  )
}

type ButtonProps = {
  selectMode: (mode: CheckoutMode) => void
}

const ToastCheckoutButton = ({ selectMode }: ButtonProps) => {
  const [isOpen, setIsOpen] = React.useState(false)

  const onOpen = () => setIsOpen(true)
  const onClose = () => setIsOpen(false)

  return (
    <div
      className={cx(styles.checkoutMode, 'mb-4')}
      data-testid='expressCheckoutButton'
    >
      <button
        type='button'
        className={styles.toastCheckout}
        onClick={onOpen}
        aria-expanded='false'
        aria-controls='checkoutSection'
      >
        <div className={styles.text}>
          <Image
            alt='Toast logo'
            aria-hidden='true'
            path='icons/toast-logo-filled.svg'
          />{' '}
          Express checkout with Toast
        </div>
      </button>
      <PwlessAuthModal
        isOpen={isOpen}
        onClose={onClose}
        source={AuthenticationSource.ExpressCheckout}
        onSuccessfulLogin={() => selectMode(CheckoutMode.Toast)}
      />
    </div>
  )
}

const GuestCheckoutButton = ({ selectMode }: ButtonProps) => {
  const { setPaymentOption } = usePayment()
  const onButtonClick = useCallback(() => {
    selectMode(CheckoutMode.Guest)
    setPaymentOption(null)
  }, [selectMode, setPaymentOption])

  return (
    <div className={styles.checkoutMode}>
      <button
        type='button'
        data-testid='guestCheckoutButton'
        className={styles.borderedGuestCheckout}
        onClick={onButtonClick}
        aria-expanded='false'
        aria-controls='checkoutSection'
      >
        <div className={styles.text}>Checkout as a guest</div>
      </button>
    </div>
  )
}
