import { ApolloClient } from '@apollo/client'
import { DDIMode } from '../../../types/DDIGlobals'
import { apolloClient as optBffApolloClient } from '../../../apollo/apollo-client'
import { doFederatedApolloClient } from '../../../apollo/do-federated-apollo-client'

const lookupMap = new Map<DDIMode, ApolloClient<any>>([
  // opt-bff
  [DDIMode.MNP, optBffApolloClient],
  [DDIMode.STP, optBffApolloClient],
  [DDIMode.OPT_PREVIEW, optBffApolloClient],
  [DDIMode.OPT, optBffApolloClient],
  [DDIMode.TTS, optBffApolloClient],
  // do-federated gateway
  [DDIMode.QR, doFederatedApolloClient]
])

export const getApolloClientByMode = (mode: DDIMode): ApolloClient<any> => {
  return lookupMap.get(mode) ?? optBffApolloClient
}
