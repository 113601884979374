import React, { FC, useCallback, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import { alertError, alertSuccess } from '../auth/alertUtils'
import { useCustomerAuth } from '../auth/CustomerAuthContext'
import { useApolloClient } from '@apollo/client'
import InputField from '../auth/InputField'
import { ToastLogo } from '../auth/ToastLogo'
import { profileInformationSchema } from '../auth/auth-form-helpers'

import styles from './account.module.css'

import {
  IdentityType,
  useAddGuestIdentityMutation,
  useUpdateBasicInfoMutation
} from './account-mutations'
import VerifyEmailModal from './VerifyEmailModal'
import { Label } from '@toasttab/buffet-pui-input-base'

const toastSuccess = () => {
  alertSuccess('Your name has been updated')
}

const toastFail = () => {
  alertError('There was an error while saving. Please refresh the page.')
}

const toastEmailFailGeneric = () => {
  alertError('There was an error while updating email. Please try again.')
}

interface ProfileInformationForm {
  firstName: string | null | undefined
  lastName: string | null | undefined
  email: string | null | undefined
  phone: string | null | undefined
}

export const ProfileInformation: FC = () => {
  const { customer } = useCustomerAuth()
  const [firstName, setFirstName] = useState(customer?.firstName)
  const [lastName, setLastName] = useState(customer?.lastName)
  const ooClient = useApolloClient()
  const [updateBasicInfo] = useUpdateBasicInfoMutation()
  const [addGuestIdentity] = useAddGuestIdentityMutation()
  const [updatedEmail, setUpdatedEmail] = useState('')

  const [updating, setUpdating] = useState(false)

  const formMethods = useForm<ProfileInformationForm>({
    mode: 'onChange',
    // @ts-ignore
    resolver: yupResolver(profileInformationSchema),
    defaultValues: {
      firstName: customer?.firstName,
      lastName: customer?.lastName,
      email: customer?.email,
      phone: customer?.phone
    }
  })

  useEffect(() => {
    return formMethods.reset({
      firstName: firstName || customer?.firstName,
      lastName: lastName || customer?.lastName,
      email: updatedEmail || customer?.email,
      phone: customer?.phone
    })
  }, [
    formMethods,
    customer,
    formMethods.reset,
    updatedEmail,
    firstName,
    lastName
  ])

  const sendCode = useCallback(
    async (email: string) => {
      return await addGuestIdentity({
        variables: {
          input: {
            identityType: IdentityType.Email,
            identityString: email
          }
        },
        client: ooClient
      })
    },
    [addGuestIdentity, ooClient]
  )

  const updateGuestsBasicInfo = useCallback(
    async (data: any, didUpdateEmail: boolean) => {
      if (
        customer?.firstName !== data.firstName ||
        customer?.lastName !== data.lastName
      ) {
        const phone = data.phone
        const firstName = data.firstName
        const lastName = data.lastName
        try {
          const { data } = await updateBasicInfo({
            variables: {
              input: {
                firstName,
                lastName,
                phone
              }
            },
            client: ooClient
          })
          if (data?.updateBasicInfo.__typename === 'UpdateBasicInfoResponse') {
            toastSuccess()
          } else {
            toastFail()
          }
        } catch (err) {
          toastFail()
        }
      }

      setUpdatedEmail('')

      didUpdateEmail && alertSuccess('Email updated')
    },
    [updateBasicInfo, ooClient, customer]
  )

  const didUpdateGuestsEmail = useCallback(
    async (data: any) => {
      const email = data.email
      if (customer?.email && email !== customer?.email) {
        setUpdating(true)
        try {
          const { data } = await sendCode(email)
          const response = data?.addGuestIdentity
          setUpdating(false)
          switch (response?.__typename) {
            case 'AddGuestIdentitySuccess':
              setUpdatedEmail(email)
              return true
            case 'GuestIdentityAlreadyExistsForCurrentGuestError':
            case 'InvalidEmailError':
            case 'ProfileLockedForDeletionError':
            case 'UnexpectedError':
              alertError(response.message)
              break
          }
        } catch (err) {
          setUpdating(false)
          toastEmailFailGeneric()
        }
      }
      return false
    },
    [customer, sendCode]
  )

  const onSubmit = useCallback(
    async (data: any) => {
      setFirstName(data.firstName)
      setLastName(data.lastName)
      const updatedEmail = await didUpdateGuestsEmail(data)
      if (updatedEmail) return // If guest attempts to update their email, the closing actions on the modal will trigger the basic account updates.
      updateGuestsBasicInfo(data, updatedEmail)
    },
    [didUpdateGuestsEmail, updateGuestsBasicInfo]
  )

  const disabled =
    !(formMethods.formState.isValid && formMethods.formState.isDirty) ||
    formMethods.formState.isSubmitting

  return (
    <div
      className={styles.profileInformation}
      data-testid='ProfileInformation'
      role='form'
    >
      <Label>My Information</Label>
      <div>
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(onSubmit)}>
            <InputField
              id='firstName'
              type='text'
              label='First name'
              required
            />
            <InputField id='lastName' type='text' label='Last name' required />
            <InputField
              id='email'
              type='text'
              label='Email'
              {...{ required: true }}
            />
            <InputField id='phone' type='text' label='Phone' readOnly={true} />
            <button
              type='submit'
              className={styles.submitPhoneButton}
              disabled={disabled}
              aria-live='polite'
              aria-disabled={disabled}
            >
              <div className={styles.text}>
                <>
                  <span>{updating ? `Updating` : `Update`} account on </span>
                  <ToastLogo />
                  <span className={'-ml-3'}>{updating ? `...` : ``}</span>
                </>
              </div>
            </button>
            {updatedEmail && (
              <VerifyEmailModal
                email={updatedEmail}
                onCloseModal={async (didUpdateEmail: boolean) =>
                  updateGuestsBasicInfo(formMethods.getValues(), didUpdateEmail)
                }
                resendCode={sendCode}
              />
            )}
          </form>
        </FormProvider>
      </div>
    </div>
  )
}

export default ProfileInformation
