import React from 'react'

export const ToastTOSAndPrivacy = () => {
  return (
    <>
      Toast&apos;s{' '}
      <a
        href='https://pos.toasttab.com/terms-of-service/#diner-tos'
        rel='noopener noreferrer'
        target='_blank'
      >
        Terms of Service
      </a>{' '}
      and{' '}
      <a
        href='https://pos.toasttab.com/privacy'
        rel='noopener noreferrer'
        target='_blank'
      >
        Privacy Statement
      </a>
    </>
  )
}
export const CAInfo = () => {
  return (
    <>
      Info for CA residents available{' '}
      <a
        href='https://pos.toasttab.com/privacy#addendum-a'
        rel='noopener noreferrer'
        target='_blank'
      >
        here
      </a>
      .
    </>
  )
}
