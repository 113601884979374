/* eslint-disable */
/* Generated by GraphQL Code Generator against pre-production environment */


      export default {
  "possibleTypes": {
    "CheckSelection": [
      "CheckSelectionGuid",
      "CheckSelectionNoGuid"
    ],
    "CompleteProfileCreationResponseOrError": [
      "CompleteProfileCreationResponse",
      "CompleteProfileCreationError"
    ],
    "CreateAccountResponseOrError": [
      "CreateAccountResponse",
      "CreateAccountError"
    ],
    "CreateConfirmedAccountResponseOrError": [
      "AuthenticationResponse",
      "CreateAccountError"
    ],
    "CreatePaymentIntentResponse": [
      "PaymentIntent",
      "PaymentIntentError"
    ],
    "DeleteAddressResponseOrError": [
      "DeleteAddressResponse",
      "DeleteAddressError"
    ],
    "DeleteCreditCardResponseOrError": [
      "DeleteCreditCardResponse",
      "DeleteCreditCardError"
    ],
    "Error": [
      "CompleteProfileCreationError",
      "CreateAccountError",
      "DeleteAddressError",
      "DeleteCreditCardError",
      "GeneralError",
      "GetMenusError",
      "GetPopularItemsError",
      "ImportAccountError",
      "ItemFeedbackSurveyError",
      "LoginError",
      "LoyaltySignupError",
      "MakeCreditCardPrimaryError",
      "OPTCartError",
      "OPTOrderError",
      "OPTPartiesLookupError",
      "OPTPartyError",
      "OPTPartyPaymentError",
      "OPTPartyPaymentErrorV2",
      "OPTSplitCheckPaymentError",
      "PayForCheckError",
      "PlaceOrderCartUpdatedError",
      "PlaceOrderError",
      "PlaceOrderOutOfStockError",
      "RefreshError",
      "ResetPasswordError",
      "SignUpToLoyaltyError",
      "StartProfileCreationError",
      "UpdateAddressError",
      "UpdateBasicInfoError",
      "UpdatePasswordError",
      "ValidateApplePayMerchantError",
      "VerifyEmailError"
    ],
    "FeatureFlagResult": [
      "BooleanFeatureFlagResult",
      "NumericFeatureFlagResult",
      "StringFeatureFlagResult"
    ],
    "GetMenusResponseOrError": [
      "GetMenusResponse",
      "GetMenusError"
    ],
    "GetPopularItemsResponse": [
      "PopularItemsV3",
      "GetPopularItemsError"
    ],
    "ImportAccountResponseOrError": [
      "ImportAccountResponse",
      "ImportAccountError"
    ],
    "Info": [],
    "ItemFeedbackSurveyResponse": [
      "ItemFeedbackSurvey",
      "ItemFeedbackSurveyError"
    ],
    "ItemLevelFeedbackSubmissionsStatus": [
      "ItemLevelFeedbackSubmissionSuccess",
      "ItemLevelFeedbackSubmissionFailure"
    ],
    "LoginResponse": [
      "AuthenticationResponse",
      "MfaChallengeGeneratedResponse",
      "LoginError"
    ],
    "LoyaltySignupResponseOrError": [
      "LoyaltySignupResponse",
      "LoyaltySignupError"
    ],
    "MDSCustomServerNotificationResponse": [
      "MDSServerNotificationResponse",
      "OPTPartyError"
    ],
    "MakeCreditCardPrimaryResponseOrError": [
      "MakeCreditCardPrimaryResponse",
      "MakeCreditCardPrimaryError"
    ],
    "OPTCartResponse": [
      "OPTCart",
      "OPTCartError"
    ],
    "OPTCheckV2": [
      "OPTCheckV2Guid",
      "OPTCheckV2NoGuid",
      "OPTSplitCheckPreview"
    ],
    "OPTCloseoutResponse": [
      "OPTPartyPaymentResponse",
      "OPTPartyPaymentErrorV2",
      "OPTSplitCheckPaymentError"
    ],
    "OPTOrder": [
      "OPTOrderGuid",
      "OPTOrderNoGuid"
    ],
    "OPTPartiesLookupResponse": [
      "OPTParties",
      "OPTPartiesLookupError"
    ],
    "OPTPartyCartResponse": [
      "OPTCart",
      "OPTPartyError"
    ],
    "OPTPartyRefreshResponseV2": [
      "OPTPartyRefreshV2",
      "OPTPartyError"
    ],
    "OPTPartyResponseV2": [
      "OPTPartyV2",
      "OPTPartyError"
    ],
    "OPTPartyStubResponse": [
      "OPTPartyStub",
      "OPTPartyError"
    ],
    "OPTSplitCheckResponse": [
      "OPTSplitCheckPreview",
      "OPTPartyError"
    ],
    "PartyMemberResponse": [
      "OPTPartyMemberV2",
      "OPTPartyError"
    ],
    "PayForCheckResponseOrError": [
      "PayForCheckResponse",
      "PayForCheckError"
    ],
    "PlaceApplePayOrderResponse": [
      "PlaceOrderResponse",
      "PlaceOrderError",
      "PlaceOrderOutOfStockError",
      "PlaceOrderCartUpdatedError"
    ],
    "PlaceOPTOrderResponse": [
      "OPTPayForCheckResponse",
      "OPTOrderError"
    ],
    "RefreshResponse": [
      "AuthenticationResponse",
      "RefreshError"
    ],
    "ResetPasswordResponse": [
      "AuthenticationResponse",
      "ResetPasswordError"
    ],
    "RestaurantOrError": [
      "Restaurant",
      "GeneralError"
    ],
    "SignUpToLoyaltyResponseOrError": [
      "SignUpToLoyaltyResponse",
      "SignUpToLoyaltyError"
    ],
    "StartProfileCreationResponseOrError": [
      "StartProfileCreationResponse",
      "StartProfileCreationError"
    ],
    "UpdateAddressResponseOrError": [
      "UpdateAddressResponse",
      "UpdateAddressError"
    ],
    "UpdateBasicInfoResponseOrError": [
      "UpdateBasicInfoResponse",
      "UpdateBasicInfoError"
    ],
    "UpdatePasswordResponseOrError": [
      "UpdatePasswordResponse",
      "UpdatePasswordError"
    ],
    "UpdatePaymentIntentResponse": [
      "UpdatePaymentIntentSuccess",
      "PaymentIntentError"
    ],
    "ValidateApplePayMerchantResponse": [
      "ValidateApplePayMerchantSuccessResponse",
      "ValidateApplePayMerchantError"
    ],
    "VerifyEmailResponse": [
      "AuthenticationResponse",
      "VerifyEmailError"
    ],
    "Warning": [
      "PlaceOrderWarning"
    ]
  }
}
    
// Generated on 2024-12-05T22:15:31-05:00
