import React, { useEffect, useState } from 'react'
import * as CustomerInfo from './CustomerInfo/CustomerInfo'
import { useFulfillment } from '../FulfillmentProvider/FulfillmentProvider'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import {
  SERVICE_AVAILABILITY,
  SERVICES,
  useServiceAvailability
} from '../ServiceAvailabilityProvider/ServiceAvaialbilityProvider'
import {
  getArgsForSubmit,
  getInitialValues,
  getValidationSchema
} from './utils'
import { useCart } from '@local/do-secundo-cart-provider/src'
import { CheckoutFormBody } from './CheckoutFormBody'
import { useGetCart } from '../CartQuery/CartQuery'
import { useCartValidator } from '../Cart/Validation/CartValidation'
import { Loading, LoadingVariant } from '@local/do-secundo-loading'
import { ErrorComponent } from '@local/do-secundo-error'
import { Form, Formik } from 'formik'
import { getCheckTotal } from '../../utils/cart-helpers'
import styles from './CheckoutForm.module.css'
import { CartCheckoutFields } from './CartCheckoutFields'
import { FF, useFlag } from '@local/do-secundo-feature-flag/src'
import { CheckoutMode, useCustomerAuth } from '../../auth/CustomerAuthContext'
import { SelectCheckoutMode } from '../CheckoutPage/SelectCheckoutMode'

interface Props {
  onSubmit: (...opts: any[]) => Promise<void>
  error?: Error | string
}

export const CheckoutForm: React.FC<Props> = ({ onSubmit, error }) => {
  const pwLessAuthEnabled = useFlag(FF.NVS_COO_PWLESS_AUTH)
  const spiEnabled = useFlag(FF.NVS_COO_SPI)

  const {
    applicableConfigs,
    applicableConfigsLoading,
    applicableConfigsError
  } = useRestaurant()

  const {
    loadingCustomer,
    checkoutMode,
    customer: authedCustomer
  } = useCustomerAuth()

  const [selectedCheckoutMode, setSelectedCheckoutMode] = useState(false)

  useEffect(() => {
    if (!loadingCustomer && checkoutMode === CheckoutMode.Toast) {
      setSelectedCheckoutMode(true)
    }
  }, [loadingCustomer, checkoutMode])

  const fulfillmentContext = useFulfillment()
  const { cart } = useGetCart()
  const { cartValid } = useCartValidator(cart)
  const { willEarnLoyalty, setWillEarnLoyalty } = useCart()

  const { [SERVICES.PLACE_ORDER]: placeOrderService } = useServiceAvailability()

  const isPlaceOrderDegraded = React.useMemo(
    () =>
      placeOrderService &&
      placeOrderService.status === SERVICE_AVAILABILITY.DEGRADED,
    [placeOrderService]
  )

  const eventType = applicableConfigs.eventType

  if (fulfillmentContext.loading || (!eventType && applicableConfigsLoading)) {
    return <Loading variant={LoadingVariant.SECONDARY} />
  }

  if (fulfillmentContext.error || (!eventType && applicableConfigsError)) {
    return (
      <ErrorComponent error={fulfillmentContext.error} isActionable={false} />
    )
  }

  const initialValues = getInitialValues({
    fulfillmentContext,
    formFields: eventType?.formFields || [],
    authedCustomer: authedCustomer,
    customer: cart?.order.checks[0].customer,
    paymentType: getCheckTotal(cart) > 0 ? 'CREDIT' : 'OTHER',
    preComputedTips: cart?.preComputedTips,
    companyName: cart?.metadata?.companyName || ''
  })

  const validationSchema = getValidationSchema({
    fulfillmentContext,
    formFields: eventType?.formFields || [],
    useSpiForPayment: spiEnabled
  })

  // See this regarding custom validate prop instead of validationSchema so
  // that we can pass custom context to yup.
  // https://github.com/jaredpalmer/formik/issues/1359
  return (
    <Formik
      enableReinitialize
      data-testid='checkout-form'
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          const submitArgs = getArgsForSubmit({
            fulfillmentContext,
            values,
            eventType: eventType!!
          })

          // since we enroll loyalty asynchronously, we will use this to show
          // the confirmation before applied loyalty appears on the order
          setWillEarnLoyalty(
            willEarnLoyalty || Boolean(submitArgs.loyaltyEnroll)
          )

          await onSubmit(submitArgs)
        } catch (e) {
          console.warn('onSubmit', e)
        } finally {
          setSubmitting(false)
        }
      }}
    >
      {({ values, isSubmitting, isValid: isFormValid, handleSubmit }) => {
        const customer = CustomerInfo.getArgsForSubmit({ values }).customer
        return (
          <Form className={styles.form} onSubmit={handleSubmit}>
            {pwLessAuthEnabled &&
            !selectedCheckoutMode &&
            checkoutMode != CheckoutMode.Toast ? (
              <>
                <CartCheckoutFields
                  values={values}
                  customer={customer}
                  cart={cart}
                  showTip={false}
                />
                <SelectCheckoutMode
                  setSelectedCheckoutMode={setSelectedCheckoutMode}
                />
              </>
            ) : (
              <CheckoutFormBody
                eventType={eventType}
                handleSubmit={handleSubmit}
                customer={CustomerInfo.getArgsForSubmit({ values }).customer}
                values={values}
                isSubmitting={isSubmitting}
                canCheckout={
                  isFormValid && !fulfillmentContext.updating && cartValid
                }
                isPlaceOrderDegraded={isPlaceOrderDegraded}
                error={error}
              />
            )}
          </Form>
        )
      }}
    </Formik>
  )
}
