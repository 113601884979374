import trimEnd from 'lodash/trimEnd'
import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  defaultDataIdFromObject
} from '@apollo/client'
import envFromHostname from '@toasttab/env-from-hostname'
import { getHttpLink } from './links/http-link'
import { getErrorLink } from './links/error-link'
import { getRestLink } from './links/rest-link'
import { getRetryLinkFactory } from './links/retry-link'
import PossibleTypesResult from '../apollo/generated/PossibleTypes'
import { menuResolvers } from './menu/menu-resolvers'
import { geolocationResolvers } from './geolocation/geolocation-resolvers'
import { managementGroupResolvers } from './management-group/management-group-resolvers'
import { GET_ACTIVE_MENU } from './menu/menus-client.graphql'
import {
  getGiaAuthLink,
  securityTokenHeaderLinkFactory
} from './apollo-client-utils'

export const defaultData = {
  query: GET_ACTIVE_MENU,
  data: {
    activeMenu: window.location.hash.split('#')[1] || null
  }
}

const doNotCacheThisType = () => null
const cachePromoBanner = (object) => `${object.bannerGuid}${object.__typename}`

const dataIdFromObjectMap = {
  CompletedOrder: defaultDataIdFromObject,
  Menu: defaultDataIdFromObject,
  MenuItem: (object) => `${object.guid}${object.itemGroupGuid || ''}`,
  MenuGroup: defaultDataIdFromObject,
  SelectionItem: doNotCacheThisType,
  SelectionModifierGroup: doNotCacheThisType,
  SelectionModifier: doNotCacheThisType,
  ModifierGroup: doNotCacheThisType,
  Modifier: doNotCacheThisType,
  // CartResponse and Cart share a GUID, so we need a way
  // to differentiate the two responses
  CartResponse: (object) => object.cart.guid + object.__typename,
  AddressSuggestion: (object) => `${object.latitude}+${object.longitude}`,
  LoyaltyRedemption: (object) => object.redemptionGuid,
  LoyaltyDiscount: doNotCacheThisType,
  Selection: doNotCacheThisType,
  PromoCodeBanner: cachePromoBanner,
  LoyaltyBanner: cachePromoBanner,
  _default: (object) => object.guid || null
}

export const dataIdFromObject = (object) => {
  const idMapper =
    dataIdFromObjectMap[object.__typename] || dataIdFromObjectMap._default
  return idMapper(object)
}

export const cache = new InMemoryCache({
  dataIdFromObject,
  possibleTypes: PossibleTypesResult.possibleTypes
})
cache.writeQuery(defaultData)
cache.deleteKeyByRegex = (regex) => {
  Object.keys(cache.data.data).forEach((key) => {
    if (key.match(regex)) {
      cache.data.delete(key)
    }
  })
}

const ooGlobals = window.OO_GLOBALS || {}
const enableRetries = false
const env = envFromHostname(window.location.hostname)
// TODO Need to change this to support sandbox once the BFF can be deployed there.
// https://toasttab.atlassian.net/browse/OMG-328
const prefix =
  env === 'prod'
    ? 'ws-api'
    : env === 'preprod'
    ? 'ws-preprod-api.eng'
    : 'ws-dev-api.eng'

export const resolvers = [
  menuResolvers,
  geolocationResolvers,
  managementGroupResolvers
]

export const config = {}
config.cache = cache
config.GATEWAY_BASE_URI = trimEnd(
  process.env.GATEWAY_BASE_URI ||
    ooGlobals.gatewayBaseUri ||
    `https://${prefix}.toasttab.com`,
  '/'
)
config.BFF_BASE_URI = trimEnd(
  process.env.BFF_BASE_URI ||
    ooGlobals.bffBaseUri ||
    `${config.GATEWAY_BASE_URI}/do-federated-gateway/v1`,
  '/'
)
config.OO_BASE_URI = trimEnd(
  process.env.OO_BASE_URI ||
    ooGlobals.ooBaseUri ||
    `${config.GATEWAY_BASE_URI}/online-ordering/v1`,
  '/'
)
config.RESTAURANT_GUID = ooGlobals.restaurantGuid || ''

const linkFactories = [
  getRestLink,
  getRetryLinkFactory(enableRetries),
  getErrorLink,
  getHttpLink
]

const getApolloClientOptionsLink = (apolloLinks) => {
  const link = ApolloLink.from(apolloLinks.map((fn) => fn(config)))

  const sessionToken = ooGlobals?.session?.id
  const sessionLink = sessionToken
    ? securityTokenHeaderLinkFactory(sessionToken)
    : null

  const authLink = getGiaAuthLink()
  const secLink = sessionLink ? authLink.concat(sessionLink) : authLink
  return secLink.concat(link)
}

const apolloClientOptions = {
  link: getApolloClientOptionsLink(linkFactories),
  cache: config.cache,
  resolvers,
  name: process.env.PKG_NAME,
  version: process.env.PKG_VERSION
}

// keeping this so that tests are happy
config.client = new ApolloClient(apolloClientOptions)
config.client.onResetStore(() => {
  cache.writeQuery(defaultData)
})
export const apolloClient = config.client

/**
 * Returns apolloClient for use with PasswordlessAuthentication
 * @returns ApolloClient
 */
export const getApolloClient = () => {
  config.client = new ApolloClient(apolloClientOptions)
  config.client.onResetStore(() => {
    cache.writeQuery(defaultData)
  })

  return config.client
}
