import React, { useEffect, useState } from 'react'
import qs from 'qs'
import { Redirect, RouteChildrenProps } from 'react-router'

import Progress from '../Progress/Progress'
import ErrorComponent from '../Error/Error'
import { useRestaurant } from '../RestaurantProvider/RestaurantProvider'
import ScrollToTop from '../ScrollToTop/ScrollToTop'
import Link from '../Link/Link'
import GuestFeedback from '../GuestFeedback/GuestFeedback'
import { useParty } from '../PartyProvider/PartyProvider'
import {
  useGetPartyRefresh,
  useGetAllChecks,
  useGetIsServerSplitCheck,
  useGetPartyMode,
  useGetMainCheck
} from '../PartyQuery/PartyQuery'
import { CTA } from '@/il8n/en'
import { useTabEnabled } from '../../hooks/tabs/useTabEnabled'
import { combineChecks } from '../../utils/check-helpers'
import { CheckBreakdownAccordionV2 } from '../CloseOrderTab/CheckBreakdownAccordionV2'
import { useAvailability } from '../../utils/availability'
import { useRestaurantInfo } from '../../hooks/restaurant-info/use-restaurant-info'
import { LoyaltyEarnedNotification } from '../Loyalty/LoyaltyEarnedNotification'
import { LoyaltyForm } from '../Loyalty/LoyaltyForm'
import { LoyaltyMode } from '../../hooks/place-order/v2/use-handle-party-closeout'
import { useFlag } from '../FeatureFlag/use-flag'
import { LDFlags } from '../../launchdarkly/flags'
import { Button } from '@toasttab/buffet-pui-buttons'
import { PartyInviteButton } from '../PartyInviteButton/PartyInviteButton'
import { ConfirmPageHeader } from './ConfirmPageHeader/ConfirmPageHeader'
import { DDIMode } from '../../types/DDIGlobals'

import styles from './ConfirmPage.module.css'
import { track } from '@toasttab/do-secundo-analytics'
import { ShowForUS } from '../ShowForUS/ShowForUS'
import { TakeoutCTA } from '../TakeoutCTA/TakeoutCTA'
import { SELECTED_TIP } from '../CheckoutForm/Tip/TipComponent/helpers'
import { ErrorBoundary } from '../Error/ErrorBoundary/ErrorBoundary'
import { useSplitPaymentsEnabled } from '../../hooks/split-payments/use-split-payments-enabled'
import { FormatCurrency } from '../Currency/FormatCurrency'
import { isTaxInclusive } from '../../utils/tax-inclusive'

type ConfirmPageParams = { orderGuid?: string; checkGuid?: string }
type ConfirmPageRouteProps = RouteChildrenProps<ConfirmPageParams>

const ItemFeedbackSurveyLazy = React.lazy(() => {
  // @ts-ignore
  return import('./ItemFeedbackSurveyWrapper')
})

const RemainingBalanceMessage = () => {
  const { partyRefresh, refetch, loading } = useGetPartyRefresh({
    notifyOnNetworkStatusChange: true
  })
  const { mainCheck } = useGetMainCheck()
  const [canRefetchParty, setCanRefetchParty] = useState(true)
  const isServerSplitCheck = useGetIsServerSplitCheck()
  const { data: restaurantInfo } = useRestaurantInfo()

  const checkPreDiscountedSubtotal = mainCheck?.preDiscountedSubtotal ?? 0.0
  const checkTax = mainCheck?.tax ?? 0.0

  const remainingBalance = isTaxInclusive(restaurantInfo)
    ? checkPreDiscountedSubtotal + checkTax
    : checkPreDiscountedSubtotal

  const completedOrder = partyRefresh?.order

  // make refetchable after 5 seconds
  useEffect(() => {
    if (!canRefetchParty) {
      const timeout = setTimeout(() => {
        setCanRefetchParty(true)
      }, 5000)
      // cleanup function if unmounted
      return () => {
        clearTimeout(timeout)
      }
    }
  }, [canRefetchParty])

  const handleRefetch = React.useCallback(() => {
    track('refreshedRemainingGroupBalance')
    setCanRefetchParty(false)
    refetch()
  }, [refetch])

  if (!completedOrder) {
    return null
  }

  // If the checks appear to have been split by the server,
  // do not show remaining balance for the rest of the table
  if (!completedOrder.isClosed && !isServerSplitCheck) {
    return (
      <div data-testid='remaining-balance' className='text-center '>
        <p>Remaining group balance</p>
        {loading ? (
          <div className='p-4'>
            <Progress variant='initialHeight' />
          </div>
        ) : (
          <h2 className='pt-1 font-medium'>
            <FormatCurrency amount={remainingBalance} />
          </h2>
        )}
        <Button
          variant='text-link'
          disabled={!canRefetchParty}
          className='-mt-1'
          testId='remaining-balance-refresh-button'
          onClick={() => canRefetchParty && handleRefetch()}
        >
          Refresh
        </Button>
      </div>
    )
  }
  return null
}

export const ConfirmPageContents = () => {
  const splitPaymentsEnabled = useSplitPaymentsEnabled()
  const { deletePartyProperties } = useParty()
  const { getRestaurantPath, restaurantGuid } = useRestaurant()
  // data fetching
  const { partyRefresh, refetch } = useGetPartyRefresh()
  const { allChecks, loading, error } = useGetAllChecks()
  const { data: restaurantInfo } = useRestaurantInfo()
  const { name: restaurantName } = restaurantInfo ?? {}

  const completedOrder = partyRefresh?.order
  const party = partyRefresh?.party

  const checkToShow = combineChecks(allChecks)
  useEffect(() => {
    if (!loading && !error) {
      track('confirmPage', {
        restaurantGuid: restaurantGuid ?? 'undefined'
      })
    }
  }, [loading, error, restaurantGuid])

  if (loading) {
    return <Progress />
  }

  return (
    <>
      {error && (
        <>
          <ErrorComponent error={error} retry={refetch} />
          <div
            data-testid='order-more-items-1'
            className={styles.orderMoreButton}
          >
            <Link
              type='link'
              variant='primaryWide'
              to={getRestaurantPath()}
              onClick={deletePartyProperties}
            >
              {CTA.ORDER_MORE}
            </Link>
          </div>
        </>
      )}
      <ConfirmPageHeader party={party} completedOrder={completedOrder} />
      <div>
        <RemainingBalanceMessage />
        {splitPaymentsEnabled && !partyRefresh?.order?.isClosed && (
          <div className='flex flex-col w-full px-5 mt-3 space-y-4'>
            <PartyInviteButton
              button={
                <Button
                  variant='primary'
                  className='w-full border-solid'
                  testId='confirm-party-invite-button'
                >
                  Invite others to pay
                </Button>
              }
              whiteLabelName={restaurantName}
            />
          </div>
        )}
      </div>
      {checkToShow && <CheckBreakdownAccordionV2 />}
    </>
  )
}

const ConfirmPage: React.FC<ConfirmPageRouteProps> = (props) => {
  const { data: restaurantInfo } = useRestaurantInfo()

  const loyaltyConfig = restaurantInfo?.loyaltyConfig

  const { getRestaurantPath, restaurantGuid } = useRestaurant()
  const { deletePartyProperties } = useParty()
  // features/modules enabled
  const tabEnabled = useTabEnabled()
  const { orderingAvailable } = useAvailability()
  const { mode } = useGetPartyMode()
  const isCTAFlagEnabled = useFlag(LDFlags.OPT_TOAST_TAKEOUT_CTA)
  const showTakeoutAppCTA =
    isCTAFlagEnabled &&
    restaurantInfo?.consumerCTAEnabled &&
    mode === DDIMode.OPT

  const orderGuid = props.match?.params.orderGuid
  const checkGuid = props.match?.params.checkGuid
  const searchString = props.location.search

  const loyaltyMode =
    qs.parse(searchString, { ignoreQueryPrefix: true }).loyaltyMode ||
    LoyaltyMode.none

  React.useEffect(() => {
    window.sessionStorage.removeItem(SELECTED_TIP)
  }, [])

  const showLoyaltySignup =
    loyaltyConfig?.loyaltySignupEnabled &&
    loyaltyConfig?.signupMethod === 'EMAIL' &&
    restaurantGuid &&
    checkGuid &&
    loyaltyMode === LoyaltyMode.signup

  const showLoyaltyEarned =
    Boolean(loyaltyConfig?.signupMethod) &&
    loyaltyConfig?.loyaltySignupEnabled &&
    loyaltyMode === LoyaltyMode.earned

  // on TTS, guests cannot start a new order from the app, they would need a new receipt printed out
  const hideNewOrderCtaForTts = tabEnabled && mode === DDIMode.TTS

  return (
    <div className={styles.confirmPage}>
      <ScrollToTop />
      <>
        <div>
          <ConfirmPageContents />
          {showTakeoutAppCTA && <TakeoutCTA />}
          {orderingAvailable && !hideNewOrderCtaForTts && (
            <div
              data-testid='order-more-items-2'
              className={styles.orderMoreButton}
            >
              <Link
                type='link'
                variant='secondaryWide'
                to={getRestaurantPath()}
                onClick={deletePartyProperties}
              >
                {tabEnabled ? CTA.CONFIRM_NEW_ORDER_CREATED : CTA.ORDER_MORE}
              </Link>
            </div>
          )}
          {/* Okay to simply not render this component if lazy fetching fails. Sentry will log the error. */}
          <ErrorBoundary>
            <React.Suspense fallback={null}>
              <ItemFeedbackSurveyLazy />
            </React.Suspense>
          </ErrorBoundary>
          {showLoyaltySignup && (
            <LoyaltyForm
              checkGuid={checkGuid}
              restaurantGuid={restaurantGuid}
            />
          )}
          {showLoyaltyEarned && (
            <LoyaltyEarnedNotification restaurant={restaurantInfo} />
          )}
          <ShowForUS>
            <GuestFeedback orderGuid={orderGuid} />
          </ShowForUS>
        </div>
      </>
    </div>
  )
}

/**
 * Super basic guard to prevent confirm page from being shown to
 * users no longer in a party session.
 */
const ConfirmPageGuarded: React.FC<ConfirmPageRouteProps> = (props) => {
  const { partyGuid } = useParty()
  const { getRestaurantPath } = useRestaurant()
  // redirect if no party present
  if (!partyGuid) {
    return <Redirect to={getRestaurantPath('/')} />
  } else {
    return <ConfirmPage {...props} />
  }
}

export default ConfirmPageGuarded
