import { RegistrationSource } from '../apollo/generated/TakeoutWebGraphQLOperations'
import {
  AuthedCustomerQuery,
  AuthedCustomerQueryVariables
} from './CustomerAuthContext'

import * as ApolloReactHooks from '@apollo/client'
import { gql } from '@apollo/client'

export enum AuthenticationStatus {
  Authenticated = 'Authenticated',
  Guest = 'Guest'
}

export enum AuthenticationSource {
  AccountCreation = 'Create a Toast account',
  UserNav = 'User nav',
  ExpressCheckout = 'Express checkout with Toast',
  GuestCheckout = 'Guest checkout page'
}

export const getRegistrationSource = () => {
  return RegistrationSource.Catering
}

const defaultOptions = { errorPolicy: 'all' } as const

export const CustomerFieldsFragmentDoc = gql`
  fragment CustomerFields on Customer {
    id: guid
    lastName
    firstName
    phone
    email
    creditCards {
      guid
      cardType
      maskedPan
      expirationMonth
      expirationYear
      isPrimary
    }
    addresses {
      guid
      name
      deliveryInfo {
        address1
        address2
        city
        state
        zipCode
        latitude
        longitude
        notes
      }
    }
  }
`

export const CustomerDocument = gql`
  query Customer {
    customer {
      ...CustomerFields
    }
  }
  ${CustomerFieldsFragmentDoc}
`

export function useAuthedCustomerQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AuthedCustomerQuery,
    AuthedCustomerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    AuthedCustomerQuery,
    AuthedCustomerQueryVariables
  >(CustomerDocument, options)
}
