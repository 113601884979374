import React from 'react'
import { getCreditCardImage } from './SavedCreditCards'

import styles from './account.module.css'

type SavedCreditCardProps = {
  maskedPan: string
  expirationMonth?: string | null
  expirationYear?: string | null
  cardType?: string
}

export const SavedCreditCard = ({
  maskedPan,
  expirationMonth,
  expirationYear,
  cardType
}: SavedCreditCardProps) => {
  return (
    <div className={styles.savedCardLabel}>
      {getCreditCardImage(cardType)}
      <div>{maskedPan.replaceAll('X', '•').replace('-', ' ')}</div>
      <div className={styles.expiration}>
        Exp. {expirationMonth}/{expirationYear}
      </div>
    </div>
  )
}
