import { getImageUrl } from '../RestaurantHeader/RestaurantLogo'
import { brandingPublicAssetURL } from '../../api/util'
import React from 'react'

export type ImageProps = {
  path: string
  testId?: string
  alt: string
  className?: string
}

export const Image = ({ path, testId, alt, className }: ImageProps) => {
  const src = getImageUrl(brandingPublicAssetURL, path)

  return <img className={className} data-testid={testId} src={src} alt={alt} />
}
