import React, { useCallback, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { alertError } from '../auth/alertUtils'
import {
  ConfirmGuestIdentityMutation,
  IdentityType,
  useConfirmGuestIdentityMutation
} from './account-mutations'
import { onCompleteProps } from '../auth/ConfirmCode'
import { CustomerDocument } from '../auth/auth'
import { ConfirmationCodeModal } from './ConfirmationCodeModal'

interface HandleResponseProps {
  response: ConfirmGuestIdentityMutation | null | undefined
  setError: (err: string) => void
}

interface VerifyEmailModalProps {
  email: string
  onCloseModal: (didUpdateEmail: boolean) => Promise<void>
  resendCode: (email: string) => void
}

const VerifyEmailModal = ({
  email,
  onCloseModal,
  resendCode
}: VerifyEmailModalProps) => {
  const apolloClient = useApolloClient()
  const combinedOnClose = useCallback(
    (didUpdateEmail: boolean) => {
      onCloseModal(didUpdateEmail)
    },
    [onCloseModal]
  )

  const handleResponse = useCallback(
    async ({ response, setError }: HandleResponseProps) => {
      const confirmGuestIdentityResult = response?.confirmGuestIdentity
      switch (confirmGuestIdentityResult?.__typename) {
        case 'ConfirmGuestIdentitySuccess':
          setError('')
          combinedOnClose(true)
          break
        case 'IncorrectCodeError':
        case 'CodeExpiredError':
        case 'CodeMaxAttemptsReachedError':
          setError(confirmGuestIdentityResult?.message)
          break
        case 'GuestIdentityAlreadyExistsForAnotherGuestError':
        case 'CodeAuthenticationError':
        case 'UnexpectedConfirmCodeError':
          alertError(confirmGuestIdentityResult?.message)
          combinedOnClose(false)
          break
      }
    },
    [combinedOnClose]
  )

  const [confirmGuestIdentity] = useConfirmGuestIdentityMutation({
    refetchQueries: [{ query: CustomerDocument }],
    awaitRefetchQueries: true
  })

  const onComplete = async ({ code, setError }: onCompleteProps) => {
    const { data } = await confirmGuestIdentity({
      variables: {
        input: {
          code,
          identityType: IdentityType.Email,
          identityString: email
        }
      },
      client: apolloClient
    })
    return handleResponse({ response: data, setError })
  }

  return (
    <ConfirmationCodeModal
      onComplete={onComplete}
      identity={email}
      isOpen={true}
      onClose={() => combinedOnClose(false)}
      sendCode={() => resendCode(email)}
    />
  )
}

export default VerifyEmailModal
