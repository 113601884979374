import React from 'react'

const LogoutIcon = ({ color }: { color: string }) => (
  <svg
    className='logoutIcon'
    width='22'
    height='22'
    viewBox='0 0 22 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    {/* eslint-disable-next-line max-len */}
    <path
      id='Vector'
      d='M-2.44833e-05 17.3291L-2.33593e-05 4.47185C-2.32513e-05 3.2362 0.742798 0.900389 3.57143 0.900389L16.4287 0.90039C17.6643 0.90039 20.0001 1.64321 20.0001 4.47185L20.0001 6.61472C20.0001 7.00758 19.6787 7.32901 19.2858 7.32901C18.893 7.32901 18.5716 7.00758 18.5716 6.61472L18.5716 4.47185C18.5716 2.45746 16.7858 2.33616 16.4215 2.32897L3.5716 2.32897C1.55721 2.32897 1.43591 4.1147 1.42872 4.47903L1.42872 17.3289C1.42872 19.3433 3.21445 19.4646 3.57878 19.4718L16.4287 19.4718C18.4431 19.4718 18.5644 17.6861 18.5716 17.3217L18.5716 15.1861C18.5716 14.7932 18.893 14.4718 19.2858 14.4718C19.6787 14.4718 20.0001 14.7932 20.0001 15.1861L20.0001 17.3289C20.0001 18.5646 19.2573 20.9004 16.4287 20.9004L3.57143 20.9004C2.33579 20.9004 -2.47306e-05 20.1576 -2.44833e-05 17.3289L-2.44833e-05 17.3291ZM10.3217 15.779C10.6503 16.0005 11.093 15.9075 11.3073 15.5791C11.5288 15.2576 11.4358 14.8077 11.1074 14.5934C9.62175 13.6006 8.2217 12.4077 7.55729 11.6147L19.286 11.6147C19.6788 11.6147 20.0002 11.2933 20.0002 10.9005C20.0002 10.5076 19.6788 10.1862 19.286 10.1862L7.55729 10.1862C8.2215 9.39326 9.62155 8.20051 11.1074 7.20749C11.436 6.9932 11.5288 6.54328 11.3073 6.22184C11.1717 6.01473 10.9432 5.90041 10.7146 5.90041C10.5789 5.90041 10.4432 5.93613 10.3217 6.02191C8.93601 6.9433 5.71431 9.26475 5.71431 10.9003C5.71431 12.5359 8.9358 14.8575 10.3217 15.7787L10.3217 15.779Z'
      fill={color}
    />
  </svg>
)

export default LogoutIcon
