import React, { useCallback, useState } from 'react'
import { Loading, LoadingVariant } from '@local/do-secundo-loading'
import { useCustomerAuth } from './CustomerAuthContext'

import styles from './auth.module.css'
import { OtpInput } from 'reactjs-otp-input'
import classnames from 'classnames'

const CONFIRMATION_CODE_LENGTH = 6

export type onCompleteProps = {
  identity: string
  code: string
  setError: (err: string) => void
}

type ConfirmCodeProps = {
  identity: string
  onComplete: ({ identity, code, setError }: onCompleteProps) => Promise<any>
  sendCode?: () => void
}

export const ConfirmCodeForm = ({
  identity,
  onComplete,
  sendCode
}: ConfirmCodeProps) => {
  const { loadingCustomer } = useCustomerAuth()

  if (loadingCustomer) {
    return <Loading variant={LoadingVariant.SECONDARY} />
  }

  return (
    <div className={styles.codeForm} role='form'>
      <ConfirmationCodeInputs identity={identity} onComplete={onComplete} />
      {sendCode && (
        <button className={styles.resendCodeButton} onClick={sendCode}>
          Resend code
        </button>
      )}
    </div>
  )
}

interface ConfirmationCodeInputsProps {
  identity: string
  onComplete: ({ identity, code, setError }: onCompleteProps) => Promise<any>
}

export function ConfirmationCodeInputs({
  identity,
  onComplete
}: ConfirmationCodeInputsProps) {
  const { loadingCustomer } = useCustomerAuth()
  const [code, setCode] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  // Reset key to force re-render of OTP input when code is incorrect (resets focus)
  const [reset, setReset] = useState(0)

  const onChangeCode = useCallback(
    async (code: string) => {
      setCode(code)

      if (code.length === CONFIRMATION_CODE_LENGTH) {
        try {
          setLoading(true)
          await onComplete({ identity, code, setError })
        } catch (e) {
          setError('An error occurred. Please try again.')
        } finally {
          setCode('')
          setLoading(false)
          setReset((key) => key + 1)
        }
      }
    },
    [identity, onComplete, setCode]
  )
  return (
    <>
      <OtpInput
        key={reset}
        shouldAutoFocus={true}
        isInputNum={true}
        isDisabled={loadingCustomer || loading}
        containerStyle={classnames(styles.inputField, styles.inputFieldOtp)}
        inputStyle={styles.inputContainer}
        value={code}
        numInputs={CONFIRMATION_CODE_LENGTH}
        onChange={onChangeCode}
      />
      {error && <span className={styles.formError}>{error}</span>}
      {loading && <Loading variant={LoadingVariant.SECONDARY} />}
    </>
  )
}
