import 'regenerator-runtime/runtime'
import * as React from 'react'
import ReactDOM from 'react-dom'
import { App } from './app/App'
import { banquetSingleSpaReact } from 'banquet-runtime-modules'
import './index.css'
import { getSentryConfig } from 'do-cornucopia-root'
import { amplitudeAnalytics as createAmplitudeAnalytics } from '@toasttab/do-secundo-amplitude-analytics'
import { envFromOrderHostname } from '@toasttab/do-secundo-env-from-orders-hostname'

const tempWindow: any = window

// We only use corn-layout-spa for OPT nowadays so we can just look at the order hostname
const env = envFromOrderHostname(window.location.hostname)

const amplitudeApiKey =
  env === 'prod'
    ? 'd2c74425e0f21e96d41c9dc5f5c8ffbe'
    : '4f931568751686a8bd7c64f90c5812b6'

const amplitudeAnalytics = createAmplitudeAnalytics()

amplitudeAnalytics.load(amplitudeApiKey)

tempWindow.toastAnalytics = amplitudeAnalytics

const sentry = getSentryConfig(tempWindow.OO_GLOBALS.mode)

const mountPoint = 'single-spa-application:root'
const reactLifecycles = banquetSingleSpaReact({
  React,
  ReactDOM,
  cssScope: 'corn-layout-spa',
  rootComponent: App,
  sentry,
  domElementGetter: () => {
    const el = document.getElementById(mountPoint)
    if (!el) {
      throw new Error(
        `${mountPoint} does not exist, it is required for layout spas`
      )
    }
    return el
  }
})

export const bootstrap = reactLifecycles.bootstrap
export const mount = reactLifecycles.mount
export const unmount = reactLifecycles.unmount
