import * as ApolloReactHooks from '@apollo/client'
import {
  BasicInfoInput,
  CardType,
  DeleteAccountErrorCode,
  DeleteAddressErrorCode,
  DeleteAddressInput,
  DeleteCreditCardErrorCode,
  DeleteCreditCardInput,
  Exact,
  MakeCreditCardPrimaryErrorCode,
  MakeCreditCardPrimaryInput,
  Scalars,
  UpdateBasicInfoErrorCode
} from '../apollo/generated/TakeoutWebGraphQLOperations'
import { gql } from '@apollo/client'
import { CustomerFieldsFragmentDoc } from '../auth/auth'

const defaultOptions = { errorPolicy: 'all' } as const

export function useUpdateBasicInfoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateBasicInfoMutation,
    UpdateBasicInfoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    UpdateBasicInfoMutation,
    UpdateBasicInfoMutationVariables
  >(UpdateBasicInfoDocument, options)
}

export function useAddGuestIdentityMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddGuestIdentityMutation,
    AddGuestIdentityMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    AddGuestIdentityMutation,
    AddGuestIdentityMutationVariables
  >(AddGuestIdentityDocument, options)
}

export function useConfirmGuestIdentityMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ConfirmGuestIdentityMutation,
    ConfirmGuestIdentityMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    ConfirmGuestIdentityMutation,
    ConfirmGuestIdentityMutationVariables
  >(ConfirmGuestIdentityDocument, options)
}

export function useDeleteCreditCardMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteCreditCardMutation,
    DeleteCreditCardMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    DeleteCreditCardMutation,
    DeleteCreditCardMutationVariables
  >(DeleteCreditCardDocument, options)
}

export function useMakeCreditCardPrimaryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    MakeCreditCardPrimaryMutation,
    MakeCreditCardPrimaryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    MakeCreditCardPrimaryMutation,
    MakeCreditCardPrimaryMutationVariables
  >(MakeCreditCardPrimaryDocument, options)
}

export function useDeleteAccountMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteAccountMutation,
    DeleteAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    DeleteAccountMutation,
    DeleteAccountMutationVariables
  >(DeleteAccountDocument, options)
}

export function useDeleteAddressMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteAddressMutation,
    DeleteAddressMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    DeleteAddressMutation,
    DeleteAddressMutationVariables
  >(DeleteAddressDocument, options)
}

export type DeleteAddressMutationVariables = Exact<{
  input: DeleteAddressInput
}>

export type DeleteAddressMutation = {
  __typename?: 'Mutation'
  deleteAddress:
    | {
        __typename?: 'DeleteAddressError'
        code: DeleteAddressErrorCode
        message: string
      }
    | {
        __typename?: 'DeleteAddressResponse'
        customer: {
          __typename?: 'Customer'
          lastName?: string | null
          firstName?: string | null
          phone?: string | null
          email: string
          id: string
          creditCards: Array<{
            __typename?: 'SavedCreditCard'
            guid: string
            cardType: CardType
            maskedPan: string
            expirationMonth: string
            expirationYear: string
            isPrimary: boolean
          }>
          addresses: Array<{
            __typename?: 'SavedAddress'
            guid: string
            name?: string | null
            deliveryInfo: {
              __typename?: 'DeliveryInfo'
              address1: string
              address2?: string | null
              city: string
              state: string
              zipCode: string
              latitude: number
              longitude: number
              notes?: string | null
            }
          }>
        }
      }
}

export type DeleteAccountMutationVariables = Exact<{ [key: string]: never }>

export type DeleteAccountMutation = {
  __typename?: 'Mutation'
  deleteAccount:
    | {
        __typename?: 'DeleteAccountError'
        code: DeleteAccountErrorCode
        message: string
      }
    | { __typename?: 'DeleteAccountResponse'; success: boolean }
}

export type MakeCreditCardPrimaryMutationVariables = Exact<{
  input: MakeCreditCardPrimaryInput
}>

export type MakeCreditCardPrimaryMutation = {
  __typename?: 'Mutation'
  makeCreditCardPrimary:
    | {
        __typename?: 'MakeCreditCardPrimaryError'
        code: MakeCreditCardPrimaryErrorCode
        message: string
      }
    | {
        __typename?: 'MakeCreditCardPrimaryResponse'
        customer: {
          __typename?: 'Customer'
          lastName?: string | null
          firstName?: string | null
          phone?: string | null
          email: string
          id: string
          creditCards: Array<{
            __typename?: 'SavedCreditCard'
            guid: string
            cardType: CardType
            maskedPan: string
            expirationMonth: string
            expirationYear: string
            isPrimary: boolean
          }>
          addresses: Array<{
            __typename?: 'SavedAddress'
            guid: string
            name?: string | null
            deliveryInfo: {
              __typename?: 'DeliveryInfo'
              address1: string
              address2?: string | null
              city: string
              state: string
              zipCode: string
              latitude: number
              longitude: number
              notes?: string | null
            }
          }>
        }
      }
}

export type DeleteCreditCardMutationVariables = Exact<{
  input: DeleteCreditCardInput
}>

export type DeleteCreditCardMutation = {
  __typename?: 'Mutation'
  deleteCreditCard:
    | {
        __typename?: 'DeleteCreditCardError'
        code: DeleteCreditCardErrorCode
        message: string
      }
    | {
        __typename?: 'DeleteCreditCardResponse'
        customer: {
          __typename?: 'Customer'
          lastName?: string | null
          firstName?: string | null
          phone?: string | null
          email: string
          id: string
          creditCards: Array<{
            __typename?: 'SavedCreditCard'
            guid: string
            cardType: CardType
            maskedPan: string
            expirationMonth: string
            expirationYear: string
            isPrimary: boolean
          }>
          addresses: Array<{
            __typename?: 'SavedAddress'
            guid: string
            name?: string | null
            deliveryInfo: {
              __typename?: 'DeliveryInfo'
              address1: string
              address2?: string | null
              city: string
              state: string
              zipCode: string
              latitude: number
              longitude: number
              notes?: string | null
            }
          }>
        }
      }
}

export type ConfirmGuestIdentityMutationVariables = Exact<{
  input: ConfirmGuestIdentityInput
}>

/** Specifies the identity field to verify for the guest using the code. */
export type ConfirmGuestIdentityInput = {
  code: Scalars['String']
  identityString: Scalars['String']
  identityType: IdentityType
}

export type ConfirmGuestIdentityMutation = {
  __typename?: 'Mutation'
  confirmGuestIdentity:
    | { __typename?: 'CodeAuthenticationError'; message: string }
    | { __typename?: 'CodeExpiredError'; message: string }
    | { __typename?: 'CodeMaxAttemptsReachedError'; message: string }
    | {
        __typename?: 'ConfirmGuestIdentitySuccess'
        guest: { __typename?: 'Guest'; id: string }
      }
    | {
        __typename?: 'GuestIdentityAlreadyExistsForAnotherGuestError'
        message: string
      }
    | { __typename?: 'IncorrectCodeError'; message: string }
    | { __typename?: 'UnexpectedConfirmCodeError'; message: string }
}

export type AddGuestIdentityMutationVariables = Exact<{
  input: AddGuestIdentityInput
}>

/** Specifies the identity field to update for the guest. */
export type AddGuestIdentityInput = {
  identityString: Scalars['String']
  identityType: IdentityType
}

/** The type of identity to add to the guest. */
export enum IdentityType {
  Email = 'EMAIL'
}

export type AddGuestIdentityMutation = {
  __typename?: 'Mutation'
  addGuestIdentity:
    | { __typename?: 'AddGuestIdentitySuccess'; success: boolean }
    | {
        __typename?: 'GuestIdentityAlreadyExistsForCurrentGuestError'
        message: string
      }
    | { __typename?: 'InvalidEmailError'; message: string }
    | { __typename?: 'ProfileLockedForDeletionError'; message: string }
    | { __typename?: 'UnexpectedError'; message: string }
}

export const UpdateBasicInfoDocument = gql`
  mutation UpdateBasicInfo($input: BasicInfoInput!) {
    updateBasicInfo(input: $input) {
      ... on UpdateBasicInfoResponse {
        customer {
          id: guid
          firstName
          lastName
          phone
        }
      }
      ... on UpdateBasicInfoError {
        code
        message
      }
    }
  }
`

export type UpdateBasicInfoMutationVariables = Exact<{
  input: BasicInfoInput
}>

export type UpdateBasicInfoMutation = {
  __typename?: 'Mutation'
  updateBasicInfo:
    | {
        __typename?: 'UpdateBasicInfoError'
        code: UpdateBasicInfoErrorCode
        message: string
      }
    | {
        __typename?: 'UpdateBasicInfoResponse'
        customer: {
          __typename?: 'Customer'
          firstName?: string | null
          lastName?: string | null
          phone?: string | null
          id: string
        }
      }
}

export const AddGuestIdentityDocument = gql`
  mutation AddGuestIdentity($input: AddGuestIdentityInput!) {
    addGuestIdentity(input: $input) {
      ... on AddGuestIdentitySuccess {
        success
      }
      ... on GuestIdentityAlreadyExistsForCurrentGuestError {
        message
      }
      ... on InvalidEmailError {
        message
      }
      ... on ProfileLockedForDeletionError {
        message
      }
      ... on UnexpectedError {
        message
      }
    }
  }
`

export const ConfirmGuestIdentityDocument = gql`
  mutation ConfirmGuestIdentity($input: ConfirmGuestIdentityInput!) {
    confirmGuestIdentity(input: $input) {
      ... on ConfirmGuestIdentitySuccess {
        guest {
          id
        }
      }
      ... on IncorrectCodeError {
        message
      }
      ... on CodeExpiredError {
        message
      }
      ... on CodeAuthenticationError {
        message
      }
      ... on CodeMaxAttemptsReachedError {
        message
      }
      ... on GuestIdentityAlreadyExistsForAnotherGuestError {
        message
      }
      ... on UnexpectedConfirmCodeError {
        message
      }
    }
  }
`

export const DeleteCreditCardDocument = gql`
  mutation DeleteCreditCard($input: DeleteCreditCardInput!) {
    deleteCreditCard(input: $input) {
      ... on DeleteCreditCardResponse {
        customer {
          ...CustomerFields
        }
      }
      ... on DeleteCreditCardError {
        code
        message
      }
    }
  }
  ${CustomerFieldsFragmentDoc}
`

export const MakeCreditCardPrimaryDocument = gql`
  mutation MakeCreditCardPrimary($input: MakeCreditCardPrimaryInput!) {
    makeCreditCardPrimary(input: $input) {
      ... on MakeCreditCardPrimaryResponse {
        customer {
          ...CustomerFields
        }
      }
      ... on MakeCreditCardPrimaryError {
        code
        message
      }
    }
  }
  ${CustomerFieldsFragmentDoc}
`

export const DeleteAccountDocument = gql`
  mutation deleteAccount {
    deleteAccount {
      ... on DeleteAccountResponse {
        success
      }
      ... on DeleteAccountError {
        code
        message
      }
    }
  }
`

export const DeleteAddressDocument = gql`
  mutation DeleteAddress($input: DeleteAddressInput!) {
    deleteAddress(input: $input) {
      ... on DeleteAddressResponse {
        customer {
          ...CustomerFields
        }
      }
      ... on DeleteAddressError {
        code
        message
      }
    }
  }
  ${CustomerFieldsFragmentDoc}
`
