import {
  Cart,
  CartMetadata,
  CartMetadataResponse,
  FulfillmentInfoRequest
} from '../types/cart'
import { FormValue } from '../types/form'
import {
  AppliedDiscount,
  DeliveryInfo,
  ItemSelectionRequest,
  PaymentType,
  SelectionRequest
} from '../types/orders'
import { domain, doDelete, get, patch, post, put, getQueryParams } from './util'
import { InquiryResponse } from '../types/loyalty'
import { UpdateCustomerRequest } from '../components/CheckoutForm/CustomerInfo/CustomerInfo'

export const getCart = (
  cartGuid: string,
  fastLinkName: string | undefined
): Promise<Cart> => {
  return get(
    `https://${domain}/catering/v1/public/carts/${cartGuid}${getQueryParams({
      fastLinkName: fastLinkName
    })}`
  ).then((response) => response.json())
}

interface CreateCartRequest {
  fulfillment: FulfillmentInfoRequest
  selections: SelectionRequest[]
  metadata: CartMetadata
}

export const createCart = (request: CreateCartRequest): Promise<Cart> => {
  return post(`https://${domain}/catering/v1/public/carts`, request).then(
    (response) => response.json()
  )
}

export const addItemToCart = (
  cartGuid: string,
  selection: ItemSelectionRequest
): Promise<Cart> => {
  return post(
    `https://${domain}/catering/v1/public/carts/${cartGuid}/items`,
    selection
  ).then((r) => r.json())
}

export const editItemInCart = (
  cartGuid: string,
  selectionGuid: string,
  selection: ItemSelectionRequest
): Promise<Cart> => {
  return put(
    `https://${domain}/catering/v1/public/carts/${cartGuid}/items/${selectionGuid}`,
    selection
  ).then((r) => r.json())
}

export const deleteItemInCart = (
  cartGuid: string,
  selectionGuid: string,
  fastLinkName: string | undefined
): Promise<Cart> => {
  const path = `https://${domain}/catering/v1/public/carts/${cartGuid}/items/${selectionGuid}${getQueryParams(
    {
      fastLinkName: fastLinkName
    }
  )}`

  return doDelete(path).then((r) => r.json())
}

export const updateFulfillment = (
  cartGuid: string,
  request: FulfillmentInfoRequest
): Promise<Cart> => {
  return patch(
    `https://${domain}/catering/v1/public/carts/${cartGuid}/fulfillment`,
    request
  ).then((r) => r.json())
}

export const updateCustomer = (
  cartGuid: string,
  customer: UpdateCustomerRequest
): Promise<Cart> => {
  return patch(
    `https://${domain}/catering/v1/public/carts/${cartGuid}/customer`,
    customer
  ).then((r) => r.json())
}

export const updateCartMeta = (
  cartGuid: string,
  metadata: CartMetadata
): Promise<Cart> => {
  return patch(
    `https://${domain}/catering/v1/public/carts/${cartGuid}/metadata`,
    metadata
  ).then((r) => r.json())
}

export const getCartMeta = (
  cartGuid: string
): Promise<CartMetadataResponse> => {
  return get(
    `https://${domain}/catering/v1/public/carts/${cartGuid}/metadata`
  ).then((r) => r.json())
}

export const addPromoCode = async (
  cartGuid: string,
  request: { promoCode: string }
): Promise<Cart> => {
  const r = await post(
    `https://${domain}/catering/v1/public/carts/${cartGuid}/promocode`,
    request
  )
  return await r.json()
}

export const setTaxExempt = async (
  cartGuid: string,
  request: { taxExemptId?: string; taxExemptState?: string }
): Promise<Cart> => {
  const r = await post(
    `https://${domain}/catering/v1/public/carts/${cartGuid}/taxexempt`,
    request
  )
  return await r.json()
}

export const removePromoCode = async (cartGuid: string): Promise<Cart> => {
  const r = await doDelete(
    `https://${domain}/catering/v1/public/carts/${cartGuid}/promocode`
  )
  return await r.json()
}

export const loyaltyInquire = async (
  cartGuid: string
): Promise<InquiryResponse> => {
  const r = await get(
    `https://${domain}/catering/v1/public/carts/${cartGuid}/rewards`
  )
  return await r.json()
}

export const addLoyaltyDiscount = async (
  cartGuid: string,
  discount: AppliedDiscount
): Promise<Cart> => {
  const r = await post(
    `https://${domain}/catering/v1/public/carts/${cartGuid}/rewards`,
    discount
  )
  return await r.json()
}

export interface PaymentInput {
  paymentGuid?: string
  paymentType: PaymentType
  tipAmount?: number
}

export interface PlaceOrderRequest {
  formValues: FormValue[]
  paymentInput: PaymentInput
  customer: {
    phoneToken?: string
    firstName?: string
    lastName?: string
    emailToken?: string
    phone?: string
    entityType?: string
    guid?: string
    email?: string
  }
  customerGuid?: string
  deliveryInfo?: DeliveryInfo
  loyaltyEnroll?: boolean
  companyName?: string
  shouldLookupLoyalty?: boolean
  fastLinkName: string | undefined
}

export const placeOrder = (
  cartGuid: string,
  request: PlaceOrderRequest
): Promise<Cart> => {
  return post(
    `https://${domain}/catering/v1/public/carts/${cartGuid}/orders`,
    request
  ).then((r) => r.json())
}
